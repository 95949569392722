import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
//import Materialize from 'materialize-css/dist/js/materialize.min.js'

import { createProduct } from '../../store/actions/productActions';

const CreateProduct = (props) => {      
    const dispatch = useDispatch();

    // useEffect(() => {
    //     Materialize.AutoInit();
    // },[])

    const [componentState, setComponentState] = useState({});

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);     
    const validation = useSelector(state => state.product.validation); 
    const productError = useSelector(state => state.product.productError); 

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/pro'/>;

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value.trim()
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log('props state',componentState);
        dispatch(createProduct(componentState));
    }

    return ( 
        <div className="container product">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">Create Product</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/pro' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="SKU">SKU</label>
                        <input id="sku" onChange={handleChange} type="number" className="validate" required/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Name">Product Name</label>
                        <input id="name" onChange={handleChange} type="text" className="all-cap validate" required/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Description">Description</label>
                        <input id="description" onChange={handleChange} type="text" className="validate" required/>
                    </div>
                </div>
                <div className="input-field">
                    <button className="btn red lighten-1 z-depth-0">Create</button>                        
                    <div className="red-text center">
                        {productError ? <p>{productError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default CreateProduct;