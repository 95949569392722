import React, { useEffect } from 'react'; 
import { Link, Redirect } from 'react-router-dom';
import {  useSelector, useDispatch } from 'react-redux';

import UserList from './UserList';
import { loadUsers, resetValidation } from '../../store/actions/userActions';
import { deleteUser } from '../../store/actions/userActions';
import { loadDivisions } from '../../store/actions/divisionActions';

let fetchData = true;
let timer = null;
const pageInfo = { nameFilter: ''}; 
const pagination = {
    pageNumber: 0,
    limit: 6,
    orderBy: 'asc',
    cursor: ''
};      

const Users = () => {    
    const dispatch = useDispatch();

    useEffect(() => {
        /*User rudex hook to dispatch data fetch */
        if(fetchData){
            dispatch(loadUsers('',pagination,pageInfo));
            dispatch(loadDivisions('',pagination,pageInfo));
        } 
        dispatch(resetValidation());
        fetchData = false;
    });

    /*
    Get input from text fields and apply filtered list 
    Set a time delay of 1 sec to fetch data
    */
    const handleOnChange = (e) => {
        clearTimeout(timer);
        pageInfo.nameFilter = e.target.value;  
        timer = setTimeout(() => {
            triggerChange(pageInfo.nameFilter)
            }, 1000);      
    }

    const triggerChange = (search) => {
        pagination.pageNumber = 0;
        pagination.orderBy = 'asc';        
        pagination.cursor ='';
        dispatch(loadUsers(search, pagination, pageInfo));
    }

    /** Marking user as deleted when user clik delete from user summary */
    const handleDeleteUser = (user, id) => {
        dispatch(deleteUser(user, id));
    }
   
    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);
    const divisions = useSelector(state => state.division.data);
    const data = useSelector(state =>  state.user.data);
    const filteredData = useSelector(state => state.user.filteredData);
    const user = Object.keys(filteredData).length !== 0  ? filteredData : data;
    
    /*Create Array list from object collection */
    const orderedArray = Object.keys(user).map((key) => [key, user[key]]);    
    const ordered_div = Object.keys(divisions).map((key) => [key, divisions[key]]);

    let orderedDiv = [];
    ordered_div.map(item => {    
        orderedDiv.push(item.pop());
        return null;
    }); 
       
    let ordered = [];
    orderedArray.map(item => {  
        ordered.push(item.pop());
        return null;
    });       

    /*Filter out deleted divisions */
    ordered = ordered.filter(item => {
        return item.status !== 'deleted'
    });

    /* Check authentication and redirect to login page */
    if(!auth.uid) return <Redirect to='/signin'/>; 
    return ( 
        <div className="section user">
            <div className="row">                                        
                <div className="col s12">
                    <div className="card">
                        <div className="card-content sec-head">                            
                            <div className="row">
                                <div className="input-field col s12 m6, l6">
                                   <span className="card-title red-text text-lighten-1">
                                        Manage Users
                                    </span>
                                </div>
                                <div className="input-field col s12 m6, l6">
                                    <i className="material-icons prefix">search</i>
                                    <input onChange={handleOnChange} type="text" defaultValue="" placeholder="Search Users"/>
                                </div>
                            </div>
                            <div className="section sec-details">                
                                <UserList users={ordered} divisions={orderedDiv} onDeleteUser = {(user, id) => {handleDeleteUser(user, id)}}/>
                                
                                <div className="fixed-action-btn floting-bottom-left">
                                    <Link to='/crusr' className="btn-floating btn-large halfway-fab waves-effect waves-light red" fab="top"><i className="material-icons">add</i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
     );
}
 
export default Users;
