import React, { useEffect } from 'react';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { ViewButton, EditLinkButton } from '../../utils/components/buttons/LinkButtons';

const SparePartsList = ({ data, onViewCompatible }) => {    
    useEffect(() => {
        Materialize.AutoInit();
    },[]);

    const handleViewCompatible = (sparePart) => {
        console.log(sparePart);
        onViewCompatible(sparePart);
    }

     /* Defactor data from props */
     //const { data } = props;
     console.log('products',data);
     const products = data ? (
        data.map(product => {
             return(
                <tr className="list" key={product.spId}>                           
                    <td>{product.sku}</td>                           
                    <td>{product.modelName}</td>
                    <td>{product.sparePartName}</td>
                    <td>{product.boardName}</td>
                    <td>{product.partsSku}</td>                    
                    <td>{product.location}</td>                          
                    <td className="right">{product.price}</td>           
                    <td className="right">{product.stockQuantity}</td> 
                    <td>
                        <EditLinkButton to={'/spa/' + product.spId} />
                        <ViewButton onClick={() => {handleViewCompatible(product.partsSku)}}  tooltip="Compatible Spare"/>
                    </td>
                </tr>
             );                                
         }) 
     ) : null;
    return ( 
        <div className="table-overflow">
            <table className=" highlight">
                <thead>
                    <tr>
                        <th data-field="id">Model No.</th>
                        <th data-field="modelName">Model Name</th>
                        <th data-field="description">Sapre Part Name</th>
                        <th data-field="boardName">Board Name</th>
                        <th data-field="sapNo">SAP No.</th>
                        <th data-field="location">Location</th>
                        <th className="right" data-field="price">Price</th>
                        <th className="right" data-field="stock">Stock</th>
                        <th data-field="edit" className="icon-head"></th>
                    </tr>
                </thead>

                <tbody>
                    {products}
                </tbody>
            </table>
        </div>        
     );
}
 
export default SparePartsList;