import React from 'react';
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PrintRMAAcknowledgement } from '../../store/actions/rmaActions';

import { DeleteLinkButton, EditLinkButton, PrintButton } from '../../utils/components/buttons/LinkButtons';

const RMAList = ({data, deleteHandler, profile}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleOnSelect = (id) => {
        history.push('/rma/' + id);
    }

    
    const rmaList = data ? data.map(rma => {  
        return(                
           <tr onDoubleClick={() => {handleOnSelect(rma.id)}} className='list' key={rma.id}> 
               <td className="td0">{rma.rmaDate}</td>   
               <td className="td1">{rma.rmaNo}</td>
               <td>{rma.customerName}</td> 
               <td className="td1">
                   <EditLinkButton to={'/rma/' + rma.id} />
                   <PrintButton onClick={() => {handleOnPrint(rma.id)}} />
                   {profile.privilege === 'service-manager' ? 
                   <DeleteLinkButton  onClick={() => {deleteHandler(rma.id)}} />
                   : null}
               </td>
           </tr>
        );                                
    }) : null;

    const handleOnPrint = (rmaId) => {
        console.log('rmaList',data);       
        const rma = data  !== null ? data.filter(item => {
            return item.id === rmaId
         }) : [];
         
        console.log('RMA', rma);
        dispatch(PrintRMAAcknowledgement(rma));
    }

    return ( 
        <div className="section sec-details">  
            <div className="table-overflow">
                <table className=" highlight">
                    <thead>
                        <tr>
                            <th data-field="date">Date</th>
                            <th data-field="rmaNumber">RMA Number</th>
                            <th data-field="customerName">Customer Name</th>
                            <th data-field="edit" className="icon-head"></th>
                        </tr>
                    </thead>

                    <tbody>
                        {rmaList}
                    </tbody>
                </table>
            </div>
        </div>
     );
}
 
export default RMAList;