const initState = {
    authError: null,
    passwordReset: false
}
const authReducer = (state = initState, action) =>{
    switch(action.type){
        case 'LOGIN_ERROR':
            console.log('Login failed');
            return{
                ...state,
                authError: action.err.message
            }
        case 'LOGIN_SUCCESS':
            console.log('Login success');
            return{
                ...state,
                authError: null
            }
        case 'SIGNOUT_ERROR':
            console.log('Sign out failed');
            return{
                ...state,
                authError: action.err.message
            }
        case 'SIGNOUT_SUCCESS':
            console.log('Sign out success');
            return{
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('Sign up failed');
            return{
                ...state,
                authError: action.err.message
            }
        case 'SIGNUP_SUCCESS':
            console.log('Sign up success');
            return{
                ...state,
                authError: null
            }
        case 'RESET_PASSWORD_ERROR':
            console.log('Password reset failed');
            return{
                ...state,
                authError: action.err.message,
                passwordReset: false
            }
        case 'RESET_PASSWORD_SUCCESS':
            console.log('Password reset mail sent');
            return{
                ...state,
                authError: null,
                passwordReset: true
            } 
        case 'CLEAR_MESSAGES':
            console.log('Clearing messages');
            return{
                ...state,
                authError: action.err.message,
            }       
        default:
            return state;
    }
}

export default authReducer;