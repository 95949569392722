import { GenerateKeywords } from '../../utils/GenerteKeywords';

const collection = 'products';
const account = 'organizations';

export const createProduct = (newProduct) => {
    return(dispatch, getState, { getFirebase }) => {
        const profile = getState().firebase.profile;
        const createdBy = getState().firebase.auth.uid;

        /*Make async call to database */
        const firestore = getFirebase().firestore();
        try{
            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .where("sku", "==", newProduct.sku)
            .get()
            .then((res) => {
                if(res.empty){
                    /** No records found - create product logic */
                    let keywords = GenerateKeywords(newProduct.name);
                    keywords = [...keywords, ...GenerateKeywords(newProduct.description)];
                    keywords = [...keywords, ...GenerateKeywords(newProduct.sku)];
                    keywords = [...keywords, ...GenerateKeywords(newProduct.name.replace(/[^a-zA-Z0-9 ]/g, ' '))];
                    
                    const statRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection('stat')
                    .doc('productStat');

                    const newProductRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection(collection)
                    .doc();

                    firestore.runTransaction(async transaction => {
                        const stat = await transaction.get(statRef);
                        const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                        const newActive= stat.data().active === undefined ? 1 : stat.data().active + 1;
                        //console.log('new index', newIndex);
                        transaction.update(statRef, {count: newIndex, active: newActive});

                        const res = await transaction.set(newProductRef, {
                            ...newProduct,
                            index: newIndex,
                            status: 'active',
                            keywords: keywords,
                            creator_id: createdBy,
                            created_at: new Date()
                        });
                        let pid = newIndex;
                        res.md.mutations.map(key => {
                            //console.log('key', key.key.path.segments[3]);
                            if(key.key.path.segments[3] !== 'productStat' ) pid = key.key.path.segments[3];
                            return null;
                        });  
                        
                        const product = {data:{}}
                        const docData = {
                            ...newProduct,
                            index: newIndex,
                            status: 'active',
                            id: pid
                        };
                        const key = pid;
                        product.data[key] = docData;
                        dispatch({type: 'CREATE_PRODUCT', product})
                    });
                }else{
                    /** Product exist with same sku */
                    dispatch({type: 'PRODUCT_DUPLICATE', product: newProduct})
                }
            });

        }catch(err){
            dispatch({type:'CREATE_PRODUCT_ERROR', err});
        }
        
    }
}

export const exportProducts = (exportList) => {
    return(dispatch, getState, { getFirebase }) => {
        const { products } = exportList;
        
        const profile = getState().firebase.profile;
        const createdBy = getState().firebase.auth.uid;
        /*Make async call to database */
        const firestore = getFirebase().firestore();
        products && dispatch({type: 'EXPORT_PRODUCT_QUEUED', queued: products.length})

        let i = 0;        
        products && products.map(pro => {
            i++;
            setTimeout(function(){
                try{
                    firestore.collection(account)
                    .doc(profile.account)
                    .collection(collection)
                    .where("sku", "==", pro.sku)
                    .get()
                    .then((res) => {
                        if(res.empty){
                            /** No records found - create product logic */
                            let keywords = GenerateKeywords(pro.name);
                            keywords = [...keywords, ...GenerateKeywords(pro.description)];
                            keywords = [...keywords, ...GenerateKeywords(pro.sku)];
                            keywords = [...keywords, ...GenerateKeywords(pro.name.replace(/[^a-zA-Z0-9 ]/g, ' '))];
                            
                            const statRef = firestore.collection(account)
                            .doc(profile.account)
                            .collection('stat')
                            .doc('productStat');
    
                            const newProductRef = firestore.collection(account)
                            .doc(profile.account)
                            .collection(collection)
                            .doc();
    
                            firestore.runTransaction(async transaction => {
                                const stat = await transaction.get(statRef);
                                const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                                const newActive= stat.data().active === undefined ? 1 : stat.data().active + 1;
                                //console.log('new index', newIndex);
                                transaction.update(statRef, {count: newIndex, active: newActive});
    
                                const res = await transaction.set(newProductRef, {
                                    index: newIndex,
                                    sku: pro.sku.trim(),
                                    name: pro.name.trim(),
                                    description: pro.description.trim(),
                                    status: 'active',
                                    keywords: keywords,
                                    creator_id: createdBy,
                                    created_at: new Date()
                                });
                                let pid = newIndex;
                                res.md.mutations.map(key => {
                                    //console.log('key', key.key.path.segments[3]);
                                    if(key.key.path.segments[3] !== 'productStat' ) pid = key.key.path.segments[3];
                                    return null;
                                });  
                                
                                const product = {data:{}}
                                const docData = {
                                    index: newIndex,
                                    sku: pro.sku.trim(),
                                    name: pro.name.trim(),
                                    description: pro.description.trim(),
                                    status: 'active',
                                    id: pid
                                };
                                const key = pid;
                                product.data[key] = docData;
                                dispatch({type: 'EXPORT_PRODUCT', product})
                            });
                        }else{
                            /** Product exist with same sku */
                            dispatch({type: 'EXPORT_PRODUCT_DUPLICATE', product: pro})
                        }
                    });
                }catch(err){
                    dispatch({type: 'EXPORT_PRODUCT_ERROR', err})
                }
            },(i * 2000)) // Iterate time deay for each export
             
            return null;
        })
    }
}

export const loadProducts = (search, pagination, pageInfo) => {
    search = search.toLowerCase().replace(/\W/g, "");
    return(dispatch, getState,{ getFirebase }) => {
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();

        firestore.collection(account)
        .doc(profile.account)
        .collection(collection)      
        .where("keywords", "array-contains", search)
        .limit(pagination.limit)
        .get()
        .then((snapshot) => { 
            const products = {data:{}, pageInfo:pageInfo}
            snapshot.docs.map(doc => {
                const docData = {
                    ...doc.data(),
                    id: doc.id
                };
                const key = doc.id;
                products.data[key] = docData;
                return null;
            });
            if(search === '') Object.keys(products.data).length === 0  
                ? dispatch({type: 'PAGE_ERROR'}) 
                : dispatch({type: 'LOAD_PRODUCTS', products});
            else dispatch({type: 'FILTER_PRODUCTS', products});
        }).catch((err) => {
            dispatch({type: 'LOAD_PRODUCT_ERROR', err});
        });
        
    }
} 

export const getProductDetails = (id) => {
    return(dispatch, getState,{ getFirebase }) => {
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();

        firestore.collection(account)
        .doc(profile.account)
        .collection(collection)   
        .doc(id)
        .get()
        .then((snapshot) => { 
            if(!snapshot.empty){
                const products = {data:{}}
                const docData = {
                    ...snapshot.data(),
                    id: id
                };
                const key = id;
                products.data[key] = docData;
                dispatch({type: 'GET_PRODUCT_DETAILS', products});
            }
        }).catch((err) => {
            dispatch({type: 'LOAD_PRODUCT_ERROR', err});
        });
        
    }
} 

export const updateProduct = (prod, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(prod.name !== ''  
            && prod.sku !== '' 
            && id !== ''){
            const profile = getState().firebase.profile;    
            const modifiedBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const keywords = GenerateKeywords(prod.name);
            const firestore = getFirebase().firestore();

            firestore.collection(account)
            .doc(profile.account)
            .collection(collection).doc(id).update({
                ...prod,
                status: 'active',
                keywords: keywords,
                modifier: modifiedBy,
                modified_at: new Date()
            }).then(() => {
                const product = {data:{}}
                const docData = {
                    ...prod,
                    status: 'active',
                    id: id
                };
                const key = id;
                product.data[key] = docData;
                dispatch({type: 'UPDATE_PRODUCT', product})
            }).catch((err) => {
                dispatch({type: 'UPDATE_PRODUCT_ERROR', err})
            });
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
        }
    }
}

export const deleteProduct = (prod, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(id !== ''){
            const profile = getState().firebase.profile;
            const modifiedBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const keywords = GenerateKeywords(prod.name);
            const firestore = getFirebase().firestore();

            firestore.collection(account)
            .doc(profile.account)
            .collection(collection).doc(id).update({
                ...prod,
                status: 'deleted',
                keywords: keywords,
                modifier: modifiedBy,
                modifiedTime: new Date()
            }).then(() => {
                const product = {data:{}}
                const docData = {
                    ...prod,
                    status: 'deleted',
                    id: id
                };
                const key = id;
                product.data[key] = docData;
                dispatch({type: 'DELETE_PRODUCT', product})
            }).catch((err) => {
                dispatch({type: 'DELETE_PRODUCT_ERROR', err})
            });
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
        }
    }
}

export const resetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().product.validation;
        if(validation) dispatch({type: 'RESET_VALIDATION'})
    }
}

export const closeControl = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        dispatch({type: 'CLOSE_CONTROL'})
    }
}