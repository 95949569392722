import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { signIn, signOut, clearMessages } from '../../store/actions/authActions';

class SignIn extends Component {
    state = { 
        email: '',
        password: ''
     }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();  
        this.props.signIn(this.state) ;    
    }
    componentDidMount() {
        //console.log('rendering clear msg');
       this.props.clearMessages();
    }
    render() { 
        const { authError, auth } = this.props;
        if(auth.uid) return <Redirect to='/'/>;
        return ( 
            <div>
                <div className="container auth">
                    <div className="row">                   
                        <div className="card col s12 m7 l7 offset-m5 offset-l5">
                            <form onSubmit={this.handleSubmit} className="white">
                                <h5 className="grey-text text-darken-3">Sign In</h5>
                                <div className="input-field">
                                    <label htmlFor="email">Email</label>
                                    <input id="email" onChange={this.handleChange} type="email"/>
                                </div>
                                <div className="input-field">
                                    <label htmlFor="password">Password</label>
                                    <input id="password" onChange={this.handleChange} type="password"/>
                                </div>
                                <div className="input-field right">                                
                                    <button className="btn red lighten-1 z-depth-0">Login</button>                                    
                                </div>
                                <div className="red-text center">
                                    {authError ? <p>{authError}</p> : null}
                                </div>
                            </form>
                        </div>                
                    </div>
                </div>                
                <div className="container"> 
                    <div className="row">                   
                        <div className="col s12 m7 l7 offset-m5 offset-l5">                               
                             <span>
                                Don't have an accoount? <NavLink to='/signup'className="red-text text-darken-3">Sign Up</NavLink> here. 
                                <p>Forgot password? <NavLink to='/forgot'className="red-text text-darken-3">Reset</NavLink> here.</p>
                             </span>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}

const mapStateToProps = (state) => { 
    return{
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        signIn: (cred) => dispatch(signIn(cred)),
        signOut: () => dispatch(signOut),
        clearMessages: () => dispatch(clearMessages())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);