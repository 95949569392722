import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPassword } from '../../store/actions/authActions';

class ForgotPassword extends Component {
    state = { 
        email: ''
     }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    } 
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.forgotPassword(this.state);
        this.props.history.push('/reset');
    }
    render() { 
        const { auth, authError } = this.props;
        if(auth.uid) return <Redirect to='/'/>
        return ( 
            <div>
                <div className="container auth">
                    <div className="row">                   
                        <div className="card col s12 m7 l7 offset-m5 offset-l5">
                            <form onSubmit={this.handleSubmit} className="white">
                                <h5 className="grey-text text-darken-3">Forgot Password?</h5>
                                <p>Please enter the email address below and we will send you 
                                    information to recover your account.</p>
                                <div className="input-field">
                                    <label htmlFor="email">Email</label>
                                    <input id="email" onChange={this.handleChange} type="email"/>
                                </div>
                                <div className="input-field right">                                
                                    <button className="btn red lighten-1 z-depth-0">Reset</button>                                    
                                </div>
                                <div className="red-text center">
                                    {authError ? <p>{authError}</p> : null}
                                </div>
                            </form>
                        </div>                
                    </div>
                </div>                
                <div className="container"> 
                    <div className="row">                   
                        <div className="col s12 m7 l7 offset-m5 offset-l5">                               
                             <span>Don't have an accoount? <NavLink to='/signup'className="red-text text-darken-3">Sign Up</NavLink> here.</span>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        forgotPassword: (cred) => dispatch(forgotPassword(cred))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);