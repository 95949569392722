import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { GetOrderStat } from '../../store/actions/orderActions';
import SalesStat from './SalesStat';
import OrderStat from './OrderStat';

const Orders = () => {   
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetOrderStat(''));
    },[dispatch]);

    useEffect(() =>  {
         Materialize.AutoInit();  
    });

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);
    const stat = useSelector(state => state.order.stat);
    const divisions = useSelector(state => state.order.divisions);

    /* Check authentication and redirect to login page */
    if(!auth.uid) return <Redirect to='/signin'/>;

    //console.log('data', data);
    //console.log('orderStat', orderStat);
    const handleRangeSelection = (range) => {
        dispatch(GetOrderStat(range));
    }
    
    return ( 
        <div className="section dashboard white">
            <div className="row">
                <OrderStat
                orderStat = {stat.OrderStat}
                divisions = {divisions}
                storeOrderStat = {stat.StoreOrderStat}
                />
                <SalesStat
                salesStat = {stat.SalesStat}
                OnRageSelection = {(range) => handleRangeSelection(range)}
                />
            </div>                      
        </div>
     );
}

export default Orders;