import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseLinkButton } from '../../utils/components/buttons/LinkButtons';
import { GetOrderItems, UpdateOrderStatus, ClearItems } from '../../store/actions/orderActions';

const OrderDetails = (props) => {
    const dispatch = useDispatch();
    const [componentState] = useState({
        id: props.match.params.id,
        status: ''
    })

    

    const choice = useSelector(state => state.order.choice);
    const orders = useSelector(state => state.order.data);
    const items = useSelector(state => state.order.items);

    useEffect(() => {
        if(orders) {
            dispatch(ClearItems());
            dispatch(GetOrderItems(props.match.params.id));
        }
    },[orders, dispatch, props.match.params.id ])

    const order = orders  !== null ? orders.filter(ord => {
       return ord.ofId === props.match.params.id
    }) : [];
    
    let slNo = 1;
    //let totalAmount = 0;
    const products = items  ? (       
        items.map(product => {
            //totalAmount += parseFloat(product.amount);
             return(
                <tr className="list" key={product.productId}>                            
                    <td>{slNo++}</td>
                    <td>{product.referenceId}</td>
                    <td>{product.productName}</td>
                    <td><span className="right">{product.quantity}</span></td>                    
                    <td><span className="right">{product.unitPrice}</span></td>                    
                    <td><span className="right">{product.taxAmount}</span></td>
                    <td><span className="right">{product.amount}</span></td>
                    <td></td>
                </tr>
             );                                
         }) 
     ) : null;

     const productTotal = items ? ( 
        <React.Fragment>
            <tr className="list" key="totalAmount">  
                <td colSpan="6"><span className="bold right grey-text text-darken-2">Total Amount</span></td>
                <td><span className="right bold grey-text text-darken-2">AED {order.length > 0 ? order[0].totalAmount : null}</span></td>
                <td></td>
            </tr>
            <tr className="list" key="totalTax">  
                <td colSpan="6"><span className="bold right grey-text text-darken-2">Total Tax</span></td>
                <td><span className="right bold grey-text text-darken-2">AED {order.length > 0 ? order[0].totalTax : null}</span></td>
                <td></td>
            </tr>
            <tr className="list" key="grandTotal">  
                <td colSpan="6"><span className="bold right grey-text text-darken-2">Grand Total</span></td>
                <td><span className="right bold grey-text text-darken-2">AED {order.length > 0 ? order[0].grandTotal : null}</span></td>
                <td></td>
            </tr>
             
        </React.Fragment>
     ) : null;

     const productTable = items ? (       
        <table className=" highlight">
            <thead>
                <tr>
                    <th data-field="slNo">No.</th>
                    <th data-field="name">Model No.</th>
                    <th data-field="description">Description</th>
                    <th data-field="quantity"><span className="right">Qty.</span></th>
                    <th data-field="price"><span className="right">Unit Price</span></th>
                    <th data-field="netAmount"><span className="right">Tax</span></th>
                    <th data-field="return"><span className="right">Amount</span></th>
                    <th data-field="edit" className="icon-head"></th>
                </tr>
            </thead>

            <tbody>
                {products}
                {productTotal}
            </tbody>
        </table>
     ) : null;

    let shippingAddress = order.length > 0 ? order[0].deliveryMode === 0 ? (
        order[0].deliveryLocation === 0 ? (
            <p>
                <span className="bold">Delivery : Pickup from Warehouse</span><br/>
                HIKVISION HTL Warehouse<br/>Ras Al Khor<br/>Dubai.
            </p>
        ) : (
            <p>
                <span className="bold">Delivery : Pickup from Store</span><br/>
                {order[0].deliveryAddress}
            </p>
        )
        
    ) : (
        <p>
            <span className="bold">Shipping Address:</span><br/>
            {order.length > 0 ? order[0].address : null} <br/>
            {order.length > 0 ? order[0].street : null} <br/>
            {order.length > 0 ? order[0].city : null} <br/>
            Phone : {order[0].mobile}
        </p>
    ) : null;

    /** Handle default values in controls */
    let status = '';
    if(order.length){
        status = order[0].status;
    } 
    
    const handleStatusChange= (e) => {
        // setComponentState({
        //     ...componentState,
        //     status: e.target.value
        // });
        console.log('status', componentState);
        dispatch(UpdateOrderStatus(componentState, e.target.value));
    }
     
    return ( 
        <div className="section ord">
            <form className="white">
                <div className="row">
                    <div className="col s10">
                        <div className="row">
                        <h5 className="grey-text text-darken-2">ORDER FORM</h5>
                        </div>
                        <div className="row ord-status">
                        <span className='new badge grey darken-1 ' data-badge-caption=''>{order.length > 0 ? order[0].orderStatus : null}</span>
                        </div>
                    </div>
                    <div className="col s2 btn-gr">
                        <CloseLinkButton to={'/lsord/' + choice}/>
                    </div>
                </div>
                <br />
                <div className="row ord-sum">
                    <div className="col s12">
                        <div className="card z-depth-1">
                            <div className="ord-det">
                                <div className="row">
                                    <div className="col s2">
                                        <p>Customer Name :</p>
                                    </div>
                                    <div className="col s7">
                                        <p className="bold grey-text text-darken-2">{order.length > 0 ? order[0].companyName : null}</p>
                                    </div>
                                    <div className="col s1">
                                        <p>Order # :</p>
                                    </div>
                                    <div className="col s2">
                                        <p className="right-align bold grey-text text-darken-2">{order.length > 0 ? order[0].orderNumber : null}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s2">
                                        <p>Address :</p>
                                    </div>
                                    <div className="col s7">
                                        <p>
                                            {order.length > 0 ? order[0].address : null} <br/>
                                            {order.length > 0 ? order[0].street : null} <br/>
                                            {order.length > 0 ? order[0].city : null} <br/>
                                            Ph: {order.length > 0 ? order[0].phone : null} <br/>
                                        </p>
                                    </div>
                                    <div className="col s1">
                                        <p>Date :</p>
                                    </div>
                                    <div className="col s2">
                                        <p className="right-align">{order.length > 0 ? order[0].orderDate : null}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s2">
                                        <p>Contact Person :</p>
                                    </div>
                                    <div className="col s7">
                                        <p>
                                            {order.length > 0 ? order[0].contactPerson : null}  |  
                                            Phone: {order.length > 0 ? order[0].mobile : null}  |  
                                            Email: {order.length > 0 ? order[0].emailAddress : null}
                                        </p>
                                    </div>
                                    <div className="col s1">
                                        <p>Status :</p>
                                    </div>
                                    <div className="col s2">
                                        <select onChange={handleStatusChange} className="browser-default bold grey-text text-darken-2" defaultValue={status}>
                                            <option value="0">Order Placed</option>
                                            <option value="1">In Process</option>
                                            <option value="5">Ready for Collection</option> 
                                            <option value="2">Delivered</option> 
                                            <option value="3">Cancelled</option>  
                                        </select>  
                                    </div>
                                </div>
                                <br/>
                                <div className="card-action card-bottom-badge">
                                    <div className="table-overflow">
                                        {productTable}
                                    </div>
                                    <br/>
                                    {shippingAddress}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default OrderDetails;