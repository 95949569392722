import { GenerateKeywords } from '../../utils/GenerteKeywords';
import axios from 'axios';
import {Signature} from '../../utils/GenerateSignature'

const collection = 'customers';
const account = 'organizations';

export const createCustomer = (newCustomer) => {
    return(dispatch, getState, { getFirebase }) => {
        const profile = getState().firebase.profile;
        const createdBy = getState().firebase.auth.uid;

        /*Make async call to database */
        const firestore = getFirebase().firestore();
        try{
            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .where("sku", "==", newCustomer.name.trim())
            .get()
            .then((res) => {
                if(res.empty){
                    /** No records found - create product logic */
                    let keywords = GenerateKeywords(newCustomer.name);
                    
                    const statRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection('stat')
                    .doc('customerStat');

                    const newCustomerRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection(collection)
                    .doc();

                    firestore.runTransaction(async transaction => {
                        const stat = await transaction.get(statRef);
                        const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                        const newActive= stat.data().active === undefined ? 1 : stat.data().active + 1;
                        //console.log('new index', newIndex);
                        transaction.update(statRef, {count: newIndex, active: newActive});

                        const res = await transaction.set(newCustomerRef, {
                            ...newCustomer,
                            index: newIndex,
                            status: 'active',
                            keywords: keywords,
                            creator_id: createdBy,
                            created_at: new Date()
                        });
                        let cid = newIndex;
                        res.md.mutations.map(key => {
                            //console.log('key', key.key.path.segments[3]);
                            if(key.key.path.segments[3] !== 'customerStat' ) cid = key.key.path.segments[3];
                            return null;
                        });  
                        
                        const customer = {data:{}}
                        const docData = {
                            ...newCustomer,
                            index: newIndex,
                            status: 'active',
                            id: cid
                        };
                        const key = cid;
                        customer.data[key] = docData;
                        dispatch({type: 'CREATE_CUSTOMER', customer})
                    });
                }else{
                    /** Customer exist with same name */
                    dispatch({type: 'CUSTOMER_DUPLICATE', product: newCustomer})
                }
            });

        }catch(err){
            dispatch({type:'CREATE_CUSTOMER_ERROR', err});
        }
        
    }
}



export const exportCustomers = (exportList) => {
    return(dispatch, getState, { getFirebase }) => {
        const { customers } = exportList;
        const profile = getState().firebase.profile;
        const createdBy = getState().firebase.auth.uid;
        /*Make async call to database */
        const firestore = getFirebase().firestore();
        customers && dispatch({type: 'EXPORT_CUSTOMER_QUEUED', queued: customers.length});

        let i = 1
        customers && customers.map(cus => { 
            i++;
            setTimeout(function(){               
                try{
                    firestore.collection(account)
                    .doc(profile.account)
                    .collection(collection)
                    .where("code", "==", cus.code.trim())
                    .get()
                    .then((res) => {
                        if(res.empty){
                            /** No records found - create product logic */
                            let keywords = GenerateKeywords(cus.code);
                            keywords = [...keywords, ...GenerateKeywords(cus.name)];
                            
                            const statRef = firestore.collection(account)
                            .doc(profile.account)
                            .collection('stat')
                            .doc('customerStat');

                            const newCustomerRef = firestore.collection(account)
                            .doc(profile.account)
                            .collection(collection)
                            .doc();

                            firestore.runTransaction(async transaction => {
                                const stat = await transaction.get(statRef);
                                const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                                const newActive= stat.data().active === undefined ? 1 : stat.data().active + 1;
                                //console.log('new index', newIndex);
                                transaction.update(statRef, {count: newIndex, active: newActive});

                                const res = await transaction.set(newCustomerRef, {
                                    index: newIndex,
                                    code: cus.code.trim(),
                                    name: cus.name.trim(),
                                    address: cus.address,
                                    city: cus.city,
                                    phone: cus.phone,
                                    email: cus.email,
                                    status: 'active',
                                    keywords: keywords,
                                    creator_id: createdBy,
                                    created_at: new Date()
                                });
                                let cid = newIndex;
                                res.md.mutations.map(key => {
                                    //console.log('key', key.key.path.segments[3]);
                                    if(key.key.path.segments[3] !== 'customerStat' ) cid = key.key.path.segments[3];
                                    return null;
                                });  
                                
                                const customer = {data:{}}
                                const docData = {
                                    index: newIndex,
                                    code: cus.code.trim(),
                                    name: cus.name.trim(),
                                    address: cus.address.trim(),
                                    city: cus.city.trim(),
                                    phone: cus.phone.trim(),
                                    email: cus.email.trim(),
                                    status: 'active',
                                    id: cid
                                };
                                const key = cid;
                                customer.data[key] = docData;
                                dispatch({type: 'EXPORT_CUSTOMER', customer})
                            });
                        }else{
                            /** Product exist with same code */
                            dispatch({type: 'EXPORT_CUSTOMER_DUPLICATE', customer: cus})
                        }
                    });
                }catch(err){
                    dispatch({type: 'EXPORT_CUSTOMER_ERROR', err})
                } 
            }, (i * 1000));// Iterate time deay for each export
            
           return null;
       });
    }
}

export const loadCustomers = (search, pagination, pageInfo) => {
    search = search.toLowerCase().replace(/\W/g, "");
    return(dispatch, getState,{ getFirebase }) => {
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();

        firestore.collection(account)
        .doc(profile.account)
        .collection(collection)      
        .where("keywords", "array-contains", search)
        .limit(pagination.limit)
        .get()
        .then((snapshot) => { 
            const customers = {data:{}, pageInfo:pageInfo}
            snapshot.docs.map(doc => {
                const docData = {
                    ...doc.data(),
                    id: doc.id
                };
                const key = doc.id;
                customers.data[key] = docData;
                return null;
            });
            if(search === '') Object.keys(customers.data).length === 0  
                ? dispatch({type: 'PAGE_ERROR'}) 
                : dispatch({type: 'LOAD_CUSTOMERS', customers});
            else dispatch({type: 'FILTER_CUSTOMERS', customers});
        }).catch((err) => {
            dispatch({type: 'LOAD_CUSTOMER_ERROR', err});
        });
        
    }
} 

export const getCustomerDetails = (id) => {
    return(dispatch, getState,{ getFirebase }) => {
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();

        firestore.collection(account)
        .doc(profile.account)
        .collection(collection)   
        .doc(id)
        .get()
        .then((snapshot) => { 
            if(!snapshot.empty){
                const customers = {data:{}}
                const docData = {
                    ...snapshot.data(),
                    id: id
                };
                const key = id;
                customers.data[key] = docData;
                dispatch({type: 'GET_CUSTOMER_DETAILS', customers});
                dispatch({type: 'GET_CUSTOMER', customer: snapshot.data()});
            }
        }).catch((err) => {
            dispatch({type: 'LOAD_CUSTOMER_ERROR', err});
        });
        
    }
} 

export const setCustomerDetails = (key, value) => {   
    return(dispatch) => {
        const customer = {
            [key]: value
        }
        dispatch({type: 'SET_CUSTOMER_DETAILS', customer: customer});   
        dispatch({type: 'GET_CUSTOMER', customer: value});  
    }
} 

export const setCustomer = (key, value) => {   
    return(dispatch) => {
        const customer = {
            [key]: value
        }
        dispatch({type: 'SET_CUSTOMER', customer: customer});  
    }
}

export const updateCustomer = (cus, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(cus.name !== '' && cus.name !== undefined ){
            // Hash encode data 
            const payload = {
                account: cus,
                query: 'saveAccount',
            }
            const encoded_hash = Signature(JSON.stringify(payload));
            
            const api = axios.create({
                baseURL: `https://onlinecart.ae/core-customer-api.php/`,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });

            //const fb = getFirebase();
            const profile = getState().firebase.profile;    
            const modifiedBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const keywords = GenerateKeywords(cus.name);
            const firestore = getFirebase().firestore();

            firestore.collection(account)
            .doc(profile.account)
            .collection(collection).doc(id).update({
                ...cus,
                status: 'active',
                keywords: keywords,
                modifier: modifiedBy,
                modified_at: new Date()
            }).then(() => {
                const customer = {data:{}}
                const docData = {
                    ...cus,
                    status: 'active',
                    id: id
                };
                const key = id;
                customer.data[key] = docData;
                dispatch({type: 'UPDATE_CUSTOMER', customer});
                
            }).catch((err) => {
                dispatch({type: 'UPDATE_CUSTOMER_ERROR', err});
            });
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'});
        }
    }
}

export const deleteCustomer = (cus, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(id !== ''){
            const profile = getState().firebase.profile;
            const modifiedBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const firestore = getFirebase().firestore();

            firestore.collection(account)
            .doc(profile.account)
            .collection(collection).doc(id).update({
                ...cus,
                status: 'deleted',
                modifier: modifiedBy,
                modifiedTime: new Date()
            }).then(() => {
                const customer = {data:{}}
                const docData = {
                    ...cus,
                    status: 'deleted',
                    id: id
                };
                const key = id;
                customer.data[key] = docData;
                dispatch({type: 'DELETE_CUSTOMER', customer})
            }).catch((err) => {
                dispatch({type: 'DELETE_CUSTOMER_ERROR', err})
            });
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
        }
    }
}

export const resetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().customer.validation;
        if(validation) dispatch({type: 'RESET_VALIDATION'})
    }
}

export const ClearCustomer = () => {
    return(dispatch, getState) => {
        /*Clear customer details */
        dispatch({type: 'CLEAR_CUSTOMER'})
    }
}

export const closeControl = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        dispatch({type: 'CLOSE_CONTROL'})
    }
}