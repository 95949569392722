import React from 'react';
import { Link } from 'react-router-dom';
import UserModal from '../users/UserModal';

const Members = ({members, userList, divisions, onRemove}) => {
    let ordered = [];
    if(members){
        const orderedArray = Object.keys(members).map((key) => [key, members[key]]);    
       
        orderedArray.map(item => {    
            ordered.push(item.pop());
            return null;
        });     
    }

    const compare = (a, b) =>{
        const rlA = a.reportingLevel;
        const rlB = b.reportingLevel;

        let comparison = 0;
        if (rlA > rlB) {
            comparison = 1;
        } else if (rlA < rlB) {
            comparison = -1;
        }
        return comparison;
    }
    /** Sorting list with reporting level so that manager comes at top of the list */
    const sortedMembers = ordered && ordered.sort(compare);

    const membersList = ordered && sortedMembers.map(member => {
        const user = userList.find(m => {
            return m.id === member.id
        });
        return user ? (
            <li className="collection-item users-list" key={user.id}>
                <p>                    
                    {user.firstName + ' ' + user.lastName}  {/*member.reportingLevel === 1 ? <i className="material-icons">grade</i> : null*/}                     
                    <span className={member.reportingLevel === 1 ? 'badge orange white-text' : 'badge'}>{member.reportingLevel === 1 ? 'Manager' : 'User'}</span>   
                    <span className="collection-ed-del">
                        <a href={'#member' + user.id}  className="btn-flat modal-trigger"><i className="material-icons white grey-text text-darken-2">visibility</i></a>                    
                        {member.reportingLevel === 1 ? (
                            <React.Fragment></React.Fragment>
                        ) : (
                            <Link to='#' onClick={() => {handleOnRemove(member.id)}} className="btn-flat"><i className="material-icons white grey-text text-darken-2">delete_outline</i></Link>                           
                        )}
                        
                    </span>                 
                </p>
            </li>
        ) : (  null );        
    });  
    
    const handleOnRemove = (id) =>{
        onRemove(id);
    }
    return ( 
        <React.Fragment>
        <ul className="collection">
            {membersList}
        </ul> 
        <UserModal userList={userList} divisions={divisions}/>    
        </React.Fragment>        
     );
}
 
export default Members;