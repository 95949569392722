import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from '../../store/actions/authActions';
import { connect } from 'react-redux';

class AdminConsole extends Component {
    state = {  }
    handleSignOut = (e) => {
        this.props.signOut();
    }
    
    render() { 
        const { profile } = this.props;
        const console = profile.privilege === 'super' ? (
            <div>
                <div className="hide-on-large-only">                
                    <li><Link to='/' className="sidenav-close grey-text text-darken-3"><i className="material-icons">dashboard</i>Dashboard</Link></li>
                </div>
                <li><div className="divider"></div></li>
                <li><Link to='/org' className="sidenav-close grey-text text-darken-3"><i className="material-icons">business</i>Organizational Units</Link></li>
                
                <li><div className="divider"></div></li>
                <li><a href="/" onClick={this.handleSignOut} className="sidenav-close"><i className="material-icons">power_settings_new</i>Sign Out</a></li>
            </div>
        ) : (
            <div>
                <div className="hide-on-large-only">                
                    <li><Link to='/' className="sidenav-close grey-text text-darken-3"><i className="material-icons">dashboard</i>Dashboard</Link></li>
                </div>
                <li><div className="divider"></div></li>
                <li><Link to='/div' className="sidenav-close grey-text text-darken-3"><i className="material-icons">store</i>Divisions</Link></li>
                <li><Link to='/usr' className="sidenav-close grey-text text-darken-3"><i className="material-icons">fingerprint</i>Users</Link></li>
                <li><Link to='/pro' className="sidenav-close grey-text text-darken-3"><i className="material-icons">shopping_bag</i>Products</Link></li>                
                <li><Link to='/cus' className="sidenav-close grey-text text-darken-3"><i className="material-icons">account_box</i>Customers</Link></li>
                
                {/* <li><div className="divider"></div></li>
                <li><a href="/" onClick={this.handleSignOut} className="sidenav-close"><i className="material-icons">power_settings_new</i>Sign Out</a></li> */}
            </div>
        );
        return ( 
           <React.Fragment>
               {console}
           </React.Fragment>
         );
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        signOut: () => dispatch(signOut())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminConsole);