import React from 'react';

import UserSummary from './UserSummary';

const UserList = (props) => {
    /* Defactor division from props */
    const { users, divisions } = props;

    const handleDeleteUser = (user, id) => {
        props.onDeleteUser(user, id);
    }

    return ( 
        <div className="section">
            <div className="row">
                { users && users.map(user => {
                    return(               
                        <div className='list-wrap' key={user.id}>
                            <UserSummary user={user} divisions={divisions} onDeleteUser={(user, id) => {handleDeleteUser(user, id)}}/>
                        </div>
                    ) 
                })}
            </div>
         </div>
     );
}
 
export default UserList;