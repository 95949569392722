import React from 'react';

const Profile = () => {
    return ( 
        <div className="container">
            <h5 className="center">User Profile</h5>
        </div>
     );
}
 
export default Profile;