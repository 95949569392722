import React from 'react';
import { AddButton } from '../../utils/components/buttons/LinkButtons';

const DeliveryNoteQueue = ({data}) => {
    let i = 0;
    const rmaList = data ? data.map(item => {  
        i++;
        return(                
           <tr className='list' key={item.id}> 
               <td>{i}</td>  
               <td>{item.customerName}</td>  
               <td className="td1">{item.productCount}</td>
               <td>
                   <AddButton to={'/crdln/' + item.id} bg='grey lighten-5' tooltip='Create DN'/>
               </td>
           </tr>
        );                                
    }) : null;

    return ( 
        <div className="section sec-details">  
            <div className="table-overflow">
                <table className=" highlight">
                    <thead>
                        <tr>
                            <th data-field="serialNumber">Sl. No.</th>
                            <th data-field="customerName">Customer Name</th>
                            <th data-field="itemCount">Item Count</th>
                            <th data-field="edit" className="icon-head"></th>
                        </tr>
                    </thead>

                    <tbody>
                        {rmaList}
                    </tbody>
                </table>
            </div>
        </div>
     );
}
 
export default DeliveryNoteQueue;