import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import moment from 'moment';

const Notifications = ({privateNotifications}) => {
    //console.log('privateNotifications',privateNotifications);
    return ( 
        <div className="section notifications">
             <ul className='collection with-header'>
                <li className="collection-header"><h4>NOTIFICATIONS</h4></li>
                {privateNotifications && privateNotifications.map(item => {
                    return(
                        <li key={item.id}>
                            <span className="pink-text">{item.user} </span>
                            <span>{item.content}</span>
                            <div className="grey-text note-date">
                                {moment(item.time.toDate()).fromNow()}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
     );
}

const mapStateToProps = (state) => {
    //console.log('state',state);
    return{
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        privateNotifications: state.firestore.ordered.privateNotifications
    }
}
 
export default compose(
    connect(mapStateToProps),
    firestoreConnect(ownProps => [
        {
            collection: 'organizations/' + ownProps.profile.account + '/notifications',
            storeAs: 'privateNotifications',
            where: ["privateMsgList", "array-contains", ownProps.auth.uid],
            orderBy: ["time", "desc"],
            limit: 10
        }
    ])
)(Notifications);