import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import moment from 'moment';
import { NextLinkButton, DownloadLinkButton, UploadLinkButton } from '../../utils/components/buttons/LinkButtons';

import Materialize from 'materialize-css/dist/js/materialize.min.js';

const IOUTodos = ({todos, onShowIOU, onUploadIOU, onDeliveryIOU }) => { 
    useEffect(() => {
        Materialize.AutoInit();
    })   

    const handleShowIOU = (id) =>{
        onShowIOU(id);
    }

    const handleUpload = (id) => {
       onUploadIOU(id);
    }

    const handleDelivery = (id) => {
        onDeliveryIOU(id);
    }

    const actionScreen = (req) => {
        //console.log('req.request',req.request);
        const donwloadUrl = req.documents && req.documents.filter(doc => {
            return doc.name === 'Agreement Draft';
        });
        //console.log('donwloadUrl',donwloadUrl[0].dataURL);
        switch(req.request){
            case 'Customer Sign':
                return (
                    <React.Fragment>
                        <DownloadLinkButton href={donwloadUrl[0].dataURL} tooltip='Download agreement'/>
                        <UploadLinkButton onClick={(id) => {handleUpload(req.id)}} to='#' tooltip='Upload signed agreement'/>
                    </React.Fragment>
                )
            case 'Delivery':
                return <NextLinkButton onClick={(id) => {handleDelivery(req.id)}} to='#' tooltip='Create DN'/> ;
            case 'Approval':
                return <NextLinkButton onClick={(id) => {handleShowIOU(req.id)}} to='#' tooltip='Approve/Reject '/> ;
            default:
                return null;    
        }
    }

    //const { todos } = props;
    //console.log('iouApproval', todos);
    const requests = todos &&  todos.map(req => {
        const requsted = req.request !== 'Customer Sign' ? (
            <p>Requested: <span className="red-text text-lighten-1">{req.requested}</span> {moment(req.requested_at.toDate()).calendar()} </p> 
        ) : null;
        const divApproval = req.divisionalApproval !== undefined ? (
            <p>Approved: <span className="red-text text-lighten-1 div-approval">{req.divisionalApproval}</span> {moment(req.divisionalApproval_at.toDate()).calendar()} </p> 
        ) : null;
        const orgApproval = req.organizationalApproval !== undefined ? (
            <p className='org-approval'><span className="red-text text-lighten-1">{req.organizationalApproval}</span> {moment(req.organizationalApproval_at.toDate()).calendar()} </p> 
        ) : null;

        const iouDetails =  req.request !== 'Delivery' ? (
            <div className="box">
                <div className="row ">
                    <div className="col s4">
                        <p>Customer</p>
                    </div>
                    <div className="col s8">
                        <p>{req.customer}</p>
                    </div>
                </div>
                <div className="row ">
                    <div className="col s4">
                        <p>Project</p>
                    </div>
                    <div className="col s8">
                        <p>{req.projectName.toUpperCase()}</p>
                    </div>
                </div>
                <div className="row ">
                    <div className="col s4">
                        <p>Value</p>
                    </div>
                    <div className="col s8">
                        <p>{parseFloat(req.projectValue).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                    </div>
                </div>
                <div className="row ">
                    <div className="col s4">
                        <p>End User</p>
                    </div>
                    <div className="col s8">
                        <p>{req.endUser.toUpperCase()}</p>
                    </div>
                </div>                        
                <div className="row ">
                    <div className="col s4">
                        <p>IOU Amt.</p>
                    </div>
                    <div className="col s8">
                        <p>{parseFloat(req.iouAmount).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                    </div>
                </div>
            </div>
        ) : (
            <div className="box">
                <div className="row ">
                    <div className="col s4">
                        <p>Customer</p>
                    </div>
                    <div className="col s8">
                        <p>{req.customer}</p>
                    </div>
                </div>
                <div className="row ">
                    <div className="col s4">
                        <p>Project</p>
                    </div>
                    <div className="col s8">
                        <p>{req.projectName.toUpperCase()}</p>
                    </div>
                </div>
                <div className="row ">
                    <div className="col s4">
                        <p>End User</p>
                    </div>
                    <div className="col s8">
                        <p>{req.endUser.toUpperCase()}</p>
                    </div>
                </div>                                     
                <div className="row ">
                    <div className="col s4">
                        <p>IOU Amt.</p>
                    </div>
                    <div className="col s8">
                        <p>{parseFloat(req.iouAmount).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                    </div>
                </div>  
                <div className="row ">
                    <div className="col s4">
                        <p>Purpose</p>
                    </div>
                    <div className="col s8">
                        <p>{req.iouPurpose.toUpperCase()}</p>
                    </div>
                </div> 
                <div className="row ">
                    <div className="col s4">
                        <p>Period</p>
                    </div>
                    <div className="col s8">
                        <p>{req.iouPeriod} Days</p>
                    </div>
                </div>
            </div>
        );
        
        return( 
            <div className="card todo" key={req.id}>
                <div className="card-content">
                    <div className="row">
                        <div className="col s10">
                            <span className="card-title grey-text text-darken-3">IOU {req.request}</span>
                        </div>
                        <div className="col s2 btn-edit">
                            {
                                actionScreen(req)
                            }
                        </div>
                    </div>
                    {iouDetails}
                    <div className="grey-text text-darken-1 todo-footer">
                        {requsted}
                        {divApproval}
                        {orgApproval}
                    </div>
                    
                </div>
            </div>
        )
    });
    return ( 
        <div>
            {requests}
        </div>
     );
}

const mapStateToProps = (state) =>{
    return{
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        todos: state.firestore.ordered.iouTodos
    }
}
 
export default compose(
    connect(mapStateToProps),
    firestoreConnect((ownProps) => [
        {
            collection: 'organizations/' + ownProps.profile.account + '/requests/todos/iou',
            storeAs: 'iouTodos',
            where: ["todo", "==", ownProps.auth.uid]
        }
    ])
)(IOUTodos);