import React, {useState, useEffect } from 'react';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { DeleteLinkButton, CollapseButton } from '../../utils/components/buttons/LinkButtons';

const ProductList = ({data, logData, onItemSelected, onItemDelete, onViewLog, mode='save'}) => {    
    useEffect(() => {
        Materialize.AutoInit();
    },[]);

    const [logView, setLogView] =  useState(false);
    const [item, setItem] = useState('');

     /* Defactor data from props */
    //console.log('products',data);
    const orderedArray = Object.keys(data).map((key) => [key, data[key]]);    
       
    let ordered = [];
    orderedArray.map(item => {    
        ordered.push(item.pop());
        return null;
    }); 

    const handleItemDelete = (id) => {
        onItemDelete(id);
    }

    const handleViewLog = (id) => {
        //onViewLog(id);
        //console.log("id", id);
        const log = document.querySelector(".id-" + id);
        
        if(item !== id){
            if(item !== ''){
                const prevLog= document.querySelector(".id-" + item);
                prevLog.style.display = 'none';
            }
            log.style.display = 'table-row';            
            onViewLog(id);
        }else{
            log.style.display = logView === false ? 'table-row' : 'none';
        }
        
        setLogView(!logView);
        setItem(id);
    }

    const logs = logData ? (
        logData.map(log => {
             return(
                <div className="row" key={log.logId}>
                    <div className="col s3">{log.userName}</div>
                    <div className="col s7">{log.message}</div>
                    <div className="col s2">{log.logTime}</div>
                </div>
             );                                
         }) 
     ) : null;

     const products = ordered ? (
        ordered.map(product => {
             return(
                 <React.Fragment key={product.serialNumber}>
                <tr className="list" >   
                    <td onClick={(serialNumber) => {handleItemSelected(product.serialNumber)}}>{product.serialNumber}</td>                         
                    <td>{product.modelNumber}</td>
                    <td>{product.warrantyStatus}</td>
                    <td>{product.faultDescription}</td>
                    {mode !== 'save' ? <td> 
                        {product.status} 
                        <CollapseButton
                        onClick={(serialNumber) => {handleViewLog(product.serialNumber)}}
                        tooltip='Log'
                        /></td> : null}
                    <td>
                        <DeleteLinkButton 
                        to='#'
                        onClick={(id) => {handleItemDelete(product.serialNumber)}}
                        bg='grey lighten-5'
                        />
                    </td>
                </tr>
                {mode !== 'save' ?
                <tr style={{display: 'none'}} className={'id-' + product.serialNumber}>
                    <td colSpan = "6">{logs}</td>
                </tr> : 
                null
                }
                
                </React.Fragment>
             );                                
         }) 
     ) : null;

     

    const handleItemSelected = (serialNumber) => {
        onItemSelected(serialNumber);
    }

    return ( 
        <div className="table-overflow">
            <table className=" highlight">
                <thead>
                    <tr>
                        <th data-field="id">Serial Number</th>
                        <th data-field="model">Model</th>
                        <th data-field="name">Warranty Status</th>
                        <th data-field="description">Fault Description</th>
                        {mode !== 'save' ? <th data-field="status" className="collapse-head">Status</th> : null}
                        <th data-field="edit" className="icon-head"></th>
                    </tr>
                </thead>

                <tbody>
                    {products}
                </tbody>
            </table>
        </div>       
     );
}
 
export default ProductList;