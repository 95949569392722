import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ExportSpareParts, closeControl } from '../../store/actions/sparePartsActions';

const ExportSpares = ({data, onExportCompleted}) => {    
    const dispatch = useDispatch();

    const profile = useSelector(state => state.firebase.profile);
    const duplicate = useSelector(state => state.spares.duplicate);
    const exportStat = useSelector(state => state.spares.exportStat);

    useEffect(() => {
        dispatch(ExportSpareParts(data, profile.account));
    },[dispatch, data, profile.account])

    const handleClose = () => { 
        dispatch(closeControl());         
        onExportCompleted();   
    }
    
      
  

    const heading = exportStat.queued === (exportStat.uploaded + exportStat.failed) ? 'Export completed' : 'Exporting products...';
    const failedProducts = duplicate && duplicate.map(item => {
        return(
        <li className="grey-text" key={item.partsSku}>{item.partsSku}  -  {item.sparePartName}</li>
        )
    })

    /*
        Value of exportStat.control decides the view state
    */    
    return ( 
        <React.Fragment>
        <div id="message" className={'message ' + exportStat.control}>
            <div className="right"><Link to='#' onClick={handleClose}><i className="material-icons grey-text text-darken-2">close</i></Link></div> 
            <div className="modal-content"> 
                <div className="card z-depth-1 user-summary modal-card">                
                    <div className="card-content grey-text text-darken-3">
                        <span className="card-title">{heading}</span>
                        <p className="red-text text-lighten-1"></p>
                        <div className="contact">
                            <p>Total Queued products: {exportStat.queued} </p>
                            <p>Uploaded : {exportStat.uploaded}</p>
                            <p>Failed : {exportStat.failed}</p>
                        </div>
                    </div>
                    <div className="card-action">
                        <p className="grey-text">Failed products</p>  
                        <ul className="action-li">
                           {failedProducts}
                        </ul>
                    </div>
                </div>
            </div>
        </div>        
        <div id="overlay" className={'message-overlay ' + exportStat.control}></div>
        </React.Fragment>
     );
}
 
export default ExportSpares;