import React from 'react';
import { Link  } from 'react-router-dom';

const OrgUnitList = (props) => {
    /* Defactor auth and org from props */
    const { org } = props;
    const organizations = org ? (
        org.map(organization => {
            return(
                 <tr className="list" key={organization.id}>                            
                    <td>{organization.name}</td>
                    <td>{organization.details}</td>
                    <td><Link to={'/org/' + organization.id} ><i className="material-icons grey-text text-darken-3">edit</i></Link></td>
                </tr>
            );                                
        }) 
    ) : null;

    return ( 
        <table className="highlight">
            <thead>
                <tr>
                    <th data-field="id">Organizational Unit Name</th>
                    <th data-field="name">Details</th>
                    <th data-field="name" className="icon-head"></th>
                </tr>
            </thead>

            <tbody>
                {organizations}
            </tbody>
        </table>
     );
}
 
export default OrgUnitList;