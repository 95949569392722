import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Materialize from 'materialize-css/dist/js/materialize.min.js'

//import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import { useEffect } from 'react';
import { createUser } from '../../store/actions/userActions';

const CreateUser = (props) => {      
    const dispatch = useDispatch();

    useEffect(() => {
        Materialize.AutoInit();
    },[])

    const [componentState, setCompoentState] = useState({});

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);  
    const profile = useSelector(state => state.firebase.profile);   
    const validation = useSelector(state => state.user.validation); 
    const userError = useSelector(state => state.user.userError); 

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/usr'/>;

    const handleChange = (e) => {
        setCompoentState({
            ...componentState,
            [e.target.id]: e.target.value
        })
    }
    
    const powerPrivilegeOpt = profile ? (
        <option value="power">Power</option>
    ) : null;

    // const handleOrganizationSelection = (tags) => {
    //     setCompoentState({
    //         ...componentState,            
    //         account: tags.value,
    //         organization: tags.label
    //     })
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('props state',componentState);
        dispatch(createUser(componentState));
    }

    return ( 
        <div className="container user">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">Create User</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/usr' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="email">Email</label>
                        <input id="email" onChange={handleChange} type="email" className="validate" required/>
                        {/* <AutoComplete onSelected={handleOrganizationSelection} collection={'organizations'} search={'name'} placeholder={'Select organization'}/> */}
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <select id="privilege" defaultValue="" onChange={handleChange}>
                            <option value="" disabled >Choose your option</option>
                            {powerPrivilegeOpt}
                            <option value="admin">Admin</option>
                            <option value="finance">Finance</option>
                            <option value="logistics">Logistics</option>
                            <option value="service-manager">Service Manager</option>
                            <option value="service-personnel">Service Personnel</option>
                            <option value="user">User</option>
                        </select>
                        <label>Privilege</label>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col s12 m6 l6 input-field">
                        <label htmlFor="email">Email</label>
                        <input id="email" onChange={handleChange} type="email"/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="password">Password</label>
                        <input id="password" onChange={handleChange} type="password"/>
                    </div> */}
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="First Name">First Name</label>
                        <input id="firstName" onChange={handleChange} type="text" className="validate" required/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Last Name">Last Name</label>
                        <input id="lastName" onChange={handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Designation">Designation</label>
                        <input id="designation" onChange={handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Mobile Number">Mobile Number</label>
                        <input id="mobileNumber" onChange={handleChange} type="tel" className="validate" required/>
                    </div> 
                </div>
                <div className="input-field">
                    <button className="btn red lighten-1 z-depth-0">Create</button>                        
                    <div className="red-text center">
                        {userError ? <p>{userError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default CreateUser;

// const mapStateToProps = (state) => {   
//     console.log(state); 
//     return{
//         auth: state.firebase.auth,
//         users: Object.keys(state.user.filteredData).length !== 0  ? state.user.filteredData : state.user.data ? state.user.data : null 
//         // divisionError: state.division.divisionErr,
//         // validation: state.division.validation
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return{
//         loadUsers: (search, pagination, pageInfo) => dispatch(loadUsers(search, pagination, pageInfo)),
//         createDivision: (division) => dispatch(createDivision(division))
//     }
// }
 
// export default connect(mapStateToProps, mapDispatchToProps)(CreateDivision);