import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const SignedOutLinks = () => {
    return ( 
       <div>
            <Link to='/' className="sidenav-close brand-logo left red-text text-lighten-1">HTL Core</Link>   
            <ul id="nav-mobile"> 
                <div className="right hide-on-med-and-down">
                    <li><NavLink to='/signup' className="sidenav-close grey-text text-darken-3">Sign Up</NavLink></li>
                    <li><NavLink to='/signin'className="sidenav-close grey-text text-darken-3">Login</NavLink></li>
                </div>  
            </ul>
       </div>
     );
}

export default SignedOutLinks;