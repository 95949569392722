import React from 'react';
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PrintDeliveryNote } from '../../store/actions/rmaActions';

import { PrintButton } from '../../utils/components/buttons/LinkButtons';

const DeliveryNoteList = ({data}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleOnSelect = (id) => {
        history.push('/dln/' + id);
    }

    
    const dnList = data ? data.map(item => {  
        return(                
           <tr onDoubleClick={() => {handleOnSelect(item.id)}} className='list' key={item.id}> 
               <td className="td0">{item.dnDate}</td>   
               <td className="td1">{item.dnNo}</td>
               <td>{item.customerName}</td> 
               <td className="right">{item.amount.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</td> 
               <td>
                   <PrintButton onClick={() => {handleOnPrint(item.id)}} />
               </td>
           </tr>
        );                                
    }) : null;

    const handleOnPrint = (dnId) => {
        console.log('dnList',data);       
        const dn = data  !== null ? data.filter(item => {
            return item.id === dnId
         }) : [];
         
        console.log('DN', dn);
        dispatch(PrintDeliveryNote(dn));
    }

    return ( 
        <div className="section sec-details">  
            <div className="table-overflow">
                <table className=" highlight">
                    <thead>
                        <tr>
                            <th data-field="date">Date</th>
                            <th data-field="rmaNumber">DN Number</th>
                            <th data-field="customerName">Customer Name</th>
                            <th data-field="customerName" className="right">Amount</th>
                            <th data-field="edit" className="icon-head"></th>
                        </tr>
                    </thead>

                    <tbody>
                        {dnList}
                    </tbody>
                </table>
            </div>
        </div>
     );
}
 
export default DeliveryNoteList;