import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import { signUp } from '../../store/actions/authActions';

class SignUp extends Component {
    state = { 
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        account: ''
     }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signUp(this.state);
    }
    render() { 
        const {auth, authError} = this.props;
        if(auth.uid) return <Redirect to='/'/>;
        return ( 
            <div>
                <div className="container auth">
                    <div className="row">                   
                        <div className="card  col s12 m8 l8 offset-m2 offset-l2">
                            <form onSubmit={this.handleSubmit} className="white">                                
                                <h5 className="grey-text text-darken-3">Sign Up</h5>
                                <div className="row">                                    
                                    <div className="col s12 m6 l6 input-field">
                                        <label htmlFor="email">Email</label>
                                        <input id="email" onChange={this.handleChange} type="email"/>
                                    </div>
                                    <div className="col s12 m6 l6 input-field">
                                        <label htmlFor="password">Password</label>
                                        <input id="password" onChange={this.handleChange} type="password"/>
                                    </div>
                                    <div className="col s12 m6 l6 input-field">
                                        <label htmlFor="firstName">First Name</label>
                                        <input id="firstName" onChange={this.handleChange} type="text"/>
                                    </div>
                                    <div className="col s12 m6 l6 input-field">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input id="lastName" onChange={this.handleChange} type="text"/>
                                    </div>
                                    <div className="col s12 input-field">
                                        <label htmlFor="account">Account</label>
                                        <input id="account" onChange={this.handleChange} type="text"/>
                                    </div>
                                </div>
                                <div className="input-field right">
                                    <button className="btn red lighten-1 z-depth-0">Sign Up</button>                                    
                                </div>
                                <div className="red-text center">
                                    {authError ? <p>{authError}</p> : null}
                                </div>
                            </form>
                        </div>                
                    </div>                
                </div>                            
                <div className="container"> 
                    <div className="row">                   
                        <div className="col s12 hide-on-med-and-up">                               
                                <span>Already have an accoount? <NavLink to='/signin'className="red-text text-darken-3">Login</NavLink> here.</span>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        signUp : (newUser) => dispatch(signUp(newUser))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);