import axios from 'axios';
import {Signature} from '../../utils/GenerateSignature';

const account = 'organizations';
//const URL="https://cors-anywhere.herokuapp.com/https://onlinecart.ae/core-order-api.php";

export const GetOrderStat = (range) => {
    return(dispatch, getState, { getFirebase }) => {    
        /*Validate auhorisation */
        const loginId = getState().firebase.auth.uid;
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();
        if(loginId !== undefined && profile.account !== undefined){
            firestore.collection(account)
            .doc(profile.account)
            .collection('divisions') 
            .where('membersList', 'array-contains', profile.userId)
            .get()
            .then((snapshot) => { 
                //const division = {data:{}}
                let location = '';
                if(!snapshot.empty){
                    const divisions = {
                        locations: []
                    }
                    if(profile.privilege === 'power' 
                    || profile.privilege === 'logistics' 
                    || profile.privilege === 'finance' 
                    ){
                        divisions.locations = [...divisions.locations, 'HTL'];
                        location += "all";
                    }else{
                        snapshot.docs.map(doc => {
                            divisions.locations = [...divisions.locations, doc.data().name];
                            location +=  "'" + doc.data().name + "',";
                            return null;
                        });
                    }
                    
                    dispatch({type: 'LOAD_DIVISIONS', divisions});
                    const payload = {
                        query: 'orderStat',
                        location: location !== 'all' ? divisions.locations : location
                    }
                    const encoded_hash = Signature(JSON.stringify(payload));

                    const api = axios.create({
                        baseURL: `https://onlinecart.ae/core-order-api.php/`,
                        headers: {
                            'X-HTL-CORE-HMAC-SHA256': encoded_hash
                        }
                    });
        
                    api.get('/',{
                        params: {
                            query: 'orderStat',
                            range: range,
                            location: location !== 'all' ? divisions.locations : location
                        }
                    }).then( res => {
                        console.log('result', res);
                        dispatch({type: 'ORDER_STAT', stat: res.data});
                    })
                    .catch(err => {
                        //console.log(err);
                        dispatch({type: 'ORDER_ERROR', err});
                    });
                }else if(profile.privilege === 'power' 
                || profile.privilege === 'logistics' 
                || profile.privilege === 'finance' 
                ){
                    location = 'all';
                    const divisions = {
                        locations: ['HTL']
                    }
                    dispatch({type: 'LOAD_DIVISIONS', divisions});
                    const payload = {
                        query: 'orderStat',
                        location: location
                    }
                    const encoded_hash = Signature(JSON.stringify(payload));

                    const api = axios.create({
                        baseURL: `https://onlinecart.ae/core-order-api.php/`,
                        headers: {
                            'X-HTL-CORE-HMAC-SHA256': encoded_hash
                        }
                    });
        
                    api.get('/',{
                        params: {
                            query: 'orderStat',
                            range: range,
                            location: location
                        }
                    }).then( res => {
                        console.log('result', res);
                        dispatch({type: 'ORDER_STAT', stat: res.data});
                    })
                    .catch(err => {
                        //console.log(err);
                        dispatch({type: 'ORDER_ERROR', err});
                    });
                }
            });
        } 
    }
}


export const GetOrderList = (pageInfo, pagination) => {
    return(dispatch, getState, { getFirebase }) => {
        /*Validate auhorisation */
        const loginId = getState().firebase.auth.uid;
        //console.log('login details', loginId);
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();
        
        let location = '';
        if(loginId !== undefined && profile.account !== undefined){
            const divisions = getState().order.divisions;
            console.log('profile privilege', profile.privilege);
            if(divisions){
                if(profile.privilege === 'power' 
                    || profile.privilege === 'logistics' 
                    || profile.privilege === 'finance' 
                ){
                    location = 'all';
                }else{
                    location = divisions.locations;
                }
                
                const payload = {
                    query: 'orderList'
                }
                const encoded_hash = Signature(JSON.stringify(payload));
                
                const api = axios.create({
                    baseURL: `https://onlinecart.ae/core-order-api.php/`,
                    headers: {
                        'X-HTL-CORE-HMAC-SHA256': encoded_hash
                    }
                });
    
                api.get('/',{
                    params: {
                        query: 'orderList',
                        location: location,
                        choice: pageInfo.statusFilter,
                        pageInfo: pageInfo,
                        pagination: pagination
                    }
                })
                .then(res => {
                    //console.log(res);
                    dispatch({type: 'ORDER_LIST', data: res.data});
                })
                .catch(err => {
                    //console.log(err);
                    dispatch({type: 'ORDER_ERROR', err});
                });
            }else{
                firestore.collection(account)
                .doc(profile.account)
                .collection('divisions') 
                .where('membersList', 'array-contains', profile.userId)
                .get()
                .then((snapshot) => { 
                    //const division = {data:{}}
                    if(!snapshot.empty){
                        snapshot.docs.map(doc => {
                            location +=  "'" + doc.data().name + "',";
                            return null;
                        });
                    }

                    if(profile.privilege === 'power' 
                    || profile.privilege === 'logistics' 
                    || profile.privilege === 'finance' 
                    ){
                        location = 'all';
                    }

                    // Hash encode data 
                    const payload = {
                        query: 'orderList'
                    }
                    const encoded_hash = Signature(JSON.stringify(payload));
                    
                    const api = axios.create({
                        baseURL: `https://onlinecart.ae/core-order-api.php/`,
                        headers: {
                            'X-HTL-CORE-HMAC-SHA256': encoded_hash
                        }
                    });
        
                    api.get('/',{
                        params: {
                            query: 'orderList',
                            location: location,
                            choice: pageInfo.statusFilter,
                            pageInfo: pageInfo,
                            pagination: pagination
                        }
                    })
                    .then(res => {
                        //console.log(res);
                        dispatch({type: 'ORDER_LIST', data: res.data});
                    })
                    .catch(err => {
                        //console.log(err);
                        dispatch({type: 'ORDER_ERROR', err});
                    });
                });
            }
        } 
    }
}

export const GetOrderItems = (orderId) => {
    return(dispatch, getState) => {
          /*Validate auhorisation */
          const loginId = getState().firebase.auth.uid;
          if(loginId !== undefined){

            const payload = {
                query: 'orderItems'
            }
            const encoded_hash = Signature(JSON.stringify(payload));
            
            const api = axios.create({
                baseURL: `https://onlinecart.ae/core-order-api.php/`,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.get('/',{
                params: {
                    query: 'orderItems',
                    id: orderId
                }
            })
            .then(res => {
                //console.log(res);
                dispatch({type: 'ORDER_ITEMS', items: res.data});
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'ORDER_ERROR', err});
            });
        } 
         
    }
}

export const UpdateOrderStatus = (order, status) => {
    return(dispatch, getState) => {
          /*Validate auhorisation */
          const loginId = getState().firebase.auth.uid;
          if(loginId !== undefined && order.id !== null){
            //const URL="/core-order-api.php"; 

            // Hash encode data 
            const payload = {
                order: {
                    ...order,
                    status: status
                },
                query: 'updateStatus',
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            /** */
            const api = axios.create({
                baseURL: `https://onlinecart.ae/core-order-api.php/`,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                //console.log(res);
                dispatch({type: 'ORDER_UPDATE', order: res.data});
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'ORDER_ERROR', err});
            });
        } 
         
    }
}

export const ClearItems = () => {
    return(dispatch, getState) => {
        dispatch({type: 'CLEAR'});
    }
}

export const SetChoice = (ch) => {
    return(dispatch, getState) => {
        const choice = getState().order.choice;
        if(choice !== ch) dispatch({type: 'SET_CHOICE', choice:ch});
    }
}

export const ResetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().order.validation;
        if(validation) dispatch({type: 'RESET_VALIDATION'})
    }
}

export const CloseControl = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        dispatch({type: 'CLOSE_CONTROL'})
    }
}