const initState = {
    data: null,
    repairReg: null,
    dnData: null,
    dnReg:null,
    filteredData: null,
    filteredRepairData: null,
    log: null,
    stat: {},
    loadData: true,
    pageInfo: {},
    choice:'all',
    items: [],
    componentsUsed: [],
    customer: {},
    selectedIndex: 0,
    validation: null
}

const rmaReducer = (state= initState, action) => {
    switch(action.type){
        case 'RMA_ERROR':
            console.log('Error in getting rma API', action.err);
            return{
                ...state,
                items: [],
                rmaError: action.err.message
            }
        case 'CREATE_RMA':
            console.log('Created new RMA');
            return{
                ...state,
                validation: 'Passed'
            }
        case 'DELETE_RMA':
            const newData = state.data.filter(rma => rma.id !== action.rmaId );            
            console.log('Deleted RMA', action.rmaId);
            return{
                ...state,
                data: newData,
                validation: 'Passed'
            }
        case 'RMA_STAT':
            console.log('Loading rma status', action.stat);
            return{
                ...state,
                stat: action.stat,
                rmaError: null
            }
        case 'RMA_LIST':
            console.log('Loading rma list', action.data);
            return{
                ...state,
                data: action.data.RMAList,
                pageInfo: {
                    ...state.pageInfo, 
                    currentPage: action.data.CurrentPage,
                    lastPage: action.data.LastPage,
                    totalRecords: action.data.TotalRows
                },
                rmaError: null
            }                                      
        case 'COMPONENTS_USED':
            console.log('Loading components used', action.components);
            return{
                ...state,
                componentsUsed: action.components,
                rmaError: null
            }                                                  
        case 'DELETE_COMPONENT_USED':
            console.log('Delete components used', action.sku);
            let newList = state.componentsUsed.filter(item => {
                return action.sku !== item.value
            });
            return{
                ...state,
                componentsUsed: newList,
                rmaError: null
            }             
        case 'RMA_DN_LIST':
            console.log('Loading rma waiting delivery', action.data);
            return{
                ...state,
                dnData: action.data.RMAList,
                rmaError: null
            }                         
        case 'RMA_DN_ITEMS':
            console.log('Loading rma delivery items', action.dnDetails);
            return{
                ...state,
                customer: action.dnDetails.rma,
                items: action.dnDetails.data,
                rmaError: null
            }  
        case 'RMA_UPDATE_SERVICECHARGE':
            console.log('Handle rma service charge', action.data);
            const item = state.items[action.data.serialNumber];
            const newItem = {data:{}};
            const docData = {
                id: item.id,
                serialNumber: item.serialNumber,
                modelNumber: item.modelNumber,
                warrantyStatus: item.warrantyStatus,
                rmaNo: item.rmaNo,
                rmaDate: item.rmaDate,
                serviceCharge: action.data.serviceCharge
            }
            newItem.data[action.data.serialNumber] = docData;
            return{
                ...state,
                items: {...state.items, ...newItem.data}
            }             
        case 'CREATE_DELIVERY_NOTE':
            console.log('Created new delivery note');
            return{
                ...state,
                validation: 'Passed'
            }            
        case 'DELIVERY_NOTE_REG':
            console.log('Loading delivery note reg', action.data);
            return{
                ...state,
                dnReg: action.data.DNList,
                pageInfo: {
                    ...state.pageInfo, 
                    currentPage: action.data.CurrentPage,
                    lastPage: action.data.LastPage,
                    totalRecords: action.data.TotalRows
                },
                rmaError: null
            }         
        case 'REPAIR_REG_LIST':
            console.log('Loading repair register', action.data);
            return{
                ...state,
                repairReg: action.data.RepairReg,
                rmaError: null
            }
        case 'REPAIR_REG_SEARCH_RESULT':
            console.log('Loading rma list', action.data);
            return{
                ...state,
                filteredRepairData: action.data.RepairRegList,
                pageInfo: {
                    ...state.pageInfo, 
                    currentPage: action.data.CurrentPage,
                    lastPage: action.data.LastPage,
                    totalRecords: action.data.TotalRows
                },
                rmaError: null
            }
        case 'RMA_ITEMS':
            console.log('Loading rma items', action.itemList);
            return{
                ...state,
                items: action.itemList.data,
                rmaError: null
            }
        case 'UPDATE_RMA':
            console.log('RMA details updated');
            return{
                ...state,
                validation: 'Passed',
                rmaError: null
            }
        case 'RMA_LOG':
            console.log('Loading rma log', action.data);
            return{
                ...state,
                log: action.data.Log,
                rmaError: null
            } 
        case 'SET_CHOICE':
            console.log('Reset choice', action.choice);
            return{
                ...state,
                choice: action.choice
            }
        case 'CLEAR':
            console.log('Clear items');
            return{
                ...state,
                items: []
            }
        case 'CLEAR_SEARCH':
            console.log('Clear search');
            return{
                ...state,
                filteredData: [],
                selectedIndex: 0
            }
        case 'STATUS_SELECTION':
            return{
                ...state,
                selectedIndex: action.index
            }
        case 'RESET_VALIDATION':
            return{
                ...state,
                validation: null
            }
        case 'CLOSE_CONTROL':
            console.log('Closing rma control');
            return{
                ...state,                                
                orderStat: {...state.rmaStat, 
                control: 'close' },
                validation: null
            }
        default:
            return state;
    }
}

export default rmaReducer;