const initState = {
    data: {},
    filteredData: {},
    userDivisions: {},
    loadData: true,
    pageInfo: {},
    validation: null
}
const divisionReducer = (state = initState, action) => {
    switch(action.type){
        case 'CREATE_DIVISION_ERROR':
            console.log('Create division error', action.err);
            return{
                ...state,
                divisionError: action.err.message,
                validation: null
            }
        case 'CREATE_DIVISION':
            console.log('Created new division', action.division);
            return{
                ...state,
                data: {...state.data, ...action.division.data},
                divisionError: null,
                validation: 'Passed'
            }
        case 'UPDATE_DIVISION_ERROR':
            console.log('Update division error', action.err);
            return{
                ...state,
                divisionError: action.err.message,
                validation: null
            }
        case 'UPDATE_DIVISION':
            console.log('Updated division', action.division);
            return{
                ...state,
                data: {...state.data, ...action.division.data},
                divisionError: null,
                validation: 'Passed'
            }
        case 'DELETE_DIVISION_ERROR':
            console.log('Update division error', action.err);
            return{
                ...state,
                divisionError: action.err.message
            }
        case 'DELETE_DIVISION':
            console.log('Updated division', action.division);
            return{
                ...state,
                data: {...state.data, ...action.division.data},
                divisionError: null
            }
        case 'FILTER_DIVISION':
            console.log('Fileterd List', action.division);            
            return{
                ...state,             
                data: {...state.data, ...action.division.data},
                filteredData: action.division.data,
                divisionError: null
            } 
        case 'LOAD_DIVISION':
            console.log('Loading division List', action);
            return{
                ...state,               
                data: {...state.data, ...action.division.data},
                pageInfo: action.division.pageInfo,
                filteredData: {},
                loadData: false,
                divisionError: null
            }  
        case 'LOAD_DIVISION_ERROR':
            console.log('Load division Error', action.err);
            return{
                ...state,
                divisionError: action.err.message,                
                filteredData: {}
            }
        case 'LOAD_ERROR':
            console.log('Load division Error');
            return{
                ...state,
                divisionError: 'load error'
            }
        case 'GET_USER_DIVISIONS':
            console.log('Loading user division List', action);
            return{
                ...state,                       
                userDivisions: action.division.data
            }  
        case 'PAGE_ERROR':
            console.log('Page Error');
            return{
                ...state,
                divisionError: 'Page Error'
            }
        case 'VALIDATION_ERROR':
            console.log('Validation error', action.err);
            return{
                ...state,  
                validation: action.err
            }
        case 'RESET_VALIDATION':
            return{
                ...state,
                validation: null
            }
        default:
            return state;
    }   
}
 
export default divisionReducer;