import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CloseCompatible } from '../../store/actions/sparePartsActions';

import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import { DeleteLinkButton } from '../../utils/components/buttons/LinkButtons';
import Materialize from 'materialize-css/dist/js/materialize.min.js';

const Compatible = ({data, onCompatibleSpareAdd, onCompatibleSpareDelete, edit=false}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        Materialize.AutoInit();
    },[]);

    const [clearField, setClearField] = useState();
    const [componentState, setComponentState] = useState({
        productList: {}
    });
    
    const viewState = useSelector(state => state.spares.compatibleViewState);   
    const profile = useSelector(state => state.firebase.profile); 

    const handleClose = () =>{
        console.log("close clicked");
        dispatch(CloseCompatible());
    }

    const handleAddCompatibleSpare = () => {
        console.log("Added: " + componentState.sparePartName);        
        onCompatibleSpareAdd(componentState.sku);
        setComponentState({
            ...componentState,
            sku: null,
            sparePartName: null
        });  
        setClearField({label:  'Search Parts'});
    }

    const handlePartsSelection = (tags) => {
        setClearField();
        setComponentState({
            ...componentState,
            sku: tags.value,
            sparePartName: tags.label
        });  
    }

    const handleItemDelete = (compatibleSpare) => {
        onCompatibleSpareDelete(compatibleSpare);
    }

    const parts = data ? (
        data.map(item => {
             return(
                <tr className="list" key={item.spId}>   
                    <td>{item.sparePartName}</td>
                    <td>{item.boardName}</td>
                    <td>{item.partsSku}</td>                    
                    <td>{item.location}</td>                  
                    <td className="center">{item.stockQuantity}</td>                  
                    <td className="right">{item.price}</td>   
                    <td>
                        {edit && profile.privilege === 'service-manager' ? 
                        <DeleteLinkButton
                        to='#'
                        onClick={(id) => {handleItemDelete(item.partsSku)}}
                        bg='grey lighten-5'
                        />
                        : null
                    }
                        
                    </td>
                </tr>
             );                                
         }) 
     ) : null;
     
    return ( 
        <div id="message" className={'message top-1 ' + viewState}>
            <div className="modal-content comp">                 
                <div className="card z-depth-1  modal-card">  
                    <div className="right"><Link to='#' onClick={handleClose}><i className="material-icons grey-text text-darken-2">close</i></Link></div>              
                    <div className="card-content grey-text text-darken-3">
                        <div className="row">
                            <div className="input-field col s12 m4 l4">                                
                                <span className="card-title">Compatible Parts</span>
                            </div> 
                            {edit  && profile.privilege === 'service-manager' ? 
                                <React.Fragment>
                                <div className="input-field col s12 m6 l6">
                                    <i className="material-icons prefix">search</i>
                                    <AutoComplete
                                    onSelected={handlePartsSelection} 
                                    collection={'spareparts'} search={'compatibleParts'} account={profile.account}
                                    placeholder={'Search Parts'}
                                    value={clearField}                                
                                    clearSearchParam={false}
                                    api={'rma'}
                                    />
                                    
                                </div>
                                <div className="input-field col s12 m2 l2">
                                <button onClick={handleAddCompatibleSpare} className="btn red lighten-1 z-depth-0">Add</button> 
                                </div>
                                </React.Fragment>
                                : null
                            }
                            
                        </div>
                        <div className="table">
                            <table className=" highlight">
                                <thead>
                                    <tr>
                                        <th data-field="description">Sapre Part Name</th>
                                        <th data-field="boardName">Board Name</th>
                                        <th data-field="sapNo">SAP No.</th>
                                        <th data-field="location">Location</th>
                                        <th data-field="stock">Stock</th>
                                        <th className="right" data-field="price">Price</th>
                                        <th data-field="edit" className="icon-head"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {parts}
                                </tbody>
                            </table>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Compatible;