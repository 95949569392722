import React from 'react';
import { CloseButton } from './components/buttons/LinkButtons';

const PDFViewer = ({pdfURL, viewStatus = 'close', onClose}) => {
    
    const handleClose = () => {
        /**Set viewStatus to close from parent control */
        onClose();
    }

    return ( 
        <React.Fragment>
            <div id="message" className={'big message ' + viewStatus}>
                <div className="right"><CloseButton to='#'  onClick={() =>  handleClose()} bg='grey lighten-5'/> </div> 
                <div className="modal-content"> 
                    <embed src={pdfURL} type="application/pdf" className='pdf-view'/>
                </div>
            </div>     
              
            <div id="overlay" className={'message-overlay ' + viewStatus}></div>
        </React.Fragment>
     );
}
 
export default PDFViewer;