const initState = {
    data: {},
    filteredData: {},
    loadData: true,
    pageInfo: {},
    validation: null
}
const orgReducer = (state = initState, action) => {
    switch(action.type){
        case 'CREATE_ORG_ERROR':
            console.log('Create Org error', action.err);
            return{
                ...state,
                orgError: action.err.message,
                validation: null
            }
        case 'CREATE_ORG':
            console.log('Created new org', action.org);
            return{
                ...state,
                orgError: null,
                validation: 'Passed'
            }
        case 'UPDATE_ORG_ERROR':
            console.log('Update Org error', action.err);
            return{
                ...state,
                orgError: action.err.message,
                validation: null
            }
        case 'UPDATE_ORG':
            console.log('Updated org', action.org.data);
            return{
                ...state,            
                data: {...state.data, ...action.org.data},
                orgError: null,
                validation: 'Passed'
            }
        case 'FILTER_ORG':
            console.log('Fileterd List', action.org);            
            return{
                ...state,             
                data: {...state.data, ...action.org.data},
                filteredData: action.org.data,
                orgError: null,
                validation: null
            } 
        case 'LOAD_ORG':
            console.log('Loading org List', action);
            return{
                ...state,               
                data: {...state.data, ...action.org.data},
                pageInfo: action.org.pageInfo,
                filteredData: {},
                loadData: false,
                orgError: null,
                validation: null
            }  
        case 'LOAD_ORG_ERROR':
            console.log('Load org Error', action.err);
            return{
                ...state,
                orgError: action.err.message,                
                filteredData: {},
                validation: null
            }
        case 'PAGE_ERROR':
            console.log('Page Error');
            return{
                ...state,
                orgError: 'Page Error',
                filteredData: {},
                validation: null
            }
        case 'VALIDATION_ERROR':
            console.log('Validation error', action.err);
            return{
                ...state,              
                filteredData: {},
                validation: action.err
            }
        case 'RESET_VALIDATION':
            return{
                ...state,
                validation: null
            }
        default:
            return state;
    }   
}
 
export default orgReducer;