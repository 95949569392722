import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Materialize from 'materialize-css/dist/js/materialize.min.js';

const CustomerList = (props) => {
    useEffect(() => {
        Materialize.AutoInit();
    },[]);

     /* Defactor data from props */
     const { data } = props;
     //console.log('customers',data);
     const customers = data ? (
        data.map(customer => {
             return(
                <tr className="list" key={customer.id}>                            
                    <td>{customer.code}</td>
                    <td>{customer.name}</td>
                    <td>{customer.city}</td>                    
                    <td>{customer.phone}</td>                    
                    <td>{customer.email}</td>
                    <td><Link to={'/cus/' + customer.id} ><i className="material-icons grey-text text-darken-3">edit</i></Link></td>
                </tr>
             );                                
         }) 
     ) : null;

    return ( 
        <div className="table-overflow">
            <table className=" highlight">
                <thead>
                    <tr>
                        <th data-field="id">Customer Code</th>
                        <th data-field="name">Name</th>
                        <th data-field="description">City</th>
                        <th data-field="description">Phone</th>
                        <th data-field="description">Email</th>
                        <th data-field="edit" className="icon-head"></th>
                    </tr>
                </thead>

                <tbody>
                    {customers}
                </tbody>
            </table>
        </div>
     );
}
 
export default CustomerList;