import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import { getCustomerDetails } from '../../store/actions/customerActions';
import { getOrgDetails } from '../../store/actions/orgActions';
import { getUserDetails } from '../../store/actions/userActions';
import { getProductDetails } from '../../store/actions/productActions';
import { getUserDivisions } from '../../store/actions/divisionActions';
import IOUSummary from './IOUSummary';
import { createIOUDraft, RequestDivisionalApproval } from '../../store/actions/iouActions';

let productId = '';
let eudFlag = true;
let orgFlag = true;
let totalAmount = 0;
const CreateIOU = (props) => {
    const dispatch = useDispatch();
    const [componentState, setComponentState] = useState({
        organizationalApproval_at: ''});
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const [itemName, setItemName] = useState();
    const [willReturn, setWillReturn] = useState('Yes');
    const div = useRef(null);    
    
    let orderedUED = [];
    useEffect(() => {
        Materialize.AutoInit();
        if(org === undefined) orgFlag = true;
        if(orderedUED.length === 0) eudFlag = true;
    })

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);     
    const validation = useSelector(state => state.iou.validation); 
    const iouError = useSelector(state => state.iou.iouError); 
    const profile = useSelector(state => state.firebase.profile); 
    const customer = useSelector(state => state.customer.data);
    const product = useSelector(state => state.product.data);
    const customerDetails = useSelector(state => state.customer.data[componentState.customerId]);
    const userDetails = useSelector(state => state.user.loggedInUser);
    const userEnrolledDivisions = useSelector(state => state.division.userDivisions);
    const org = useSelector(state => state.org.data[profile.account]);

     /*User authentication and redirect checks  */
     if(!auth.uid) return <Redirect to='/signin'/>;
     if(validation === 'Passed') return <Redirect to='/iou'/>;

    /*Create Array of user enrolled divisions from object collection userEnrolledDivisions */
    const orderedUEDArray = Object.keys(userEnrolledDivisions).map((key) => [key, userEnrolledDivisions[key]]);    
       
    
    orderedUEDArray.map(item => {    
        orderedUED.push(item.pop());
        return null;
    });       

    /*Filter out deleted divisions */
    orderedUED = orderedUED.filter(item => {
        return item.status !== 'deleted'
    });

    //console.log('profile.account', profile.account, 'userDetails', userDetails,'orgFlag', orgFlag, 'eudFlag', eudFlag, 'org',org);

    if(profile.account !== undefined && userDetails === null && eudFlag) dispatch(getUserDetails(auth.uid));

    if(profile.account !== undefined  && orgFlag ){
        orgFlag = false;
        dispatch(getOrgDetails(profile.account));
    } 

    if(userDetails !== null && eudFlag){
        eudFlag = false;
        dispatch(getUserDivisions(userDetails.id));
    }

    const userEDList = orderedUED.length ? (
        orderedUED.map(div => {
            return(
                <option key={div.id} value={div.id}>{div.name}</option>
            )     
        })
               
    ) : (        
        null
    );
    
    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value.trim()
        })
    }

    const handlePrefill = (e) => {       
        e.target.value = e.target.value === '' || e.target.value === 'N' ? 'NA' : e.target.value.trim();
    }

    const handleQuantityChange = (e) => {
        //console.log('componentState',componentState);
        const selectedPro = componentState.productList[productId];
        const netAmount = selectedPro.price === 0 ? 0 : (selectedPro.price * e.target.value) + (selectedPro.quantity * e.target.value) * (0.01 * selectedPro.taxPer);
        const pro = {data:{}}
        const docData = {
            ...selectedPro,
            quantity: e.target.value,            
            netAmount: netAmount
        };
        const key = productId;
        pro.data[key] = docData;
        setComponentState({
            ...componentState,
            productList: {...componentState.productList,...pro.data }
        });
        setQuantity(e.target.value);
    }

    const handlePriceChange = (e) => {
        //console.log('productId',productId);
        if(productId !== ''){
            const selectedPro = componentState.productList[productId];
            const netAmount = selectedPro.quantity === 0 ? 0 : (selectedPro.quantity * e.target.value) + (selectedPro.quantity * e.target.value) * (0.01 * selectedPro.taxPer);
            const pro = {data:{}}
            const docData = {
                ...selectedPro,
                price: parseFloat(e.target.value),
                netAmount: netAmount
            };
            const key = productId;
            pro.data[key] = docData;
            setComponentState({
                ...componentState,
                productList: {...componentState.productList,...pro.data }
            });
            setPrice(e.target.value);
        }
        
    }

    const handleCustomerSelection = (tags) => {
        //console.log(componentState);
        setComponentState({
            ...componentState,
            customerId: tags.value,
            customerName: tags.label
        });
        /** Fetch data if not exist */    
        !customer[tags.value] && dispatch(getCustomerDetails(tags.value));
    }

    const handleProductSelection = (tags) => {               
        productId = tags.value;
        
        if(!componentState.productList){
            //console.log('componentState',componentState);
            const pro = {data:{}}
            const docData = {
                id: tags.value,
                name: tags.label,
                quantity: 1,
                price: 0,
                taxPer: 5,
                netAmount: 0,
                willReturn: 'Yes',
                deliveredQuantity: 0,
                returenedQuantity: 0
            };
            const key = tags.value;
            pro.data[key] = docData;

            setComponentState({
                ...componentState,
                productList: {...componentState.productList,...pro.data }
            });
            setQuantity(1);
            setPrice(0);
            setWillReturn('Yes');
            document.getElementById('willReturn').value = 'Yes';
        }else{
            if(!componentState.productList[tags.value]){
                const pro = {data:{}}
                const docData = {
                    id: tags.value,
                    name: tags.label,
                    quantity: 1,
                    price: 0,
                    taxPer: 5,
                    netAmount: 0,
                    willReturn: 'Yes',
                    deliveredQuantity: 0,
                    returenedQuantity: 0
                };
                const key = tags.value;
                pro.data[key] = docData;

                setComponentState({
                    ...componentState,
                    productList: {...componentState.productList,...pro.data }
                });
                setQuantity(1);
                setPrice(0);
                setWillReturn('Yes');
                document.getElementById('willReturn').value = 'Yes';
            }else{
                const pro = componentState.productList[tags.value];
                setQuantity(pro.quantity);
                setPrice(pro.price);
                setWillReturn(pro.willReturn);                
                document.getElementById('willReturn').value = pro.willReturn.toString();
            }            
        }

        !product[tags.value] && dispatch(getProductDetails(tags.value));
    }

    const handlePurposeSelection = (e) => {
        setComponentState({
            ...componentState,
            purpose: e.target.value
        });
    }

    const handleReturnSelection = (e) => {
        const selectedPro = componentState.productList[productId];
        const pro = {data:{}}
        const docData = {
            ...selectedPro,
            willReturn: e.target.value
        };
        const key = productId;
        pro.data[key] = docData;
        setComponentState({
            ...componentState,
            productList: {...componentState.productList,...pro.data }
        });
        setWillReturn(e.target.value);
    }

    const handleItemDelete = (id) => {
        delete componentState.productList[id];        
    }

    const handleItemSelected = (id) => {
        productId = id;
        const pro = componentState.productList[id];
        document.getElementById('willReturn').value = pro.willReturn.toString();
        setPrice(pro.price.toString());
        setQuantity(pro.quantity.toString());
        setWillReturn(pro.willReturn.toString());
        setItemName({
            value: id, 
            label: pro.name
        });
    }   

    const handleApprovalDivision = (e) => {
        console.log('componentState', componentState);       
        //setRequestedDiv(div.current.value);
        const division = userEnrolledDivisions[div.current.value];
        const requested = {
            id: userDetails.id,
            name: userDetails.firstName + ' ' + userDetails.lastName,
            email: userDetails.email,
            phone: userDetails.mobileNumber,
            designation: userDetails.designation,
            division: division.id
        }

        const applied = {
            id: customerDetails.id,
            code: customerDetails.code,
            name: customerDetails.name,
            address: customerDetails.address,
            city: customerDetails.city,
            email: userDetails.email === undefined ? '' : userDetails.email,
            phone: userDetails.phone === undefined ? '' : userDetails.phone,
            contact: userDetails.contact === undefined ? '' : userDetails.contact,
            contactEmail: userDetails.contactEmail === undefined ? '' : userDetails.contactEmail
        }
        setComponentState({
            ...componentState,
            projectName:  componentState.projectName === undefined ? 'NA' : componentState.projectName,
            projectValue: componentState.projectValue === undefined ? 0 : componentState.projectValue,
            endUser: componentState.endUser === undefined ? 'NA' : componentState.endUser,
            psm: componentState.psm === undefined ? 'NA' : componentState.psm,
            orderExpected: componentState.orderExpected === undefined ? 'NA' : componentState.orderExpected,
            requested: requested,
            applied: applied,
            requestedBy: userDetails.id,
            requestedDivision: division.id,
            divisionalApprovalFrom: division.divisionalApprovalFrom,
            organizationalApprovalFrom: division.organizationalApprovalFrom,
            divisionalApproval: '',
            organizationalApproval: '', 
            organizationalApproval_at: '',
            totalAmount: totalAmount,
            status: 'Draft'
        });
    }

    const handleValidation = (valid, iouAmount) => {
        //console.log('validation', valid); 
        if(div.current){
            totalAmount = iouAmount;
            //console.log('div.current.value', div.current.value); 
            div.current.disabled = !valid;
            const approvalButton = document.querySelector('.approval');
            const draftButton = document.querySelector('.draft');
            if(div.current.value === ''){
                approvalButton.disabled = true;
                draftButton.disabled = true;
            }else{
                approvalButton.disabled = !valid;
                draftButton.disabled = !valid;                
            }
        }
    }

    const handleSaveDraft = (e) => {
        e.preventDefault();
                
        console.log('Save Draft',componentState);
        dispatch(createIOUDraft(componentState));
    }

    const handleRequestApporval = (e) => {
        e.preventDefault();
                
        console.log('Request Approval',componentState);
        dispatch(RequestDivisionalApproval(componentState));
    }
    
    return ( 
        <div className="section iou">
            <form  className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">IOU Form</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/iou' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row iou-sum">
                    <div className="col s12 m4 l4">
                        <div className="col s12 input-field">
                            <label htmlFor="Customer">Customer</label>
                            <div className="input-field">
                                <AutoComplete 
                                    onSelected={handleCustomerSelection} 
                                    collection={'customers'} search={'name'} account={profile.account}
                                    placeholder={'Select customer'}
                                    />
                            </div>
                        </div>
                        <div className="col s12 input-field">
                            <label htmlFor="Contact Person">Contact Person</label>
                            <input id="contactPerson" onChange={handleChange} type="text" className="validate" required/>
                        </div>                     
                        <div className="col s12 input-field">
                            <label htmlFor="Contact Number">Contact Number</label>
                            <input id="contactNumber" onChange={handleChange} type="text" className="validate" required/>
                        </div>                     
                        <div className="col s12">
                            <div className="row">
                                <div className="col s6 input-field">
                                    <label className="active" htmlFor="Purpose">Purpose</label>
                                    <select onChange={handlePurposeSelection} defaultValue="" id="purpose">
                                        <option value="" disabled>Select purpose</option>
                                        <option value="POC">POC</option>
                                        <option value="Testing">Testing</option>
                                        <option value="Display">Display</option>
                                    </select> 
                                </div>
                                <div className="col s6 input-field">
                                    <label htmlFor="Period">Period in Days</label>
                                    <input id="period" onChange={handleChange} type="number" className="validate" required/>
                                </div>
                            </div>
                           
                        </div>                     
                        <div className="col s12 input-field">
                            <label htmlFor="Product">Product</label>
                            <div className="input-field">
                                <AutoComplete 
                                    onSelected={handleProductSelection} 
                                    collection={'products'} search={'name'} account={profile.account}
                                    placeholder={'Select product'}
                                    value={itemName}
                                />
                            </div>
                        </div> 
                        <div className="col s12">
                            <div className="row">
                                <div className="col s4 input-field">
                                    <label className="active" htmlFor="Quantity">Quantity</label>
                                    <input id="quantity" onChange={handleQuantityChange} type="number" value={quantity} className="validate" required/>
                                </div>
                                <div className="col s4 input-field">
                                    <label className="active" htmlFor="Unit Price">Unit Price</label>
                                    <input id="unitPrice" onChange={handlePriceChange} type="number" value={price.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2}) } className="validate" required/>
                                </div>
                                <div className="col s4 input-field">
                                    <label className="active" htmlFor="Return">Will Return</label>
                                    <select onChange={handleReturnSelection} defaultValue={willReturn} id="willReturn">
                                        <option value="" disabled>Choose option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> 
                                </div>
                            </div>
                           
                        </div> 
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Project Name">Project Name</label>
                            <input id="projectName" defaultValue='NA' onBlur={handlePrefill}  onChange={handleChange} type="text" className="validate all-cap" required/>
                        </div>                     
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Project Value">Project Value</label>
                            <input id="projectValue" defaultValue='0' onChange={handleChange} type="number" className="validate" required/>
                        </div>                      
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="End User">End User</label>
                            <input id="endUser" defaultValue='NA' onBlur={handlePrefill}  onChange={handleChange} type="text" className="validate all-cap" required/>
                        </div>                                                
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="PSM">PSM</label>
                            <input id="psm" defaultValue='NA' onBlur={handlePrefill}  onChange={handleChange} type="text" className="validate all-cap" required/>
                        </div> 
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Order Expected">Order Expected</label>
                            <input id="orderExpected" defaultValue='NA' onBlur={handlePrefill}  onChange={handleChange} type="text" className="validate all-cap" required/>
                        </div> 
                        <div className="col s12 input-field">
                            <label htmlFor="Message">Message</label>
                            <textarea id="message" onChange={handleChange} className="validate materialize-textarea" required></textarea>
                        </div> 
                        <div className="col s12">
                            <div className="input-field">
                                <label className="active" htmlFor="Division">Request approval from</label>
                                <select ref={div} onChange={handleApprovalDivision} defaultValue='' disabled id="division">
                                    <option value="" disabled>Select division</option>
                                    {userEDList}
                                </select> 
                            </div>
                            <div className="input-field"> 
                                <div className="row">
                                    <div className="col s8">
                                        <button onClick={handleRequestApporval} className={'btn orange z-depth-0 approval'}>Request Approval</button> 
                                    </div>
                                    <div className="col s4">
                                        <button onClick={handleSaveDraft} className={'btn red lighten-1 z-depth-0 right draft'}>Save Draft</button>
                                    </div>
                                </div>  
                            </div>
                            <div className="input-field">                                                          
                                <div className="red-text center">
                                    {iouError ? <p>{iouError}</p> : validation ? <p>{validation}</p> : null}
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <IOUSummary 
                    customer={customerDetails} 
                    iou={componentState}
                    organization={org}
                    profile={userDetails}
                    onItemDelete={(id) => {handleItemDelete(id)}}
                    onItemSelected={(id) => {handleItemSelected(id)}}
                    onValidated={(valid, iouAmount) => {handleValidation(valid, iouAmount)}}
                    />                  
                    
                </div>
            </form>
        </div>
     );
}
 
export default CreateIOU;