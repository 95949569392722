import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import MsgAndDoc from './MsgAndDoc';
import { DeleteLinkButton } from '../../utils/components/buttons/LinkButtons';

const IOUSummary = ({customer, iou, messages, documents, 
    organization, profile, onItemDelete, onItemSelected, 
    onValidated, onPdfView, onDelete}) => {
    

    //console.log('iou', iou);
    let valid = true;
    const customerDetails = customer ? (
        <div className="row">
            <div className="col s1">
                <p>To:</p>
            </div>
            <div className="col s11">
                <p className="bold">{iou ? iou.contactPerson : null}</p>
                <p className="bold">{customer.name}</p>
                <p>{customer.address}</p>
                <p>{iou.contactNumber ? 'Mob: ' + iou.contactNumber : null}</p>
            </div>
        </div>
    ) : null;

    const orgDetails = customer &&  organization ? (
        <div className="row">
        <div className="col s1">
            <p>From:</p>
        </div>
        <div className="col s11">
            <p className="bold">{organization ? organization.name : null}</p>
            <p>{organization.address}</p>
            <p>{organization ? 'Tel: ' + organization.phone + ' Fax: ' + organization.fax : null}</p>
            <p className="bold">{profile ? 'Requested by: ' + profile.firstName + ' ' + profile.lastName : null}</p>
            <p>{profile ? 'Mob: ' + profile.mobileNumber : null}</p>
        </div>
    </div>
    ) : null;    
    
    const iouBody = customer && iou.contactPerson && iou.period && organization ? (
        <div className="row">
             <div className="col s12">
                <br />
                <p>Dear {iou.contactPerson},</p>
                <br />
                <p>
                Further our conversation, please be advised we agree to lend the following 
                product listed to your company for the purposes of <span className="red-text bold"> {iou.purpose} </span> for the period of <span className="red-text bold"> {iou.period} days </span> from the date of 
                acceptance. After the evaluation periods, {customer.name} is required to return 
                the products to {organization.name}. Otherwise, {organization.name}  
                will charge the amount listed in the following details.
                </p>
                <br/>
                <p>If return is not required after get approval, please mention in below form.</p>
             </div>
        </div>
    ) : null;

    //console.log('productList', iou.productList);
    /*Create Array list from object collection */
    let ordered = [];
    if(iou.productList){
        const orderedArray = Object.keys(iou.productList).map((key) => [key, iou.productList[key]]);    
       
        orderedArray.map(item => {    
            ordered.push(item.pop());
            return null;
        });     
    }
    
    const delPro = (iou.status === 'Draft' || iou.status === 'Rejected' || iou.status === undefined) ? (        
        true
    ) : false;
    let slNo = 1;
    let totalAmount = 0;
    const products = ordered.length ? (       
        ordered.map(product => {
            totalAmount += parseFloat(product.netAmount);
             return(
                <tr className="list" key={product.id}>                            
                    <td>{slNo++}</td>
                    <td onClick={(id) => {handleItemSelected(product.id)}}>{product.name}</td>
                    <td><span className="right">{product.quantity}</span></td>                    
                    <td><span className="right">{product.price.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</span></td>                    
                    <td><span className="right">{product.netAmount.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</span></td>
                    <td><span className="center">{product.willReturn}</span></td>
                    <td>{delPro ? <Link to='#' onClick={(id) => {handleItemDelete(product.id)}}><i className="material-icons grey-text text-darken-3">delete</i></Link> : null}</td>
                </tr>
             );                                
         }) 
     ) : null;

     const productTotal = ordered.length ? (       
       <tr className="list" key="grandTotal">  
            <td colSpan="4"><span className="bold">Total</span></td>
            <td><span className="right bold">AED {totalAmount.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</span></td>
            <td colSpan="2"></td>
        </tr>
     ) : null;

     const productTable = ordered.length ? (       
        <table className=" highlight">
            <thead>
                <tr>
                    <th data-field="slNo">No.</th>
                    <th data-field="name">Model No.</th>
                    <th data-field="quantity"><span className="right">Qty.</span></th>
                    <th data-field="price"><span className="right">Unit Price</span></th>
                    <th data-field="netAmount"><span className="right">Total Amount</span></th>
                    <th data-field="return"><span className="center">Return</span></th>
                    <th data-field="edit" className="icon-head"></th>
                </tr>
            </thead>

            <tbody>
                {products}
                {productTotal}
            </tbody>
        </table>
     ) : null;

     valid = customer && iou.contactPerson 
     && iou.contactNumber && iou.period 
     && valid && ordered.length 
     && totalAmount > 0 ? true : false;
     
     onValidated(valid, totalAmount);
     
     const handleItemDelete = (id) =>{
         onItemDelete(id);
         checkValidation();
     }

     const handleItemSelected = (id) => {
         onItemSelected(id);
     }

     const handlePdfView = (pdfURL) => {
        onPdfView(pdfURL);
     }

     const checkValidation = () => {
        if(customer && iou.contactPerson 
        && iou.contactNumber && iou.period 
        && valid && ordered.length 
        && totalAmount > 0){
            onValidated(true, totalAmount);
        }else{
            onValidated(false, totalAmount);
        }
     }

     const footer = customer && iou.contactPerson && iou.period ? (       
        <div>
            <p>Note: Please keep the {iou ? iou.purpose : null} items same as it was.</p>
            <div className="row">
                <div className="col s6">
                    <p>For and On behalf of:</p>
                    <p>{organization ? organization.name.toUpperCase() :  null}</p>
                    <br/>
                </div>
                <div className="col s6">
                    <p>Accepted by:</p>
                </div>
            </div>
            <div className="row">
                <div className="col s6">
                    <p>Date: {iou ? iou.organizationalApproval_at !== '' ? moment(iou.organizationalApproval_at.toDate()).format('DD-MM-YYYY') : null : null}</p>
                    <p>Name of Signatory: {iou ? iou.requested !== undefined ? iou.requested.name : null : null}</p>
                </div>
                <div className="col s6">
                    <p>Date:</p>
                    <p>Name of Signatory:</p>
                </div>
            </div>
        </div>
     ) : null;

    const iouStatus = iou.status !== undefined  && iou.id !== undefined ? (
        <span className='new badge grey darken-1 ' data-badge-caption=''>{iou.status}</span>
    ) : null

    const handleDelete = (id) => {
        onDelete(id);
    }
    
    const del = iou && iou.id !== undefined && (iou.status === 'Draft' || iou.status === 'Rejected') ? (        
        <DeleteLinkButton to='#' onClick={(id) => {handleDelete(iou.id)}}/>
    ) : null;
    
    //console.log('iou status', iou.status);
    const summaryView =  (iou.status === undefined|| iou.status === '' || iou.status === 'Draft' || iou.status === 'Rejected') ? 'col s12 m8 l8' : 'col s12';
     
    return ( 
        <div className={summaryView}>
            <div className="card z-depth-1 iou-sum">                
                <div className="card-content grey-text text-darken-3">
                    <div className="row">
                        <div className="col s5">
                            <div className="row">
                                <span className="card-title ">IOU AGREEMENT </span>
                            </div>
                            <div className="row iou-status">
                                {iouStatus}
                                {del}
                            </div>
                        </div>
                        <div className="col s7">
                            <MsgAndDoc
                            messages={messages}
                            documents={documents}
                            onPdfView={(url) => {handlePdfView(url)}}
                            />
                        </div>
                    </div>
                   
                    <br />
                    <div className="row">
                        <div className="col s1">
                            <p>Date:</p>
                        </div>
                        <div className="col s11">
                            <p>{moment(iou.date !== undefined ? iou.date.toDate() : new Date()).format('MMMM Do YYYY')}</p>
                        </div>
                    </div>
                    {customerDetails}
                    {orgDetails}
                    <div className="iou-sec">
                        {iouBody}
                    </div>                   
                </div>
                <div className="card-action card-bottom-badge">
                    <div className="table-overflow">
                        {productTable}
                    </div>
                    <br/>
                    {footer}
                </div>    
            </div>
        </div> 
     );
}
 
export default IOUSummary;