import { GenerateKeywords } from '../../utils/GenerteKeywords';

export const createOrg = (org) => {
    return(dispatch, getState, { getFirebase }) => {
        /*Validate data */
       if(org.name !== '' 
            && org.name !== undefined
       ){
            let orgId = '';  
            let userId = ''; 
            const profile = getState().firebase.profile;
            const createdBy = getState().firebase.auth.uid;
            /*Make async call to database */
            let keywords = GenerateKeywords(org.name);
            const firebase = getFirebase();
            const firestore = getFirebase().firestore();
            
            //const orgRef = firestore.collection('organizations');
            // const statRef = firestore.collection('stat').doc('orgStat');
            // const increment = firebase.firestore.FieldValue.increment(1);
            // const batch = firestore.batch();
            // batch.add(orgRef, {
            //     name: org.name,
            //     details: org.details,
            //     keywords: keywords,
            //     creator: profile.firstName + ' ' + profile.lastName,
            //     creatorId: createdBy,
            //     time: new Date()
            // });

            // batch.set(statRef, {count: increment}, {merge: true});
            // batch.commit();

            firestore.collection('organizations').add({
                name: org.name,
                city: org.city,
                phone: org.phone,
                fax: org.fax,
                email: org.companyEmail,
                address: org.address,
                keywords: keywords,
                creator: profile.firstName + ' ' + profile.lastName,
                creator_id: createdBy,
                created_at: new Date()
            })
            .then((res) => {
                orgId = res.id;      
                const statRef = firestore.collection('stat').doc('orgStat');
                const increment = firebase.firestore.FieldValue.increment(1);
                statRef.update({count: increment});

                firebase.auth().createUserWithEmailAndPassword(
                    org.email,
                    org.password
                )
                .then((res) => {
                    userId = res.user.uid; /** uid from auth */
                    const userStatRef = firestore.collection('organizations')
                    .doc(orgId)
                    .collection('stat')
                    .doc('userStat');

                    const customerStatRef = firestore.collection('organizations')
                    .doc(orgId)
                    .collection('stat')
                    .doc('customerStat');

                    const divisionStatRef = firestore.collection('organizations')
                    .doc(orgId)
                    .collection('stat')
                    .doc('divisionStat');

                    const productStatRef = firestore.collection('organizations')
                    .doc(orgId)
                    .collection('stat')
                    .doc('productStat');

                    const iouStatRef = firestore.collection('organizations')
                    .doc(orgId)
                    .collection('stat')
                    .doc('iouStat');

                    const newUserRef = firestore.collection('organizations')
                    .doc(orgId)
                    .collection('users')
                    .doc();

                    firestore.runTransaction(async transaction => {
                        keywords = GenerateKeywords(org.firstName + ' ' + org.lastName);
                        /** Set default statitics  */
                        const uStatRef = firestore.collection('stat').doc('userStat');
                        const stat = await transaction.get(uStatRef);
                        const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                        transaction.set(uStatRef, {count: newIndex});

                        transaction.set(userStatRef, {count: 1, active: 1, inactive:0});
                        transaction.set(customerStatRef, {count: 0, active: 0, inactive:0});
                        transaction.set(divisionStatRef, {count: 0, active: 0, inactive:0});
                        transaction.set(productStatRef, {count: 0, active: 0, inactive:0});
                        transaction.set(iouStatRef, {count: 0, open: 0, closed:0});
        
                        const res = await transaction.set(newUserRef, {
                            email: org.email,
                            firstName: org.firstName,
                            lastName: org.lastName, 
                            designation: 'Administrator',
                            mobileNumber: org.phone,
                            privilege: 'power',
                            index: 1,
                            status: 'active',
                            uid: userId,
                            keywords: keywords,
                            creator_id: createdBy,
                            created_at: new Date()
                        });
                        let uid = 1;
                        res.md.mutations.map(key => {
                            //console.log('key', key.key.path.segments[3]);
                            if(key.key.path.segments[3] !== 'userStat' ) uid = key.key.path.segments[3];
                            return null;
                        }); 
                        
                        const userRef = firestore.collection('users')
                        .doc(userId);

                        transaction.set(userRef, {
                            firstName: org.firstName,
                            lastName: org.lastName,
                            initials: org.firstName[0] + org.lastName[0],
                            privilege: 'power',
                            account: orgId,
                            userId: uid
                        });    
        
                    })
                })
            })
            .then(() => {                
                dispatch({type: 'CREATE_ORG', org})
            }).catch((err) => {
                dispatch({type: 'CREATE_ORG_ERROR', err})
            });
       }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
       }
        
    }
}

export const updateOrg = (organization, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(organization.name !== '' && organization.name !== undefined && id !== ''){
            const modifiedBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const keywords = GenerateKeywords(organization.name);
            const firestore = getFirebase().firestore();
            firestore.collection('organizations').doc(id).update({
                ...organization,
                keywords: keywords,
                modifier_id: modifiedBy,
                modified_at: new Date()
            }).then(() => {
                const org = {data:{}}
                const docData = {
                    ...organization,
                    status: 'active',
                    id: id
                };
                const key = id;
                org.data[key] = docData;
                dispatch({type: 'UPDATE_ORG', org})
            }).catch((err) => {
                dispatch({type: 'UPDATE_ORG_ERROR', err})
            });
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
        }
    }
}

export const loadOrg = (search, pagination, pageInfo) => {
    search = search.toLowerCase().replace(/\W/g, "");
    return(dispatch, getState, { getFirebase }) => {
        const firestore = getFirebase().firestore();
        firestore.collection('organizations')
        .where("keywords", "array-contains", search)
        .orderBy('name', pagination.orderBy)
        .startAfter(pagination.cursor)
        .limit(pagination.limit)
        .get()
        .then((snapshot) => { 
            const org = {data:{}, pageInfo:pageInfo}
            snapshot.docs.map(doc => {
                const docData = {
                    ...doc.data(),
                    id: doc.id
                };
                const key = doc.id;
                org.data[key] = docData;
                return null;
            });
            if(search === '') Object.keys(org.data).length === 0  ? dispatch({type: 'PAGE_ERROR'}) : dispatch({type: 'LOAD_ORG', org});
            else dispatch({type: 'FILTER_ORG', org});
        }).catch((err) => {
            dispatch({type: 'LOAD_ORG_ERROR', err});
        });
    }
} 

export const getOrgDetails = (id) => {
    return(dispatch, getState,{ getFirebase }) => {
        const firestore = getFirebase().firestore();

        if(id !== undefined && id !== ''){
            firestore.collection('organizations')
            .doc(id)
            .get()
            .then((snapshot) => { 
                if(snapshot.exists){
                    const org = {data:{}}
                    const docData = {
                        ...snapshot.data(),
                        id: id
                    };
                    const key = id;
                    org.data[key] = docData;
                    dispatch({type: 'LOAD_ORG', org})
                }
            }).catch((err) => {
                dispatch({type: 'LOAD_ORG_ERROR', err});
            }); 
        }
               
    }
} 

export const resetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().org.validation;
        if(validation) dispatch({type: 'RESET_VALIDATION'})
    }
}