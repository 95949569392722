import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { createOrg } from '../../store/actions/orgActions';
import { connect } from 'react-redux';

class CreateOrgUnit extends Component {
    state = {
        email: '',
        password: '',
        firstName: '',
        lastName: '', 
        name: '',
        details: ''
     }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createOrg(this.state);
        //this.props.history.push('/orgunits');
    }
    render() { 
        const {auth, orgError, validation} = this.props;
        if(!auth.uid) return <Redirect to='/signin'/>;        
        //if(validation === 'Passed') return <Redirect to='/orgunits'/>;
        return ( 
            <div className="container org-unit">
                <form onSubmit={this.handleSubmit} className="white">
                    <div className="row">
                        <div className="col s11">
                            <h5 className="red-text text-lighten-1">Create Organizational Unit</h5>
                        </div>
                        <div className="col s1 btn-close">
                            <Link to='/orgunits' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                        </div>
                    </div>
                    <br />
                    <div className="row">                        
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="email">Email</label>
                            <input id="email" onChange={this.handleChange} type="email" className="validate" required/>
                        </div>
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="password">Password</label>
                            <input id="password" onChange={this.handleChange} type="password" className="validate" required/>
                        </div>
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="firstName">First Name</label>
                            <input id="firstName" onChange={this.handleChange} type="text" className="validate" required/>
                        </div>
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="lastName">Last Name</label>
                            <input id="lastName" onChange={this.handleChange} type="text" className="validate" required/>
                        </div>
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="name">Name of organization unit</label>
                            <input type="text" id="name"  onChange={this.handleChange} className="validate" required/>
                        </div>                    
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="City">City</label>
                            <input id="city" onChange={this.handleChange} type="text" className="validate" required/>
                        </div>                     
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="Phone">Phone</label>
                            <input id="phone" onChange={this.handleChange} type="tel" className="validate" required/>
                        </div>                        
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="Fax">Fax</label>
                            <input id="fax" onChange={this.handleChange} type="tel" className="validate" required/>
                        </div>                  
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="Company Email">Company Email</label>
                            <input id="companyEmail" onChange={this.handleChange} type="email" className="validate" required/>
                        </div>                     
                        <div className="col s12 m6 l6 input-field">
                            <label htmlFor="Address">Address</label>
                            <textarea id="address" onChange={this.handleChange} className="validate materialize-textarea" required></textarea>
                        </div> 
                    </div>
                    <div className="input-field">
                        <button className="btn red lighten-1 z-depth-0">Create</button>                        
                        <div className="red-text center">
                            {orgError ? <p>{orgError}</p> : validation ? <p>{validation}</p> : null}
                        </div>
                    </div>
                </form>
            </div>
         );
    }
}

const mapStateToProps = (state) => {   
    //console.log(state); 
    return{
        auth: state.firebase.auth,
        orgError: state.org.orgError,
        validation: state.org.validation
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        createOrg: (org) => dispatch(createOrg(org))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CreateOrgUnit);