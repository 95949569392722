import React from 'react';
import IOUTodos from '../iou/IOUTodos';

const Todo = ({onShowIOU, onUploadIOU, onDeliveryIOU, iouTodoCount}) => {
   
    const handleOnShowIOU = (id) => {
        onShowIOU(id);
    }

    const handleUploadIOU = (id) => {
        onUploadIOU(id);
    }

    const handleDeliveryIOU = (id) => {
        onDeliveryIOU(id);
    }
    console.log('iouTodoCount', iouTodoCount);
    const nothingTodo = iouTodoCount === 0 ? 'show' : 'hide';

    return ( 
        <div className="section todo">
            <ul className='collection with-header'>
                <li className="collection-header"><h4>TO DO</h4></li>
            </ul>
            <div className={'nothingToDo ' + nothingTodo}>
                <div className="card todo" key='nothingToDo'>
                    <div className="card-image">
                        <img alt='Nothing to do' src={require('../../images/empty-todo.png')}/>
                    </div>
                    <div className="card-content">
                        <div className="row">
                            <div className="col s10">
                                <span className="card-title grey-text text-darken-3">No task for today!! </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <IOUTodos 
            onShowIOU={(id) => handleOnShowIOU(id)}
            onUploadIOU={(id) => handleUploadIOU(id)}
            onDeliveryIOU={(id) => {handleDeliveryIOU(id)}}/>
        </div>
     );
}

export default Todo;