import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './authReducer';
import iouReducer from './iouReducer';
import orgReducer from './orgReducer';
import userReducer from './userReducer';
import divisionReducer from './divisionReducer';
import productReducer from './productReducer';
import customerReducer from './customerReducer';
import orderReducer from './orderReducer';
import rmaReducer from './rmaReducer';
import sparePartsReducer from './sparePartsReducer';

// const rootReducer =  combineReducers({
//     firebase: firebaseReducer,
//     firestore: firestoreReducer,
//     auth: authReducer,
//     org: orgReducer,
//     iou: iouReducer,
//     user: userReducer,
//     division: divisionReducer,
//     product: productReducer,
//     customer: customerReducer
// })
const INITIAL_STATE = {};

const appReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    org: orgReducer,
    iou: iouReducer,
    user: userReducer,
    division: divisionReducer,
    product: productReducer,
    customer: customerReducer,
    order: orderReducer,
    rma: rmaReducer,
    spares: sparePartsReducer
});

const rootReducer = (state, action) => {
    if(action.type === 'SIGNOUT_SUCCESS'){
        state = INITIAL_STATE;
    }
    return appReducer(state, action);
}

export default rootReducer;