import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { CloseLinkButton } from '../../utils/components/buttons/LinkButtons';
import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { createDeliveryNote, GetDeliveryItems, HandleRMAServiceCharge } from '../../store/actions/rmaActions';

import DeliveryNoteItems from './DeliveryNoteItems';

let totalSC = 0;
const CreateDeliveryNote = (props) => {    
    const dispatch = useDispatch();
    useEffect(() => {
        Materialize.AutoInit();
    });

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth); 
    const profile = useSelector(state => state.firebase.profile);
    const customer = useSelector(state => state.rma.customer);
    const itemList = useSelector(state => state.rma.items); 
    const validation = useSelector(state => state.rma.validation); 
    const rmaError = useSelector(state => state.rma.rmaError);  
    
    
    useEffect(() =>{
        dispatch(GetDeliveryItems(profile.account, props.match.params.id));
    },[dispatch, profile.account, props.match.params.id]);

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/crdln'/>;  

    const handleServiceCharge = (serialNumber, serviceCharge) => {
        console.log('serialNumber',serialNumber + ' '+ serviceCharge);
        dispatch(HandleRMAServiceCharge(serialNumber, serviceCharge));       
    } 

    const handleTotalSC = (totalAmount) => {
        //console.log('totalAmount', totalAmount);
        totalSC = totalAmount;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(props.match.params.id === '' || props.match.params.id === 0){
            Materialize.toast({html: 'Error: Invalid data'});
        }else if(Object.keys(itemList).length === 0){
            Materialize.toast({html: 'Error: No more items in delivery status'});
        }else{
            dispatch(createDeliveryNote(profile.account, customer, itemList, totalSC));
        }
    }

    const customerDetails = (
        <div className="row">
            <div className="col s2">
                <p>Customer:</p>
            </div>
            <div className="col s10">
                <p className="bold">{customer && customer.customerName}</p>
                <p>{customer && customer.customerAddress}</p>
                <p>{customer && customer.customerPhone !== '' ? 'Phone: ' + customer.customerPhone + ',  Email : ' + customer.customerEmail : null}</p>
            </div>
        </div>
    );

    return (       
        <div className="section rma">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h6 className="red-text text-lighten-1">Create Delivery Note</h6>
                    </div>
                    <div className="col s1 btn-close">
                        <CloseLinkButton to='/crdln' />
                    </div>
                </div>                
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                    {customerDetails}
                    </div>
                    <div className="col s12 m6 l6 mb-5">
                        <div className="row">
                            <div className="col s2">
                            </div>
                            <div className="col s10">
                                <p className="right">Date: {moment(new Date()).format('DD-MM-YYYY')}</p>
                            </div>
                        </div>
                        
                    </div>             
                </div>
                <div className="blank"></div>
                <div className="row">
                    <div className="mt-5 mb-5">
                        <div className="card z-depth-1">
                        <div className="row">
                                <DeliveryNoteItems
                                data = {itemList}
                                onServiceChargeChange = {(serialNumber, serviceCharge) => {handleServiceCharge(serialNumber, serviceCharge)}}
                                totalSC = {totalSC}
                                onTotalSCChange = {(totalAmount) => {handleTotalSC(totalAmount)}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-field  pl-5 mb-5">
                    <button className="btn red lighten-1 z-depth-0 right">SAVE</button>                        
                    <div className="red-text center">
                        {rmaError ? <p>{rmaError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default CreateDeliveryNote;