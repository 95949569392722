import { GenerateKeywords } from '../../utils/GenerteKeywords';

const collection = 'divisions';
const account = 'organizations';

export const createDivision = (newDivision) => {
    return(dispatch, getState, { getFirebase }) => {
        /*Validate data */
       if(newDivision.managerId !== '' && newDivision.managerId !== undefined ){
            const profile = getState().firebase.profile;
            const createdBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const keywords = GenerateKeywords(newDivision.name);
            //const firebase = getFirebase();
            const firestore = getFirebase().firestore();

            try{
                const statRef = firestore.collection(account)
                .doc(profile.account)
                .collection('stat')
                .doc('divisionStat');

                // const usrRef = firestore.collection(account)
                // .doc(profile.account)
                // .collection('users')
                // .doc(newDivision.managerId);

                const newDivisionRef = firestore.collection(account)
                .doc(profile.account)
                .collection(collection)
                .doc();

                firestore.runTransaction(async transaction => {
                    const stat = await transaction.get(statRef);
                    const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                    const newActive= stat.data().active === undefined ? 1 : stat.data().active + 1;
                    
                    // const u_data = await transaction.get(usrRef);
                    // const enrolled_div = u_data.data().enrolledDivisions === undefined ? [] : u_data.data().enrolledDivisions;

                    transaction.update(statRef, {count: newIndex, active: newActive});

                    const res = await transaction.set(newDivisionRef, {
                        ...newDivision,
                        index: newIndex,
                        status: 'active',
                        keywords: keywords,
                        creator_id: createdBy,
                        created_at: new Date()
                    });
                    let divId = newIndex;
                    res.md.mutations.map(key => {
                        //console.log('key', key.key.path.segments[3]);
                        if(key.key.path.segments[3] !== 'divisionStat' ) divId = key.key.path.segments[3];
                        return null;
                    });  

                    // transaction.update(usrRef, {enrolledDivisions: [...enrolled_div, divId]});
                    
                    const division = {data:{}}
                    const docData = {
                        ...newDivision,
                         status: 'active',
                        index: newIndex,
                        id: divId
                    };
                    const key = divId;
                    division.data[key] = docData;
                    dispatch({type: 'CREATE_DIVISION', division});
                });
            }catch(err){
                dispatch({type: 'CREATE_DIVISION_ERROR', err});
            }

            // firestore.collection(account)
            // .doc(profile.account)
            // .collection(collection).add({
            //     ...newDivision,
            //     status: 'active',
            //     keywords: keywords,
            //     creator_id: createdBy,
            //     created_at: new Date()
            // })
            // .then((res) => { 
            //     const statRef = firestore.collection(account)
            //     .doc(profile.account)
            //     .collection('stat')
            //     .doc('divisionStat');

            //     const increment = firebase.firestore.FieldValue.increment(1);
            //     statRef.update({count: increment});
                
            //     const division = {data:{}}
            //     const docData = {
            //         ...newDivision,
            //         status: 'active',
            //         id: res.id
            //     };
            //     const key = res.id;
            //     division.data[key] = docData;
            //     dispatch({type: 'CREATE_DIVISION', division})
            // }).catch((err) => {
            //     dispatch({type: 'CREATE_DIVISION_ERROR', err})
            // });
       }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
       }
        
    }
}

export const loadDivisions = (search, pagination, pageInfo) => {
    search = search.toLowerCase().replace(/\W/g, "");
    return(dispatch, getState,{ getFirebase }) => {
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();
        const divisionList = getState().division.data;
        if(Object.keys(divisionList).length === 0 || search !== '') {
            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)        
            .where("keywords", "array-contains", search)
            .get()
            .then((snapshot) => { 
                const division = {data:{}, pageInfo:pageInfo}
                snapshot.docs.map(doc => {
                    const docData = {
                        ...doc.data(),
                        id: doc.id
                    };
                    const key = doc.id;
                    division.data[key] = docData;
                    return null;
                });
                if(search === '') Object.keys(division.data).length === 0  
                    ? dispatch({type: 'PAGE_ERROR'}) 
                    : dispatch({type: 'LOAD_DIVISION', division});
                else dispatch({type: 'FILTER_DIVISION', division});
            }).catch((err) => {
                dispatch({type: 'LOAD_DIVISION_ERROR', err});
            });
        }
        else
        {
            const division = {data:divisionList, pageInfo:pageInfo}
            dispatch({type: 'LOAD_DIVISION', division});
        }        
    }
} 

export const getUserDivisions = (uid) => {
    //console.log('getUserDivisions',uid);
    return(dispatch, getState,{ getFirebase }) => {
        if(uid !== undefined || uid === ''){
            const profile = getState().firebase.profile;
            const firestore = getFirebase().firestore();
            if(profile.account !== undefined || profile.account === '' ){
                firestore.collection(account)
                .doc(profile.account)
                .collection(collection) 
                .where('membersList', 'array-contains', uid)
                .get()
                .then((snapshot) => { 
                    const division = {data:{}}
                    snapshot.docs.map(doc => {
                        const docData = {
                            ...doc.data(),
                            id: doc.id
                        };
                        const key = doc.id;
                        division.data[key] = docData;
                        return null;
                    });
                    if(Object.keys(division.data).length > 0) dispatch({type: 'GET_USER_DIVISIONS', division});
                }).catch((err) => {
                    dispatch({type: 'GET_USER_DIVISIONS_ERROR', err});
                });
            }else dispatch({type: 'GET_USER_DIVISIONS_ERROR', err: 'Invalid operator'});
            
        }else{
            dispatch({type: 'GET_USER_DIVISIONS_ERROR', err: 'Invalid operator'});
        }
        
    }
}

export const updateDivision = (div, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(div.managerId !== '' && div.managerId !== undefined  && id !== ''){
            const profile = getState().firebase.profile;
            const modifiedBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const keywords = GenerateKeywords(div.name);
            const firestore = getFirebase().firestore();

            firestore.collection(account)
            .doc(profile.account)
            .collection(collection).doc(id).update({
                ...div,
                status: 'active',
                keywords: keywords,
                modifier_id: modifiedBy,
                modified_at: new Date()
            }).then(() => {
                const division = {data:{}}
                const docData = {
                    ...div,
                    status: 'active',
                    id: id
                };
                const key = id;
                division.data[key] = docData;
                dispatch({type: 'UPDATE_DIVISION', division})
            }).catch((err) => {
                dispatch({type: 'UPDATE_DIVISION_ERROR', err})
            });
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'});
        }
    }
}

export const deleteDivision = (div, id) => {
    return(dispatch, getState, { getFirebase }) => {
        console.log('div', div);
         /*Validate data */
        // if(id !== ''){            
        //     const profile = getState().firebase.profile;
        //     const modifiedBy = getState().firebase.auth.uid;
        //     /*Make async call to database */
        //     const keywords = GenerateKeywords(div.name);
        //     const firebase = getFirebase();
        //     const firestore = getFirebase().firestore();
        //     firestore.collection(account)
        //     .doc(profile.account)
        //     .collection(collection).doc(id).update({
        //         ...div,
        //         status: 'deleted',
        //         keywords: keywords,
        //         modifier: modifiedBy,
        //         modifiedTime: new Date()
        //     }).then(() => {
        //         const statRef = firestore.collection(account)
        //         .doc(profile.account)
        //         .collection('stat')
        //         .doc('divisionStat');
        //         const increment = firebase.firestore.FieldValue.increment(1);
        //         statRef.update({inactive: increment});

        //         const division = {data:{}}
        //         const docData = {
        //             ...div,
        //             status: 'deleted',
        //             id: id
        //         };
        //         const key = id;
        //         division.data[key] = docData;
        //         dispatch({type: 'DELETE_DIVISION', division})
        //     }).catch((err) => {
        //         dispatch({type: 'DELETE_DIVISION_ERROR', err})
        //     });
        // }else{
        //     dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
        // }
    }
}

export const resetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().division.validation;
        if(validation) dispatch({type: 'RESET_VALIDATION'})
    }
}