const CreateKeywords = (text) => {
    const arrText = [];
    let currText = '';
    text.split('').forEach(element => {
        currText += element;
        arrText.push(currText);
    });
    return arrText;
}

export const GenerateKeywords = (inputText) => {
    let text = inputText;
    const keywords = [];
    while(text.length > 0){  
        keywords.push(text);
        text = text.split(' ').slice(1).join(' '); 
    }
    let possibleWords = [];
    keywords.map(word => {
        const textValue = word.toLowerCase().replace(/\W/g, ""); 
        possibleWords = new Set([ ...possibleWords, ...CreateKeywords(textValue) ])
        return possibleWords;
    })

    return[...new Set([
        '',
        ...possibleWords
    ])]
}