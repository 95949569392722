import React,{ useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Materialize from 'materialize-css/dist/js/materialize.min.js'
import { getIOUDetails, loadIOUs, resetValidation } from '../../store/actions/iouActions';
import IOUList from './IOUList';
import IOUReturn from './IOUReturn';
import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import Pagination from '../../utils/components/buttons/Pagination';

const pageInfo = { statusFilter: '', dateFilter: {}, customerFilter: '', dueFilter: ''}; 
const pagination = {
    pageNumber: 0,
    limit: 10,
    orderBy: 'asc',
    cursor: 0
}; 
let getIOUList = true;   
let preCursor = 0;
const IOU = () => {
    const dispatch = useDispatch();
    const [statusFilter, setStatusFilter] = useState(''); 
    const [dueFilter, setDueFilter] = useState('');  
    const [clearField, setClearField] = useState();

    const [iouId, setIouId] = useState();
    useEffect(() => {
        Materialize.AutoInit();
        const startDatePicker = document.querySelector('.startDatepicker');
        Materialize.Datepicker.init(startDatePicker, {
            autoClose: true,
            format: "yyyy-mm-dd",
            onSelect: function () {
                //console.log(this);
                handleStartDateSelect(this.date);
            }
        });
        const endDatePicker = document.querySelector('.endDatepicker');
        Materialize.Datepicker.init(endDatePicker, {
            autoClose: true,
            format: "yyyy-mm-dd",
            onSelect: function () {
                handleEndDateSelect(this.date);
            }
        });
    })

    /** Reset flags on each rendering */
    useEffect(() => {
        //console.log('getIOUList',getIOUList);
        //dispatch(TestAction());
        getIOUList = true;
    },[])

    useEffect(() =>{
         /*User rudex hook to dispatch data fetch */
        //console.log('getIOUList',getIOUList);
        if(profile.account !== undefined && getIOUList ){
            dispatch(loadIOUs('',pagination,pageInfo));
            getIOUList = false;
        } 
       
        dispatch(resetValidation());
    })

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);
    const profile = useSelector(state => state.firebase.profile);
    const data = useSelector(state =>  state.iou.data);
    const filteredData = useSelector(state => state.iou.filteredData);
    const pageStatus = useSelector(state => state.iou.pageInfo);
    const iou = Object.keys(filteredData).length !== 0  ? filteredData : pageStatus.filtering ? filteredData : data;
    
    /* Check authentication and redirect to login page */
    if(!auth.uid) return <Redirect to='/signin'/>;
    
    /*Create Array list from object collection */
    const orderedArray = Object.keys(iou).map((key) => [key, iou[key]]);    
       
    let ordered = [];
    orderedArray.map(item => {    
        ordered.push(item.pop());
        return null;
    }); 
    /*Filter out deleted divisions */
    ordered = ordered.filter(item => {
        return item.status !== 'deleted'
    }); 
    
    /*Get first and last doc name for pagination */
    let firstDoc = '';
    let lastDoc = '';
    if(iou){   
        ordered.map( (item) => {
            if(firstDoc === '') firstDoc =  item.index;
            lastDoc = item.index;
            return null;
         } );
    }

    const handleNextPage = (cursor) => {     
        console.log('cursor',cursor);    
        if(cursor !== ''){
            preCursor = pagination.cursor;
            if(cursor !== pagination.cursor){
                pagination.pageNumber++;
                pagination.orderBy = 'asc';        
                pagination.cursor = cursor;
                dispatch(loadIOUs(pageInfo.statusFilter,pagination,pageInfo));
            } 
        }  
    }

    const handlePreviousPage = (cursor) => {
        //console.log('previous page',cursor);        
        if(cursor !== ''){       
            preCursor = pagination.cursor;     
            if(pagination.pageNumber > 0) pagination.pageNumber--;        
            pagination.orderBy = 'desc';
            pagination.cursor = cursor;
            dispatch(loadIOUs(pageInfo.statusFilter,pagination,pageInfo));
        }else{
            if(pagination.pageNumber > 0) pagination.pageNumber--;        
            pagination.orderBy = 'desc';
            pagination.cursor = preCursor;
            dispatch(loadIOUs(pageInfo.statusFilter,pagination,pageInfo));
        }
    }

    const handleCustomerSelection = (tags) => {
        //console.log(componentState);        
        setClearField();
        pageInfo.customerFilter = tags.value;
    }
    
    const handleStatusSelection = (e) => {
        //console.log(e.target.value);
        setStatusFilter(e.target.value);
        pageInfo.statusFilter = e.target.value;
    }

    const handleDueSelection = (e) => {
        //console.log(e.target.value);
        setDueFilter(e.target.value);
        pageInfo.dueFilter = e.target.value;
    }

    const handleStartDateSelect = (date) => {
        //console.log('date picked', pageInfo);
        pageInfo.dateFilter = {
            ...pageInfo.dateFilter,
            startDate: date
        }
    }

    const handleEndDateSelect = (date) => {
        //console.log('date picked', date);
        pageInfo.dateFilter = {
            ...pageInfo.dateFilter,
            endDate: date
        }
    }

    const handleSearch = () => {
        // let datePicker = document.querySelectorAll('.datepicker');
        // Materialize.Datepicker.init(datePicker, {});        
        pagination.cursor = 0;
        pagination.pageNumber = 0;
        dispatch(loadIOUs(pageInfo.statusFilter,pagination,pageInfo));
    }

    const handleClear = () => {
        pageInfo.statusFilter = '';
        pageInfo.dueFilter = '';
        pageInfo.dateFilter = {
            startDate: '',
            endDate: ''
        }
        pageInfo.customerFilter = '';
        pagination.cursor = 0;
        pagination.pageNumber = 0;
        setStatusFilter('');
        setDueFilter('');
        document.querySelector('.startDatepicker').value = '';
        document.querySelector('.endDatepicker').value = '';
        setClearField({label:  'Search by customers'});
        dispatch(loadIOUs(pageInfo.statusFilter,pagination,pageInfo));
    }

    /*
    Get input from search text fields and apply filtered list 
    Set a time delay of 1 sec to fetch data
    */
    //const handleOnChange = (e) => {
        // clearTimeout(timer);
        // pageInfo.nameFilter = e.target.value;  
        // timer = setTimeout(() => {
        //     triggerChange(pageInfo.nameFilter)
        //     }, 1000);      
    //}

    const handleReturnIOU = (id) => {
        setIouId(id);
        dispatch(getIOUDetails(id, 'iou-return'));
    }

    return ( 
        <div className="section iou">
            <IOUReturn iouId={iouId}/>
            <div className="row">                                        
                <div className="col s12">
                    <div className="card">
                        <div className="card-content sec-head">                            
                            <div className="row">
                                <div className="input-field col s12 m6 l6 iou-cr">
                                    <Link to='/criou' className="waves-effect waves-light btn-large"><i className="material-icons left">group_work</i>CREATE NEW IOU</Link>
                                </div>
                                <div className="input-field col s12 m6 l6">
                                    <i className="material-icons prefix">search</i>
                                    <AutoComplete
                                    onSelected={handleCustomerSelection} 
                                    collection={'customers'} search={'name'} account={profile.account}
                                    placeholder={'Search by customers'}
                                    value={clearField}
                                    />
                                    {/* <input onChange={handleOnChange} type="text" defaultValue="" placeholder="Search by customers"/> */}
                                </div> 
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m2 l2">
                                    <select onChange={handleStatusSelection} value={statusFilter} className="browser-default">
                                        <option value="" disabled>Select IOU Status</option>
                                        <option value="all">All</option>
                                        <option value="Open">Open</option>
                                        <option value="Closed">Closed</option>   
                                    </select>                                    
                                </div>
                                <div className="input-field col s12 m2 l2">
                                    <label htmlFor="Date From">Date from</label>
                                    <input id='sartDate' type="text" className="startDatepicker"></input>
                                </div>
                                <div className="input-field col s12 m2 l2">
                                    <label htmlFor="Date To">Date to</label>
                                    <input id='endDate' type="text"  className="endDatepicker"></input>
                                </div>
                                <div className="input-field col s12 m2 l2">
                                     <select onChange={handleDueSelection} value={dueFilter} className="browser-default">
                                        <option value="" disabled>Select due stauts</option>
                                        <option value="1">Due in 1 Day</option>
                                        <option value="2">Due in 2 Days</option>                                        
                                        <option value="3">Due in 3 Days</option>                                       
                                        <option value="0">Over Due</option>
                                    </select>
                                </div>
                                <div className="input-field col s12 m4 l4">                                   
                                    <Link to='#' onClick={handleSearch} className="orange waves-light btn"><i className="material-icons left">search</i>SEARCH</Link>
                                    <Link to='#' onClick={handleClear} className="grey waves-light btn"><i className="material-icons left">clear</i>CLEAR</Link>
                                </div>
                            </div>                            
                            <div className="section sec-details">                                
                                <IOUList data={ordered} 
                                auth={auth}
                                onReturnIOU={(id) => {handleReturnIOU(id)}}
                                />
                            </div>
                        </div>
                        <div className="card-action">
                            <Pagination
                            pageNumber = {pagination.pageNumber}
                            firstDoc = {firstDoc}
                            lastDoc ={lastDoc}
                            onNextPageClick={handleNextPage} 
                            onPreviousPageClick={handlePreviousPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default IOU;