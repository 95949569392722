import React, { useEffect } from 'react';
import moment from 'moment';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { CloseButton, EditLinkButton, ViewLinkButton } from '../../utils/components/buttons/LinkButtons';

const IOUList = ({ data, auth, onReturnIOU, pageSize }) => {   

    useEffect(() => {
        //console.log('rendering');
        Materialize.AutoInit();
    });

    const handleReturnIOU = (id) => {
        onReturnIOU(id);
    }

     
     //console.log('IOUs',data);
     const IOUs = data ? (
        data.map(iou => {  
             return(                
                <tr className='list' key={iou.id}> 
                    <td>{iou.index}</td>                           
                    <td>{moment(iou.date === undefined ? new Date() : iou.date.toDate()).format('MM-DD-YYYY')}</td>
                    <td>{iou.customerName}</td>  
                    <td>{iou.projectName && iou.projectName.toUpperCase()}</td>
                    <td>{parseFloat(iou.projectValue).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</td>
                    <td>{iou.endUser ? iou.endUser.toUpperCase() : null}</td>                  
                    <td>{iou.status}</td>
                    <td>{iou.returnDate & iou.status !== 'Closed' ? moment(iou.returnDate.toDate()).fromNow() : null}</td>
                    <td>
                        <div className="row">
                            <div className="col s6">
                                {
                                    (iou.status === '' || iou.status === 'Draft' || iou.status === 'Rejected') ? 
                                    <EditLinkButton to={'/iou/' + iou.id} bg='grey lighten-5'/>
                                    : <ViewLinkButton to={'/iou/' + iou.id} bg='grey lighten-5'/>
                                }
                                
                                {/* <Link to={'/iou/' + iou.id} ><i className="material-icons grey-text text-darken-3">edit</i></Link> */}
                            </div>
                            <div className="col s6">
                                {
                                    auth && (auth.uid === iou.storeManager  && iou.status === 'Open') ? 
                                    <CloseButton to='#' onClick={(id) => {handleReturnIOU(iou.id)}} bg='grey lighten-5'/> 
                                    : null
                                }
                            </div>
                        </div>
                    </td>
                </tr>
             );                                
         }) 
     ) : null;
    return ( 
        <div className="table-overflow">
            <table className=" highlight">
                <thead>
                    <tr>
                        <th data-field="iouNo">IOU #</th>
                        <th data-field="date">Date</th>
                        <th data-field="customer">Customer</th>  
                        <th data-field="customer">Project</th>   
                        <th data-field="customer">Project Value</th>   
                        <th data-field="customer">End User</th>                         
                        <th data-field="status">Status</th>
                        <th data-field="status">Due</th>
                        <th data-field="edit" className="icon-head"></th>
                    </tr>
                </thead>

                <tbody>
                    {IOUs}
                </tbody>
            </table>
        </div>        
     );
}
 
export default IOUList;