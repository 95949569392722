import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

//import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import AutoSelect from '../../utils/components/autoComplete/AutoSelect';
import { loadUsers } from '../../store/actions/userActions';
import { createDivision } from '../../store/actions/divisionActions';


class CreateDivision extends Component {
    state = {
        name: '',
        location: '',
        managerId: '',
        manager: '',
        members: {},
        membersList: []
     }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {        
        e.preventDefault();
        this.props.createDivision(this.state);
    }

    // handleOrganizationSelection = (tags) => {
    //     //console.log(tags);
    //     this.setState({
    //         ...this.state,
    //         orgId: tags.value,
    //         orgName: tags.label
    //     })
    // }
    

    handleManagerSelection = (tags) => {
        /** Divisional approval setting  */
        const usersArray = Object.keys(this.props.users).map((key) => [key, this.props.users[key]]);
        
        let orderedUserList = [];
        usersArray.map(item => {
            orderedUserList.push(item.pop());
            return null;
        });
        //console.log('orderedUserList',orderedUserList);
         let approvalUser = orderedUserList.filter(m => {
            return m.id === tags.value 
        }); 
        
        const divApproval = {
            id: approvalUser[0].id,
            name: approvalUser[0].firstName + ' ' + approvalUser[0].lastName,
            designation: approvalUser[0].designation
        };

        //console.log('state',this.state);
        const newMember = {data:{}}
        const docData = {
            id: tags.value,
            reportingLevel: 1
        };
        const key = tags.value;
        newMember.data[key] = docData;
        this.setState({
            ...this.state,
            managerId: tags.value,
            manager: tags.label,          
            divisionalApprovalFrom: approvalUser[0].uid,
            divisionalApproval: divApproval , 
            membersList: [tags.value],
            members: {...newMember.data }
        })
    }
    
    componentDidMount() {
        this.props.loadUsers('',{
            pageNumber: 0,
            limit: 6,
            orderBy: 'asc',
            cursor: ''
        },
        { nameFilter: ''});
    }
    
    render() { 
        const {auth, users, validation, divisionError} = this.props;

        /*Create Array list from object collection */
        const orderedArray = Object.keys(users).map((key) => [key, users[key]]);
        
        let ordered = [];
        orderedArray.map(item => {
            ordered.push(item.pop());
            return null;
        });        

        let userList = [];
        if (ordered.length > 0) {      
            ordered.forEach((doc) => {
                const tag = {
                    value: doc.id,
                    label: doc.firstName + ' ' + doc.lastName
                }
                /**List only useres who completes sign up process */
                if(doc.uid) userList.push(tag)
            });
        }

        if(!auth.uid) return <Redirect to='/signin'/>;  
        if(validation === 'Passed') return <Redirect to='/div'/>;

        return ( 
            <div className="container division">
                <form onSubmit={this.handleSubmit} className="white">
                    <div className="row">
                        <div className="col s11">
                            <h5 className="red-text text-lighten-1">Create Division</h5>
                        </div>
                        <div className="col s1 btn-close">
                            <Link to='/div' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                        </div>
                    </div>
                    <br />
                    {/* <div className="input-field">
                        <AutoComplete 
                            onSelected={this.handleOrganizationSelection} 
                            collection={'organizations'} search={'name'} 
                            placeholder={'Select organization'}/>
                    </div> */}
                    <div className="input-field">
                        <label htmlFor="Division Name">Division Name</label>
                        <input id="name" onChange={this.handleChange} type="text" className="validate" required/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="Location">Location</label>
                        <input id="location" onChange={this.handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="input-field">
                        <AutoSelect 
                            onSelected={this.handleManagerSelection} 
                            data={userList} placeholder="Select"/>
                    </div>
                    <div className="input-field">
                        <button className="btn red lighten-1 z-depth-0">Create</button>                        
                        <div className="red-text center">
                            {divisionError ? <p>{divisionError}</p> : validation ? <p>{validation}</p> : null}
                        </div>
                    </div>
                </form>
            </div>
         );
    }
}

const mapStateToProps = (state) => {   
    //console.log(state); 
    return{
        auth: state.firebase.auth,
        users: Object.keys(state.user.filteredData).length !== 0  ? state.user.filteredData : state.user.data ? state.user.data : null, 
        divisionError: state.division.divisionErr,
        validation: state.division.validation
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        loadUsers: (search, pagination, pageInfo) => dispatch(loadUsers(search, pagination, pageInfo)),
        createDivision: (division) => dispatch(createDivision(division))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CreateDivision);