import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import { compose } from 'redux';
// import { firestoreConnect } from 'react-redux-firebase'

import { updateOrg } from '../../store/actions/orgActions';

class OrgUnitDetails extends Component {  

    componentDidMount() {
        this.setState({
            ...this.props.org
        })
    } 

    handleChange = (e) => {        
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();    
        this.props.updateOrg(this.state);
    }

    // componentWillReceiveProps = () =>{
    //     const {org} = this.props;
    //     console.log('prop', this.props);
    //     if(org !== null) (
    //         this.setState({
    //             name: org.name,
    //             city: org.city
    //         })
    //     )
    // }

    render() {
        const { auth, org, validation } = this.props;
        if(!auth.uid) return <Redirect to='/signin'/>;
        if(validation === 'Passed') return <Redirect to='/org'/>;
        if(org){
            return ( 
                <div className="container org-unit">
                    <form onSubmit={this.handleSubmit} className="white">
                        <div className="row">
                            <div className="col s11">
                                <h5 className="red-text text-lighten-1">Update Organizational Unit</h5>
                            </div>
                            <div className="col s1 btn-close">
                                <Link to='/org' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                            </div>
                        </div>
                        <br />
                        <div className="row">   
                            <div className="col s12 m6 l6 input-field">
                                <label className="active" htmlFor="name">Name of organization unit</label>
                                <input type="text" defaultValue={org.name} id="name"  onChange={this.handleChange} className="validate" required/>
                            </div>                    
                            <div className="col s12 m6 l6 input-field">
                                <label className="active" htmlFor="City">City</label>
                                <input id="city" defaultValue={org.city} onChange={this.handleChange} type="text" className="validate" required/>
                            </div>                     
                            <div className="col s12 m6 l6 input-field">
                                <label className="active" htmlFor="Phone">Phone</label>
                                <input id="phone" defaultValue={org.phone} onChange={this.handleChange} type="tel" className="validate" required/>
                            </div>                        
                            <div className="col s12 m6 l6 input-field">
                                <label className="active" htmlFor="Fax">Fax</label>
                                <input id="fax" defaultValue={org.fax} onChange={this.handleChange} type="tel" className="validate" required/>
                            </div>                  
                            <div className="col s12 m6 l6 input-field">
                                <label className="active" htmlFor="Company Email">Company Email</label>
                                <input id="email" defaultValue={org.email} onChange={this.handleChange} type="email" className="validate" required/>
                            </div>                     
                            <div className="col s12 m6 l6 input-field">
                                <label className="active" htmlFor="Address">Address</label>
                                <textarea id="address" defaultValue={org.address} onChange={this.handleChange} className="validate materialize-textarea" required></textarea>
                            </div> 
                        </div>
                        <div className="input-field">
                            <button className="btn red lighten-1 z-depth-0">Update</button>                        
                            <div className="red-text center">
                                <p></p>
                            </div>
                        </div>
                    </form>
                </div>
             );
        }else{
            return ( 
                <div className="container center">
                    <p>Loading details...</p>
                </div>
            );
        }        
    }
}

const mapStateToProps = (state, ownProps) => {
    //console.log('data',state.firestore.data.organizations);
    const {id} = ownProps.match.params;
    //const orgs = state.firestore.data.organizations;
    const orgs = state.org.data;
    const org = orgs ? orgs[id] : null; 
    return{
        auth: state.firebase.auth,
        org: org,
        orgError: state.org.orgError,
        validation: state.org.validation
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {id} = ownProps.match.params;
    return{
        updateOrg: (org) => dispatch(updateOrg(org, id))
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(OrgUnitDetails);
 
// export default compose(
//     connect(mapStateToProps, mapDispatchToProps),
//     firestoreConnect([
//         {
//             collection: 'organizations'
//         }
//     ])
// )(OrgUnitDetails);