import React from 'react';
import Pagination from '../../utils/components/buttons/Pagination';
import RepairItems from './RepairItems';

const RepairList = ({stat, rmaList, searchResult, onSelection, pageStatus, onNextPageClick, onPreviousPageClick}) => {
    //console.log('page details', pageStatus);
    const status = {
        waitingRepairs: 0,
        waitingDelivery: 0,
        waitingParts: 0,
        waitingApproval: 0,
        inProcess: 0,
        cancelled: 0,
        closed: 0,
        searchResult: 0
    }

    stat && stat.map(item => {
        switch(item.status){
            case 'Waiting for Repairs':
                status.waitingRepairs = item.statusCount;
                break;
            case 'In Process':
                status.inProcess = item.statusCount;
                break;
            case 'Ready for Collection':
                status.waitingDelivery = item.statusCount;
                break;
            case 'Waiting for Parts':
                status.waitingParts = item.statusCount;
                break;
            case 'Waiting for Customer Approval':
                status.waitingApproval = item.statusCount;
                break;
            case 'Cancelled':
                status.cancelled = item.statusCount;
                break;
            case 'Closed':
                status.closed = item.statusCount;
                break;   
            default:
                break;    
        }
        return null;
    });

    
    const waitingRepairs = rmaList && rmaList.filter(item => {
        return item.status === 'Waiting for Repairs';
    });
    const inProcess = rmaList && rmaList.filter(item => {
        return item.status === 'In Process';
    });
    const waitingDelivery = rmaList && rmaList.filter(item => {
        return item.status === 'Ready for Collection';
    });
    const waitingParts = rmaList && rmaList.filter(item => {
        return item.status === 'Waiting for Parts';
    });
    const waitingApproval = rmaList && rmaList.filter(item => {
        return item.status === 'Waiting for Customer Approval';
    });   
    
    const handleStatusSelection = (index) => {
        //console.log('selected index', index);
        onSelection(index);
    }

    const handleNextPage = (cursor) => {     
        onNextPageClick();
    }
    
    const handlePreviousPage = (cursor) => {
        onPreviousPageClick();
    }
    
    return ( 
        <div className="section sec-details">  
            <div className="">
                <ul className="collapsible popout">
                    <li onClick={() => {handleStatusSelection(0)}}>
                    <div className="collapsible-header"><i className="material-icons">access_time</i>Waiting for Repairs<span className={status.waitingRepairs !== 0 ? "new badge" : "badge"} data-badge-caption="">{status.waitingRepairs}</span></div>
                    <div className="collapsible-body">
                        <span>
                            <RepairItems
                            data= {waitingRepairs}
                            search = {false}
                            />
                        </span>
                    </div>
                    </li>
                    <li onClick={() => {handleStatusSelection(1)}}>
                    <div className="collapsible-header"><i className="material-icons">local_shipping</i>Waiting for Delivery<span className={status.waitingDelivery !== 0 ? "new badge" : "badge"} data-badge-caption="">{status.waitingDelivery}</span></div>
                    <div className="collapsible-body">
                        <span>
                            <RepairItems
                            data= {waitingDelivery}
                            search = {false}
                            />
                        </span>
                    </div>
                    </li>
                    <li onClick={() => {handleStatusSelection(2)}}>
                    <div className="collapsible-header"><i className="material-icons">settings_input_component</i>Waiting for Parts<span className={status.waitingParts !== 0 ? "new badge" : "badge"} data-badge-caption="">{status.waitingParts}</span></div>
                    <div className="collapsible-body">
                        <span>
                            <RepairItems
                            data= {waitingParts}
                            search = {false}
                            />
                        </span>
                    </div>
                    </li>
                    <li onClick={() => {handleStatusSelection(3)}}>
                    <div className="collapsible-header"><i className="material-icons">payment</i>Waiting for Customer Approval<span className={status.waitingApproval !== 0 ? "new badge" : "badge"} data-badge-caption="">{status.waitingApproval}</span></div>
                    <div className="collapsible-body">
                        <span>
                            <RepairItems
                            data= {waitingApproval}
                            search = {false}
                            />
                        </span>
                    </div>
                    </li>
                    <li onClick={() => {handleStatusSelection(4)}}>
                    <div className="collapsible-header"><i className="material-icons">build</i>In Process<span className={status.inProcess !== 0 ? "new badge" : "badge"} data-badge-caption="">{status.inProcess}</span></div>
                    <div className="collapsible-body">
                        <span>
                            <RepairItems
                            data= {inProcess}
                            search = {false}
                            />
                        </span>
                    </div>
                    </li>
                    <li onClick={() => {handleStatusSelection(5)}}>
                    <div className="collapsible-header"><i className="material-icons">search</i>Search Result<span className={searchResult ? "new badge" : "badge"} data-badge-caption="">{searchResult ? searchResult.length : 0}</span></div>
                    <div className="collapsible-body">
                        <span>
                            <RepairItems
                            data= {searchResult}
                            search = {true}
                            />
                        </span>
                        <Pagination
                            pageNumber = {pageStatus.currentPage}
                            firstDoc = ''
                            lastDoc = ''
                            onNextPageClick={handleNextPage} 
                            onPreviousPageClick={handlePreviousPage}
                            enablePrevious = { true }
                        />
                    </div>
                    </li>
                </ul>
            </div> 
        </div>
     );
}
 
export default RepairList;