import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({pageNumber, firstDoc, lastDoc, onNextPageClick, onPreviousPageClick, enablePrevious = false}) => {
    
    const prev = enablePrevious && pageNumber > 0 ? (
        <li onClick={() => onPreviousPageClick(firstDoc)} className="disabled"><Link to='#'><i className="material-icons">chevron_left</i></Link></li>
    ) : null;
    return ( 
        <ul className="pagination">
            {prev}
            <li><Link to='#'>Page {pageNumber + 1}</Link></li>
            <li onClick={() => onNextPageClick(lastDoc)} className="waves-effect"><Link to='#'><i className="material-icons">chevron_right</i></Link></li>
        </ul>
     );
}
 
export default Pagination;