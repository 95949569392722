const initState = {
    data: {},
    loggedInUser: null,
    filteredData: {},
    loadData: true,
    pageInfo: {},
    validation: null
}

const userReducer = (state= initState, action) => {
    switch(action.type){
        case 'CREATE_USER_ERROR':
            console.log('Create user error', action.err);
            return{
                ...state,
                userError: action.err.message,
                validation: null
            }
        case 'CREATE_USER':
            console.log('Created new user', action.user);
            return{
                ...state,
                data: {...state.data, ...action.user.data},
                userError: null,
                validation: 'Passed'
            }
        case 'UPDATE_USER_ERROR':
            console.log('Update user error', action.err);
            return{
                ...state,
                userError: action.err.message,
                validation: null
            }
        case 'UPDATE_USER':
            console.log('Updated user', action.user);
            return{
                ...state,
                data: {...state.data, ...action.user.data},
                userError: null,
                validation: 'Passed'
            }
        case 'DELETE_USER':
            console.log('User deleted', action.user);
            return{
                ...state,
                data: {...state.data, ...action.user.data},
                userError: null,
                validation: 'Passed'
            }
        case 'DELETE_USER_ERROR':
            console.log('Delete user error', action.err);
            return{
                ...state,
                userError: action.err.message,
                validation: null
            }
        case 'LOAD_USERS':
            console.log('Loading users', action.user);
            return{
                ...state,
                data: {...state.data, ...action.user.data},
                filteredData: {},
                pageInfo: {...state.pageInfo, ...action.user.pageInfo},
                loadData: false,
                userError: null,
                validation: null
            }
        case 'PAGE_LOADING':
            console.log('Loading users...');
            return{
                ...state,
                pageInfo: {...state.pageInfo, getUsersList: false}
            }
        case 'FILTER_USERS':
            console.log('Loading users', action.data);
            return{
                ...state,
                data: {...state.data, ...action.user.data},
                filteredData: action.user.data,
                userError: null,
                validation: null
            }
        case 'LOAD_ERROR':
            console.log('Loading users', action.data);
            return{
                ...state,
                userError: null,
                validation: null
            }
        case 'GET_USER_DETAILS':
            console.log('Loading users', action.user);
            return{
                ...state,
                data: {...state.data, ...action.user.data},
                loggedInUser: action.user.loggedInUser
            }
        case 'RESET_VALIDATION':
            return{
                ...state,
                validation: null
            }
        default:
            return state;
    }
}

export default userReducer;