import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeControl, ApproveIOU, RejectIOU } from '../../store/actions/iouActions';
import { CloseButton } from '../../utils/components/buttons/LinkButtons';
import MsgAndDoc from './MsgAndDoc';

// import Materialize from 'materialize-css/dist/js/materialize.min.js';

let ordered = [];
let products = null;
let productTable = null;
let productTotal = null;
const ApprovalRequestSummary = ({iouId}) => {
    const dispatch = useDispatch();    
    const [componentState, setComponentState] = useState({});
    const [purpose, setPurpose] = useState();
    const [period, setPeriod] = useState();
    const [customerName, setCustomerName] = useState();
    const [address, setAddress] = useState();
    const [contactPerson, setContactPerson] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [requested, setrRequested] = useState();
    const [requestedPhone, setRequestedPhone] = useState();
    const [projectName, setProjectName] = useState('');
    const [projectValue, setProjectValue] = useState();
    const [endUser, setEndUser] = useState('');
    const [psm, setPsm] = useState('');
    const [totalAmount, setTotalAmount] = useState();
    const [message, setMessage] = useState();
    const [userMessage, setUserMessage] = useState();
 

    // useEffect(() => {
    //     Materialize.AutoInit();
    // })

    const handleClose = () => {     
        dispatch(closeControl());
    }   
    
    const handleChange = (e) => {
        if( e.target.id === 'userMessage') setUserMessage(e.target.value);
    }

    const handleApprovingPeriod = (e) => {
        setPeriod(e.target.value);
    }

    const handleApprove = () => {
        if(userMessage !== undefined){
            const msg = {
                message_at: new Date(),
                messageFrom: profile.firstName + ' ' + profile.lastName,
                message: userMessage
            }
            const userMessages = componentState.iou.messages === undefined ? [msg] : [...componentState.iou.messages, msg];
            dispatch(ApproveIOU(componentState.iou.id, componentState.iou.requestedDivision, userMessages, period));
        } 
    }

    const handleReject = () => {
        //console.log('Reject');
        if(userMessage !== undefined){
            const msg = {
                message_at: new Date(),
                messageFrom: profile.firstName + ' ' + profile.lastName,
                message: userMessage
            }
            const userMessages = componentState.iou.messages === undefined ? [msg] : [...componentState.iou.messages, msg];
            dispatch(RejectIOU(componentState.iou.id,  userMessages));
        } 
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handlePdfView = (url) => {

    }

    const iou = useSelector(state => state.iou);
    const iouDetails = useSelector(state => state.iou.data[iouId]); //Data loaded from dashboard
    const profile = useSelector(state => state.firebase.profile);

    useEffect(() => {
        console.log('iouDetails', iouDetails);
        if(iouDetails){
            setComponentState({
                iou: iouDetails
            });  
            setPurpose(iouDetails.purpose);
            setPeriod(iouDetails.period);
            setCustomerName(iouDetails.customerName);
            setAddress(iouDetails.applied ? iouDetails.applied.address : null);
            setContactPerson(iouDetails.contactPerson);
            setContactNumber(iouDetails.contactNumber);
            setrRequested(iouDetails.requested ? iouDetails.requested.name : null);
            setRequestedPhone(iouDetails.requested ? iouDetails.requested.phone : null);
            setProjectName(iouDetails.projectName);
            setProjectValue(iouDetails.projectValue);
            setEndUser(iouDetails.endUser);
            setPsm(iouDetails.psm);
            setTotalAmount(iouDetails.totalAmount);
            setMessage(iouDetails.message);

            /*Create Array list from object collection */
            ordered = [];
            const orderedArray = Object.keys(iouDetails.productList).map((key) => [key, iouDetails.productList[key]]);    
        
            orderedArray.map(item => {    
                ordered.push(item.pop());
                return null;
            });   
    
            //console.log('ordered',ordered);
            let slNo = 1;
            let totalAmount = 0;
            products = ordered.length ? (       
                ordered.map(product => {
                    totalAmount += parseFloat(product.netAmount);
                    return(
                        <div className="row bb" key={product.id}>
                            <div className="col s1">
                                <p>{slNo++}</p>
                            </div>
                            <div className="col s4">
                                <p>{product.name}</p>
                            </div>
                            <div className="col s2">
                                <p>{product.quantity}</p>
                            </div>                
                            <div className="col s2">
                                <p className="right">{product.price.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                            </div>                                
                            <div className="col s2">
                                <p className="right">{product.netAmount.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                            </div>                                                
                            <div className="col s1">
                                <p className="center">{product.willReturn}</p>
                            </div>
                        </div>
                    );                                
                }) 
            ) : null;

            productTotal = ordered.length ? ( 
                <div className="product-total">
                    <div className="row bold">
                        <div className="col s1">
                        </div>   
                        <div className="col s8">
                            <p>Total</p>
                        </div>                        
                        <div className="col s2">
                            <p className="right">AED {totalAmount.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                        </div>                                                
                        <div className="col s1">
                        </div>
                    </div>
                </div>           
            ) : null;

            productTable = ordered.length ? (  
                <div className="product-list">
                    <div className="row bold grey lighten-4 bb">
                        <div className="col s1">
                            <p>Sl No.</p>
                        </div>
                        <div className="col s4">
                            <p>Model No.</p>
                        </div>
                        <div className="col s2">
                            <p>Qty. (Pcs)</p>
                        </div>                
                        <div className="col s2">
                            <p className="right">Unit Price</p>
                        </div>                                
                        <div className="col s2">
                            <p className="right">Total Amount</p>
                        </div>                                                
                        <div className="col s1">
                            <p className="center">Return</p>
                        </div>
                    </div>
                    {products}
                </div>
            ) : null;   
        } 
    },[iouDetails]); 
    
    return ( 
        <React.Fragment>
            <div id="message" className={'big message ' + iou.approvalScreenControl}>
                <div className="right"><CloseButton  onClick={handleClose} bg='grey lighten-5'/> </div> 
                <div className="modal-content"> 
                    <div className="card z-depth-1 modal-card">                
                        <div className="card-content msg grey-text text-darken-3">
                            <span className="card-title teal-text text-darken-4">IOU Approval <span className="red-text sub">{purpose} for {period} days</span></span>
                            
                            <div className="row">
                                <div className="col s7">
                                    <div className="box-container">
                                        <div className="box">
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Customer Name</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{customerName}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Address</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{address}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Contact Person</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{contactPerson}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Contact Number</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{contactNumber}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Requested by</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{requested}</p>
                                                </div>
                                            </div>                           
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Mobile</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{requestedPhone}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s5">
                                    <div className="todo">
                                        <div className="box">
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Project Name</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{projectName ? projectName.toUpperCase() : null}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Project Value</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{parseFloat(projectValue).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>End User</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{endUser ? endUser.toUpperCase() : null}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>PSM</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{psm ? psm.toUpperCase() : null}</p>
                                                </div>
                                            </div>                           
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>IOU Amount.</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{parseFloat(totalAmount).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Message</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{message}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    {productTable}                            
                                    {productTotal}
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="msg">
                            <div className="row">
                                <div className="col s6 input-field">                                     
                                    <div className="row">
                                        <div className="col s4">
                                            <div className="input-field">
                                                <label className='active'  htmlFor="Approving Period">Approving Period</label>
                                                <input id="period" onChange={handleApprovingPeriod} defaultValue={period}  className="validate" required type='number'/>
                                            </div>
                                        </div>
                                    </div>   
                                    <div className="input-field">
                                        <label  htmlFor="Message">Message</label>
                                        <textarea id="userMessage" onChange={handleChange} className="validate materialize-textarea" required></textarea>
                                    </div>                                
                                    
                                    <div className="input-field"> 
                                        <button onClick={handleApprove} className={'btn orange z-depth-0'}>Approve</button> 
                                        <button onClick={handleReject} className={'btn red lighten-1 z-depth-0'}>Reject</button>
                                    </div>
                                </div> 
                                <div className="col s6">
                                    <MsgAndDoc
                                    messages={iouDetails && iouDetails.messages}
                                    documents={iouDetails && iouDetails.documents}
                                    onPdfView={(url) => {handlePdfView(url)}}                                    
                                    />
                                </div>
                            </div>                            
                           
                        </form>
                    </div>
                </div>
            </div>     
              
            <div id="overlay" className={'message-overlay ' + iou.approvalScreenControl}></div>
        </React.Fragment>
     );
}
 
export default ApprovalRequestSummary;