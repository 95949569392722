const initState = {
    data: {},
    approvalRequests: {},
    filteredData: {},
    pageInfo: {},
    validation: null,
    approvalScreenControl: 'close',
    deliveryScreen: 'close',
    returnScreen: 'close',
    uploadProgress: 0
}

const iouReducer = (state= initState, action) => {
    switch(action.type){
        case 'CREATE_IOU_ERROR':
            console.log('Create iou error', action.err);
            return{
                ...state,
                iouError: action.err.message
            }
        case 'CREATE_IOU':
            console.log('Created new iou', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data},
                iouError: null,
                validation: 'Passed'
            }
        case 'UPDATE_IOU_ERROR':
            console.log('Update iou error', action.err);
            return{
                ...state,
                iouError: action.err.message
            }
        case 'UPDATE_IOU':
            console.log('Updated iou', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data},
                iouError: null,
                validation: 'Passed'
            }
        case 'DELETE_IOU_ERROR':
            console.log('Update iou error', action.err);
            return{
                ...state,
                iouError: action.err.message
            }
        case 'DELETE_IOU':
            console.log('Updated iou', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data},
                iouError: null,
                validation: 'Passed'
            }
        case 'LOAD_IOU':
            console.log('Loading iou', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data},
                filteredData: {},
                pageInfo: action.iou.pageInfo
            }
        case 'FILTER_IOU':
            console.log('Loading iou', action.iou);
            return{
                ...state,
                filteredData: action.iou.data,
                pageInfo: action.iou.pageInfo
            }
        case 'IOU_ERROR':
            console.log('Loading iou', action.err);
            return{
                ...state,
                iouError: action.err
            }
        case 'IOU_APPROVALS':
            console.log('Loading iou approvals', action.approvalRequests);
            return{
                ...state,
                approvalRequests: {...state.approvalRequests, ...action.approvalRequests.data}
            }
        case 'APPROVE_IOU':
            console.log('IOU approved');
            return{
                ...state,
                approvalScreenControl: 'close'
            }
        case 'REJECT_IOU':
            console.log('IOU rejected');
            return{
                ...state,
                approvalScreenControl: 'close'
            }
        case 'REQUEST_IOU_DELIVERY':
            console.log('IOU delivery requested');
            return{
                ...state,
                uploadProgress: 0
            }
        case 'IOU_DELIVERY':
            console.log('IOU delivery', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data},
                deliveryScreen: 'close'
            }            
        case 'CLOSE_IOU':
            console.log('IOU delivery', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data},
                returnScreen: 'close'
            }
        case 'IOU_RETURN':
            console.log('IOU return', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data},
                returnScreen: 'close'
            }
        case 'IOU_UPLOAD_PROGRESS':
            console.log('Upload is ' + action.progress + '% done');
            return{
                ...state,
                uploadProgress: action.progress
            }
        case 'GET_IOU_DETAILS':
            console.log('Get iou details', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data}
            }
        case 'GET_IOU_DETAILS_SUMMARY':
            console.log('Get iou for summary', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data}, 
                approvalScreenControl: 'open'
            }
        case 'GET_IOU_DETAILS_DELIVERY':
            console.log('Get iou details for delivery', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data}, 
                deliveryScreen: 'open'
            }
        case 'GET_IOU_DETAILS_RETURN':
            console.log('Get iou details for return', action.iou);
            return{
                ...state,
                data: {...state.data, ...action.iou.data}, 
                returnScreen: 'open'
            }
        case 'RESET_IOU_VALIDATION':
            return{
                ...state,
                validation: null
            }
        case 'CLOSE_IOU_APPROVAL_CONTROL':
            console.log('Closing approval screen');
            return{
                ...state, 
                approvalScreenControl: 'close'
            }
        case 'CLOSE_IOU_DELIVERY_CONTROL':
            console.log('Closing iou delivery screen');
            return{
                ...state, 
                deliveryScreen: 'close'
            }
        case 'CLOSE_IOU_RETURN_CONTROL':
            console.log('Closing iou return screen');
            return{
                ...state, 
                returnScreen: 'close'
            }
        case 'CLEAR_IOU_MESSAGES':
            console.log('Clearing messages');
            return{
                ...state,
                iouError: action.err.message,
            } 
        default:
            return state;
    }
}

export default iouReducer;