import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from '../../store/actions/authActions';
import { connect } from 'react-redux';

import Materialize from 'materialize-css/dist/js/materialize.min.js';

class UserConsole extends Component {
    state = {  }

    componentDidMount() {
        const collapsibleElement = document.getElementById('subcat');
        if(collapsibleElement !== null){
            const collapsibleInstance =  Materialize.Collapsible.getInstance(collapsibleElement);     
            collapsibleInstance.close(0);
        }
    }    

    
    handleSignOut = (e) => {
        this.props.signOut();
    }
    render() { 
        const privilege = this.props.privilege;
        //console.log('privilege', privilege);
        return ( 
           <div>
                <div>                
                    <li><Link to='/' className="sidenav-close grey-text text-darken-3"><i className="material-icons">dashboard</i>Dashboard</Link></li>
                    <li><Link to='/iou' className="sidenav-close grey-text text-darken-3"><i className="material-icons">group_work</i>IOU</Link></li>
                    <li><Link to='/ord' className="sidenav-close grey-text text-darken-3"><i className="material-icons">system_update_alt</i>Orders</Link></li>
                    {
                        privilege && (privilege === 'service-manager' || privilege === 'service-personnel' || privilege === 'admin' || privilege === 'power') ? 
                        
                        <li className="no-padding">
                            <ul className="collapsible" id="subcat">
                                    <li>
                                        <span className="collapsible-header grey-text text-darken-3 block">RMA<i className="material-icons">build</i></span>
                                        <div className="collapsible-body">
                                            <ul> 
                                                <li><Link to='/crrma' className="sidenav-close grey-text text-darken-3">New RMA</Link></li>
                                                <li><Link to='/rma' className="sidenav-close">RMA Register</Link></li> 
                                                <li><Link to='/rgrma' className="sidenav-close">Repairing Register</Link></li>
                                                <li><Link to='/crdln' className="sidenav-close grey-text text-darken-3">Create DN</Link></li>
                                                <li><Link to='/dln' className="sidenav-close grey-text text-darken-3">Delivery Notes</Link></li>
                                                <li><Link to='/spa' className="sidenav-close grey-text text-darken-3">Spare Parts</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                            </ul>
                        {/* <li><Link to='/rma' className="sidenav-close grey-text text-darken-3"><i className="material-icons">build</i>RMA</Link></li>  */}
                        </li>
                        
                        : '' 
                    }
                </div>
                <li><div className="divider"></div></li>
                <li><Link to='/profile' className="sidenav-close grey-text text-darken-3"><i className="material-icons">account_circle</i>Profile</Link></li>
                
                <li><div className="divider"></div></li>
                <li><a href="/" onClick={this.handleSignOut} className="sidenav-close sidenav-close"><i className="material-icons">power_settings_new</i>Sign Out</a></li>
           </div>
         );
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        signOut: () => dispatch(signOut())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(UserConsole);