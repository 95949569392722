import React from 'react';
import { useHistory } from 'react-router-dom';

import { EditLinkButton } from '../../utils/components/buttons/LinkButtons';

const RepairItems = ({data, search}) => {  
    const history = useHistory();
    const handleOnSelect = (id) => {
        history.push('/rgrma/' + id);
    }
    const rmaList = data ? (
        search ? data.map(item => {  
            return(                
               <tr onDoubleClick={() => {handleOnSelect(item.id)}} className='list' key={item.id}> 
                   <td className="td0">{item.rmaDate}</td>   
                   <td>{item.customerName}</td>    
                   <td className="td1">{item.productName}</td> 
                   <td className="td1">{item.serialNumber}</td>
                   <td>{item.faultDescription}</td>         
                   <td>{item.status}</td>
                   <td>
                       <EditLinkButton to={'/rgrma/' + item.id} bg='grey lighten-5'/>
                   </td>
               </tr>
            );                                
        })  :
        data.map(item => {  
             return(                
                <tr onDoubleClick={() => {handleOnSelect(item.id)}} className='list' key={item.id}> 
                    <td className="td0">{item.rmaDate}</td>   
                    <td>{item.customerName}</td>    
                    <td className="td1">{item.productName}</td> 
                    <td className="td1">{item.serialNumber}</td>
                    <td>{item.faultDescription}</td>
                    <td>{item.problemIdentified}</td>                  
                    <td>{item.repairNotes}</td>                 
                    <td>{item.remarks}</td>
                    <td>
                        <EditLinkButton to={'/rgrma/' + item.id} bg='grey lighten-5'/>
                    </td>
                </tr>
             );                                
         }) 
     ) : null;

     const tableHeads = search ? <tr>
     <th data-field="orderNumber">Date</th>
     <th data-field="date">Customer Name</th>
     <th data-field="customer">Product Name</th>  
     <th data-field="salesPerson">Serial Number</th>   
     <th data-field="location">Customer Problem</th>                
     <th data-field="status">Stauts</th>
     <th data-field="edit" className="icon-head"></th>
 </tr> :
 <tr>
 <th data-field="orderNumber">Date</th>
 <th data-field="date">Customer Name</th>
 <th data-field="customer">Product Name</th>  
 <th data-field="salesPerson">Serial Number</th>   
 <th data-field="location">Customer Problem</th>   
 <th data-field="grandTotal">Problem Tested</th>                         
 <th data-field="status">Repair Notes</th>                        
 <th data-field="status">Remarks</th>
 <th data-field="edit" className="icon-head"></th>
</tr>;

    return ( 
        <div className="section sec-details">  
            <div className="table-overflow">
                <table className=" highlight">
                    <thead>
                        {tableHeads}
                    </thead>                                            
                    <tbody>
                        {rmaList}
                    </tbody>
                </table>
            </div> 
        </div>
     );
}
 
export default RepairItems;