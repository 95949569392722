import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
//import { BaseRoutes } from './BaseRoutes';
// import { AdminRoutes } from './AdminRoutes';

// import AdminPanel from '../layout/AdminPanel';
import PageNotFound from '../notFound/404';
import OrgUnits from '../orgUnits/Component';
import CreateOrgUnit from '../orgUnits/CreateOrgUnit';
import OrgUnitDetails from '../orgUnits/OrgUnitDetails';
import Divisions from '../divisions/Component';
import CreateDivision from '../divisions/CreateDivision';
import DivisionDetails from '../divisions/DivisionDetails';
import Users from '../users/Component'
import CreateUser from '../users/CreateUser';
import UserDetails from '../users/UserDetails';
import Proudcts from '../products/Component';
import ExportProducts from '../products/ExportProducts';
import CreateProduct from '../products/CreateProduct';
import ProductDetails from '../products/ProductDetails';
import Customers from '../customers/Component';
import CreateCustomer from '../customers/CreateCustomer';
import CustomerDetails from '../customers/CustomerDetails';
//import { ServiceRoutes } from './ServiceRoutes';

/**Base Rotes */
import Profile from '../../components/users/Profile';
import IOU from '../iou/Component';
import CreateIOU from '../iou/CreateIOU';
import IOUDetails from '../iou/IOUDetails';
import Orders from '../orders/Component';
import OrderList from '../orders/OrderList';
import OrderDetails from '../orders/OrderDetails';

/**Service Routes */
import RMA from '../rma/Component';
import CreateRMA from '../rma/CreateRMA';
import RepairRegister from '../rma/RepairRegister';
import RMADetails from '../rma/RMADetails';
import RepairItemDetails from '../rma/RepairItemDetails';
import SpareParts from '../spareParts/Component';
import SparePartDetails from '../spareParts/SparePartDetails';
import CreateSparePart from '../spareParts/CreateSaparePart';
import DeliveryNotes from '../rma/DeliveryNotes';
import CreateDeliveryNote from '../rma/CreateDeliveryNote';
import DeliveryNoteRegister from '../rma/DeliveryNoteRegister';

// import Profile from '../../components/users/Profile';
// import IOU from '../iou/Component';

const Routes = ({profile}) => {

    const customerRoutes =  
    <>        
        <Route exact path='/cus' component={Customers}/>      
        <Route path='/crcus' component={CreateCustomer}/>
        <Route path='/cus/:id' component={CustomerDetails}/> 
    </> ;
    
    const ServiceRoutes = (profile.privilege === 'service-personnel' || 
    profile.privilege === 'service-manager' || 
    profile.privilege === 'admin' || 
    profile.privilege === 'power') ? 
    <>
        <Route exact path='/rma' component={RMA}/> 
        <Route exact path='/rgrma' component={RepairRegister}/>
        <Route exact path='/crrma' component={CreateRMA}/> 
        <Route path='/rgrma/:id' component={RepairItemDetails}/>
        <Route path='/rma/:id' component={RMADetails}/>
        <Route exact path='/spa' component={SpareParts}/>
        <Route path='/crspa' component={CreateSparePart}/>
        <Route path='/spa/:id' component={SparePartDetails}/>
        <Route exact path='/crdln' component={DeliveryNotes}/>
        <Route path='/crdln/:id' component={CreateDeliveryNote}/>
        <Route path='/dln' component={DeliveryNoteRegister}/>
        
        {customerRoutes}
    </> :  
    null;

    const userRoutes =  
    <>
        <Route path='/profile' component={Profile}/>    
        <Route exact path='/iou' component={IOU}/> 
        <Route path='/criou' component={CreateIOU}/>
        <Route path='/iou/:id' component={IOUDetails}/>  
        <Route exact path='/ord' component={Orders}/> 
        <Route path='/lsord/:ch' component={OrderList}/>
        <Route path='/ord/:id' component={OrderDetails}/>
    </> ;

    const routes = profile.privilege === 'super' ? (
        <>
            <Route exact path='/org' component={OrgUnits}/>    
            <Route path='/crorg' component={CreateOrgUnit}/>
            <Route path='/org/:id' component={OrgUnitDetails}/>
            <Route  component={PageNotFound}/>
        </>
    ) : profile.privilege === 'power' || profile.privilege === 'admin' ?  (
        <>
            <Route exact path='/div' component={Divisions}/>    
            <Route path='/crdiv' component={CreateDivision}/>
            <Route path='/div/:id' component={DivisionDetails}/> 
            <Route exact path='/usr' component={Users}/> 
            <Route path='/crusr' component={CreateUser}/> 
            <Route path='/usr/:id' component={UserDetails}/>
            <Route exact path='/pro' component={Proudcts}/>      
            <Route path='/pro/ex' component={ExportProducts}/> 
            <Route path='/crpro' component={CreateProduct}/>  
            <Route path='/pro/:id' component={ProductDetails}/>  
            {customerRoutes}
            {userRoutes}
            {ServiceRoutes} 
        </>
    ) : (
        <>             
            {userRoutes}
            {ServiceRoutes}  
        </>
    );
    
    return ( 
        <Switch>
            {routes}               
            <Route  component={PageNotFound}/>        
        </Switch>
     );
}

const mapStateToProps = (state) => {
    return{
        profile: state.firebase.profile
    }
}
 
export default connect(mapStateToProps)(Routes);