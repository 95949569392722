import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { ExcelRenderer } from 'react-excel-renderer';

import { ClearCustomer, getCustomerDetails, setCustomerDetails, setCustomer } from '../../store/actions/customerActions';
import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import ProductList from './ProductList';
import {createRMA} from '../../store/actions/rmaActions';
import { UploadLinkButton } from '../../utils/components/buttons/LinkButtons';

const CreateRMA = (props) => {      
    const dispatch = useDispatch();
    
    const [clearField] = useState();
    const [componentState, setComponentState] = useState({
        productList: {}
    });
    
    const [serialNumber, setSerialNumber] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [warrantyStatus, setWarrantyStatus] = useState('');
    const [faultDescription, setFaultDescription] = useState('');

    useEffect(() => {
        Materialize.AutoInit();
    },[]);

    useEffect(() => {
        dispatch(ClearCustomer());
    },[dispatch]);

    /* Importing data from excel file */
     /** Handling input file component from Link component */
     const inputFile = useRef(null);

     const handleImport = () => {      
         inputFile.current.click();  
     }
 
     /** Resetting the input file component for multiselect */
     const handleImportClick = (e) => {
         e.target.value = null;
     }
 
     /** Importing data from excel
      * Column names required [sku, name, description]
      */
     const changeHandler = (e) => {  
         e.stopPropagation();
         e.preventDefault();
         let fileObj = e.target.files[0];
         if(fileObj !== undefined){
             if(!(fileObj.type === 'application/vnd.ms.excel' 
             || fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')){
                 console.log('Unkonwn file format');     
                 return false;           
             }else{
                 ExcelRenderer(fileObj, (err, resp) => {
                     if(err){
                         console.log('Excel read error', err);
                         return false;
                     }else{
                         //let newRows = [];
                         let newCols = [];                         
                         const newProduct = {data:{}};
                         let serialNumberIndex = null;
                         let modelNumberIndex = null;
                         let warrantyStatusIndex = null;  
                         let faultDescriptionIndex = null; 
                         let i = 0;
                         //console.log('Excel Data', resp.rows);
                         resp.rows.map((row, index) => {                       
                             if(row && row !== undefined){
                             if(index  === 0){
                                     row.map(r => {
                                         if(r === 'serialNumber') serialNumberIndex = i;
                                         if(r === 'modelNumber') modelNumberIndex = i;
                                         if(r === 'warrantyStatus') warrantyStatusIndex = i;
                                         if(r === 'faultDescription') faultDescriptionIndex = i;
                                         if(serialNumberIndex !== null 
                                         || modelNumberIndex !== null 
                                         || warrantyStatusIndex !== null 
                                         || faultDescriptionIndex !== null ) 
                                         {
                                             newCols.push({
                                                 key: i,
                                                 name: r
                                             })
                                             i++;
                                         }   
                                         return null;                                 
                                     });
                             }else{
                                     if(serialNumberIndex !== null 
                                     && modelNumberIndex !== null 
                                     && warrantyStatusIndex !== null 
                                     && faultDescriptionIndex !== null ) 
                                     {
                                         if(row[serialNumberIndex] !== undefined && row[modelNumberIndex] !== undefined  && row[warrantyStatusIndex] !== undefined
                                             && row[serialNumberIndex] !== '' && row[modelNumberIndex] !== ''
                                             && ( row[warrantyStatusIndex] === 'In Warranty' || row[warrantyStatusIndex] === 'Out of Warranty')
                                         ){                                             
                                            const docData = {
                                                serialNumber: row[serialNumberIndex],
                                                modelNumber: row[modelNumberIndex],
                                                warrantyStatus: row[warrantyStatusIndex],
                                                faultDescription: row[faultDescriptionIndex]
                                            };
                                            newProduct.data[row[serialNumberIndex]] = docData;  
                                         }
                                     } else return false;
                             }
                                 
                             }
                             return null;
                         })
                         if(newProduct.length === 0){
                             return false;
                         }else{
                             if(serialNumberIndex === null 
                                 || modelNumberIndex === null 
                                 || warrantyStatusIndex === null 
                                 || faultDescriptionIndex === null 
                             ) return false;
                             console.log('rows',newProduct);
                             setComponentState({
                                 ...componentState, 
                                 productList: {...componentState.productList,...newProduct.data }
                             })
                         }
                     }    
                 })
                 return false;
             }
         }
                
     }

     

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth); 
    const profile = useSelector(state => state.firebase.profile);   
    const validation = useSelector(state => state.rma.validation); 
    const rmaError = useSelector(state => state.rma.rmaError); 
    const customers = useSelector(state => state.customer.data);
    const customerDetails = useSelector(state => state.customer.customer);

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/rma'/>;    

    const handleCustomerSelection = (tags) => {
        setComponentState({
            ...componentState,
            customerId: tags.value,
            customerName: tags.label
        });  
        /** Fetch data if not exist */
        if(customers[tags.value]) {            
            //console.log('Selected Customer: ', tags);
            dispatch(setCustomerDetails(tags.value, customers[tags.value]));
        }else{
            dispatch(getCustomerDetails(tags.value));
        } 
    }

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value.trim()
        })
        dispatch(setCustomer(e.target.id, document.getElementById(e.target.id).value));
    }

    const handleSerialNumber = (e) => {
        setSerialNumber(e.target.value);
    }

    const handleModelNumber = (e) => {
        setModelNumber(e.target.value);
    }

    const handleWarrantyStatus = (e) => {
        setWarrantyStatus(e.target.value);
    }

    const handleFaultDescription = (e) => {
        setFaultDescription(e.target.value);
    }

    const handleAdd = () => {
        //Add product to the list
        if(serialNumber !== '' &&
        modelNumber !== '' && 
        warrantyStatus !== '' &&
        faultDescription !== ''){
            if(!componentState.productList){
                const newProduct = {data:{}};
                const docData = {
                    serialNumber: serialNumber,
                    modelNumber: modelNumber,
                    warrantyStatus: warrantyStatus,
                    faultDescription: faultDescription
                }
                newProduct.data[serialNumber] = docData;
                setComponentState({
                    ...componentState,
                    productList: {...componentState.productList,...newProduct.data }
                });
                
            }else{
                const newProduct = {data:{}}
                const docData = {
                    serialNumber: serialNumber,
                    modelNumber: modelNumber,
                    warrantyStatus: warrantyStatus,
                    faultDescription: faultDescription
                };
                newProduct.data[serialNumber] = docData;
                setComponentState({
                    ...componentState,
                    productList: {...componentState.productList,...newProduct.data }
                });    
            }

            handleClear();
        }
    }

    const handleItemSelected = (serialNumber) => {
        //console.log('serialNumber',serialNumber);
        const selection = componentState.productList[serialNumber];
        if(selection){
            setSerialNumber(selection.serialNumber);
            setModelNumber(selection.modelNumber);
            setWarrantyStatus(selection.warrantyStatus);
            setFaultDescription(selection.faultDescription);
        }
       
    } 

    const handleItemDelete = (serialNumber) => {
        delete componentState.productList[serialNumber];
    }
    
    const handleClear = () => {
        setSerialNumber('');
        setModelNumber('');
        setWarrantyStatus('');
        setFaultDescription('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('props state',customerDetails.customerName);
        if(customerDetails.customerName === '' || 
        customerDetails.customerEmail === '' || 
        customerDetails.customerPhone === '' ){
            Materialize.toast({html: 'Fill Customer details'});
        }else if(
        Object.keys(componentState.productList).length === 0){
            Materialize.toast({html: 'Enter product details'});
        }else{
            dispatch(createRMA(profile.account, customerDetails, componentState.productList));
        }
        
    }

    return ( 
        <div className="section rma">
            <div className="white p-5">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">New RMA</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/rma' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m5 l5 input-field">
                    
                        <AutoComplete
                        onSelected={handleCustomerSelection} 
                        collection={'customers'} search={'name'} account={profile.account}
                        placeholder={'Search customers'}
                        value={clearField}
                        //clearSearchParam={false}
                        />
                        
                    </div>
                    <div className="col s12 m1 l1 input-field">
                    <Link to='/crcus' className="btn-floating btn-small waves-effect waves-light red"><i className="material-icons">add</i></Link>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <p className="right" htmlFor="Date">Date : {moment(new Date()).format('DD-MM-YYYY')}</p>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <div className="row cus-det">
                            <div className="col s12">
                                <label htmlFor="Customer Name">Customer Name</label>
                                <input id="customerName" onChange={handleChange} value={customerDetails.customerName} type="text" className="validate" required/>

                            </div>
                            <div className="col s6">
                                <label htmlFor="Email Address">Email Address</label>
                                <input id="customerEmail" onChange={handleChange} value={customerDetails.customerEmail} type="text" className="validate" required/>
                            </div>
                            <div className="col s6">
                                <label htmlFor="Phone">Phone</label>
                                <input id="customerPhone" onChange={handleChange} value={customerDetails.customerPhone} type="text" className="validate" required/>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Address">Address</label>
                        <textarea id="customerAddress" onChange={handleChange} value={customerDetails.customerAddress} type="text" className="materialize-textarea validate" required></textarea>
                    </div>                
                </div>
                <div className="row">
                    <div className="mt-5">
                    <div className="card z-depth-1">
                        <div className="card-content">
                            <div className="row">
                                <div className="input-field col s12 m2 l2">
                                    <label htmlFor="Serial Number">Product Serial Number</label>
                                    <input onChange={handleSerialNumber} value={serialNumber} className="serialNumber all-cap" id="serialNumber" type="text"/>
                                </div>
                                <div className="input-field col s12 m2 l2">
                                    <label htmlFor="Model Number">Product Model Number</label>
                                    <input onChange={handleModelNumber} value={modelNumber} className="modelNumber all-cap" id="modelNumber" type="text"/>
                                </div>
                                <div className="input-field col s12 m2 l2">
                                    <select onChange={handleWarrantyStatus} value={warrantyStatus}  className="warrantyStatus" id="warrantyStatus">
                                        <option value="" disabled>Choose Warranty Status</option>
                                        <option value="In Warranty">In Warranty</option>
                                        <option value="Out of Warranty">Out of Warranty</option>
                                    </select> 
                                </div>
                                <div className="input-field col s12 m3 l3">
                                    <label htmlFor="Fault Description">Fault Description</label>
                                    <input onChange={handleFaultDescription} value={faultDescription} className="faultDescription" id="faultDescription" type="text"/>
                                </div>
                                <div className="input-field col s12 m3 l3">
                                    <Link to='#' onClick={handleAdd} className="orange waves-light btn"><i className="material-icons left">add</i>Add</Link>
                                    <Link to='#' onClick={handleClear} className="grey waves-light btn"><i className="material-icons left">clear</i>CLEAR</Link>
                                    
                                    <input  type="file" 
                                        ref={inputFile}
                                        style={{display: 'none'}}
                                        onChange={changeHandler.bind(this)}
                                        onClick={handleImportClick} 
                                    />
                                    <UploadLinkButton 
                                        tooltip={'Import from Excel'}
                                        onClick={handleImport}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-action">
                            <ProductList
                            data = {componentState.productList}
                            onItemSelected = {(serialNumber) => {handleItemSelected(serialNumber)}}
                            onItemDelete = {(serialNumber) => {handleItemDelete(serialNumber)}}
                            />
                            <div className="blank"></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="input-field mt-5">
                    <button onClick={handleSubmit} className="btn red lighten-1 z-depth-0">Create</button>                        
                    <div className="red-text center">
                        {rmaError ? <p>{rmaError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default CreateRMA;