import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { loadOrg, resetValidation } from '../../store/actions/orgActions';
import OrgUnitList from './OrgUnitList';
import Pagination from '../../utils/components/buttons/Pagination';
import ManageOrgUnits from './ManageOrgUnits';

class OrgUnits extends Component {
    constructor(props){
        super(props);
        this.timer = null; 
        this.pageInfo = { nameFilter: ''}; 
        this.pagination = {
            pageNumber: 0,
            limit: 5,
            orderBy: 'asc',
            cursor: ''
        };      
    }
    
    componentDidMount() {
        if(this.props.loadData) this.triggerChange('', this.pagination);
        this.props.resetValidation();
    }

    /*
    Get input from text fields and apply filtered list 
    Set a time delay of 1 sec to fetch data
    */
    handleOnChange = (e) => {
        clearTimeout(this.timer);
        this.pageInfo.nameFilter = e.target.value;  
        this.timer = setTimeout(() => {
            this.triggerChange(this.pageInfo.nameFilter)
            }, 1000);      
    }

    triggerChange = (search) => {
        this.pagination.pageNumber = 0;
        this.pagination.orderBy = 'asc';        
        this.pagination.cursor ='';
        this.props.loadOrg(search, this.pagination, this.pageInfo); 
    }

    handleNextPage = (cursor) => {
        this.pagination.pageNumber++;
        this.pagination.orderBy = 'asc';        
        this.pagination.cursor = cursor;
        this.props.loadOrg('',  this.pagination, this.pageInfo);
    }

    handlePreviousPage = (cursor) => {
        console.log('previous page',cursor);
        if(this.pagination.pageNumber > 0) this.pagination.pageNumber--;        
        this.pagination.orderBy = 'desc';
        this.pagination.cursor = cursor;
        this.props.loadOrg('',  this.pagination, this.pageInfo);
    }

    render() { 
        /*Destructuring data from props */                
        const { auth, org, pageInfo } = this.props;
        this.pageInfo.nameFilter = pageInfo.nameFilter;

        /*Create Array list from object collection */
        const orderedArray = Object.keys(org).map((key) => [key, org[key]]);
        
        let ordered = [];
        orderedArray.map(item => {
            ordered.push(item.pop());
            return null;
        });       

        /*Get first and last doc name for pagination */
        let firstDoc = '';
        let lastDoc = '';
        if(org){   
            ordered.map( (item) => {
                if(firstDoc === '') firstDoc =  item.name;
                lastDoc = item.name;
                return null;
             } );
        }

        /* Check authentication and redirect to login page */
        if(!auth.uid) return <Redirect to='/signin'/>; 

        return ( 
            <div className="section org-unit">
                <div className="row">                                        
                    <div className="col s12">
                        <div className="card">
                            <div className="card-content">
                                <ManageOrgUnits 
                                    nameFilter={this.pageInfo.nameFilter}
                                    onSearchFieldChange={this.handleOnChange}
                                />
                                <OrgUnitList org={ordered}/>
                                <div className="card-action">
                                    <Pagination 
                                    pageNumber = {this.pagination.pageNumber}
                                    firstDoc = {firstDoc}
                                    lastDoc ={lastDoc}
                                    onNextPageClick={this.handleNextPage} 
                                    onPreviousPageClick={this.handlePreviousPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
const mapStateToProps = (state) => {
    //console.log('state', state);
    return{
        auth: state.firebase.auth,
        loadData: state.org.loadData,
        pageInfo: state.org.pageInfo,
        org: Object.keys(state.org.filteredData).length !== 0  ? state.org.filteredData : state.org.data ? state.org.data : null 
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        loadOrg: (search, pagination, pageInfo) => dispatch(loadOrg(search, pagination, pageInfo)),
        resetValidation: () => dispatch(resetValidation())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(OrgUnits);

