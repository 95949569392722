import axios from 'axios';
import {Signature} from '../../utils/GenerateSignature';

//const BaseURL = `http://localhost/www/htl/rma/core-rma-api.php/`;
const BaseURL = `https://rma.onlinecart.ae/core-rma-api.php/`;

export const ExportSpareParts = (exportList, accountId) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const { spareParts } = exportList;
            //console.log('data', exportList);
            spareParts && dispatch({type: 'EXPORT_SPARES_QUEUED', queued: spareParts.length})

            //let i = 0;        
            spareParts && spareParts.map(spare => {
                //console.log('parts', spare);
                // Hash encode data 
                const payload = {
                    sparePart: spare,
                    accountId: accountId,
                    query: 'createSparePart',
                }
                const encoded_hash = Signature(JSON.stringify(payload));

                /** */
                const api = axios.create({
                    baseURL: BaseURL,
                    headers: {
                        'X-HTL-CORE-HMAC-SHA256': encoded_hash
                    }
                });

                api.post('/', payload)
                .then(res => {
                    console.log(res.data.message);
                    switch(res.data.message){
                        case 'Created':
                            const sparePart = {data:{}}
                            const docData = {
                                id: spare.spId,
                                sku: spare.sku,
                                modelName: spare.modelName,
                                sparePartName: spare.sparePartName,
                                boardName: spare.boardName,
                                partsSku: spare.partsSku,
                                location: spare.location,
                                stockQuantity: spare.stockQuantity,
                                price: spare.price,
                                cost: spare.cost
                            };
                            const key = spare.partsSku;
                            sparePart.data[key] = docData;
                            dispatch({type: 'EXPORT_SPARE', sparePart});
                            break;
                        case 'Duplicate':
                            dispatch({type: 'EXPORT_SPARE_DUPLICATE', sparePart: spare})
                            break;
                        default:
                            dispatch({type: 'EXPORT_SPARE_ERROR', err: res.data.message});
                            break;
                    }
                })
                .catch(err => {
                    //console.log(err);
                    dispatch({type: 'EXPORT_SPARE_ERROR', err});
                });
                
                return null;
            })
        }
    }
}

export const createSparePart = (spare, accountId) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            // Hash encode data 
            const payload = {
                sparePart: spare,
                accountId: accountId,
                query: 'createSparePart',
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            /** */
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                console.log(res);
                const sparePart = {data:{}}
                const docData = {
                    spId: res.data.spId,
                    sku: spare.sku,
                    modelName: spare.modelName,
                    sparePartName: spare.sparePartName,
                    boardName: spare.boardName,
                    partsSku: spare.partsSku,
                    location: spare.location,
                    stockQuantity: spare.stockQuantity,
                    price: spare.price,
                    cost: spare.cost
                };
                sparePart.data = docData;
                dispatch({type: 'CREATE_SPARE', sparePart});
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'SPARE_ERROR', err});
            });
        }
    }
}

export const UpdateSparePart = (spare, Id) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            // Hash encode data 
            const payload = {
                spId: Id,
                sparePart: spare,
                query: 'updateSparePart',
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            /** */
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                console.log(res);
                const sparePart = {
                    data:{
                    spId: spare.spId,
                    sku: spare.sku,
                    modelName: spare.modelName,
                    sparePartName: spare.sparePartName,
                    boardName: spare.boardName,
                    partsSku: spare.partsSku,
                    location: spare.location,
                    stockQuantity: spare.stockQuantity,
                    price: spare.price,
                    cost: spare.cost
                    }, 
                    Id: Id
                };
                dispatch({type: 'UPDATE_SPARE', sparePart: sparePart});
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'SPARE_ERROR', err});
            });
        }
    }
}

export const LoadSpareParts = (search, pagination, pageInfo) => {    
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'getSpareParts',
                pageInfo: pageInfo,
                pagination: pagination
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            console.log('pagination', pagination);
            api.get('/', {
                params: {
                    query: 'getSpareParts',
                    pageInfo: pageInfo,
                    pagination: pagination
                }
            }).then(res => {
                console.log('result', res);
                // const spareParts = {
                //     data:{}, 
                //     pageInfo: {
                //         currentPage: res.data.CurrentPage,
                //         lastPage: res.data.LastPage,
                //         totalRecords: res.data.TotalRows
                //     }
                // };
                // res.data.SpareParts.map(item => {
                //     const docData = {
                //         sku: item.sku,
                //         modelName: item.modelName,
                //         sparePartName: item.sparePartName,
                //         boardName: item.boardName,
                //         partsSku: item.partsSku,
                //         location: item.location,
                //         stockQuantity: item.stockQuantity
                //     };
                //     const key = item.partsSku;
                //     spareParts.data[key] = docData;
                //     return null;
                // });
                
                dispatch({type: 'LOAD_SPARES', data: res.data});
            }).catch(err => {
                dispatch({type: 'LOAD_ERROR', err});
            });
        }
    }
}

export const resetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().spares.validation;
        if(validation) dispatch({type: 'RESET_VALIDATION'})
    }
}

export const closeControl = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        dispatch({type: 'CLOSE_EXPORT_SPARE_WIDGET'});
    }
}

export const ShowCompatible = (spId) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            
            const payload = {
                query: 'getCompatibleParts'
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            api.get('/', {
                params: {
                    spId: spId,
                    query: 'getCompatibleParts'
                }
            }).then( res => {
                
                if(res.data.hasOwnProperty('SpareParts')){
                    console.log('result', res.data.SpareParts);
                    
                    dispatch({type: 'SHOW_COMPATIBLE_SPARES', data: res.data});
                }
            }).catch(err => {
                dispatch({type: 'SPARE_ERROR', err});
            });
        }
        
    }
}

export const CloseCompatible = () => {
    return(dispatch, getState) => {
        dispatch({type: 'CLOSE_COMPATIBLE_SPARES'});
    }
}

export const AddCompatibleSpare = (spId, compatibleSpare) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            // Hash encode data 
            const payload = {
                spId: spId,
                compatibleSpare: compatibleSpare,
                query: 'addCompatibleSpare',
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                api.get('/', {
                    params: {
                        spId: spId,
                        query: 'getCompatibleParts'
                    }
                }).then( res => {
                    
                    if(res.data.hasOwnProperty('SpareParts')){
                        dispatch({type: 'SHOW_COMPATIBLE_SPARES', data: res.data});
                    }
                }).catch(err => {
                    dispatch({type: 'SPARE_ERROR', err});
                }); 
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'SPARE_ERROR', err});
            });
        }
    }
}

export const DeleteCompatibleSpare = (spId, compatibleSpare) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            // Hash encode data 
            const payload = {
                spId: spId,
                compatibleSpare: compatibleSpare,
                query: 'deleteCompatibleSpare',
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => { 
                api.get('/', {
                    params: {
                        spId: spId,
                        query: 'getCompatibleParts'
                    }
                }).then( res => {
                    
                    if(res.data.hasOwnProperty('SpareParts')){
                        dispatch({type: 'SHOW_COMPATIBLE_SPARES', data: res.data});
                    }
                }).catch(err => {
                    dispatch({type: 'SPARE_ERROR', err});
                }); 
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'SPARE_ERROR', err});
            });
        }
    }
}