import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link, Redirect } from 'react-router-dom';

import { CloseLinkButton } from '../../utils/components/buttons/LinkButtons';
import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { GetRMAItems, UpdateRMA, DeleteRMAItem, GetRMALog } from '../../store/actions/rmaActions';
import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import { ClearCustomer, getCustomerDetails, setCustomerDetails, setCustomer } from '../../store/actions/customerActions';

import ProductList from './ProductList';

const RMADetails = (props) => {    
    const dispatch = useDispatch();
    useEffect(() => {
        Materialize.AutoInit();
    });

    useEffect(() => {
        dispatch(ClearCustomer());
    },[dispatch]);
    
    const [clearField] = useState();
    const [componentState, setComponentState] = useState({
        productList: {}
    });
    
    const [id, setId] = useState(0);
    const [serialNumber, setSerialNumber] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [warrantyStatus, setWarrantyStatus] = useState('');
    const [faultDescription, setFaultDescription] = useState('');
    const [rmaDate, setRmaDate] = useState('');
    const [rmaNumber, setRmaNumber] = useState('');
    const [rmaStatus, setRmaStatus] = useState();
    

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth); 
    const profile = useSelector(state => state.firebase.profile);
    const rmaList = useSelector(state => state.rma.data);  
    const itemList = useSelector(state => state.rma.items);  
    const log = useSelector(state => state.rma.log); 
    const validation = useSelector(state => state.rma.validation); 
    const rmaError = useSelector(state => state.rma.rmaError);  
    const customers = useSelector(state => state.customer.data);
    const customerDetails = useSelector(state => state.customer.customer);    
     
    useEffect(() => {     
        const rma = rmaList  !== null ? rmaList.filter(item => {
            return item.id === props.match.params.id
         }) : [];
        if(rmaList){
            if(itemList){
                setComponentState({
                    id: rma[0].id,
                    rmaDate: rma[0].rmaDate,
                    rmaNumber: rma[0].rmaNo,
                    customerName: rma[0].customerName,
                    customerAddress: rma[0].customerAddress ? rma[0].customerAddress : '',
                    customerEmail: rma[0].customerEmail ? rma[0].customerEmail : '',
                    customerPhone: rma[0].customerPhone ? rma[0].customerPhone: '',
                    status: rma[0].status ? rma[0].rmaStatus: '',
                    productList: {...itemList }
                });
            }
            setId(rma[0].id)
            setRmaDate(rma[0].rmaDate);
            setRmaNumber(rma[0].rmaNo);
            
            dispatch(setCustomer('customerName', rma[0].customerName));
            dispatch(setCustomer('customerEmail', rma[0].customerEmail));
            dispatch(setCustomer('customerPhone', rma[0].customerPhone));
            dispatch(setCustomer('customerAddress', rma[0].customerAddress));
        }
    },[dispatch, rmaList, itemList, props.match.params.id]);    

    /** Handle default values in controls */
    if(rmaList){
        const rma = rmaList  !== null ? rmaList.filter(item => {
            return item.id === props.match.params.id
         }) : [];
        if(id !== props.match.params.id){
            dispatch(GetRMAItems(profile.account, rma[0].id));
        }
    }

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/rma'/>;  

    const handleCustomerSelection = (tags) => {
        setComponentState({
            ...componentState,
            customerId: tags.value,
            customerName: tags.label
        });  
        /** Fetch data if not exist */
        if(customers[tags.value]) {
            dispatch(setCustomerDetails(tags.value, customers[tags.value]));
        }else{
            dispatch(getCustomerDetails(tags.value));
        } 
    }

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value.trim()
        })
        dispatch(setCustomer(e.target.id, document.getElementById(e.target.id).value));
    }

    const handleSerialNumber = (e) => {
        setSerialNumber(e.target.value);
    }

    const handleModelNumber = (e) => {
        setModelNumber(e.target.value);
    }

    const handleWarrantyStatus = (e) => {
        setWarrantyStatus(e.target.value);
    }

    const handleFaultDescription = (e) => {
        setFaultDescription(e.target.value);
    }

    const handleAdd = () => {
        //Add product to the list
        if(serialNumber !== '' &&
        modelNumber !== '' && 
        warrantyStatus !== '' &&
        faultDescription !== ''){
            if(!componentState.productList){
                const newProduct = {data:{}};
                const docData = {
                    serialNumber: serialNumber,
                    modelNumber: modelNumber,
                    warrantyStatus: warrantyStatus,
                    faultDescription: faultDescription
                }
                newProduct.data[serialNumber] = docData;
                setComponentState({
                    ...componentState,
                    productList: {...componentState.productList,...newProduct.data }
                });
                
            }else{
                const newProduct = {data:{}}
                const docData = {
                    serialNumber: serialNumber,
                    modelNumber: modelNumber,
                    warrantyStatus: warrantyStatus,
                    faultDescription: faultDescription,
                    status: rmaStatus
                };
                newProduct.data[serialNumber] = docData;
                setComponentState({
                    ...componentState,
                    productList: {...componentState.productList,...newProduct.data }
                });    
            }

            handleClear();
        }
    }

    const handleItemSelected = (serialNumber) => {
        //console.log('serialNumber',serialNumber);
        const selection = componentState.productList[serialNumber];
        console.log('selection',selection);
        if(selection){
            setSerialNumber(selection.serialNumber);
            setModelNumber(selection.modelNumber);
            setWarrantyStatus(selection.warrantyStatus);
            setFaultDescription(selection.faultDescription);
            setRmaStatus(selection.status);
        }
       
    } 

    const handleItemDelete = (serialNumber) => {
        delete componentState.productList[serialNumber];
        dispatch(DeleteRMAItem(profile.account, serialNumber, id));
    }

    const handleViewLog = (serialNumber) => {
        dispatch(GetRMALog(profile.account, serialNumber, id));
    }

    const handleClear = () => {
        setSerialNumber('');
        setModelNumber('');
        setWarrantyStatus('');
        setFaultDescription('');
        setRmaStatus('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();        
        setRmaStatus('');
        console.log('props state',customerDetails.customerName);
        if(id === '' || id === 0){
            Materialize.toast({html: 'Error: Invalid data'});
        }else if(customerDetails.customerName === '' || 
        customerDetails.customerEmail === '' || 
        customerDetails.customerPhone === '' ){
            Materialize.toast({html: 'Fill Customer details'});
        }else if(
        Object.keys(componentState.productList).length === 0){
            Materialize.toast({html: 'Enter product details'});
        }else{
            dispatch(UpdateRMA(profile.account, customerDetails, componentState.productList, id));
        }
    }

    return (       
        <div className="section rma">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h6 className="red-text text-lighten-1">Update RMA</h6>
                    </div>
                    <div className="col s1 btn-close">
                        <CloseLinkButton to='/rma' />
                        {/* <Link to='/pro' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link> */}
                    </div>
                </div>                
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <i className="material-icons prefix">search</i>
                        <AutoComplete
                        onSelected={handleCustomerSelection} 
                        collection={'customers'} search={'name'} account={profile.account}
                        placeholder={'Search customers'}
                        value={clearField}
                        />
                    </div>
                    <div className="col s12 m6 l6 mb-5">
                        <div className="row">
                            <div className="col s12 ht-1">
                                <div className="col s9"><p className="right" htmlFor="Date">Date :</p></div>
                                <div className="col s3"><p className="right bold" >{rmaDate}</p></div>                                
                            </div>
                            <div className="col s12 ht-1">
                                <div className="col s9"><p className="right" htmlFor="Date">RMA No. :</p></div>
                                <div className="col s3"><p className="right bold" >{rmaNumber}</p></div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <div className="row cus-det">
                            <div className="col s12">
                                <label htmlFor="Customer Name">Customer Name</label>
                                <input id="customerName" value={customerDetails.customerName} onChange={handleChange} type="text" className="validate" required/>

                            </div>
                            <div className="col s6">
                                <label htmlFor="Email Address">Email Address</label>
                                <input id="customerEmail" value={customerDetails.customerEmail} onChange={handleChange}  type="text" className="validate" required/>
                            </div>
                            <div className="col s6">
                                <label htmlFor="Phone">Phone</label>
                                <input id="customerPhone" value={customerDetails.customerPhone} onChange={handleChange}  type="text" className="validate" required/>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Address">Address</label>
                        <textarea id="customerAddress" value={customerDetails.customerAddress} onChange={handleChange}  type="text" className="materialize-textarea validate" required></textarea>
                    </div>                
                </div>
                <div className="row">
                    <div className="mt-5">
                    <div className="card z-depth-1">
                        <div className="card-content">
                            <div className="row">
                                <div className="input-field col s12 m2 l2">
                                    <label className="active" htmlFor="Serial Number">Product Serial Number</label>
                                    <input value={serialNumber} onChange={handleSerialNumber}  className="serialNumber all-cap" id="serialNumber" type="text"/>
                                </div>
                                <div className="input-field col s12 m2 l2">
                                    <label className="active" htmlFor="Model Number">Product Model Number</label>
                                    <input value={modelNumber} onChange={handleModelNumber}  className="modelNumber all-cap" id="modelNumber" type="text"/>
                                </div>
                                <div className="input-field col s12 m2 l2">
                                    <select onChange={handleWarrantyStatus} value={warrantyStatus}  className="warrantyStatus" id="warrantyStatus">
                                        <option value="" disabled>Choose Warranty Status</option>
                                        <option value="In Warranty">In Warranty</option>
                                        <option value="Out of Warranty">Out of Warranty</option>
                                    </select> 
                                </div>
                                <div className="input-field col s12 m3 l3">
                                    <label className="active" htmlFor="Fault Description">Fault Description</label>
                                    <input onChange={handleFaultDescription} value={faultDescription} className="faultDescription" id="faultDescription" type="text"/>
                                </div>
                                <div className="input-field col s12 m3 l3">
                                    <Link to='#' onClick={handleAdd} className="orange waves-light btn"><i className="material-icons left">add</i>Add</Link>
                                    <Link to='#' onClick={handleClear} className="grey waves-light btn"><i className="material-icons left">clear</i>CLEAR</Link>
                                </div>
                            </div>
                        </div>
                        <div className="card-action">
                            <ProductList
                            data = {componentState.productList}
                            logData = {log}
                            onItemSelected = {(serialNumber) => {handleItemSelected(serialNumber)}}
                            onItemDelete = {(serialNumber) => {handleItemDelete(serialNumber)}}
                            onViewLog = {(serialNumber) => {handleViewLog(serialNumber)}}
                            mode = 'update'
                            />
                            <div className="blank"></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="input-field  pl-5 mb-5">
                    <button className="btn red lighten-1 z-depth-0">Update</button>                        
                    <div className="red-text center">
                        {rmaError ? <p>{rmaError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default RMADetails;