import React from 'react';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import AdminConsole from './AdminConsole';
import UserConsole from './UserConsole';
import { connect } from 'react-redux';


const Navbar = (props) => {
    const {auth, profile} = props;
    const links = auth.uid ? <SignedInLinks profile={profile}/> : <SignedOutLinks/>;
    const adminConsole = profile.privilege === 'super' 
    ||  profile.privilege === 'power' 
    ||  profile.privilege === 'admin' 
    ? <AdminConsole profile={profile}/> 
    : null;
    const userConsole = profile.privilege === 'super' ? null : 
    <UserConsole 
    privilege = {profile.privilege}
    />;
    return ( 
        <div className="navbar-fixed">
            <nav className="nav-wrapper white">
                <div className="myContainer">                                             
                    {links}
                </div>
                <ul id="slide-out" className="sidenav">   
                    <li>
                        <div className="red lighten-1">             
                            <span className="htl-brand-logo">HTL Core</span>
                        </div>
                    </li>
                    {userConsole}
                    {adminConsole}
                </ul>
            </nav>
        </div>
     );
}

const mapStateToProps = (state) => {
    //console.log(state.firebase.profile);
    return{
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}
 
export default connect(mapStateToProps)(Navbar);