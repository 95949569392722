import React from 'react';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { useEffect } from 'react';

const SalesStat = ({salesStat, OnRageSelection}) => {
    useEffect(() => {
      Materialize.AutoInit();
      // let elems = document.querySelectorAll('.dropdown-trigger');
      // var instances = Materialize.Dropdown.init(elems, options);
    })

    const sales =  [];
    const months = [];
    const visits = [];
    if(salesStat){
      salesStat.map(sale => {
          sales.push(sale.orderCount);
          months.push(sale.orderRange);
          visits.push(sale.visitorCount);
          return null;
      })
    }

    const data = {
        datasets: [{
        label: 'Sales',
        type:'line',
        data: sales,
        fill: false,
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
        yAxisID: 'y-axis-2'
        },{
        type: 'bar',
        label: 'Visitor',
        data: visits,
        fill: false,
        backgroundColor: '#71B37C',
        borderColor: '#71B37C',
        hoverBackgroundColor: '#71B37C',
        hoverBorderColor: '#71B37C',
        yAxisID: 'y-axis-1'
        }]
    };
      
    const options = {
        responsive: true,
        labels: months,
        tooltips: {
        mode: 'label'
        },
        elements: {
        line: {
        fill: false
        }
        },
        scales: {
        
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            },
        
            labels: months,
          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
              display: false
            },
            labels: {
              show: true
            }
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
              display: false
            },
            labels: {
              show: true
            }
          }
        ]
        }
    };
        
    const plugins = [{
        afterDraw: (chartInstance, easing) => {
        //const ctx = chartInstance.chart.ctx;
        }
    }];

    const handleRangeSalection = (range) => {
      OnRageSelection(range);
    }

    return ( 
        <div className="col s12 m8 l8">
            <div className="card">
                <div className="card-content">
                    <h6 className="mb-0 grey-text text-darken-2">
                        <span className="chart-title">Sales</span>
                        
                        <i className="material-icons float-right"> <Link to='#' className="dropdown-trigger" data-target="range" data-constrainwidth="true">more_vert</Link></i>
                        <ul id="range" className="dropdown-content">
                          <li onClick={() => {handleRangeSalection('6 Months')}} className="dropdown-menu"><Link to='#'>Last Six Months</Link></li>
                          <li onClick={() => {handleRangeSalection('30 Days')}} className="dropdown-menu"><Link to='#'>Last 30 Days</Link></li>
                          <li onClick={() => {handleRangeSalection('7 Days')}} className="dropdown-menu"><Link to='#'>Last 7 Days</Link></li>
                        </ul>
                    </h6>
                    <Bar
                        data={data}
                        options={options}
                        plugins={plugins}
                    />
                </div>
            
            </div>
            
        </div>
     );
}
 
export default SalesStat;