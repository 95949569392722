import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ExcelRenderer } from 'react-excel-renderer';

import Materialize from 'materialize-css/dist/js/materialize.min.js'
import Pagination from '../../utils/components/buttons/Pagination';
import SparePartsList from './SparePartsList';
import ExportSpares from './ExportSpares';
import Compatible from './Compatible';
import { LoadSpareParts, resetValidation, ShowCompatible } from '../../store/actions/sparePartsActions';

let fetchData = true;
let timer = null;
const pageInfo = { nameFilter: ''}; 
const pagination = {
    pageNumber: 0,
    limit: 15,
    orderBy: 'asc',
    cursor: '',
    currentPage: 1,
    lastPage: 1,
    totalRecords: 0
};      

const SpareParts = () => {
    const dispatch = useDispatch();
    const [componentState, setComponentState] = useState({});
    const [message, setMessage] = useState({
        totalProducts: 0,
        duplicate: {},
        failedToExport: {}
    });

    useEffect(() => {
        Materialize.AutoInit();
        /** Nested floating button */
        let fab = document.querySelector('.fab-bl');
        Materialize.FloatingActionButton.init(fab, {
            direction: 'right'
          });
        

        /*User rudex hook to dispatch data fetch */
        if(fetchData) dispatch(LoadSpareParts('',pagination,pageInfo));
        dispatch(resetValidation());
        fetchData = false;
    });

    /* Get compatible parts */
    const handleViewCompatible = (sparePart) => {
        dispatch(ShowCompatible(sparePart));
    }

    /*
    Get input from text fields and apply filtered list 
    Set a time delay of 1 sec to fetch data
    */
    const handleOnChange = (e) => {
        clearTimeout(timer);
        pageInfo.nameFilter = e.target.value;  
        timer = setTimeout(() => {
            triggerChange(pageInfo.nameFilter)
            }, 1000);      
    }

    const triggerChange = (search) => {
        pagination.pageNumber = 0;
        pagination.orderBy = 'asc';        
        pagination.cursor ='';
        dispatch(LoadSpareParts(search, pagination, pageInfo));
    }

    const handleNextPage = (cursor) => {     
        //console.log('pageNumber',pageStatus.lastPage , pageStatus.currentPage); 
        const currentPage =  pageStatus.currentPage ? pageStatus.currentPage + 1 : 1;
        if(pageStatus.lastPage > currentPage){
            pagination.pageNumber++;
            pagination.orderBy = 'asc';    
            dispatch(LoadSpareParts('',pagination,pageInfo));
        }
    }
    
    const handlePreviousPage = (cursor) => {
        //console.log('pageNumber',pagination.pageNumber);        
        if(pagination.pageNumber > 0){
            pagination.pageNumber--;        
            pagination.orderBy = 'desc';
            dispatch(LoadSpareParts('',pagination,pageInfo));
        } 
    }

    const reload = () => {
        console.log('reloading');
        dispatch(LoadSpareParts('',pagination,pageInfo));
    }

    /** Handling input file component from Link component */
    const inputFile = useRef(null);

    const handleImport = () => {      
        inputFile.current.click();  
    }

    /** Resetting the input file component for multiselect */
    const handleImportClick = (e) => {
        e.target.value = null;
    }

    /** Importing data from excel
     * Column names required [sku, name, description]
     */
    const changeHandler = (e) => {  
        e.stopPropagation();
        e.preventDefault();
        let fileObj = e.target.files[0];
        if(fileObj !== undefined){
            if(!(fileObj.type === 'application/vnd.ms.excel' 
            || fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')){
                console.log('Unkonwn file format');     
                return false;           
            }else{
                ExcelRenderer(fileObj, (err, resp) => {
                    if(err){
                        console.log('Excel read error', err);
                        return false;
                    }else{
                        let newRows = [];
                        let newCols = [];
                        let skuIndex = null;
                        let modelNameIndex = null;
                        let sparePartNameIndex = null;  
                        let boardNameIndex = null; 
                        let partsSkuIndex = null;     
                        let locationIndex = null;   
                        let stockQuantityIndex = null;    
                        let price = null;   
                        let cost = null;     
                        let interchangeable = null;         
                        let i = 0;
                        //console.log('Excel Data', resp.rows);
                        resp.rows.map((row, index) => {                       
                            if(row && row !== undefined){
                            if(index  === 0){
                                    row.map(r => {
                                        if(r === 'sku') skuIndex = i;
                                        if(r === 'modelName') modelNameIndex = i;
                                        if(r === 'sparePartName') sparePartNameIndex = i;
                                        if(r === 'boardName') boardNameIndex = i;
                                        if(r === 'partsSku') partsSkuIndex = i;
                                        if(r === 'location') locationIndex = i;
                                        if(r === 'stockQuantity') stockQuantityIndex = i;
                                        if(r === 'price') price = i;
                                        if(r === 'cost') cost = i;                                        
                                        if(r === 'interchangeable') interchangeable = i;
                                        if(skuIndex !== null 
                                        || modelNameIndex !== null 
                                        || sparePartNameIndex !== null 
                                        || boardNameIndex !== null 
                                        || partsSkuIndex !== null 
                                        || locationIndex !== null 
                                        || stockQuantityIndex !== null
                                        || price !== null
                                        || cost !== null
                                        || interchangeable !== null) 
                                        {
                                            newCols.push({
                                                key: i,
                                                name: r
                                            })
                                            i++;
                                        }   
                                        return null;                                 
                                    });
                            }else{
                                    if(skuIndex !== null 
                                    && modelNameIndex !== null 
                                    && sparePartNameIndex !== null 
                                    && boardNameIndex !== null 
                                    && partsSkuIndex !== null 
                                    && locationIndex !== null 
                                    && stockQuantityIndex !== null
                                    && price !== null
                                    && cost !== null
                                    && interchangeable !== null) 
                                    {
                                        if(row[skuIndex] !== undefined && row[modelNameIndex] !== undefined
                                            && row[skuIndex] !== '' && row[modelNameIndex] !== '' 
                                        ){
                                            newRows.push({
                                            key: index,
                                            sku: row[skuIndex],
                                            modelName: row[modelNameIndex],
                                            sparePartName: row[sparePartNameIndex],
                                            boardName: row[boardNameIndex],
                                            partsSku: row[partsSkuIndex],
                                            location: row[locationIndex],
                                            stockQuantity: row[stockQuantityIndex],
                                            price: row[price],
                                            cost: row[cost],
                                            interchangeable: row[interchangeable],
                                            })
                                        }
                                    } else return false;
                            }
                                
                            }
                            return null;
                        })
                        if(newRows.length === 0){
                            return false;
                        }else{
                            if(skuIndex === null 
                                || modelNameIndex === null 
                                || sparePartNameIndex === null 
                                || sparePartNameIndex === null 
                                || boardNameIndex === null 
                                || partsSkuIndex === null 
                                || locationIndex === null 
                                || stockQuantityIndex === null
                                || price === null
                                || cost === null
                                || interchangeable === null
                            ) return false;
                            //console.log('rows',newRows);
                            setComponentState({
                                ...componentState,            
                                headings: newCols,
                                spareParts: newRows
                            })
                            setMessage({
                                ...message,
                                totalSpareParts: newRows.length
                            })
                        }
                    }    
                })
                return false;
            }
        }
               
    }
   
    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth); 
    const data = useSelector(state =>  state.spares.data);     
    const compatibleData = useSelector(state =>  state.spares.compatibleData);
    const filteredData = useSelector(state => state.spares.filteredData);
    const product = Object.keys(filteredData).length !== 0  ? filteredData : data;
    const pageStatus = useSelector(state => state.spares.pageInfo);
    
    
    /* Check authentication and redirect to login page */
    if(!auth.uid) return <Redirect to='/signin'/>; 
    

    return ( 
        <div className="section product">
            <div className="row">                                        
                <div className="col s12">
                    <div className="card">
                        <div className="card-content sec-head">                            
                            <div className="row">
                                <div className="input-field col s12 m6, l6">
                                   <span className="card-title red-text text-lighten-1">
                                        Manage Spare Parts
                                    </span>
                                </div>
                                <div className="input-field col s12 m6, l6">
                                    <i className="material-icons prefix">search</i>
                                    <input onChange={handleOnChange} type="text" defaultValue="" placeholder="Search Products"/>
                                </div>
                            </div>
                            <div className="section sec-details">     
                                <Compatible
                                    data= {compatibleData}
                                />                           
                                <ExportSpares 
                                data={componentState}
                                onExportCompleted={() => {reload()}}
                                />    
                                <SparePartsList 
                                    data={product}
                                    onViewCompatible={(sparePart) => {handleViewCompatible(sparePart)}}
                                />
                                <div className="fixed-action-btn fab-bl">
                                    <Link to='#' className="btn-floating btn-large red">
                                        <i className="large material-icons">menu</i>
                                    </Link>
                                    <ul>
                                        <li><Link to='#' className="btn-floating green"><i className="material-icons">file_download</i></Link></li>
                                        
                                        <li>
                                            <input  type="file" 
                                                ref={inputFile}
                                                style={{display: 'none'}}
                                                onChange={changeHandler.bind(this)}
                                                onClick={handleImportClick} 
                                            />
                                            <a href="#export" onClick={handleImport} className="btn-floating blue modal-trigger"><i className="material-icons">file_upload</i></a>
                                        </li>
                                        <li><Link to='/crspa' className="btn-floating orange"><i className="material-icons">add</i></Link></li>
                                    </ul>
                                    {/* <Link to='/crpro' className="btn-floating btn-large halfway-fab waves-effect waves-light red" fab="top"><i className="material-icons">add</i></Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="card-action">
                            <Pagination
                            pageNumber = {pageStatus.currentPage ? pageStatus.currentPage : 0}
                            firstDoc = ''
                            lastDoc = ''
                            onNextPageClick={handleNextPage} 
                            onPreviousPageClick={handlePreviousPage}
                            enablePrevious = { true }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default SpareParts;