import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
//import Materialize from 'materialize-css/dist/js/materialize.min.js'

import { createCustomer } from '../../store/actions/customerActions';

const CreateCustomer = () => {
    const dispatch = useDispatch();

    // useEffect(() => {
    //     Materialize.AutoInit();
    // },[])

    const [componentState, setComponentState] = useState({});

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);     
    const validation = useSelector(state => state.customer.validation); 
    const customerError = useSelector(state => state.customer.customerError); 

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/cus'/>;

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value.trim()
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log('props state',componentState);
        dispatch(createCustomer(componentState));
    }

    return ( 
        <div className="container customer">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">Create Customer</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/cus' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Customer Code">Customer Code</label>
                        <input id="code" onChange={handleChange} type="text"/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Name">Name</label>
                        <input id="name" onChange={handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="City">City</label>
                        <input id="city" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Phone">Phone</label>
                        <input id="phone" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Email">Email</label>
                        <input id="email" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Address">Address</label>
                        <textarea id="address" onChange={handleChange} className="validate materialize-textarea" required></textarea>
                    </div> 
                </div>
                <div className="input-field">
                    <button className="btn red lighten-1 z-depth-0">Create</button>                        
                    <div className="red-text center">
                        {customerError ? <p>{customerError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default CreateCustomer;