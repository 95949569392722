import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ExcelRenderer } from 'react-excel-renderer';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import CustomerList from './CustomerList';
import ExportCustomers from './ExportCustomers';
import { loadCustomers, resetValidation } from '../../store/actions/customerActions';

let fetchData = true;
let timer = null;
const pageInfo = { nameFilter: ''}; 
const pagination = {
    pageNumber: 0,
    limit: 6,
    orderBy: 'asc',
    cursor: ''
};

const Customers = () => {
    const dispatch = useDispatch();
    const [componentState, setComponentState] = useState({});
    const [message, setMessage] = useState({
        totalCustomers: 0,
        duplicate: {},
        failedToExport: {}
    });

    useEffect(() => {
        /** Nested floating button */
        let fab = document.querySelector('.fab-bl');
        Materialize.FloatingActionButton.init(fab, {
            direction: 'right'
          });

        /*User rudex hook to dispatch data fetch */
        if(fetchData) dispatch(loadCustomers('',pagination,pageInfo));
        dispatch(resetValidation());
        fetchData = false;
    });

    /*
    Get input from search text fields and apply filtered list 
    Set a time delay of 1 sec to fetch data
        */
    const handleOnChange = (e) => {
        clearTimeout(timer);
        pageInfo.nameFilter = e.target.value;  
        timer = setTimeout(() => {
            triggerChange(pageInfo.nameFilter)
            }, 1000);      
    }

    const triggerChange = (search) => {
        pagination.pageNumber = 0;
        pagination.orderBy = 'asc';        
        pagination.cursor ='';
        dispatch(loadCustomers(search, pagination, pageInfo));
    }

    /** Handling input file component from Link component */
    const inputFile = useRef(null);

    const handleImport = () => {      
        inputFile.current.click();  
    }

    /** Resetting the input file component for multiselect */
    const handleImportClick = (e) => {
        e.target.value = null;
    }

    /** Importing data from excel
     * Column names required [code, name, address, city, phone, email]
     */
    const changeHandler = (e) => {  
        e.stopPropagation();
        e.preventDefault();
        let fileObj = e.target.files[0];
        if(fileObj !== undefined){
            if(!(fileObj.type === 'application/vnd.ms.excel' 
            || fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')){
                //console.log('Unkonwn file format');     
                return false;           
            }else{
                ExcelRenderer(fileObj, (err, resp) => {
                    if(err){
                        console.log('Excel read error', err);
                        return false;
                    }else{
                        let colFlag = true;
                        let newRows = [];
                        let newCols = [];
                        let colIndex = {
                            code: null,
                            name: null, 
                            address: null, 
                            city: null, 
                            phone: null, 
                            email: null
                        };       
                        let i = 0;
                        resp.rows.map((row, index) => {                       
                            if(row && row !== undefined){
                            if(index  === 0){
                                    row.map(r => {
                                        if( colIndex[r] === null ){
                                            colIndex[r] = i;
                                            newCols.push({
                                                key: i,
                                                name: r
                                            })
                                        }                                     
                                        i++;
                                        return null;                                 
                                    }); 
                                    //console.log('cols',newCols);
                                    Object.values(colIndex).map(ind => {
                                        if(ind === null){
                                            colFlag = false;
                                            return null;
                                        }
                                        return null;
                                    });
                            }else{   
                                    if(!colFlag) return false;
                                    else if(row[colIndex['code']] !== undefined 
                                        && row[colIndex['name']] !== undefined
                                    ){
                                        const address =  row[colIndex['address']] === undefined ? '' : row[colIndex['address']];
                                        const city =  row[colIndex['city']] === undefined ? '' : row[colIndex['city']];
                                        const phone =  row[colIndex['phone']] === undefined ? '' : row[colIndex['phone']];
                                        const email =  row[colIndex['email']] === undefined ? '' : row[colIndex['email']];
                                        newRows.push({
                                            key: index,
                                            code: row[colIndex['code']],
                                            name: row[colIndex['name']],
                                            address: address,
                                            city: city,
                                            phone: phone,
                                            email: email
                                        })
                                    } 
                            }
                                
                            }
                            return null;
                        })
                        if(newRows.length === 0){
                            return false;
                        }else{
                            console.log('rows',newRows);
                            if(!colFlag) return false;

                            setComponentState({
                                ...componentState,            
                                headings: newCols,
                                customers: newRows
                            })
                            setMessage({
                                ...message,
                                totalCustomers: newRows.length
                            })
                        }
                    }    
                })
                return false;
            }
        }
               
    }

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);
    const data = useSelector(state =>  state.customer.data);
    const filteredData = useSelector(state => state.customer.filteredData);
    const customer = Object.keys(filteredData).length !== 0  ? filteredData : data;
    
    /*Create Array list from object collection */
    const orderedArray = Object.keys(customer).map((key) => [key, customer[key]]);    
       
    let ordered = [];
    orderedArray.map(item => {    
        ordered.push(item.pop());
        return null;
    });       

    /*Filter out deleted divisions */
    ordered = ordered.filter(item => {
        return item.status !== 'deleted'
    });
    
    /* Check authentication and redirect to login page */
    if(!auth.uid) return <Redirect to='/signin'/>; 

    return ( 
        <div className="section customer">
            <div className="row">                                        
                <div className="col s12">
                    <div className="card">
                        <div className="card-content sec-head">                            
                            <div className="row">
                                <div className="input-field col s12 m6, l6">
                                   <span className="card-title red-text text-lighten-1">
                                        Manage Customers
                                    </span>
                                </div>
                                <div className="input-field col s12 m6, l6">
                                    <i className="material-icons prefix">search</i>
                                    <input onChange={handleOnChange} type="text" defaultValue="" placeholder="Search Customers"/>
                                </div>
                            </div>
                            <div className="section sec-details">                                
                                <ExportCustomers data={componentState}/>    
                                <CustomerList data={ordered}/>
                                <div className="fixed-action-btn fab-bl">
                                    <Link to='#' className="btn-floating btn-large red">
                                        <i className="large material-icons">menu</i>
                                    </Link>
                                    <ul>
                                        <li><Link to='#' className="btn-floating green"><i className="material-icons">file_upload</i></Link></li>
                                        
                                        <li>
                                            <input  type="file" 
                                                ref={inputFile}
                                                style={{display: 'none'}}
                                                onChange={changeHandler.bind(this)}
                                                onClick={handleImportClick} 
                                            />
                                            <a href="#export" onClick={handleImport} className="btn-floating blue modal-trigger"><i className="material-icons">file_download</i></a>
                                        </li>
                                        <li><Link to='/crcus' className="btn-floating orange"><i className="material-icons">add</i></Link></li>
                                    </ul>
                                    {/* <Link to='/crpro' className="btn-floating btn-large halfway-fab waves-effect waves-light red" fab="top"><i className="material-icons">add</i></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Customers;