import React from 'react';
import { Link } from 'react-router-dom';

const ManageOrgUnits = ({nameFilter, onSearchFieldChange}) => {
    const handleChange = (e) =>{
        onSearchFieldChange(e);
    }
    return ( 
        <React.Fragment>
            <span className="card-title red-text text-lighten-1">Manage Organaizations</span>
            <div className="col s12">
                <div className="row">
                    <div className="input-field col s12 m6, l6">
                        <i className="material-icons prefix">search</i>
                        <input onChange={handleChange} type="text" defaultValue={nameFilter} placeholder="Search Organization Unit"/>
                    </div>
                </div>
            </div>
            <div className="fixed-action-btn floting-bottom-left">
                <Link to='/crorg' className="btn-floating btn-large halfway-fab waves-effect waves-light red" fab="top"><i className="material-icons">add</i></Link>
            </div>
        </React.Fragment>
     );
}
 
export default ManageOrgUnits;