import React from 'react';
import { NavLink } from 'react-router-dom';

const PageNotFound = () => {
    return ( 
        <div className="container auth">
            <div className="row">                   
                <div className="card col s12 m8 l8 offset-m2 offset-l2">
                    {/* <h3 className="center grey-text text-darken-3">404</h3> */}
                    <p className="center">HTL Core powered by <span className="red-text">HTL Info Media</span></p>
                    <div className="section center">
                    <p>You don't have the permission to view this page</p>
                    <p>Go to  <NavLink to='/'className="red-text text-darken-3">Home</NavLink> page.</p>
                    </div>
                </div>                
            </div>
        </div> 
     );
}
 
export default PageNotFound;