import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';


class AutoSelect extends Component{    
  state = { 
      inputValue: '',
      selectedTag: [],
    };
  
    
  filterData = (inputValue) => {  
      const  options = this.props.data;
      return options.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    };
    
  loadOptions = (inputValue, callback) => {
      setTimeout(() => {
        callback(this.filterData(inputValue));
      }, 500);
    };
    
  
  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  };

  /* 
  user selection is captured in tags 
  onChange event of AsyncSelect component will return tags  
  */
  handleChange = (tags) => {
    this.props.onSelected(tags);
  }

  render() {
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '40px',
        height: '40px',
        boxShadow: state.isFocused ? null : null,
      }),
  
      valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px'
      }),
  
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
      }),
    };
    const { placeholder, defaultInputValue } = this.props;
    
    return (
      <div>
        <AsyncSelect 
          cacheOptions
          loadOptions={this.loadOptions}
          defaultOptions
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          placeholder = {placeholder}
          defaultInputValue = {defaultInputValue}
          styles={customStyles}
        />
      </div>
    );
  }
}

export default AutoSelect;