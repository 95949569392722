import React from 'react';
import { Link } from 'react-router-dom';

import { Doughnut } from 'react-chartjs-2';
//orderStat.open, orderStat.inProcess, orderStat.waitingDelivery
const OrderStat = ({orderStat, divisions, storeOrderStat}) => {
    console.log('divisions', divisions);
    const data = {
        labels: ['Open',
        'Processing',
        'Ready for Collection'],
        datasets: [
          {
            data: orderStat ?  [orderStat[0].open, orderStat[0].inProcess, orderStat[0].waitingDelivery] : [],
            backgroundColor: [
                '#FF6384', '#36A2EB','#FFCE56'
            ],
            hoverBackgroundColor: [
                '#FF6384','#36A2EB','#FFCE56'
            ]
          },
        ],
      }

    return ( 
        <div className="col s12 m4 l4">
            <div className="card animate fadeLeft">
                <div className="card-content">
                    <h6 className="mb-0 mt-0 display-flex justify-content-between grey-text text-darken-2">
                        Order Status
                        <i className="material-icons float-right">more_vert</i>
                    </h6>
                    {/*<p className="medium-small grey-text text-darken-1">This month</p>*/}
                    <Doughnut data={data} />
                </div>
            
            </div>
            <div className="card animate fadeLeft">
                <div className="card-content cyan white-text">
                    <p className="card-stats-title">
                        <i className="material-icons">content_copy</i>
                        {divisions ? divisions.locations.toString() : ''}
                        <i className="material-icons float-right">more_vert</i>
                    </p>                            
                </div>
                <div className="">
                    <ul className="collection">
                        <Link to='/lsord/op' className="collection-item"><span className="new badge red" data-badge-caption="">{storeOrderStat ? storeOrderStat[0].open : 0}</span>Open Orders</Link>
                        <Link to='/lsord/pr' className="collection-item"><span className="new badge blue" data-badge-caption="">{storeOrderStat ? storeOrderStat[0].inProcess : 0}</span>Processing</Link>
                        <Link to='/lsord/rc' className="collection-item"><span className="new badge orange" data-badge-caption="">{storeOrderStat ? storeOrderStat[0].waitingDelivery : 0}</span>Ready for Collection</Link>
                    </ul>
                </div> 
            </div>
        </div>
     );
}
 
export default OrderStat;