import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { createSparePart } from '../../store/actions/sparePartsActions';

const CreateSparePart = (props) => {      
    const dispatch = useDispatch();

    const [componentState, setComponentState] = useState({});

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);     
    const profile = useSelector(state => state.firebase.profile);
    const validation = useSelector(state => state.spares.validation); 
    const spareError = useSelector(state => state.spares.spareError); 

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/spa'/>;

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value.trim()
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log('props state',componentState);
        dispatch(createSparePart(componentState, profile.account));
    }

    return ( 
        <div className="container product">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">Create Spare Part</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/spa' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="SKU">SAP No.</label>
                        <input id="partsSku" onChange={handleChange} type="number" className="validate"  required/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Name">Spare Part Name</label>
                        <input id="sparePartName" onChange={handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Board Name">Board Name</label>
                        <input id="boardName" onChange={handleChange} type="text" className="validate" required/>
                    </div>                                         
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Model Name">Model Name</label>
                        <input id="modelName" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Material Code">Material Code</label>
                        <input id="sku" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="MQuantity">Quantity</label>
                        <input id="stockQuantity" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label htmlFor="Location">Location</label>
                        <input id="location" onChange={handleChange} type="text" className="validate" required/>
                    </div>                      
                    <div className="col s12 m6 l3 input-field">
                        <label htmlFor="Price">Price</label>
                        <input id="price" onChange={handleChange} type="number"  className="validate" required/>
                    </div>                                          
                    <div className="col s12 m6 l3 input-field">
                        <label htmlFor="Cost">Cost</label>
                        <input id="cost" onChange={handleChange} type="number"  className="validate" required/>
                    </div> 
                </div>
                <div className="input-field">
                    <button className="btn red lighten-1 z-depth-0">Create</button>                        
                    <div className="red-text center">
                        {spareError ? <p>{spareError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default CreateSparePart;