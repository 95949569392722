import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import Materialize from 'materialize-css/dist/js/materialize.min.js'

//import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import { updateUser } from '../../store/actions/userActions';

const UserDetails = (props) => {    
    const dispatch = useDispatch();
    /** Material javascript for selection control */
    useEffect(() => {
        Materialize.AutoInit();
    },[])
    
    //const {divisionError, validation} = props;
    const [componentState, setCompoentState] = useState({});
    // const [account ,setAccount] = useState('');
    // const [organization, setOrganization] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [designation, setDesignation] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [privilege, setPrivilege] = useState('');

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth); 
    const user = useSelector(state => state.user.data[props.match.params.id]);   
    const validation = useSelector(state => state.user.validation); 
    const userError = useSelector(state => state.user.divisionError); 
    
    useEffect(() => {
        //console.log(user);  
        if(user){
            setCompoentState({
                firstName: user.firstName,
                lastName: user.lastName,
                designation: user.designation,
                email: user.email,
                mobileNumber: user.mobileNumber,
                privilege: user.privilege
            })
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setDesignation(user.designation);
            setEmail(user.email);
            setMobileNumber(user.mobileNumber);
            setPrivilege(user.privilege);
        }
    },[user]);    

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/usr'/>;    

    /** Handle default values in controls */
    let userPrivilege = '';
    //let userOrganization = '';
    if(user){
        //userOrganization = user.organization;
        userPrivilege = user.privilege;
    } 

    const handleChange = (e) => {
        setCompoentState({
            ...componentState,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Previous state', {privilege});
        //console.log('props state',componentState);
        dispatch(updateUser(componentState, props.match.params.id));
    }
    
    // const handleOrganizationSelection = (tags) => {
    //     console.log('Previous state', {account, organization, privilege});
    //     setCompoentState({
    //         ...componentState,            
    //         account: tags.value,
    //         organization: tags.label
    //     })
    // }

    return (        

        <div className="container user">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">Update User</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/usr' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="email">Email</label>
                        <input  onChange={handleChange} defaultValue={email} id="email"  type="email" className="validate" required/>
                        {/* <label className="active" htmlFor="Organization">Organization</label>
                        <AutoComplete defaultInputValue={userOrganization} onSelected={handleOrganizationSelection} collection={'organizations'} search={'name'} placeholder='Select organization'/> */}
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <select id="privilege" onChange={handleChange} defaultValue={userPrivilege} >
                            <option value="" disabled>Choose your option</option>
                            <option value="admin">Admin</option>
                            <option value="finance">Finance</option>
                            <option value="logistics">Logistics</option>
                            <option value="service-manager">Service Manager</option>
                            <option value="service-personnel">Service Personnel</option>
                            <option value="user">User</option>
                        </select>
                        <label>Privilege</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="First Name">First Name</label>
                        <input defaultValue={firstName} id="firstName" onChange={handleChange} type="text" className="validate" required/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Last Name">Last Name</label>
                        <input defaultValue={lastName} id="lastName" onChange={handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Designation">Designation</label>
                        <input defaultValue={designation} id="designation" onChange={handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Mobile Number">Mobile Number</label>
                        <input defaultValue={mobileNumber} id="mobileNumber" onChange={handleChange} type="tel" className="validate" required/>
                    </div> 
                </div>
                <div className="input-field">
                    <button className="btn red lighten-1 z-depth-0">Update</button>                        
                    <div className="red-text center">
                        {userError ? <p>{userError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default UserDetails;