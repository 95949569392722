import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { CloseButton } from '../buttons/LinkButtons';

const PDFUpload = ({uploadFile, selectedComponent, heading, pdfViewerStatus, onCloseControl, onConfirm}) => {
    
    const handleClose = () => {   
        setPageNumber(1);  
        onCloseControl();
    }

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    
    const handleConfirm = (component) => {
        onConfirm(component); 
    }

    return ( 
        <React.Fragment>
            <div id="message" className={'message ' + pdfViewerStatus}>
                <div className="right"><CloseButton onClick={handleClose} bg='grey lighten-5' /></div> 
                <div className="modal-content"> 
                    <div className="pdf-view card z-depth-1 user-summary modal-card">                
                        <div className="card-content grey-text text-darken-3">
                            <span className="card-title">{heading}</span>
                            <p className="red-text text-lighten-1"></p>
                            <div className="card-content">
                                <Document 
                                    file={uploadFile.file}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber} />
                                </Document>

                                
                            </div>
                        </div>
                        <div className="section file-upload-ctl">
                            <div className="row">
                                <div className="col s4">
                                    <button onClick={(component) => {handleConfirm(selectedComponent)}} className={'btn orange z-depth-0 approval'}>CONFIRM</button> 
                                </div>
                                <div className="col s4">
                                    {/* {progress} */}
                                </div>
                                <div className="col s4">
                                    <p className="text-grey text-lighten-3 right">Total Pages: {numPages}</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    
                </div>
            </div>        
            <div id="overlay" className={'message-overlay ' + pdfViewerStatus}></div>
        </React.Fragment>
     );
}
 
export default PDFUpload;