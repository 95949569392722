import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import UserStatus from './UserStatus';

const UserModal = ({userList, divisions}) => {
    useEffect(() => {
        Materialize.AutoInit();
    },[])

    const membersList = userList && userList.map(user => {
        return(
            <div id={"member" + user.id} className="modal" key={user.id}>
                <div className="modal-content">                    
                    <div className="right"><Link to='#' className="modal-close"><i className="material-icons grey-text text-darken-2">close</i></Link></div> 
                    <div className="card z-depth-1 user-summary modal-card">                
                        <div className="card-content grey-text text-darken-3">
                            <span className="card-title">{user.firstName + ' ' + user.lastName}</span>
                            <p className="red-text text-lighten-1">{user.designation}</p>
                            <div className="contact">
                                <p><i className="material-icons white grey-text text-darken-2">email</i>{user.email}</p>
                                <p><i className="material-icons white grey-text text-darken-2">phone</i>{user.mobileNumber}</p>
                            </div>
                        </div>
                        <div className="card-action card-bottom-badge">
                            <UserStatus user={user} divisions={divisions}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    });

    return(
       <React.Fragment>
            {membersList}
       </React.Fragment>
    )
}
 
export default UserModal;