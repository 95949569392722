import React from 'react';

const UserStatus = ({user, divisions, showEdit}) => {
    
    const entrolledDvisions = divisions ? (
        <React.Fragment>
            {
                divisions.map(div => {
                    let members = [];
                    if(divisions){
                        const orderedArray = Object.keys(div.members).map((key) => [key, div.members[key]]);    
                    
                        orderedArray.map(item => {    
                            members.push(item.pop());
                            return null;
                        });     
                    }
                    let reportingLevel = 2;
                    if(members.some(member => member.id === user.id)){
                       members.map(mem => {
                           if(mem.id === user.id){
                                reportingLevel = mem.reportingLevel;
                           } 
                           return null;                   
                       })
                       return (<span key={ div.id } className={ reportingLevel === 1 ? 'new badge orange' : 'new badge'} data-badge-caption={div.name}></span>)
                    }
                    return null;
                })
            }
        </React.Fragment>
        
        
    ) : (
        <p>Not entrolled in any divisions</p>
    ); 

    return ( 
        <React.Fragment>
            <div className="switch">
                <label>
                Status
                <input readOnly checked = { user.status === 'active' ? 'checked' : '' } type="checkbox"/>
                <span className="lever"></span>
                </label>
            </div> 
            <ul>
                {entrolledDvisions}
            </ul>            
        </React.Fragment>
     );
}
 
export default UserStatus;