import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteDivision } from '../../store/actions/divisionActions';
import { EditLinkButton, DeleteLinkButton } from '../../utils/components/buttons/LinkButtons';
import Materialize from 'materialize-css/dist/js/materialize.min.js';

const DivisionSummary = ({division}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        Materialize.AutoInit();
    },[])
    return (
        <div className="col s12 m4 l4">
            <div className="card z-depth-1 division-summary">                
                <div className="card-content grey-text text-darken-3">
                    <div className="card-ed-del">
                        <EditLinkButton to={'/div/' + division.id}/>
                        <DeleteLinkButton to='#' onClick={() => dispatch(deleteDivision(division, division.id))}/>
                        {/* <Link to={'/div/' + division.id} className="btn-flat"><i className="material-icons white grey-text text-darken-2">edit</i></Link> */}
                        {/* <Link to='#' onClick={() => dispatch(deleteDivision(division, division.id))} className="btn-flat"><i className="material-icons white grey-text text-darken-2">delete</i></Link> */}
                    </div>
                    <span className="card-title">{division.name}</span>
                    <p>Location: {division.location}</p>
                </div>
                <div className="card-action">                    
                    <p className="grey-text">Manager: {division.manager}</p>
                </div>
            </div>
        </div> 
     );
}
 
export default DivisionSummary;