import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Redirect } from 'react-router-dom';

import { CloseLinkButton, DeleteLinkButton } from '../../utils/components/buttons/LinkButtons';
import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { DeleteComponentUsed, GetComponentsUsed, UpdateRepairItem } from '../../store/actions/rmaActions';
import AutoComplete from '../../utils/components/autoComplete/AutoComplete';

const RepairItemDetails = (props) => {    
    const dispatch = useDispatch();
    useEffect(() => {
        Materialize.AutoInit();
        const completionDate = document.querySelector('.completionDate');
        Materialize.Datepicker.init(completionDate, {
            autoClose: true,
            format: "yyyy-mm-dd",
            onSelect: function () {
                //console.log(this);
                handleCompletionDateSelect(this.date);
            }
        });

        const despatchDate = document.querySelector('.despatchDate');
        Materialize.Datepicker.init(despatchDate, {
            autoClose: true,
            format: "yyyy-mm-dd",
            onSelect: function () {
                //console.log(this);
                handleDespatchDateSelect(this.date);
            }
        });
    })
    
    const [clearField, setClearField] = useState();
    const [componentState, setComponentState] = useState({});
    const [rmaDate, setRmaDate] = useState('');
    const [rmaNumber, setRmaNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [warrantyStatus, setWarrantyStatus] = useState('');
    const [faultDescription, setFaultDescription] = useState('');
    //const [componentName, setComponentName] = useState('');
    const [sku, setSKU] = useState('');
    const [serviceCharge, setServiceCharge] = useState('');
    const [completionDate, setCompletionDate] = useState('');
    const [despatchDate, setDespatchDate] = useState('');
    const [remarks, setRemarks] = useState('');
    const [componets, setComponents] = useState({});
    // const [status, setStatus] = useState('');
    

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth); 
    const profile = useSelector(state => state.firebase.profile);
    const repairList = useSelector(state => state.rma.repairReg);   
    const searchResult = useSelector(state => state.rma.filteredRepairData); 
    const componentsUsed = useSelector(state => state.rma.componentsUsed);
    const validation = useSelector(state => state.rma.validation); 
    const rmaError = useSelector(state => state.rma.rmaError); 
    
     
    useEffect(() => {  
        //console.log('repairList',repairList);    
        let rma = repairList  !== null ? repairList.filter(item => {
            return item.id === props.match.params.id
         }) : [];
         //console.log('rmaNo',rma[0].rmaNo);
         if(rma[0] === undefined){
           // console.log('searchResult',searchResult);
            rma = searchResult  !== null ? searchResult.filter(item => {
                return item.id === props.match.params.id
             }) : [];
         }
        if((repairList || searchResult) && rma[0] !== undefined){
            setComponentState({
                id: rma[0].id,
                rmaDate: rma[0].rmaDate,
                rmaNumber: rma[0].rmaNo,
                customerName: rma[0].customerName,
                customerAddress: rma[0].customerAddress ? rma[0].customerAddress : '',
                customerEmail: rma[0].customerEmail ? rma[0].customerEmail : '',
                customerPhone: rma[0].customerPhone ? rma[0].customerPhone: '',
                serialNumber: rma[0].serialNumber,
                modelNumber: rma[0].productName,
                sku: rma[0].productSKU,
                warrantyStatus: rma[0].warrantyStatus,
                faultDescription: rma[0].faultDescription ? rma[0].faultDescription : '',
                problemIdentified: rma[0].problemIdentified ? rma[0].problemIdentified : '',
                repairNotes: rma[0].repairNotes ? rma[0].repairNotes : '',
                componentName: rma[0].componentName ? rma[0].componentName : '',
                repairStatus: rma[0].repairStatus ? rma[0].repairStatus : '',
                completionDate: rma[0].completionDate !== '0000-00-00' ? rma[0].completionDate: '',
                despatchDate: rma[0].despatchDate !== '0000-00-00' ? rma[0].despatchDate : '',
                serviceCharge: rma[0].serviceCharge,
                remarks: rma[0].remarks ? rma[0].remarks : '',
                status: rma[0].rmaStatus ? rma[0].rmaStatus: ''

            })
            setRmaDate(rma[0].rmaDate);
            setRmaNumber(rma[0].rmaNo);
            setCustomerName(rma[0].customerName);
            setCustomerAddress(rma[0].customerAddress);
            setCustomerEmail(rma[0].customerEmail);
            setCustomerPhone(rma[0].customerPhone);
            setSerialNumber(rma[0].serialNumber);
            setModelNumber(rma[0].productName);
            setWarrantyStatus(rma[0].warrantyStatus);
            setFaultDescription(rma[0].faultDescription);
            setSKU(rma[0].productSKU);
            //setComponentName(rma[0].componentName);
            setServiceCharge(rma[0].serviceCharge);
            setCompletionDate(rma[0].completionDate !== '0000-00-00' ? rma[0].completionDate : '');
            setDespatchDate(rma[0].despatchDate !== '0000-00-00' ? rma[0].despatchDate : '');
            setRemarks(rma[0].remarks);
        }
    },[repairList, searchResult, props.match.params.id]);    

    useEffect(() =>{
        //console.log('getting componentsUsed');
        dispatch(GetComponentsUsed(profile.account, props.match.params.id));
    },[dispatch, profile.account, props.match.params.id]);

    const handleDeleteComponent = (sku) => {
        console.log('delete', sku);
        dispatch(DeleteComponentUsed(profile.account, props.match.params.id, sku));
    }

    
    const usedComponent = componentsUsed && componentsUsed.map(item => {
        return (
            <li key={item.value} className="sel-comp">
                {item.label} 
                <DeleteLinkButton to='#' bg='grey lighten-5' onClick={() => {handleDeleteComponent(item.value)}}/>
            </li>
        );
    });
    //console.log('componentsUsed', usedComponent);
    useEffect(() => {        
        setComponents(componentsUsed);
    },[componentsUsed]);

    /** Handle default values in controls */
    let selectedStatus = '';
    let selectedRepairNotes = '';
    let selectedProblemIdentified = '';
    let selectedRepairStatus = '';    
    if(repairList){ 
        let rma = repairList  !== null ? repairList.filter(item => {
            return item.id === props.match.params.id
         }) : [];
        //console.log('rma', rma[0]);
        if(rma[0] === undefined){
            rma = searchResult  !== null ? searchResult.filter(item => {
                return item.id === props.match.params.id
             }) : [];
         }
        if(rma[0] !== undefined){
            selectedStatus = rma[0].rmaStatus === 0 ? '' : rma[0].rmaStatus;;
            selectedRepairNotes = rma[0].repairNotes === null ? '' : rma[0].repairNotes;;
            selectedProblemIdentified = rma[0].problemIdentified === null ? '' : rma[0].problemIdentified;
            selectedRepairStatus = rma[0].repairStatus;
        }
        
    }

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/rgrma'/>;  

    const handleCompletionDateSelect = (date) => {
        //console.log('date picked', date.toLocaleDateString('en-CA'));
        setComponentState({
            ...componentState,
            completionDate: date.toLocaleDateString('en-CA')
        })
    }

    const handleDespatchDateSelect = (date) => {
        //console.log('date picked', date.toLocaleDateString('en-CA'));
        setComponentState({
            ...componentState,
            despatchDate: date.toLocaleDateString('en-CA')
        })
    }

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value
        })
    }

    const handleProblemIdentified = (e) => {
        setComponentState({
            ...componentState,
            problemIdentified: e.target.value
        })
    }

    const handleRepairNotes = (e) => {
        setComponentState({
            ...componentState,
            repairNotes: e.target.value
        })
    }

    const handleRepairStatus = (e) => {
        setComponentState({
            ...componentState,
            repairStatus: e.target.value
        })
    }

    const handleRMAStatus = (e) => {
        setComponentState({
            ...componentState,
            status: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Previous state', componentState);
        if(componentState.id === undefined){
            Materialize.toast({html: 'Error: Invalid data'});
        }else if(componentState.status === '9'){
            /* Status value is from status dropdown selection, not from database */
            Materialize.toast({html: 'Notice: Could not update with closed status'});
        }else{
            dispatch(UpdateRepairItem(profile.account, componentState, componets, props.match.params.id));
        }
    }

    const handleComponentSelection = (tags) => {
        //console.log('tags',tags);
        setComponents(tags);
    }

    const handleProductSelection = (tags) => {        
        setClearField();
        //console.log('product ',tags);
        setSKU(tags.value);
        setComponentState({
            ...componentState,
            sku: tags.value
        });
    }

    return (       
        <div className="section rma">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h6 className="red-text text-lighten-1">Update Repair Status</h6>
                    </div>
                    <div className="col s1 btn-close">
                        <CloseLinkButton to='/rgrma' />
                    </div>
                </div>
                <br />
                <div className="row pb-3">
                    <div className="col s7 ">
                        <div className="row">
                            <div className="col s3">
                                <span  className="bold">Customer Name: </span>
                            </div>
                            <div className="col s9">
                                <span  className="bold">{customerName}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col s1 ">
                        
                    </div>
                    <div className="col s4 ">
                        <div className="row">
                            <div className="col s5">
                                <span>RMA Number: </span>
                            </div>
                            <div className="col s7">
                                <span>{rmaNumber}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-3">
                    <div className="col s7 ">
                        <div className="row">
                            <div className="col s3">
                                <span>Address: </span>
                            </div>
                            <div className="col s9">
                                <span>{customerAddress}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col s1 ">
                        
                    </div>
                    <div className="col s4 ">
                        <div className="row">
                            <div className="col s5">
                                <span>Date: </span>
                            </div>
                            <div className="col s7">
                                <span>{rmaDate}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-3">
                    <div className="col s7 ">
                        <div className="row">
                            <div className="col s3">
                                <span>Email: </span>
                            </div>
                            <div className="col s9">
                                <span>{customerEmail}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col s1 ">
                        
                    </div>
                    <div className="col s4 ">
                        <div className="row">
                            <div className="col s5">
                                <span  className="bold"></span>
                            </div>
                            <div className="col s7">
                                <span  className="bold"></span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row pb-3">
                    <div className="col s7 ">
                        <div className="row">
                            <div className="col s3">
                                <span>Phone: </span>
                            </div>
                            <div className="col s9">
                                <span>{customerPhone}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col s1 ">
                        
                    </div>
                    <div className="col s4 ">
                        <div className="row">
                            <div className="col s5">
                                <span className="bold"></span>
                            </div>
                            <div className="col s7">
                                <span  className="bold"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider mb-5"></div>
                
                <div className="row pb-3 pt-3 rma-details">
                    <div className="col s6 "> 
                        <div className="row">
                            <div className="col s3">
                                <span className="bold">Serial Number: </span>
                            </div>
                            <div className="col s9 b1">
                                <span>{serialNumber}</span>
                            </div>
                            <div className="col s3 mt-4">
                                <span className="bold">Warranty Status: </span>
                            </div>
                            <div className="col s9 b1 mt-4">
                                <span>{warrantyStatus}</span>
                            </div>
                            <div className="col s12 pt-5">
                                <div className="col s3 p-0 mt-2">
                                    <span className="bold">Problem Identified: </span>
                                </div>
                                <div className="col s9 pl-3">
                                    <select onChange={handleProblemIdentified}  defaultValue={selectedProblemIdentified}  className="browser-default" id="problemIdentified">
                                        <option value="" disabled>Select</option>
                                        <option value="Lost password">Lost password</option>
                                        <option value="Case damaged">Case damaged</option>
                                        <option value="Water damaged">Water damaged</option>
                                        <option value="Serious burned">Serious burned</option>
                                        <option value="Cable damged">Cable damged</option>
                                        <option value="No boot">No boot</option>
                                        <option value="Constantly reboot">Constantly reboot</option>
                                        <option value="DSP initialization failure">DSP initialization failure</option>
                                        <option value="Boot uncomplete">Boot uncomplete</option>
                                        <option value="No image ouput">No image ouput</option>
                                        <option value="Image abnormal">Image abnormal</option>
                                        <option value="IR abnormal">IR abnormal</option>
                                        <option value="Rotation abnormal">Rotation abnormal</option>
                                        <option value="Interface bent">Interface bent</option>
                                        <option value="Case deform">Case deform</option>
                                        <option value="Panel shell damaged">Panel shell damaged</option>
                                        <option value="VGA output abnormal">VGA output abnormal</option>
                                        <option value="VOUT abnormal">VOUT abnormal</option>
                                        <option value="HDMI output abnormal">HDMI output abnormal</option>
                                        <option value="Network abnormal">Network abnormal</option>
                                        <option value="485 Interface abnormal">485 Interface abnormal</option>
                                        <option value="RS-232 Interface fault">RS-232 Interface fault</option>
                                        <option value="USB Interface fault">USB Interface fault</option>
                                        <option value="Firmware abnormal">Firmware abnormal</option>
                                        <option value="Other abnormal">Other abnormal</option>
                                        <option value="Test ok">Test ok</option>
                                        <option value="Audio abnormal">Audio abnormal</option>
                                        <option value="Video in abnormal">Video in abnormal</option>
                                        <option value="POE not working">POE not working</option>
                                        <option value="Glass broken">Glass broken</option>
                                        <option value="Hard disk not working">Hard disk not working</option>
                                    </select> 
                                </div> 
                            </div>                            
                            <div className="col s3 mt-7">
                                <span className="bold">Product SKU: </span>
                            </div>
                            <div className="col s3 b1 mt-7">
                                <input onChange={handleChange} defaultValue={sku} className="browser-default b0 fit all-cap"  id="sku" type="text" disabled/>
                            </div>  
                            <div className="col s6 mt-4">
                                <AutoComplete
                                    onSelected={handleProductSelection} 
                                    collection={'products'} search={'productList'} condition={modelNumber} account={profile.account}
                                    placeholder={'Choose model'}
                                    value={clearField}
                                    multiSelect={false}
                                    api={'rma'}                                    
                                    clearSearchParam={false}                                                               
                                    keepCache={false}
                                />
                            </div>
                            <div className="col s3 mt-5">
                                <span className="bold">Components Used: </span>
                            </div>
                            <div className="col s9  mt-4 ">
                                <div className="row">
                                {
                                    /*
                                    If Model Number exist in the product master then 
                                    spare parts option will automatically get populated.
                                    If model number is manually entered, to get the spare list
                                    user has to type '-' in product sku field
                                    */
                                }
                                <AutoComplete
                                onSelected={handleComponentSelection} 
                                collection={'spareparts'} search={'sparePartsList'} condition={componentState.sku} account={profile.account}
                                placeholder={'Select components'}
                                value={clearField}
                                multiSelect={true}
                                api={'rma'}                                
                                keepCache={false}
                                />
                                </div>
                                <div className="row">
                                    <ul>                                        
                                        {usedComponent}
                                    </ul>
                                </div>
                            </div>                            
                            <div className="col s3 mt-4">
                                <span className="bold">Completion Date: </span>
                            </div>
                            <div className="col s3 b1 mt-4">
                                <input id='completionDate' defaultValue={completionDate} type="text" className="completionDate browser-default b0 fit"></input>
                            </div>                                                        
                            <div className="col s3 mt-4">
                                <span className="bold">Despatch Date: </span>
                            </div>
                            <div className="col s3 b1 mt-4">
                                <input id='despatchDate' defaultValue={despatchDate} type="text" className="despatchDate browser-default b0 fit"></input>
                            </div>
                        
                            {/* <div className="col s12 pt-5"> */}
                                <div className="col s3 mt-4">
                                    <span className="bold">Service Charge: </span>
                                </div>
                                <div className="col s3 b1 mt-3">
                                    <input onChange={handleChange} defaultValue={serviceCharge} className="browser-default b0 fit"  id="serviceCharge" type="number"/>
                                </div>
                                <div className="col s1 mt-4">
                                    <span className="bold">Status: </span>
                                </div>
                                <div className="col s5 pl-3 mt-2">
                                    <select onChange={handleRMAStatus}  defaultValue={selectedStatus}  className="browser-default" id="status">
                                        <option value="" disabled>Select</option>
                                        <option value="0">Waiting for Repairs</option>
                                        <option value="1">In Process</option>
                                        <option value="2">Ready for Collection</option>
                                        <option value="3">Waiting for Parts</option>
                                        <option value="4">Waiting for Customer Confirmation</option>
                                        <option value="5">Cancelled</option>
                                        <option value="9">Closed</option>
                                    </select> 
                                </div> 
                            {/* </div> */}
                        </div> 
                    </div>
                    <div className="col s6 ">                        
                        <div className="row">
                            <div className="col s3">
                                <span className="bold">Model Number: </span>
                            </div>
                            <div className="col s9 b1">
                                <span>{modelNumber}</span>
                            </div>
                        </div>
                        <div className="col s3 mt-4">
                            <span className="bold">Fault Description: </span>
                        </div>
                        <div className="col s9 b1 mt-4">
                            <span>{faultDescription}</span>
                        </div>
                        
                        <div className="col s12 pt-5">
                            <div className="col s3 p-0 mt-2">
                                <span className="bold">Repair Notes: </span>
                            </div>
                            <div className="col s9 pl-3">
                                <select onChange={handleRepairNotes}  defaultValue={selectedRepairNotes}  className="browser-default" id="repairNotes">
                                    <option value="" disabled>Select</option>
                                    <option value="Replace board">Replace board</option>
                                    <option value="Replace component">Replace component</option>
                                    <option value="Replace cable">Replace cable</option>
                                    <option value="Upgrade Firmware">Upgrade Firmware</option>
                                    <option value="Reset password">Reset password</option>
                                    <option value="Reprogrammed">Reprogrammed</option>
                                    <option value="Checked">Checked</option>
                                    <option value="Not sold from HTL">Not sold from HTL</option>
                                </select> 
                            </div> 
                        </div>
                        <div className="col s12 pt-5">
                            <div className="col s3 p-0 mt-2">
                                <span className="bold">Repair Status: </span>
                            </div>
                            <div className="col s9 pl-3">
                                <select onChange={handleRepairStatus}  defaultValue={selectedRepairStatus}  className="browser-default" id="repairStatus">
                                    <option value="0" disabled>Select</option>
                                    <option value="1">Repaired</option>
                                    <option value="2">OK</option>
                                    <option value="3">Wating for confirmation</option>
                                    <option value="4">Reset password</option>
                                    <option value="5">Reset factory default</option>
                                    <option value="6">Unrepaired</option>
                                    <option value="7">Customer Cancel</option>
                                    <option value="8">Scrap</option>
                                    <option value="9">Waiting for parts</option>
                                    <option value="10">Replacement</option>
                                    <option value="11">Sent to Jabel Ali</option>
                                    <option value="12">Return to customer</option>                                    
                                </select> 
                            </div> 
                        </div>  
                        
                        <div className="col s3 mt-5 pt-2">
                            <span className="bold">Remarks: </span>
                        </div>
                        <div className="col s9 mt-4">
                            <textarea  onChange={handleChange} id="remarks" className="txt-area-r" defaultValue={remarks} ></textarea>
                        </div>
                    </div>
                </div>
                <div className="input-field  pl-5 mb-5">
                    <button className="btn red lighten-1 z-depth-0">Update</button>                        
                    <div className="red-text center">
                        {rmaError ? <p>{rmaError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default RepairItemDetails;