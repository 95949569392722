import pdfMake from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";  
import axios from 'axios';
import {Signature} from '../../utils/GenerateSignature';

//const BaseURL = `http://localhost/www/htl/rma/core-rma-api.php/`;
const BaseURL = `https://rma.onlinecart.ae/core-rma-api.php/`;

const account = 'organizations';
const buildTableBody = (data, columns, heading, tableFooter) => {
    let body = [];

    body.push(heading);

    let i = 1;
    data.forEach(row => {
        let dataRow = [];
        console.log('row',row);
        dataRow.push(i++);
        columns.forEach(column => {
            //console.log('column',column);
            if(column === 'price' || column === 'serviceCharge'){                
                dataRow.push({text: parseFloat(row[column].replace(/,/g, '')).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2}), alignment: 'right'});
            }else{
                dataRow.push(row[column].toString());
            }         
        })

        body.push(dataRow);
    });
    for(let cnt = i; cnt < 9; cnt++ ){
        body.push([{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '}]);
    }
    if(tableFooter.length > 0) body.push(tableFooter);

    return body;
}
const table = (data, columns, heading, tableFooter, columnWidths) => {
    return {
        style: 'table',
        table: {
            widths: columnWidths,
            headerRows: 1,
            body: buildTableBody(data, columns, heading, tableFooter)
        }
    };
}

const CreateRMAAcknowledgement = (firestore, profile, rma, productList) => {
    firestore.runTransaction(async transaction => {
        const orgRef = firestore.collection(account)
        .doc(profile.account);

        const org = await transaction.get(orgRef);

        /** Create RMA Slip and save in storage */
        pdfMake.vfs = pdfFonts.pdfMake.vfs;  

        var docDefinition = {
            ownerPassword: '123456htl',
            permissions: {
                printing: 'highResolution', 
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true
            },
            info: {
                title: 'RMA SLIP - ' + rma.rmaNo,
                author: 'HTL',
                subject: 'RMA SLIP'
            },
            content: [
                { 
                    columns: [
                        {
                            image: org.data().logo,
                            width: 120,
                            height: 30
                        },
                        { 
                            width: 400,
                            text: [
                                {text : org.data().name.toUpperCase(), style: 'header' },
                            ]
                        }
                    ]
                    
                },
                {
                    columns: [
                        {
                            width: 310,
                            text: ''
                        },
                        {
                            width: 'auto',
                            text: [
                                {text : 'Service Center', style: 'subhead'}
                            ]
                        }
                    ]
                },
                {
                    columns: [
                        {
                            width: 220,
                            text: ''
                        },
                        {
                            width: 310,
                            text: [                                
                                {text : 'Address: Shop #11, China Cluster c-12, International City, Dubai-U.A.E. Mobile: 0588658566', style: 'address' },
                            ]
                        }
                    ]
                },               
                { text: 'RMA ACKNOWLEDGEMENT', style: 'title' },
                { text: '\n', style: 'para' }, 
                {
                    columns: [
                        {
                            width: 80,
                            text: [
                                {text : 'Customer Name'},
                                {text : '\nAddress'}
                            ]
                        },
                        {
                            width: 5,
                            text: [
                                {text : ':'},
                                {text : '\n :'},
                            ]
                        },
                        {
                            width: 250,
                            text: [
                                {text : rma.customerName, bold: true},
                                {text : '\n'+ rma.customerAddress},
                                {text : '\n Phone - ' + rma.customerPhone}
                            ]
                            
                        }, 
                        {
                            width: 50,
                            text: ''
                        },
                        {
                            width: 40,
                            text: [
                                {text : 'RMA No.'},
                                {text : '\n Date'},
                            ]
                        },
                        {
                            width: 5,
                            text: [
                                {text : ':'},
                                {text : '\n :'},
                            ]
                        },
                        {
                            width: 'auto',
                            text: [
                                {text : rma.rmaNo, bold: true},
                                {text : '\n'+ rma.rmaDate},
                            ]
                        },
                    ],
                    style: 'para'
                },                
                table(
                    productList, 
                    ['serialNumber', 'modelNumber', 'warrantyStatus', 'faultDescription'],  
                    [
                        {text: 'No.', style: 'tableHeader'}, 
                        {text: 'Serial No.', style: 'tableHeader'},                                                 
                        {text: 'Model', style: 'tableHeader'}, 
                        {text: 'Warranty Status', style: 'tableHeader'}, 
                        {text: 'Fault Description', style: 'tableHeader'}
                    ],
                    [],
                    [20, 80, 120, 80, 160, 10]
                ),
                { text: '\n\nNote: Items will be returned only against original RMA slip.', style: 'para' }, 
                {
                    columns: [
                        {
                            width: 300,
                            fontSize: 10,
                            text : 'For and On behalf of :' +
                            '\n' + org.data().name.toUpperCase() +
                            '\n\n\n....................................' +
                            '\nDate : ' + rma.rmaDate +
                            '\nName of Signatory: '
                            
                        },
                        {
                            width: 'auto',
                            fontSize: 10,
                            text : ' ' +
                            '\n Customer Signature :' +
                            '\n\n\n....................................'                            
                        }
                    ]
                }                               
            ],
        
            styles: {
                header: {
                    fontSize: 22,
                    bold: false,
                    alignment: 'right',
                    margin:[0, 0, 0, 0]
                },
                subhead: {
                    fontSize: 18,
                    bold: false,
                    alignment: 'left',
                    margin:[0, 0, 0, 0]
                },
                address: {
                    fontSize: 10,
                    alignment: 'center',
                    lineHeight: 1.25,
                    margin:[0, 0, 0, 10]
                },
                title: {
                    fontSize: 14,
                    bold: true,
                    decoration: 'underline',
                    alignment: 'center',
                    margin:[0, 2, 0, 2]
                },
                para: {
                    fontSize: 10,
                    lineHeight: 1.25,
                    margin:[0, 0, 0, 10]
                },
                table: {
                    margin: [0, 5, 0, 15],
                    fontSize: 9,
                },
                tableHeader: {
                    bold: true,
                    fontSize: 11,
                    color: 'black',
                    alignment: 'center',
                    fillColor: '#E7E7E7'
                },
                tableFooter: {
                    bold: true,
                    fontSize: 11,
                    color: 'black'
                }
            }
        };

        //const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfMake.createPdf(docDefinition).open();
    });
}

export const PrintRMAAcknowledgement = (rma) => {
    return(dispatch, getState, {getFirebase}) => {
         /*Validate auhorisation */          
         const firestore = getFirebase().firestore();          
         const profile = getState().firebase.profile;
         const loginId = getState().firebase.auth.uid;
         if(loginId !== undefined){
            const payload = {
                query: 'getRMAItems',
                accountId: profile.account,
                rmaId: rma[0].id
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {                    
                    query: 'getRMAItems',
                    accountId: profile.account,
                    rmaId: rma[0].id
                }
            }).then(res => {   
                CreateRMAAcknowledgement(firestore, profile, rma[0], res.data.ItemList);
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
         }
    }
}

export const createRMA = (accountId, customer, productList) => {
    return(dispatch, getState, { getFirebase }) => {
          /*Validate auhorisation */          
          const firestore = getFirebase().firestore();          
          const profile = getState().firebase.profile;
          const loginId = getState().firebase.auth.uid;
          if(loginId !== undefined){
            //const URL="/core-order-api.php"; 

            const orderedArray = Object.keys(productList).map((key) => [key, productList[key]]);    
       
            let ordered = [];
            orderedArray.map(item => {    
                ordered.push(item.pop());
                return null;
            }); 

            // Hash encode data 
            const payload = {
                accountId: accountId,
                customer: customer,
                productList: ordered,
                query: 'createRMA',
                user: {
                    uId: loginId,
                    userName: profile.firstName + ' ' + profile.lastName,
                }
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            /** */
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                console.log(res);
                dispatch({type: 'CREATE_RMA'});
                if(res.data.message === 'RMA created successfully'){
                    CreateRMAAcknowledgement(firestore, profile, res.data.RMA[0], ordered);
                }
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'RMA_ERROR', err});
            });
        } 
         
    }
}

export const GetRMAList = (accountId,pageInfo, pagination) => {
    
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'rmaList',
                accountId: accountId
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {
                    query: 'rmaList',
                    accountId: accountId,
                    pageInfo: pageInfo,
                    pagination: pagination
                }
            }).then(res => {
                console.log('result', res);
                dispatch({type: 'RMA_LIST', data: res.data});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
        }
    }
}

export const GetRMAItems = (accountId, id) => {
    
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'getRMAItems',
                accountId: accountId,
                rmaId: id
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {                    
                    query: 'getRMAItems',
                    accountId: accountId,
                    rmaId: id
                }
            }).then(res => {
                console.log('result', res);
                const itemList = {
                    data:{}
                };
                res.data.ItemList.map(item => {
                    const docData = {
                        serialNumber: item.serialNumber,
                        modelNumber: item.modelNumber,
                        warrantyStatus: item.warrantyStatus,
                        faultDescription: item.faultDescription,
                        status: item.status
                    };
                    const key = item.serialNumber;
                    itemList.data[key] = docData;
                    return null;
                });
                dispatch({type: 'RMA_ITEMS', itemList});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
        }
    }
}

export const GetRepairReg = (accountId) => {
    
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'getRepairReg',
                accountId: accountId
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {
                    query: 'getRepairReg',
                    accountId: accountId
                }
            }).then(res => {
                console.log('result', res);
                dispatch({type: 'REPAIR_REG_LIST', data: res.data});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
        }
    }
}

export const GetRMASearch = (pageInfo, pagination) => {
    return(dispatch, getState, { getFirebase }) => {
        /*Validate auhorisation */
        const loginId = getState().firebase.auth.uid;
        console.log('pageInfo', pageInfo);
        const profile = getState().firebase.profile;
        
        let location = '';
        if(loginId !== undefined && profile.account !== undefined){
            // Hash encode data 
            const payload = {
                query: 'rmaSearch',
                accountId: profile.account
            }
            const encoded_hash = Signature(JSON.stringify(payload));
            
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.get('/',{
                params: {
                    query: 'rmaSearch',
                    accountId: profile.account,
                    location: location,
                    choice: pageInfo.statusFilter,
                    pageInfo: pageInfo,
                    pagination: pagination
                }
            })
            .then(res => {
                console.log(res);
                dispatch({type: 'RMA_SEARCH_RESULT', data: res.data});
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'RMA_ERROR', err});
            });
        } 
    }
}

export const GetRepairRegSearch = (pageInfo, pagination) => {
    return(dispatch, getState, { getFirebase }) => {
        /*Validate auhorisation */
        const loginId = getState().firebase.auth.uid;
        console.log('pageInfo', pageInfo);
        const profile = getState().firebase.profile;
        
        let location = '';
        if(loginId !== undefined && profile.account !== undefined){
            // Hash encode data 
            const payload = {
                query: 'repairRegSearch',
                accountId: profile.account
            }
            const encoded_hash = Signature(JSON.stringify(payload));
            
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.get('/',{
                params: {
                    query: 'repairRegSearch',
                    accountId: profile.account,
                    location: location,
                    choice: pageInfo.statusFilter,
                    pageInfo: pageInfo,
                    pagination: pagination
                }
            })
            .then(res => {
                console.log(res);
                dispatch({type: 'REPAIR_REG_SEARCH_RESULT', data: res.data});
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'RMA_ERROR', err});
            });
        } 
    }
}

export const GetRMAStat = (accountId) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'rmaStat',
                accountId: accountId
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.get('/', {
                params: {
                    query: 'rmaStat',
                    accountId: accountId
                }
            }).then(res => {
                //console.log('result', res);
                dispatch({type: 'RMA_STAT', stat: res.data});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            })
        }
    }
}

export const UpdateRMA = (accountId, customer, productList, id) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;        
        const profile = getState().firebase.profile;
        if(loginId !== undefined){
          //const URL="/core-order-api.php"; 

          const orderedArray = Object.keys(productList).map((key) => [key, productList[key]]);    
     
          let ordered = [];
          orderedArray.map(item => {    
              ordered.push(item.pop());
              return null;
          }); 

          // Hash encode data 
          const payload = {
              accountId: accountId,
              rmaId: id,
              customer: customer,
              productList: ordered,
              query: 'updateRMA',
              user: {
                  uId: loginId,
                  userName: profile.firstName + ' ' + profile.lastName,
              }
          }
          const encoded_hash = Signature(JSON.stringify(payload));

          /** */
          console.log("Product List: ", ordered);
          const api = axios.create({
              baseURL: BaseURL,
              headers: {
                  'X-HTL-CORE-HMAC-SHA256': encoded_hash
              }
          });

          api.post('/', payload)
          .then(res => {
              console.log(res);
              dispatch({type: 'UPDATE_RMA'});
          })
          .catch(err => {
              //console.log(err);
              dispatch({type: 'RMA_ERROR', err});
          });
        }  
         
    }
}

export const DeleteRMA = (accountId, id) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;        
        const profile = getState().firebase.profile;
        if(loginId !== undefined){
          //const URL="/core-order-api.php"; 

          // Hash encode data 
          const payload = {
              accountId: accountId,
              rmaId: id,
              query: 'deleteRMA',
              user: {
                  uId: loginId,
                  userName: profile.firstName + ' ' + profile.lastName,
              }
          }
          const encoded_hash = Signature(JSON.stringify(payload));

          /** */
          const api = axios.create({
              baseURL: BaseURL,
              headers: {
                  'X-HTL-CORE-HMAC-SHA256': encoded_hash
              }
          });

          api.post('/', payload)
          .then(res => {
              console.log(res);
              dispatch({type: 'DELETE_RMA', rmaId: id});
          })
          .catch(err => {
              //console.log(err);
              dispatch({type: 'RMA_ERROR', err});
          });
        }  
         
    }
}

export const DeleteRMAItem = (accountId, productId, rmaId) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){

          // Hash encode data 
          const payload = {
              accountId: accountId,
              rmaId: rmaId,
              productId: productId,
              query: 'deleteRMAItem',   
          }
          const encoded_hash = Signature(JSON.stringify(payload));

          /** */
          const api = axios.create({
              baseURL: BaseURL,
              headers: {
                  'X-HTL-CORE-HMAC-SHA256': encoded_hash
              }
          });

          api.post('/', payload)
          .then(res => {
              console.log(res);
              dispatch({type: 'DELETE_RMA_ITEM'});
          })
          .catch(err => {
              //console.log(err);
              dispatch({type: 'RMA_ERROR', err});
          });
        }  
         
    }
}

export const GetRMALog = (accountId, productId, rmaId) => {
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'getRMALog',
                accountId: accountId,
                productId: productId,
                rmaId: rmaId
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.get('/', {
                params: {
                    query: 'getRMALog',
                    accountId: accountId,
                    productId: productId,
                    rmaId: rmaId
                }
            }).then(res => {
                console.log('result', res);
                dispatch({type: 'RMA_LOG', data: res.data});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            })
        }
    }
}

export const UpdateRepairItem = (accountId, rma, components, id) => {
    return(dispatch, getState) => {
          /*Validate auhorisation */
          const loginId = getState().firebase.auth.uid;
          const profile = getState().firebase.profile;
          if(loginId !== undefined){
            //const URL="/core-order-api.php"; 
            const orderedArray = Object.keys(components).map((key) => [key, components[key]]);    
       
            let ordered = [];
            orderedArray.map(item => {    
                ordered.push(item.pop());
                return null;
            }); 

            // Hash encode data 
            const payload = {
                accountId: accountId,
                rma: rma,
                componentsUsed: ordered,
                id: id,
                query: 'updateRepairItem', 
                user: {
                    uId: loginId,
                    userName: profile.firstName + ' ' + profile.lastName,
                }
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            /** */
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                console.log(res);
                dispatch({type: 'UPDATE_RMA'});
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'RMA_ERROR', err});
            });
        } 
         
    }
}

export const GetComponentsUsed = (accountId, refId) => {
    
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'getComponentsUsed',
                accountId: accountId,
                referenceId: refId
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {
                    query: 'getComponentsUsed',
                    accountId: accountId,
                    referenceId: refId
                }
            }).then(res => {
                console.log('result', res);
                let components = [];
                res.data.ComponentsUsed && res.data.ComponentsUsed.map(item => {
                    const docData = {
                        value: item.sku,
                        label: item.sparePartName
                    };
                    components.push(docData);
                    return null;
                });
                dispatch({type: 'COMPONENTS_USED', components});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
        }
    }
}

export const DeleteComponentUsed = (accountId, id, sku) => {
    return(dispatch, getState) => {
          /*Validate auhorisation */
          const loginId = getState().firebase.auth.uid;
          if(loginId !== undefined){

            // Hash encode data 
            const payload = {
                accountId: accountId,
                id: id,
                sku: sku,
                query: 'deleteComponentUsed',
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            /** */
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                console.log(res);
                if(res.data.message === 'Item deleted') dispatch({type: 'DELETE_COMPONENT_USED', sku});
                else dispatch({type: 'RMA_ERROR', err: res.data.message});
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'RMA_ERROR', err});
            });
        } 
         
    }
}

export const GetRMAInDeliveryStatus = (accountId,pageInfo, pagination) => {
    
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'rmaInDeliveryStatus',
                accountId: accountId
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {
                    query: 'rmaInDeliveryStatus',
                    accountId: accountId,
                    pageInfo: pageInfo,
                    pagination: pagination
                }
            }).then(res => {
                console.log('result', res);
                dispatch({type: 'RMA_DN_LIST', data: res.data});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
        }
    }
}

export const GetDeliveryItems = (accountId, refId) => {
    
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'getDeliveryItems',
                accountId: accountId,
                referenceId: refId
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {
                    query: 'getDeliveryItems',
                    accountId: accountId,
                    referenceId: refId
                }
            }).then(res => {
                console.log('result', res);
                let dnDetails = {};
                if(!res.data.Error){
                    dnDetails = {
                        rma:{
                            customerName: res.data.RMA[0].customerName,
                            customerAddress: res.data.RMA[0].customerAddress,
                            customerEmail: res.data.RMA[0].customerEmail,
                            customerPhone: res.data.RMA[0].customerPhone
                        },
                        data:{}
                    };
                    res.data.DeliveryItems.map(item => {
                        const docData = {
                            id: item.id,
                            serialNumber: item.serialNumber,
                            quantity: item.quantity,
                            modelNumber: item.modelNumber,
                            warrantyStatus: item.warrantyStatus,
                            rmaNo: item.rmaNo,
                            rmaDate: item.rmaDate,
                            serviceCharge: item.serviceCharge,
                            remarks: item.remarks
                        };
                        const key = item.serialNumber;
                        dnDetails.data[key] = docData;
                        return null;
                    });
                }else{
                    dnDetails = {
                        rma:{
                            customerName: '',
                            customerAddress: '',
                            customerEmail: '',
                            customerPhone: ''
                        },
                        data:{}
                    };
                }
                
                dispatch({type: 'RMA_DN_ITEMS', dnDetails});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
        }
    }
}

const CreateDeliveryNoteDoc = (firestore, profile, dn, productList) => {
    firestore.runTransaction(async transaction => {
        const orgRef = firestore.collection(account)
        .doc(profile.account);

        const org = await transaction.get(orgRef);
        //console.log('delivery Note Amt',parseFloat(dn.amount.replace(/[^\d\.\-]/g, "")).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2}));
        /** Create RMA Slip and save in storage */
        pdfMake.vfs = pdfFonts.pdfMake.vfs;  

        var docDefinition = {
            ownerPassword: '123456htl',
            permissions: {
                printing: 'highResolution', 
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true
            },
            info: {
                title: 'Delivery Note - ' + dn.dnNo,
                author: 'HTL',
                subject: 'DELIVERY NOTE'
            },
            content: [
                { 
                    columns: [
                        {
                            image: org.data().logo,
                            width: 120,
                            height: 30
                        },
                        { 
                            width: 400,
                            text: [
                                {text : org.data().name.toUpperCase(), style: 'header' },
                            ]
                        }
                    ]
                    
                },
                {
                    columns: [
                        {
                            width: 310,
                            text: ''
                        },
                        {
                            width: 'auto',
                            text: [
                                {text : 'Service Center', style: 'subhead'}
                            ]
                        }
                    ]
                },
                {
                    columns: [
                        {
                            width: 220,
                            text: ''
                        },
                        {
                            width: 310,
                            text: [                                
                                {text : 'Address: Shop #11, China Cluster c-12, International City, Dubai-U.A.E. Mobile: 0588658566', style: 'address' },
                            ]
                        }
                    ]
                },
                { text: 'TRN No: 100529704700003'},
                { text: '\nDELIVERY NOTE', style: 'title' },
                { text: '\n', style: 'para' }, 
                {
                    columns: [
                        {
                            width: 80,
                            text: [
                                {text : 'Customer Name'},
                                {text : '\nAddress'}
                            ]
                        },
                        {
                            width: 5,
                            text: [
                                {text : ':'},
                                {text : '\n :'},
                            ]
                        },
                        {
                            width: 250,
                            text: [
                                {text : dn.customerName, bold: true},
                                {text : '\n'+ dn.customerAddress},
                                {text : '\n Phone - ' + dn.customerPhone}
                            ]
                            
                        }, 
                        {
                            width: 50,
                            text: ''
                        },
                        {
                            width: 40,
                            text: [
                                {text : 'DO No.'},
                                {text : '\n Date'},
                            ]
                        },
                        {
                            width: 5,
                            text: [
                                {text : ':'},
                                {text : '\n :'},
                            ]
                        },
                        {
                            width: 'auto',
                            text: [
                                {text : dn.dnNo, bold: true},
                                {text : '\n'+ dn.dnDate},
                            ]
                        },
                    ],
                    style: 'para'
                },                
                table(
                    productList, 
                    ['modelNumber', 'quantity', 'serialNumber', 'serviceCharge', 'remarks'],  
                    [
                        {text: 'No.', style: 'tableHeader'}, 
                        {text: 'Description/Model', style: 'tableHeader'},
                        {text: 'Qty.', style: 'tableHeader'},                                               
                        {text: 'Serial No.', style: 'tableHeader'}, 
                        {text: 'S. Charge', style: 'tableHeader', alignment: 'right'}, 
                        {text: 'Remarks/Reason', style: 'tableHeader'}
                    ],
                    [
                        {text: '', style: 'tableFooter'}, 
                        {text: 'Total Service Charge Including VAT', style: 'tableFooter', colSpan: 4},                                                 
                        {}, 
                        {},  
                        {},
                        {text: 'AED ' + parseFloat(dn.amount.replace(/,/g, '')).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2}), style: 'tableFooter', alignment: 'right'}
                    ],
                    [20, 140, 20, 80, 60, 140, 10]
                ),
                { text: '\n\nNote: New Replaced parts of out-of-warranty item have only 60-days warranty.', style: 'para' }, 
                {
                    columns: [
                        {
                            width: 300,
                            fontSize: 10,
                            text : 'For and On behalf of :' +
                            '\n' + org.data().name.toUpperCase() +
                            '\n\n\n....................................' +
                            '\nName of Signatory: '
                            
                        },
                        {
                            width: 'auto',
                            fontSize: 10,
                            text : ' ' +
                            '\n Receiver\'s Name & Signature :' +
                            '\n\n\n....................................'                            
                        }
                    ]
                }                               
            ],
        
            styles: {
                header: {
                    fontSize: 22,
                    bold: false,
                    alignment: 'right',
                    margin:[0, 0, 0, 0]
                },
                subhead: {
                    fontSize: 18,
                    bold: false,
                    alignment: 'left',
                    margin:[0, 0, 0, 0]
                },
                address: {
                    fontSize: 10,
                    alignment: 'center',
                    lineHeight: 1.25,
                    margin:[0, 0, 0, 10]
                },
                title: {
                    fontSize: 14,
                    bold: true,
                    decoration: 'underline',
                    alignment: 'center',
                    margin:[0, 2, 0, 2]
                },
                para: {
                    fontSize: 10,
                    lineHeight: 1.25,
                    margin:[0, 0, 0, 10]
                },
                table: {
                    margin: [0, 5, 0, 15],
                    fontSize: 9,
                },
                tableHeader: {
                    bold: true,
                    fontSize: 11,
                    color: 'black',
                    alignment: 'center',
                    fillColor: '#E7E7E7'
                },
                tableFooter: {
                    bold: true,
                    fontSize: 11,
                    color: 'black'
                }
            }
        };

        //const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfMake.createPdf(docDefinition).open();
    });
}

export const PrintDeliveryNote = (dn) => {
    return(dispatch, getState, {getFirebase}) => {
         /*Validate auhorisation */          
         const firestore = getFirebase().firestore();          
         const profile = getState().firebase.profile;
         const loginId = getState().firebase.auth.uid;
         if(loginId !== undefined){
            const payload = {
                query: 'getDeliveryNoteItems',
                accountId: profile.account,
                dnId: dn[0].id
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {                    
                    query: 'getDeliveryNoteItems',
                    accountId: profile.account,
                    dnId: dn[0].id
                }
            }).then(res => {   
                CreateDeliveryNoteDoc(firestore, profile, dn[0], res.data.ItemList);
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
         }
    }
}

export const createDeliveryNote = (accountId, customer, productList, totalAmount) => {
    return(dispatch, getState, { getFirebase }) => {
          /*Validate auhorisation */          
          const firestore = getFirebase().firestore();          
          const profile = getState().firebase.profile;
          const loginId = getState().firebase.auth.uid;
          if(loginId !== undefined){
            //const URL="/core-order-api.php"; 

            const orderedArray = Object.keys(productList).map((key) => [key, productList[key]]);    
       
            let ordered = [];
            orderedArray.map(item => {    
                ordered.push(item.pop());
                return null;
            }); 

            // Hash encode data 
            const payload = {
                accountId: accountId,
                customer: customer,
                productList: ordered,
                amount: totalAmount,
                query: 'createDeliveryNote', 
                user: {
                    uId: loginId,
                    userName: profile.firstName + ' ' + profile.lastName,
                }
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            /** */
            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });

            api.post('/', payload)
            .then(res => {
                console.log(res);
                dispatch({type: 'CREATE_DELIVERY_NOTE'});
                if(res.data.message === 'Delivery Note created successfully'){
                    CreateDeliveryNoteDoc(firestore, profile, res.data.DeliveryNote[0], ordered);
                }
            })
            .catch(err => {
                //console.log(err);
                dispatch({type: 'RMA_ERROR', err});
            });
        } 
         
    }
}

export const GetDeliveryNoteList = (accountId,pageInfo, pagination) => {
    
    return(dispatch, getState) => {
        const loginId = getState().firebase.auth.uid;
        if(loginId !== undefined){
            const payload = {
                query: 'deliveryNoteList',
                accountId: accountId
            }
            const encoded_hash = Signature(JSON.stringify(payload));

            const api = axios.create({
                baseURL: BaseURL,
                headers: {
                    'X-HTL-CORE-HMAC-SHA256': encoded_hash
                }
            });
            
            api.get('/', {
                params: {
                    query: 'deliveryNoteList',
                    accountId: accountId,
                    pageInfo: pageInfo,
                    pagination: pagination
                }
            }).then(res => {
                console.log('result', res);
                dispatch({type: 'DELIVERY_NOTE_REG', data: res.data});
            }).catch(err => {
                dispatch({type: 'RMA_ERROR', err});
            });
        }
    }
}

export const HandleRMAServiceCharge = (serialNumber, serviceCharge) => {
    return(dispatch) => {
        const data = {
            serialNumber: serialNumber,
            serviceCharge: serviceCharge
        }
        dispatch({type: 'RMA_UPDATE_SERVICECHARGE', data});
    }
}

export const ClearSearch = () => {
    return(dispatch) => {
        dispatch({type: 'CLEAR_SEARCH'});
    }
}

export const StatusSelection = (index) => {
    return(dispatch) => {
        dispatch({type: 'STATUS_SELECTION', index});
    }
}

export const resetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().rma.validation;
        if(validation) dispatch({type: 'RESET_VALIDATION'})
    }
}