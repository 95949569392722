import React from 'react';
import { Link } from 'react-router-dom';

const UserTilte = ({user, onDeleteUser, showEdit}) => {
    const email = user.email === undefined || user.email === ''  ? (
        null
    ) : (
       <p><i className="material-icons white grey-text text-darken-2">email</i>{user.email}</p>
    );
    const phone = user.mobileNumber === undefined  || user.mobileNumber === ''  ? (
        null
    ) : (
       <p><i className="material-icons white grey-text text-darken-2">phone</i>{user.mobileNumber}</p>
    );

    const edit = showEdit ? (
        <div className="card-ed-del">
            <Link to={'/usr/' + user.id} className="btn-flat"><i className="material-icons white grey-text text-darken-2">edit</i></Link>
            <Link to='#' onClick={() => {handleDeleteUser(user, user.id)}} className="btn-flat"><i className="material-icons white grey-text text-darken-2">delete</i></Link>
        </div>
    ) : (
        null
    )

    const handleDeleteUser = (user, id) => {
        onDeleteUser(user, id);
    } 

    return ( 
        <div className="card-content grey-text text-darken-3">
            {edit}      
            <span className="card-title">{user.firstName + ' ' + user.lastName}</span>
            <p className="red-text text-lighten-1">{user.designation}</p>
            <div className="contact">
                {email}
                {phone}
            </div>
        </div>
     );
}
 
export default UserTilte;