import React from 'react';
import PreviewPDF from '../../utils/components/uploadControls/PreviewPDF';

const Document = ({docNumber, message, pdfFile, onValidated}) => {
    const valid = message && docNumber && pdfFile.dataURL ? true : false;
     
    onValidated(valid);

    return ( 
        <PreviewPDF pdfFile={pdfFile}/>
     );
}
 
export default Document;