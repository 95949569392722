const initState = {
    data: {},
    exportStat: {
        queued: 0,
        uploaded: 0,
        failed: 0,
        control: 'close'            
    },
    duplicate: [],
    filteredData: {},
    productStat: {},
    loadData: true,
    pageInfo: {},
    validation: null
}

const productReducer = (state= initState, action) => {
    switch(action.type){
        case 'CREATE_PRODUCT_ERROR':
            console.log('Create product error', action.err);
            return{
                ...state,
                productError: action.err.message
            }
        case 'CREATE_PRODUCT':
            console.log('Created new product', action.product);
            return{
                ...state,
                data: {...state.data, ...action.product.data},
                productError: null,
                validation: 'Passed'
            }
        case 'PRODUCT_DUPLICATE':
            console.log('Duplicate product', action.product);
            return{
                ...state, 
                duplicate: [...state.duplicate, action.product],
                productError: 'Product already exist with this SKU'
            }
        case 'EXPORT_PRODUCT_ERROR':
            console.log('Export product error', action.err);
            return{
                ...state,                                
                exportStat: {...state.exportStat, 
                    failed: state.exportStat.failed + 1},
                productError: action.err.message
            }
        case 'EXPORT_PRODUCT_QUEUED':
            console.log('Total products queued for export', action.queued);
            return{
                ...state,
                duplicate: [],
                exportStat: {...state.exportStat, 
                    queued: action.queued,
                    uploaded: 0,
                    failed: 0,
                    control: 'open'
                }
            }
        case 'EXPORT_PRODUCT':
            console.log('Exported new product', action.product);
            return{
                ...state,
                data: {...state.data, ...action.product.data},                    
                exportStat: {...state.exportStat, 
                    uploaded: state.exportStat.uploaded + 1},
                productError: null
            }
        case 'EXPORT_PRODUCT_DUPLICATE':
            console.log('Export failed, duplicate product', action.product);
            return{
                ...state, 
                duplicate: [...state.duplicate, action.product],                                 
                exportStat: {...state.exportStat, 
                    failed: state.exportStat.failed + 1 },
                productError: null
            }
        case 'UPDATE_PRODUCT_ERROR':
            console.log('Update product error', action.err);
            return{
                ...state,
                productError: action.err.message
            }
        case 'UPDATE_PRODUCT':
            console.log('Updated product', action.product);
            return{
                ...state,
                data: {...state.data, ...action.product.data},
                productError: null,
                validation: 'Passed'
            }
        case 'LOAD_PRODUCTS':
            console.log('Loading products', action.products);
            return{
                ...state,
                data: {...state.data, ...action.products.data},
                filteredData: {},
                loadData: false
            }
        case 'FILTER_PRODUCTS':
            console.log('Loading products', action.products);
            return{
                ...state,
                filteredData: action.products.data
            }
        case 'LOAD_ERROR':
            console.log('Loading products', action.data);
            return{
                ...state,
                prodcutError: null
            }
        case 'GET_PRODUCT_DETAILS':
            console.log('Loading products', action.products);
            return{
                ...state,
                data: {...state.data, ...action.products.data}
            }
        case 'RESET_VALIDATION':
            return{
                ...state,
                validation: null
            }
        case 'CLOSE_CONTROL':
            console.log('Closing export control');
            return{
                ...state,                                
                exportStat: {...state.exportStat, 
                    control: 'close' },
                validation: null
            }
        default:
            return state;
    }
}

export default productReducer;