import React, { useEffect } from 'react'; 
import { Link, Redirect } from 'react-router-dom';
import {  useSelector, useDispatch } from 'react-redux';
import { loadDivisions, resetValidation } from '../../store/actions/divisionActions';
import DivisionList from './DivisionList';

let fetchData = true;
let timer = null;
const pageInfo = { nameFilter: ''}; 
const pagination = {
    pageNumber: 0,
    limit: 6,
    orderBy: 'asc',
    cursor: ''
};      

const Divisions = () => {    
    const dispatch = useDispatch();

    useEffect(() => {
        /*User rudex hook to dispatch data fetch */
        if(fetchData) dispatch(loadDivisions('',pagination,pageInfo));
        dispatch(resetValidation());
        fetchData = false;
    });

    /*
    Get input from text fields and apply filtered list 
    Set a time delay of 1 sec to fetch data
    */
    const handleOnChange = (e) => {
        clearTimeout(timer);
        pageInfo.nameFilter = e.target.value;  
        timer = setTimeout(() => {
            triggerChange(pageInfo.nameFilter)
            }, 1000);      
    }

    const triggerChange = (search) => {
        pagination.pageNumber = 0;
        pagination.orderBy = 'asc';        
        pagination.cursor ='';
        dispatch(loadDivisions(search, pagination, pageInfo));
    }
   
    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);
    //const division = useSelector(state =>  state.division.data);
    const data = useSelector(state =>  state.division.data);
    const filteredData = useSelector(state => state.division.filteredData);
    const division = Object.keys(filteredData).length !== 0  ? filteredData : data;
    
    /*Create Array list from object collection */
    const orderedArray = Object.keys(division).map((key) => [key, division[key]]);    
       
    let ordered = [];
    orderedArray.map(item => {    
        ordered.push(item.pop());
        return null;
    });       

    /*Filter out deleted divisions */
    ordered = ordered.filter(item => {
        return item.status !== 'deleted'
    });

    /*Get first and last doc name for pagination */
    // let firstDoc = '';
    // let lastDoc = '';
    // if(ordered){   
    //     ordered.map( (item) => {
    //         if(firstDoc === '') firstDoc =  item.name;
    //         lastDoc = item.name;
    //         return null;
    //      } );
    // }

    /* Check authentication and redirect to login page */
    if(!auth.uid) return <Redirect to='/signin'/>; 
    return ( 
        <div className="section division">
            <div className="row">                                        
                <div className="col s12">
                    <div className="card">
                        <div className="card-content sec-head">                            
                            <div className="row">
                                <div className="input-field col s12 m6, l6">
                                   <span className="card-title red-text text-lighten-1">
                                       {/* <span className="btn btn-floating pink lighten-1">
                                           <i className="material-icons">store</i>
                                        </span>  */}
                                        Manage Divisions
                                    </span>
                                </div>
                                <div className="input-field col s12 m6, l6">
                                    <i className="material-icons prefix">search</i>
                                    <input onChange={handleOnChange} type="text" defaultValue="" placeholder="Search Division"/>
                                </div>
                            </div>
                            <div className="section sec-details">                
                                <DivisionList div={ordered}/>
                                
                                <div className="fixed-action-btn floting-bottom-left">
                                    <Link to='/crdiv' className="btn-floating btn-large halfway-fab waves-effect waves-light red" fab="top"><i className="material-icons">add</i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
     );
}
 
export default Divisions;
