const collection = 'users';
const account = 'organizations';

export const signIn = (cred) => {
    return(dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().signInWithEmailAndPassword(
            cred.email,
            cred.password
        ).then((res) => {
            dispatch({type: 'LOGIN_SUCCESS'});
        }).catch((err) => {
            dispatch({type: 'LOGIN_ERROR', err});
        });
    }
}

export const signOut = () => {
    return(dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().signOut()
            .then(() => {
                dispatch({type: 'SIGNOUT_SUCCESS'});
            })
            .catch((err) => {
                dispatch({type: 'SIGNOUT_ERROR', err});
            });
    }
}

export const signUp = (newUser) => {
    return(dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const firestore = firebase.firestore();
        let user = {};
        let uid = '';
        const search = newUser.account.toLowerCase().replace(/\W/g, "");
        if(search !== ''){            
            firestore.collection(account)
            .where("keywords", "array-contains", search)
            .get()
            .then((res) => {
                let orgId = ''            
                res.docs.map(doc => {
                    orgId = doc.id;
                    return null;
                });

                if(orgId !== ''){
                    firestore.collection(account)
                    .doc(orgId)
                    .collection(collection)
                    .where('email', '==', newUser.email)
                    .get()
                    .then((snapshot) => {
                        let userId = ''; /** id of user from users collection under organizations */
                        snapshot.docs.map(doc => {
                            userId = doc.id;
                            user = {
                                ...doc.data(),
                                id: doc.id
                            };
                            return null;
                        });
                        if(userId !== ''){
                            firebase.auth().createUserWithEmailAndPassword(
                                newUser.email,
                                newUser.password
                            )
                            .then((res) => {
                                uid = res.user.uid; /** id of user from auth */
                                return firestore.collection('users').doc(res.user.uid).set({
                                    firstName: newUser.firstName,
                                    lastName: newUser.lastName,
                                    initials: newUser.firstName[0] + newUser.lastName[0],
                                    privilege: user.privilege,                                
                                    account: orgId,
                                    userId: userId
                                })
                            })
                            .then(() => {         
                                /** Update uid from firebase in users collecton under organizations */                  
                                const userRef = firestore.collection(account)
                                .doc(orgId)
                                .collection(collection)
                                .doc(user.id);
    
                                userRef.update({uid: uid});
    
                                const statRef = firestore.collection('stat').doc('userStat');
                                const increment = firebase.firestore.FieldValue.increment(1);
                                statRef.update({count: increment});
    
                                dispatch({type: 'SIGNUP_SUCCESS'});
                            })
                            .catch((err) => {
                                dispatch({type: 'SIGNUP_ERROR', err})
                            });
                        }else{
                            dispatch({type: 'SIGNUP_ERROR', err: {message: 'Account not found, contact your administator'}});
                        }
                        
                    })
                }else{
                    dispatch({type: 'SIGNUP_ERROR', err: {message: 'Account not found, contact your administator'}});
                }
                
            })
        }else{
            dispatch({type: 'SIGNUP_ERROR', err: {message: 'Account not found, contact your administator'}});
        }       
    }
}

export const forgotPassword = (cred) => {
    return(dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail (
            cred.email
        )
        .then(() => {
            dispatch({type: 'RESET_PASSWORD_SUCCESS'})
        })
        .catch((err) => {
            dispatch({type: 'RESET_PASSWORD_ERROR', err})
        });
    }
}

export const clearMessages = () => {
    return(dispatch, getState, { getFirebase }) => {
        dispatch({type: 'CLEAR_MESSAGES', err: {message: ''}});
    }
}