import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import Pagination from '../../utils/components/buttons/Pagination';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import RMAList from './RMAList';
import {GetRMAList, resetValidation, StatusSelection, ClearSearch, DeleteRMA } from '../../store/actions/rmaActions';


const pageInfo = { 
    statusFilter: '', 
    dateFilter: {
        startDate: '', 
        endDate: ''}, 
    customerFilter: '',
    rmaNumber: '',
    serialNumber: '',
    productName: ''
}; 
const pagination = {
    pageNumber: 0,
    limit: 10,
    orderBy: 'asc',
    cursor: 0,
    currentPage: 1,
    lastPage: 1,
    totalRecords: 0
}; 

const RMA = (props) => {
    const dispatch = useDispatch();
    // const choice = props.match.params.ch;
    const [statusFilter, setStatusFilter] = useState(''); 
    const [clearField, setClearField] = useState();
    // const [rmaNumber, setRMANumber] = useSate('');
    // const [serialNumber, setSerialNumber] = useSate('');
    // const [productName, setProductName] = useState('');
    const [filter, setFilter] = useState({
        rmaNumber: '',
        serialNumber: '',
        productName: ''
    })

    useEffect(() => {
        Materialize.AutoInit();

        const startDatePicker = document.querySelector('.startDatepicker');
        Materialize.Datepicker.init(startDatePicker, {
            autoClose: true,
            format: "yyyy-mm-dd",
            onSelect: function () {
                //console.log(this);
                handleStartDateSelect(this.date);
            }
        });
        const endDatePicker = document.querySelector('.endDatepicker');
        Materialize.Datepicker.init(endDatePicker, {
            autoClose: true,
            format: "yyyy-mm-dd",
            onSelect: function () {
                //console.log(this);
                handleEndDateSelect(this.date);
            }
        });
    });

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);
    const profile = useSelector(state => state.firebase.profile);
    const pageStatus = useSelector(state => state.rma.pageInfo);
    const data = useSelector(state => state.rma.data);

    useEffect(() => {        
        dispatch(resetValidation());
    });
    
    useEffect(() => {
        if(profile.account !== undefined){ 
            dispatch(GetRMAList(profile.account,pageInfo, pagination));
        }
    },[dispatch, profile.account]);

    /* Check authentication and redirect to login page */
    if(!auth.uid) return <Redirect to='/signin'/>;

    const handleCustomerSelection = (tags) => {
        //console.log('tags',tags);        
        setClearField();
        pageInfo.customerFilter = tags.label;
    }

    const handleChange = (e) => {
        //console.log('pageinfo', filter);
        setFilter({
            ...filter,
            [e.target.id]: e.target.value
        });
    }
    
    const handleStatusSelection = (e) => {
        //console.log(e.target.value);
        setStatusFilter(e.target.value);
        pageInfo.statusFilter = e.target.value;
    }
    
    const handleStartDateSelect = (date) => {
        //console.log('date picked', date);
        pageInfo.dateFilter = {
            ...pageInfo.dateFilter,
            startDate: date.toLocaleDateString('en-CA')
        }
    }
    
    const handleEndDateSelect = (date) => {
        //console.log('date picked', date);
        pageInfo.dateFilter = {
            ...pageInfo.dateFilter,
            endDate: date.toLocaleDateString('en-CA')
        }
    }
    
    const handleSearch = () => {        
        dispatch(StatusSelection(5));
        pageInfo.rmaNumber = filter.rmaNumber;
        pageInfo.serialNumber = filter.serialNumber;
        pageInfo.productName = filter.productName;
        dispatch(GetRMAList(profile.account,pageInfo, pagination));
    }
    
    const handleClear = () => {
        pageInfo.statusFilter = '';
        pageInfo.dateFilter = {
            startDate: '',
            endDate: ''
        }
        pageInfo.customerFilter = '';
        pagination.cursor = 0;
        pagination.pageNumber = 0;
        setStatusFilter('');
        setFilter({
            rmaNumber: '',
            serialNumber: '',
            productName: ''
        })
        document.querySelector('.startDatepicker').value = '';
        document.querySelector('.endDatepicker').value = '';
        setClearField({label:  'Search by customers'});
        dispatch(ClearSearch());
    }
    
    const handleNextPage = (cursor) => {     
        //console.log('pageNumber',pagination.lastPage , pagination.pageNumber);  
        const currentPage =  pageStatus.currentPage ? pageStatus.currentPage + 1 : 1;
        if(pageStatus.lastPage > currentPage){
            pagination.pageNumber++;
            pagination.orderBy = 'asc';    
            dispatch(GetRMAList(profile.account, pageInfo, pagination));
        }
    }
    
    const handlePreviousPage = (cursor) => {
        //console.log('pageNumber',pagination.pageNumber);        
        if(pagination.pageNumber > 0){
            pagination.pageNumber--;        
            pagination.orderBy = 'desc';
            dispatch(GetRMAList(profile.account, pageInfo, pagination));
        } 
    }

    const deleteHandler = (rmaId) => {
        dispatch(DeleteRMA(profile.account, rmaId));
    }

    return ( 
        <div className="section rma">
            <div className="row">                                        
                <div className="col s12 p-0">
                    <div className="card">
                        <div className="card-content sec-head">                            
                            <div className="row">                               
                                <div className="input-field col s12 m3 l3">
                                    <i className="material-icons prefix">search</i>
                                    <AutoComplete
                                    onSelected={handleCustomerSelection} 
                                    collection={'customerCode'} search={'name'} account={profile.account}
                                    placeholder={'Search by customers'}
                                    value={clearField}
                                    clearSearchParam={false}
                                    />
                                </div> 
                                {/* <div className="input-field col s12 m9 l9 rma-cr"> */}
                                    <div className="row">
                                        <div className="input-field col s12 m2 l2">
                                            <label htmlFor="RMA Number">RMA Number</label>
                                            <input id='rmaNumber' onChange={handleChange} value={filter.rmaNumber} type="number"></input>
                                        </div>                                        
                                        <div className="input-field col s12 m2 l2">
                                            <label htmlFor="Serial Number">Serial Number</label>
                                            <input id='serialNumber' onChange={handleChange}  value={filter.serialNumber} type="text" className="all-cap"></input>
                                        </div>                                                                               
                                        <div className="input-field col s12 m4 l4">
                                            <label htmlFor="Product Name">Product Name</label>
                                            <input id='productName' onChange={handleChange} value={filter.productName} type="text" className="all-cap"></input>
                                        </div>
                                        <div className="input-field col s12 m2 l2">
                                            <label htmlFor="Date From">Date from</label>
                                            <input id='sartDate' type="text" className="startDatepicker"></input>
                                        </div>
                                        <div className="input-field col s12 m2 l2">
                                            <label htmlFor="Date To">Date to</label>
                                            <input id='endDate' type="text"  className="endDatepicker"></input>
                                        </div>
                                        <div className="input-field col s12 m3 l3">
                                            <select onChange={handleStatusSelection} className="browser-default" value={statusFilter}>
                                                <option value="" disabled>Select RMA Status</option>
                                                <option value="all">All</option>
                                                <option value="0">Waiting for Repairs</option>
                                                <option value="1">In Process</option>                                                   
                                                <option value="2">Ready for Collection</option> 
                                                <option value="3">Waiting for Parts</option>  
                                                <option value="4">Waiting for Customer Confirmation</option>  
                                                <option value="5">Cancelled</option>
                                                <option value="9">Closed</option>      
                                            </select>                                    
                                        </div>
                                        <div className="input-field col s12 m4 l4 mt-2">                                   
                                            <Link to='#' onClick={handleSearch} className="orange waves-light btn"><i className="material-icons left">search</i>SEARCH</Link>
                                            <Link to='#' onClick={handleClear} className="grey waves-light btn"><i className="material-icons left">clear</i>CLEAR</Link>
                                        </div>
                                    {/* </div>  */}
                                </div>
                            </div>
                                                       
                            <RMAList
                            data = {data}
                            deleteHandler={deleteHandler}
                            profile={profile}
                            />
                        </div>
                        <div className="card-action">
                            <Pagination
                            pageNumber = {pageStatus.currentPage}
                            firstDoc = ''
                            lastDoc = ''
                            onNextPageClick={handleNextPage} 
                            onPreviousPageClick={handlePreviousPage}
                            enablePrevious = { true }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed-action-btn floting-bottom-right">
                <Link to='/crrma' className="btn-floating btn-large halfway-fab waves-effect waves-light red" fab="top"><i className="material-icons">add</i></Link>
            </div>
        </div>
     );
}
 
export default RMA;