import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { updateCustomer } from '../../store/actions/customerActions';

const CustomerDetails = (props) => {
    const dispatch = useDispatch();
    
    const [componentState, setComponentState] = useState({});
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth); 
    const customer = useSelector(state => state.customer.data[props.match.params.id]);   
    const validation = useSelector(state => state.customer.validation); 
    const customerError = useSelector(state => state.customer.customerError); 
    
    useEffect(() => {
        //console.log(customer);  
        if(customer){
            setComponentState({
                code: customer.code,
                name: customer.name,
                address: customer.address,
                city: customer.city,
                phone: customer.phone,
                email: customer.email
            })
            setCode(customer.code);
            setName(customer.name);
            setAddress(customer.address);
            setCity(customer.city);
            setPhone(customer.phone);
            setEmail(customer.email);
        }
    },[customer]);    

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/cus'/>;  

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Previous state');
        //console.log('props state',componentState);
        dispatch(updateCustomer(componentState, props.match.params.id));
    }
    return ( 
        <div className="container customer">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">Update Customer</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/cus' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Customer Code">Customer Code</label>
                        <input defaultValue={code} id="code" onChange={handleChange} type="text" disabled/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Name">Name</label>
                        <input defaultValue={name} id="name" onChange={handleChange} type="text" className="validate" required/>
                    </div>                   
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="City">City</label>
                        <input defaultValue={city} id="city" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Phone">Phone</label>
                        <input defaultValue={phone} id="phone" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Email">Email</label>
                        <input defaultValue={email} id="email" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Address">Address</label>
                        <textarea id="address" defaultValue={address} onChange={handleChange} className="validate materialize-textarea" required></textarea>
                        {/* <input defaultValue={address} id="address" onChange={handleChange} type="text" className="validate" required/> */}
                    </div>   
                </div>
                <div className="input-field">
                    <button className="btn red lighten-1 z-depth-0">Update</button>                        
                    <div className="red-text center">
                        {customerError ? <p>{customerError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default CustomerDetails;