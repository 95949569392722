import React from 'react';
import UserStatus from './UserStatus';
import UserTilte from './UserTitle';

const UserSummary = ({user, divisions, onDeleteUser}) => {
    const handleDeleteUser = (user, id) => {
        onDeleteUser(user, id);
    } 
    return (
        <div className="col s12 m4 l4">
            <div className="card z-depth-1 user-summary">                
                <UserTilte 
                    user={user} onDeleteUser={(user, id) => {handleDeleteUser(user, id)}} 
                    showEdit={true}
                />
                <div className="card-action card-bottom-badge">
                    <UserStatus user={user} divisions={divisions} />
                </div>
            </div>
        </div> 
     );
}
 
export default UserSummary;