import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

//import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import AutoSelect from '../../utils/components/autoComplete/AutoSelect';
import { loadUsers } from '../../store/actions/userActions';
import { updateDivision } from '../../store/actions/divisionActions';
import Members from './Members';
import {CloseLinkButton } from '../../utils/components/buttons/LinkButtons';

let getUsersList = true; 

const DivisionDetails = (props) => {    
    const dispatch = useDispatch();
    
    //const {divisionError, validation} = props;
    const [componentState, setComponentState] = useState({});
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [managerId, setManagerId] = useState('');
    const [manager, setManager] = useState('');

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);    
    const users = useSelector(state => state.user.data);
    const userPage = useSelector(state => state.user.pageInfo);
    const divisions = useSelector(state => state.division.data);
    const division = useSelector(state => state.division.data[props.match.params.id]);   
    const validation = useSelector(state => state.division.validation); 
    const divisionError = useSelector(state => state.division.divisionError); 

    /*Check users state and if empty fecth list */
    useEffect(() => {
        if(Object.keys(userPage).length === 0 && getUsersList) {
            dispatch(loadUsers('',
            {
                pageNumber: 0,
                limit: 6,
                orderBy: 'asc',
                cursor: ''
            },
            { nameFilter: ''}));
        }
        getUsersList = false;
    })
    
    useEffect(() => {
        if(division){
            if(division.members) {
                setComponentState({
                    name: division.name,
                    location: division.location,
                    managerId: division.managerId,
                    manager: division.manager,
                    members: division.members,
                    membersList: division.membersList
                })
            }else{
                setComponentState({
                    name: division.name,
                    location: division.location,
                    managerId: division.managerId,
                    manager: division.manager,
                    members: {},
                    membersList: []
                })
            }
            setName(division.name);
            setLocation(division.location);
            setManagerId(division.managerId);
            setManager(division.manager);
        }
        return() => {
            //console.log('unmount');
        }
    },[division]);    
    
    getUsersList = Object.keys(userPage).length === 0 ? true : userPage.getUsersList;

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed' || !division) return <Redirect to='/div'/>;

    /** Handle default values in controls */
    let selectedManager = '';
    let approvalUser ='';
    let warehouse = '';
    let finDep = '';
    //let selectedOrganization = '';
    if(division){
        //selectedOrganization = division.orgName;
        selectedManager = division.manager;
        approvalUser =  division.organizationalApproval ? division.organizationalApproval.name : '';
        warehouse = division.store;
        finDep = division.financialDepartment;
    } 

    /*Create Array list from object collection */
    const orderedArray = Object.keys(users).map((key) => [key, users[key]]);   
    const ordered_div = Object.keys(divisions).map((key) => [key, divisions[key]]);

    let orderedDiv = [];
    ordered_div.map(item => {    
        orderedDiv.push(item.pop());
        return null;
    }); 

    /*Generate division list for search */
    let divisionList = [];
    if (orderedDiv.length > 0) {              
        orderedDiv.forEach((doc) => {
            const tag = {
                value: doc.id,
                label: doc.name
            }
            divisionList.push(tag)
        });
    }  
        
    let ordered = [];
    orderedArray.map(item => {
        ordered.push(item.pop());
        return null;
    });
    
    /*Generate users list for search */
    let userList = [];
    if (ordered.length > 0) {              
        ordered.forEach((doc) => {
            const tag = {
                value: doc.id,
                label: doc.firstName + ' ' + doc.lastName
            }
            userList.push(tag)
        });
    }  

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault(); 
        console.log('Previous state', {manager , managerId});
        dispatch(updateDivision(componentState, props.match.params.id));
    }

    // const handleOrganizationSelection = (tags) => {
    //     setComponentState({
    //         ...componentState,            
    //         orgId: tags.value,
    //         orgName: tags.label
    //     })
    // }
    const handleApprovalSelection = (tags) => {
        let approvalUser = ordered.filter(m => {
            return m.id === tags.value
        }); 
        const orgApproval = {
            id: approvalUser[0].id,
            name: approvalUser[0].firstName + ' ' + approvalUser[0].lastName,
            designation: approvalUser[0].designation
        };
        setComponentState({
            ...componentState,           
            organizationalApprovalFrom: approvalUser[0].uid,
            organizationalApproval: orgApproval  
        });
    }

    const handleStoreSelection = (tags) => {
        setComponentState({
            ...componentState,           
            storeId: tags.value,
            store: tags.label  
        });
    }

    const handleFinSelection = (tags) => {
        setComponentState({
            ...componentState,           
            finacialDepId: tags.value,
            financialDepartment: tags.label  
        });
    }

    const handleManagerSelection = (tags) => {
        if(division){             
            const memb = componentState.members[tags.value];
            if(memb){ 
                //console.log('is memeber');
                /** Check selected user is the existing manager, then skip action */ 
                if(memb.reportingLevel !== 1){
                    console.log('existing mangager', division.manager);
                    /** Change existing manager level to user */
                    // componentState.members[componentState.managerId].reportingLevel = 2;
                    division.membersList.map(m => {
                        componentState.members[m].reportingLevel = 2;
                        return null;
                    });
                    
                    /** Change manager with new user */
                    let approvalUser = ordered.filter(m => {
                        return m.id === tags.value
                    }); 
                    const divApproval = {
                        id: approvalUser[0].id,
                        name: approvalUser[0].firstName + ' ' + approvalUser[0].lastName,
                        designation: approvalUser[0].designation
                    };

                    componentState.members[tags.value].reportingLevel = 1;
                    setComponentState({
                        ...componentState,           
                        managerId: tags.value,
                        manager: tags.label,           
                        divisionalApprovalFrom: approvalUser[0].uid,
                        divisionalApproval: divApproval  

                    })
                }
            }
            else {
                //console.log('is not memeber');
                 /** Change existing manager level to user */
                //componentState.members[componentState.managerId].reportingLevel = 2;
                division.membersList.map(m => {
                    componentState.members[m].reportingLevel = 2;
                    return null;
                });
                
                /** Create a new member and  change manager with new member */
                const newMember = {data:{}}
                const docData = {
                    id: tags.value,
                    reportingLevel: 1
                };
                const key = tags.value;
                newMember.data[key] = docData;

                let approvalUser = ordered.filter(m => {
                    return m.id === tags.value
                }); 
                const divApproval = {
                    id: approvalUser[0].id,
                    name: approvalUser[0].firstName + ' ' + approvalUser[0].lastName,
                    designation: approvalUser[0].designation
                };
                setComponentState({
                    ...componentState,           
                    managerId: tags.value,
                    manager: tags.label,            
                    divisionalApprovalFrom: approvalUser[0].uid,
                    divisionalApproval: divApproval,  
                    membersList: [...componentState.membersList, tags.value],
                    members: {...componentState.members,...newMember.data }
                });
            }

            // if(!same){
            //     /** Removing exsiting manager */
            //     let newMembers = componentState.members.filter(m => {
            //         return m.reportingLevel !== 1
            //     });  
                
            //     /** Removing existing user if already exists */
            //     newMembers = newMembers.filter(m => {
            //         return m.id !== tags.value
            //     });  
                
            //     /** Add selected user as manager */
            //     let member =  newMembers.find(m => {
            //         return m.id === tags.value 
            //     }); 
                
            //     member = {
            //         id: tags.value,
            //         reportingLevel: 1
            //     };

            //     setComponentState({
            //         ...componentState,           
            //         managerId: tags.value,
            //         manager: tags.label,
            //         members: [...newMembers, member]
            //     })
            // }else{
            //     setComponentState({
            //         ...componentState,            
            //         managerId: tags.value,
            //         manager: tags.label
            //     })
            // }
        }
    }
    
    const handleUserSelection = (tags) => {
        //console.log('componentState',componentState);
        if(division){
            /** Find user from the existing collection */            
            if(!componentState.members[tags.value]){
                const newMember = {data:{}}
                const docData = {
                    id: tags.value,
                    reportingLevel: 2
                };
                const key = tags.value;
                newMember.data[key] = docData;
                setComponentState({
                    ...componentState,    
                    membersList: [...componentState.membersList, tags.value],
                    members: {...componentState.members,...newMember.data }
                });
            }
        }
    }

    const handleMemberRemove = (id) => {
        /** Removing exsiting user */
        const newList = componentState.membersList.filter(m => {
            return m !== id
        });  
        setComponentState({
            ...componentState,    
            membersList: newList
        });
        delete componentState.members[id];
    }

    return ( 
        <div className="container division">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s10">
                        <h5 className="red-text text-lighten-1">Update Division</h5>
                    </div>
                    <div className="col s2 btn-gr">
                        <CloseLinkButton to='/div'/>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m6 l6">                        
                        <div className="input-field">
                            <label className="active" htmlFor="Division Name">Division Name</label>
                            <input id="name" defaultValue={name} onChange={handleChange} type="text" className="valicate" required/>
                        </div>
                        <div className="input-field">
                            <label className="active" htmlFor="Location">Location</label>
                            <input id="location" defaultValue={location} onChange={handleChange} type="text" className="valicate" required/>
                        </div>
                        <div className="row">
                            <div className="col s5 m4 l4">
                                <blockquote>
                                    <h5 className="">Members</h5>
                                </blockquote>                                
                            </div>  
                            <div className="col s7 m8 l8">                                
                                {/* <div className="input-field div-sel">
                                    <label className="active" htmlFor="Users">Add Members</label>                   
                                    <AutoSelect className="search" onSelected={handleUserSelection}  data={userList} placeholder='Select users to add'/>
                                </div>  */}
                            </div>  
                            <div className="col s12">
                                <Members 
                                members={componentState.members} 
                                userList={ordered}
                                divisions={orderedDiv}
                                onRemove={handleMemberRemove}/>
                            </div>                        
                        </div>                      
                        
                        
                    </div>
                    <div className="col s12 m6 l6"> 
                        <div className="card">
                            <div className="card-content">
                                {/* <div className="input-field div-sel">
                                    <label className="active" htmlFor="Organizational Unit">Organizational Unit</label>
                                    <AutoComplete defaultInputValue={selectedOrganization} onSelected={handleOrganizationSelection} collection={'organizations'} search={'name'} placeholder='Select organization'/>
                                </div>                     */}
                                <div className="input-field div-sel">
                                    <label className="active" htmlFor="Manager">Manager</label>                   
                                    <AutoSelect className="search" defaultInputValue={selectedManager}  onSelected={handleManagerSelection} data={userList} placeholder='Select manager'/>
                                </div> 
                                <div className="input-field div-sel">
                                    <label className="active" htmlFor="Users">Add Members</label>                   
                                    <AutoSelect className="search" onSelected={handleUserSelection}  data={userList} placeholder='Select users to add'/>
                                </div> 
                                <div className="input-field div-sel">
                                    <label className="active" htmlFor="Organizational Approval">Organizational Approval</label>                   
                                    <AutoSelect className="search" onSelected={handleApprovalSelection} defaultInputValue={approvalUser}  data={userList} placeholder='Select user for organizational approval'/>
                                </div>
                                <div className="input-field div-sel">
                                    <label className="active" htmlFor="Store">Warehouse</label>                   
                                    <AutoSelect className="search" onSelected={handleStoreSelection} defaultInputValue={warehouse}  data={divisionList} placeholder='Select warehouse'/>
                                </div>                                
                                <div className="input-field div-sel">
                                    <label className="active" htmlFor="Select Finance Department">Finance Department</label>                   
                                    <AutoSelect className="search" onSelected={handleFinSelection} defaultInputValue={finDep}  data={divisionList} placeholder='Select finance department'/>
                                </div>
                            </div>
                        </div> 
                        <div className="input-field right">
                            <button onSubmit={handleSubmit} className="btn red lighten-1 z-depth-0">Update</button>                        
                            <div className="red-text center">
                                {divisionError ? <p>{divisionError}</p> : validation ? <p>{validation}</p> : null}
                            </div>
                        </div>
                                               
                    </div>
                </div>  
            </form>
        </div>
     );
}
 
export default DivisionDetails;