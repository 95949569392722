import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

const SignedInLinks = (props) => {
    return (
        <div>
             <Link to='' data-target="slide-out" className="sidenav-trigger show-on-large grey-text">
                <i className="material-icons">menu</i>
            </Link>
            <div className="right">
                <li><Link to='/' className="btn btn-floating pink lighten-1">{props.profile.initials}</Link></li>
            </div>
            <ul id="nav-mobile">
                <li><NavLink to='' className="htl-brand-logo red-text text-lighten-1">HTL Core</NavLink></li>  
                <div className="right hide-on-med-and-down">
                    <li><Link to='' onClick={props.signOut} className="grey-text text-darken-3">Log Out</Link></li>
                </div>  
            </ul>
        </div> 
        
     );
}

const mapStateToProps = (state) => {
    return{

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        signOut: () => dispatch(signOut())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks);