const initState = {
    data: null,
    stat: {},
    loadData: true,
    pageInfo: {},
    choice:'all',
    items: [],
    divisions: null,
    validation: null
}

const orderReducer = (state= initState, action) => {
    switch(action.type){
        case 'ORDER_ERROR':
            console.log('Error in getting order API', action.err);
            return{
                ...state,
                items: [],
                orderError: action.err.message
            }
        case 'LOAD_DIVISIONS':
            console.log('Loading user assigned division', action.divisions);
            return{
                ...state,
                divisions: action.divisions
            }
        case 'ORDER_STAT':
            console.log('Loading order status', action.stat);
            return{
                ...state,
                stat: action.stat,
                orderError: null
            }
        case 'ORDER_LIST':
            console.log('Loading order list', action.data);
            return{
                ...state,
                data: action.data.Orders,
                pageInfo: {
                    ...state.pageInfo, 
                    currentPage: action.data.CurrentPage,
                    lastPage: action.data.LastPage,
                    totalRecords: action.data.TotalRows
                },
                orderError: null
            }
        case 'ORDER_ITEMS':
            console.log('Loading order items', action.items);
            return{
                ...state,
                items: action.items,
                orderError: null
            }
        case 'ORDER_UPDATE':
            console.log('Order details updated', action.order);
            return{
                ...state,
                data: [...state.data, action.order],
                orderError: null
            }
        case 'SET_CHOICE':
            console.log('Reset choice', action.choice);
            return{
                ...state,
                choice: action.choice
            }
        case 'CLEAR':
            console.log('Clear items');
            return{
                ...state,
                items: []
            }
        case 'RESET_VALIDATION':
            return{
                ...state,
                validation: null
            }
        case 'CLOSE_CONTROL':
            console.log('Closing order control');
            return{
                ...state,                                
                orderStat: {...state.orderStat, 
                control: 'close' },
                validation: null
            }
        default:
            return state;
    }
}

export default orderReducer;