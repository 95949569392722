import pdfMake from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";  
import moment from 'moment';

const collection = 'ious';
const account = 'organizations';

const buildTableBody = (data, columns, heading, tableFooter) => {
    let body = [];

    body.push(heading);

    let i = 1;
    data.forEach(row => {
        let dataRow = [];
        //console.log('row',row);
        dataRow.push(i++);
        columns.forEach(column => {
            //console.log('column',column);
            if(column === 'price' || column === 'netAmount'){                
                dataRow.push({text: parseFloat(row[column]).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2}), alignment: 'right'});
            }else{
                dataRow.push(row[column].toString());
            }
            
        })

        body.push(dataRow);
    });
    for(let cnt = i; cnt < 9; cnt++ ){
        body.push([{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '},{text: ' '}]);
    }
    body.push(tableFooter);

    return body;
}

const table = (data, columns, heading, tableFooter) => {
    return {
        style: 'table',
        table: {
            widths: [20, 180, 40, 80, 80, 60],
            headerRows: 1,
            body: buildTableBody(data, columns, heading, tableFooter)
        }
    };
}

export const createIOUDraft = (iouDraft) => {
    //console.log('iou draft', iouDraft);
    return(dispatch, getState, { getFirebase }) => {
        const profile = getState().firebase.profile;
        const createdBy = getState().firebase.auth.uid;

        /*Make async call to database */
        const firestore = getFirebase().firestore();
        try{
            /** No records found - create product logic */                    
            const statRef = firestore.collection(account)
            .doc(profile.account)
            .collection('stat')
            .doc('iouStat');

            // const newIOURef = firestore.collection(account)
            // .doc(profile.account)
            // .collection(collection)
            // .doc();

            //console.log('iou id ', iouDraft.id);

            const iouRef = iouDraft.id === undefined ? 
            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc()
            :
            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc(iouDraft.id);

            firestore.runTransaction(async transaction => {
                let iouId = iouDraft.id === undefined ? 1 : iouDraft.id;
                if(iouDraft.id === undefined ){
                    const stat = await transaction.get(statRef);
                    const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                    transaction.update(statRef, {count: newIndex});

                    const res = await transaction.set(iouRef, {
                        ...iouDraft,
                        access: [createdBy],
                        index: newIndex,
                        date: new Date(),
                        creator_id: createdBy,
                        created_at: new Date(),
                        lastChangeBy: profile.firstName + ' ' + profile.lastName
                    });
                    iouId = newIndex;
                    res.md.mutations.map(key => {
                        //console.log('key', key.key.path.segments[3]);
                        if(key.key.path.segments[3] !== 'iouStat' ) iouId = key.key.path.segments[3];
                        return null;
                    }); 

                    const iou = {data:{}}
                    const docData = {
                        ...iouDraft, 
                        index: newIndex,
                        id: iouId
                    };
                    const key = iouId;
                    iou.data[key] = docData;
                    dispatch({type: 'CREATE_IOU', iou})
                }else{
                    transaction.update(iouRef, {
                        ...iouDraft,
                        modifier_id: createdBy,
                        modified_at: new Date(),
                        lastChangeBy: profile.firstName + ' ' + profile.lastName
                    });

                    const iou = {data:{}}
                    const docData = {
                        ...iouDraft 
                    };
                    const key = iouId;
                    iou.data[key] = docData;
                    dispatch({type: 'UPDATE_IOU', iou})
                }
               
            });

        }catch(err){
            dispatch({type:'CREATE_IOU_ERROR', err});
        }
        
    }
}

export const RequestDivisionalApproval = (newIOU) => {
    //console.log('iou draft', newIOU);
    return(dispatch, getState, { getFirebase }) => {
        const profile = getState().firebase.profile;
        const createdBy = getState().firebase.auth.uid;

        /*Make async call to database */
        const firestore = getFirebase().firestore();
        try{
            /** No records found - create product logic */                    
            const statRef = firestore.collection(account)
            .doc(profile.account)
            .collection('stat')
            .doc('iouStat');

            // const newIOURef = firestore.collection(account)
            // .doc(profile.account)
            // .collection(collection)
            // .doc();

            const iouRef = newIOU.id === undefined ? 
            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc()
            :
            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc(newIOU.id);
            

            firestore.runTransaction(async transaction => {
                let iouId = newIOU.id === undefined ? 1 : newIOU.id;        
                
                if(newIOU.id === undefined && newIOU.divisionalApprovalFrom !== undefined){  
                    console.log('iou draft', newIOU.id);
                    const stat = await transaction.get(statRef);
                    const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                    transaction.update(statRef, {count: newIndex});

                    const res = await transaction.set(iouRef, {
                        ...newIOU,
                        access: [createdBy, newIOU.divisionalApprovalFrom],
                        status: 'Approval Pending',
                        index: newIndex,
                        date: new Date(),
                        creator_id: createdBy,
                        created_at: new Date(),
                        lastChangeBy: profile.firstName + ' ' + profile.lastName
                    });
                    
                    iouId = newIndex;
                    res.md.mutations.map(key => {
                        //console.log('key', key.key.path.segments[3]);
                        if(key.key.path.segments[3] !== 'iouStat' ){
                            iouId = key.key.path.segments[3];                        
                        }  
                        return null;                 
                    });  
                    
                    /** Create iou approval request */
                    const newIOUApReqRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection('requests')
                    .doc('todos')
                    .collection('iou')
                    .doc(iouId);
                    transaction.set(newIOUApReqRef, {
                        requested_at: new Date(),
                        requested: newIOU.requested.name,
                        projectName: newIOU.projectName,
                        projectValue: newIOU.projectValue,
                        endUser: newIOU.endUser,
                        customer: newIOU.customerName,
                        psm: newIOU.psm,
                        orderExpected: newIOU.orderExpected,
                        iouAmount: newIOU.totalAmount,
                        iouPeriod: newIOU.period,
                        iouPurpose: newIOU.purpose,
                        todo: newIOU.divisionalApprovalFrom,                            
                        request: 'Approval'
                    });

                    /** Create data object for newly created iou to display in view */
                    const iou = {data:{}}
                    const docData = {
                        ...newIOU,   
                        status: 'Approval Pending', 
                        index: newIndex,
                        id: iouId
                    };
                    const key = iouId;
                    iou.data[key] = docData;
                    dispatch({type: 'CREATE_IOU', iou})
                }else if(newIOU.divisionalApprovalFrom !== undefined){
                    transaction.update(iouRef, {
                        ...newIOU,                        
                        access: [createdBy, newIOU.divisionalApprovalFrom],
                        status: 'Approval Pending',
                        modifier_id: createdBy,
                        modified_at: new Date(),
                        lastChangeBy: profile.firstName + ' ' + profile.lastName
                    });

                    /** Update iou approval request */
                    const newIOUApReqRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection('requests')
                    .doc('todos')
                    .collection('iou')
                    .doc(newIOU.id);
                    transaction.set(newIOUApReqRef, {
                        requested_at: new Date(),
                        requested: newIOU.requested.name,
                        projectName: newIOU.projectName,
                        projectValue: newIOU.projectValue,
                        endUser: newIOU.endUser,
                        customer: newIOU.customerName,
                        psm: newIOU.psm,
                        orderExpected: newIOU.orderExpected,
                        iouAmount: newIOU.totalAmount,
                        iouPeriod: newIOU.period,
                        iouPurpose: newIOU.purpose,
                        todo: newIOU.divisionalApprovalFrom,                            
                        request: 'Approval'
                    });

                    const iou = {data:{}}
                    const docData = {
                        ...newIOU,
                        status: 'Approval Pending'
                    };
                    const key = iouId;
                    iou.data[key] = docData;
                    dispatch({type: 'UPDATE_IOU', iou});
                }
                
            });

        }catch(err){
            dispatch({type:'CREATE_IOU_ERROR', err});
        }
        
    }
}

export const RequestDelivery = (id, signedDoc) => {
    return(dispatch, getState, { getFirebase }) => {        
        const modifier_id = getState().firebase.auth.uid;
        const profile = getState().firebase.profile;
        /**Make async call to database */
        const firebase = getFirebase();
        const firestore = getFirebase().firestore();
        try{
            /** Reference to iou document and request todo */ 
            const iouRef = firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc(id);

            const reqRef = firestore.collection(account)
            .doc(profile.account)
            .collection('requests')
            .doc('todos')
            .collection('iou')
            .doc(id);

            firestore.runTransaction(async transaction => {
                const iou = await transaction.get(iouRef);
                const request = await transaction.get(reqRef);
                
                const storeRef = firestore.collection(account)
                .doc(profile.account)
                .collection('divisions')
                .doc(request.data().storeId);

                const warehouse = await transaction.get(storeRef);  
                const store = warehouse.data();
                
                //console.log('warehouse manager', store.manager)
                if(store) {
                    const storeManagerRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection('users')
                    .doc(store.managerId);
                    const storeManager = await transaction.get(storeManagerRef);

                    const storageRef = firebase.storage().ref();
                    var uploadTask = storageRef.child(profile.account + '/iou/singedAgreement' + iou.data().index + '.pdf').put(signedDoc.file);

                    // Listen to state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                        function(snapshot) {

                            // Get task progress, inlcuding number of bytes uploaded and the total number of bytes to be uploaded

                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            dispatch({type: 'IOU_UPLOAD_PROGRESS', progress});

                        }, function(error){
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    console.log("storage/unauthorized", error)
                                    break;
                                case 'storage/canceled':
                                    // User canceled the upload
                                    console.log("storage/canceled", error)
                                    break;
                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    console.log("storage/unknown", error)
                                    break;
                                default:
                                    break;
                            }

                        }, function(){
                            // Upload completed successfully, now we can get the download URL
                            uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then((downloadURL) => {
                                /** Update iou with details of approval */
                                const documents = [...iou.data().documents, {
                                    name: 'Signed Agreement',
                                    dataURL: downloadURL,
                                    docDate: new Date()
                                }];
                                const access = [...iou.data().access, storeManager.data().uid];
                                iouRef.update({
                                    access: access,
                                    status: 'Delivery',
                                    storeManager: storeManager.data().uid,
                                    storeId: request.data().storeId,
                                    documents: documents,
                                    modifier_id: modifier_id,
                                    modified_at: new Date(),
                                    lastChangeBy: profile.firstName + ' ' + profile.lastName
                                });
                                /** Update approvel request - for customer sign */
                                reqRef.update({                                                
                                    todo: storeManager.data().uid,
                                    deliveryRequested_at: new Date(),                            
                                    request: 'Delivery',
                                    documents: documents
                                });                      
                            });
                    });
                }
            });

            dispatch({type: 'REQUEST_IOU_DELIVERY'});

        }catch(err){
            dispatch({type:'REQUEST_IOU_DELIVERY_ERROR', err});
        }
    }
}

export const ApproveIOU = (id, divisionId, messages, period) => {
    return(dispatch, getState, { getFirebase }) => {
        const profile = getState().firebase.profile;
        const auth = getState().firebase.auth;     
        const modifier_id = getState().firebase.auth.uid;

        /*Make async call to database */        
        const firebase = getFirebase();
        const firestore = getFirebase().firestore();
        try{
            /** No records found - create product logic */ 
            const divisionRef = firestore.collection(account)
            .doc(profile.account)
            .collection('divisions')
            .doc(divisionId);

            const iouRef = firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc(id);

            firestore.runTransaction(async transaction => {
                const division = await transaction.get(divisionRef);  
                const div = division.data();
                const iou = await transaction.get(iouRef);
                
                const reqRef = firestore.collection(account)
                .doc(profile.account)
                .collection('requests')
                .doc('todos')
                .collection('iou')
                .doc(id);
                
                /** Check approved user from division details */
                switch(auth.uid){
                    case div.divisionalApprovalFrom : 
                        /** Update iou with details of approval */
                        const access = [...iou.data().access, div.organizationalApprovalFrom];
                        transaction.update(iouRef, {
                            access: access,
                            period: period,
                            divisionalApproval: profile.firstName + ' ' + profile.lastName,
                            divisionalApproval_at: new Date(),
                            division: div.name,   
                            storeId: div.storeId,    
                            finacialDepId: div.finacialDepId,
                            messages: messages
                        });
                        /** Update approval request for organization approval */
                        transaction.update(reqRef, {
                            todo: div.organizationalApprovalFrom,
                            divisionalApproval: profile.firstName + ' ' + profile.lastName,
                            divisionalApproval_at: new Date(),
                            division: div.name,
                            requestedDivision: divisionId,   
                            storeId: div.storeId,    
                            finacialDepId: div.finacialDepId,                    
                            request: 'Approval'
                        });
                        break;
                    case div.organizationalApprovalFrom:
                        //console.log('organizationalApprovalFrom',div.organizationalApprovalFrom);
                        const orgRef = firestore.collection(account)
                        .doc(profile.account);

                        const org = await transaction.get(orgRef); 
                        
                        let ordered = [];
                        if(iou.data().productList){
                            const orderedArray = Object.keys(iou.data().productList).map((key) => [key, iou.data().productList[key]]);    
                        
                            orderedArray.map(item => {    
                                ordered.push(item.pop());
                                return null;
                            });     
                        }
                        
                        /** Create IOU document and save in storage */
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;  

                        var docDefinition = {
                            ownerPassword: '123456htl',
                            permissions: {
                                printing: 'highResolution', 
                                modifying: false,
                                copying: false,
                                annotating: true,
                                fillingForms: true,
                                contentAccessibility: true,
                                documentAssembly: true
                            },
                            info: {
                                title: 'IOU Agreement - ' + iou.data().index,
                                author: 'HTL',
                                subject: 'IOU Agreement',
                              },
                            content: [
                                { 
                                    image: org.data().logo,
                                    width: 120,
                                    height: 30
                                },
                                { text: 'IOU Agreement', style: 'header' },
                                {
                                    columns: [
                                        {
                                            width: 40,
                                            text: 'Date'
                                        },
                                        {
                                            width: 5,
                                            text: ':'
                                        },
                                        {
                                            width: 'auto',
                                            text: moment(iou.data().date.toDate()).format('MMMM Do YYYY')
                                        },
                                    ],
                                    style: 'para'
                                },
                                {
                                    columns: [
                                        {
                                            width: 40,
                                            text: 'To'
                                        },
                                        {
                                            width: 5,
                                            text: ':'
                                        },
                                        {
                                            width: 'auto',
                                            text: [
                                                {text : iou.data().applied.name},
                                                {text : '\n'+ iou.data().applied.address},
                                                {text : '\n Attn: ' + iou.data().contactPerson + ' - Mob: ' +  iou.data().contactNumber, bold: true}
                                            ]
                                            
                                        },
                                    ],
                                    style: 'para'
                                },
                                {
                                    columns: [
                                        {
                                            width: 40,
                                            text: 'From'
                                        },
                                        {
                                            width: 5,
                                            text: ':'
                                        },
                                        {
                                            width: 'auto',
                                            text: [
                                                {text : org.data().name.toUpperCase(), bold:true},
                                                {text : '\n'+ org.data().address},
                                                {text : '\n (Tel: ' + org.data().phone + ' Fax: ' +  org.data().fax + ')'},
                                                {text : '\n Requested by: ' + iou.data().requested.name + ' - Mob: ' +  iou.data().requested.phone, bold: true}
                                            ]
                                            
                                        },
                                    ],
                                    style: 'para'
                                },
                                { text: '\n\nDear ' + iou.data().contactPerson + ',', style: 'para' },
                                {
                                    text: [
                                        {text : 'Further our conversation, please be advised we agree to lend the following product listed to your company for the purposes of '},
                                        {text : ' ' + iou.data().purpose, color: 'red', bold: true},
                                        {text : ' for the period of '},
                                        {text : ' ' + period + ' days', color: 'red', bold: true},
                                        {text : ' from the date of acceptance. '},
                                        {text : ' After the evaluation periods, '},
                                        {text : iou.data().customerName, bold: true },
                                        {text : ' is required to return the products to '},
                                        {text : org.data().name + '.  Otherwise, ' + org.data().name + ' will charge the amount listed in the following details.'}
                                    ],                                   
                                    style: 'para'
                                },
                                { text: 'If return is not required after get approval, please mention in below form.', style: 'para' },                                
                                table(
                                    ordered, 
                                    ['name', 'quantity', 'price', 'netAmount','willReturn'],  
                                    [
                                        {text: 'No.', style: 'tableHeader'}, 
                                        {text: 'Model No.', style: 'tableHeader'},                                                 
                                        {text: 'Qty. (PCS)', style: 'tableHeader'}, 
                                        {text: 'Unit Price', style: 'tableHeader'}, 
                                        {text: 'Total Amount (Tax Inclusive)', style: 'tableHeader'}, 
                                        {text: 'Return (Yes/No)', style: 'tableHeader'}
                                    ],
                                    [
                                        {text: '', style: 'tableFooter'}, 
                                        {text: 'Total', style: 'tableFooter', colSpan: 3},                                                 
                                        {}, 
                                        {}, 
                                        {text: 'AED ' + parseFloat(iou.data().totalAmount).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2}), style: 'tableFooter', alignment: 'right'}, 
                                        {text: '', style: 'tableFooter'}
                                    ]
                                ),
                                { text: '\n\nNote: Please keep the ' + iou.data().purpose + ' items same as it was.', style: 'para' }, 
                                {
                                    columns: [
                                        {
                                            width: 300,
                                            fontSize: 10,
                                            text : 'For and On behalf of :' +
                                            '\n' + org.data().name.toUpperCase() +
                                            '\n\n\n....................................' +
                                            '\nDate : ' + moment(new Date()).format('DD-MM-YYYY') +
                                            '\nName of Signatory: ' + iou.data().requested.name
                                            
                                        },
                                        {
                                            width: 'auto',
                                            fontSize: 10,
                                            text : ' ' +
                                            '\n Accepted by :' +
                                            '\n\n\n....................................' +
                                            '\nDate : ' +
                                            '\nName of Signatory: ' 
                                            
                                        }
                                    ]
                                }                               
                            ],
                          
                            styles: {
                                header: {
                                    fontSize: 22,
                                    bold: true,
                                    alignment: 'center',
                                    margin:[0, 20, 0, 20]
                                },
                                para: {
                                    fontSize: 10,
                                    lineHeight: 1.25,
                                    margin:[0, 0, 0, 10]
                                },
                                table: {
                                    margin: [0, 5, 0, 15],
                                    fontSize: 9,
                                },
                                tableHeader: {
                                    bold: true,
                                    fontSize: 11,
                                    color: 'black',
                                    alignment: 'center',
                                    fillColor: '#E7E7E7'
                                },
                                tableFooter: {
                                    bold: true,
                                    fontSize: 11,
                                    color: 'black'
                                }
                            }
                        };

                        const pdfDocGenerator = pdfMake.createPdf(docDefinition);

                        /**Store document in storage and update iou with downlod link */
                        pdfDocGenerator.getBlob((blob) => {                            
                            const storageRef = firebase.storage().ref();
                            // var uploadTask = storageRef.child('pdf/iou.pdf').put(blob);
                            var uploadTask = storageRef.child(profile.account + '/iou/agreement' + iou.data().index + '.pdf').put(blob);

                            // Listen to state changes, errors, and completion of the upload.
                            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                                function(snapshot) {

                                    // Get task progress, inlcuding number of bytes uploaded and the total number of bytes to be uploaded

                                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                    console.log('Upload is ' + progress + '% done');

                                    // switch (snapshot.state) {
                                    //     case firebase.storage.TaskEvent.PAUSED: // or 'paused'
                                    //         console.log('Upload is paused');
                                    //         break;
                                    //     case firebase.storage.TaskState.RUNNING: // or 'running'
                                    //         console.log("Upload is running");
                                    //         break;
                                    //     default:
                                    //         break;   
                                    // }

                                }, function(error){
                                    switch (error.code) {
                                        case 'storage/unauthorized':
                                            // User doesn't have permission to access the object
                                            console.log("storage/unauthorized", error)
                                            break;
                                        case 'storage/canceled':
                                            // User canceled the upload
                                            console.log("storage/canceled", error)
                                            break;
                                        case 'storage/unknown':
                                            // Unknown error occurred, inspect error.serverResponse
                                            console.log("storage/unknown", error)
                                            break;
                                        default:
                                            break;
                                    }

                                }, function(){
                                    // Upload completed successfully, now we can get the download URL
                                    uploadTask.snapshot.ref
                                    .getDownloadURL()
                                    .then((downloadURL) => {
                                        /** Update iou with details of approval */
                                        iouRef.update({
                                            status: 'Approved',   
                                            storeId: div.storeId,    
                                            finacialDepId: div.finacialDepId,
                                            period: period,
                                            organizationalApproval: profile.firstName + ' ' + profile.lastName,
                                            organizationalApproval_at: new Date(),
                                            messages: messages,
                                            documents: [{
                                                name: 'Agreement Draft',
                                                dataURL: downloadURL,
                                                docDate: new Date()
                                            }],
                                            modifier_id: modifier_id,
                                            modified_at: new Date(),
                                            lastChangeBy: profile.firstName + ' ' + profile.lastName
                                        });
                                        /** Update approvel request - for customer sign */
                                        reqRef.update({                                                
                                            todo: iou.data().creator_id,
                                            organizationalApproval: profile.firstName + ' ' + profile.lastName,
                                            organizationalApproval_at: new Date(),                            
                                            request: 'Customer Sign',
                                            documents: [{
                                                name: 'Agreement Draft',
                                                dataURL: downloadURL,
                                                docDate: new Date()
                                            }]
                                        });
                                        
                                    });
                            });

                        });                      
                        break;
                    default:

                        break;
                }
               
                
                dispatch({type: 'APPROVE_IOU'})
            });

        }catch(err){
            dispatch({type:'APPROVE_IOU_ERROR', err});
        }
        
    }
}

export const AddDelivery = (id, iou, messages, deliveryNoteNumber, deliveryNoteNumbers, pdfFile) => {
    return(dispatch, getState, { getFirebase }) => {
        const profile = getState().firebase.profile;     
        const modifier_id = getState().firebase.auth.uid;
        /**Make async call to database */
        const firebase = getFirebase();
        const firestore = getFirebase().firestore();

        //console.log('dnItems', iou.productList);
        /** Creating delivery note items array */
        let dnItems = [];
        const dnProductArray = Object.keys(iou.productList).map((key) => [key, iou.productList[key]]);    
    
        dnProductArray.map(item => {    
            dnItems.push(item.pop());
            return null;
        });  

        let validation = true;
        dnItems.map( item => {
            const pro = iou.iou.productList[item.id];
            if((parseFloat(pro.quantity) - parseFloat(pro.deliveredQuantity)) < parseFloat(item.issuingQuantity)){
                console.log('Error issuing quantity is greather than requested');
                validation = false;
            }else pro.deliveredQuantity = parseFloat(pro.deliveredQuantity) + parseFloat(item.issuingQuantity);   
            return null;          
        })

        // console.log('status', iou.iou.status);    
        // validation = false;   

        if(validation){
             /**Creating iou items array */
            let iouItems = [];
            const iouProductArray = Object.keys(iou.iou.productList).map((key) => [key, iou.iou.productList[key]]);    
        
            iouProductArray.map(item => {    
                iouItems.push(item.pop());
                return null;
            });  

            let closeDeliveryNoteReq = true;
            iouItems.map( item => {
                
                if(item.quantity > item.deliveredQuantity) closeDeliveryNoteReq = false;
                return null;          
            });

            try{
                /** Reference to iou document and request todo */ 
                const iouRef = firestore.collection(account)
                .doc(profile.account)
                .collection(collection)
                .doc(id);

                const reqRef = firestore.collection(account)
                .doc(profile.account)
                .collection('requests')
                .doc('todos')
                .collection('iou')
                .doc(id);

                const statRef = firestore.collection(account)
                .doc(profile.account)
                .collection('stat')
                .doc('iouStat');

                firestore.runTransaction(async transaction => {   
                    const stat = await transaction.get(statRef);     
                    const storageRef = firebase.storage().ref();
                    var uploadTask = storageRef.child(profile.account + '/iou/iou' + iou.index + '/'+ deliveryNoteNumber + '.pdf').put(pdfFile.file);

                    // Listen to state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                        function(snapshot) {

                            // Get task progress, inlcuding number of bytes uploaded and the total number of bytes to be uploaded

                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            dispatch({type: 'IOU_UPLOAD_PROGRESS', progress});

                        }, function(error){
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    console.log("storage/unauthorized", error)
                                    break;
                                case 'storage/canceled':
                                    // User canceled the upload
                                    console.log("storage/canceled", error)
                                    break;
                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    console.log("storage/unknown", error)
                                    break;
                                default:
                                    break;
                            }

                        }, function(){
                            // Upload completed successfully, now we can get the download URL
                            uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then((downloadURL) => {
                                /** Update iou with details of delivery note */
                                //console.log('documents',iou);
                                const documents = [...iou.iou.documents, {
                                    deliveryNoteNumbers: deliveryNoteNumbers,
                                    name: 'Delivery Note ' + deliveryNoteNumber,
                                    dataURL: downloadURL,
                                    docDate: new Date()
                                }];
                                
                                if(iou.iou.status !== 'Open'){
                                    const date = new Date();
                                    let returnDate =  new Date();
                                    returnDate.setDate(date.getDate() + parseInt(iou.iou.period));
                                    iouRef.update({
                                        productList: iou.iou.productList,
                                        messages: messages,
                                        status: 'Open',
                                        documents: documents,
                                        deliveryDate: date,
                                        returnDate: returnDate,
                                        modifier_id: modifier_id,
                                        modified_at: new Date(),
                                        lastChangeBy: profile.firstName + ' ' + profile.lastName
                                    });  
                                }else{
                                    iouRef.update({
                                        productList: iou.iou.productList,
                                        messages: messages,
                                        documents: documents,
                                        modifier_id: modifier_id,
                                        modified_at: new Date()
                                    });  
                                }
                                
                                
                                /** Update approvel request - for customer sign */
                                if(closeDeliveryNoteReq){
                                    reqRef.update({                                                
                                        todo: ''
                                    }); 
                                }

                                /** Update Stat */
                                if(iou.iou.status !== 'Open'){
                                    statRef.update({
                                        open: stat.data().open + 1
                                    });  
                                }
                                
                            });
                    });
                });

                const newIOU = {data:{}}
                const docData = {
                    ...iou.iou,   
                    deliveryNoteNumbers: deliveryNoteNumbers,
                    status: 'Open'
                };
                const key = id;
                newIOU.data[key] = docData;
                dispatch({type: 'IOU_DELIVERY', iou: newIOU});

            }catch(err){
                dispatch({type:'IOU_ERROR', err});
            }
        }else{
            dispatch({type:'IOU_ERROR', err:'Error: Issuing quantity more than requested'});
        }

        
    }
}

export const CloseIOU = (id, iou, messages, salesReturnNumber, salesReturnNumbers, pdfFile) => {
    return(dispatch, getState, { getFirebase }) => {
        const profile = getState().firebase.profile;     
        const modifier_id = getState().firebase.auth.uid;
        /**Make async call to database */
        const firebase = getFirebase();
        const firestore = getFirebase().firestore();

        //console.log('grItems', iou.productList);
        /** Creating goods return items array */
        let grItems = [];
        const grProductArray = Object.keys(iou.productList).map((key) => [key, iou.productList[key]]);    
    
        grProductArray.map(item => {    
            grItems.push(item.pop());
            return null;
        });  

        let validation = true;
        grItems.map( item => {
            const pro = iou.iou.productList[item.id];
            if((parseFloat(pro.deliveredQuantity) - parseFloat(pro.returenedQuantity)) < parseFloat(item.returningQuantity)){
                console.log('Error returing quantity is greather than issued');
                validation = false;
            }else pro.returenedQuantity = parseFloat(pro.returenedQuantity) + parseFloat(item.returningQuantity);   
            return null;          
        })

        //console.log('iouItems', iou.iou.productList);       

        if(validation){
             /**Creating iou items array */
            let iouItems = [];
            const iouProductArray = Object.keys(iou.iou.productList).map((key) => [key, iou.iou.productList[key]]);    
        
            iouProductArray.map(item => {    
                iouItems.push(item.pop());
                return null;
            });  

            let iouStatus = 'Closed';
            iouItems.map( item => {
                
                if(item.deliveredQuantity > item.returenedQuantity && item.willReturn === 'Yes' ) iouStatus = 'Open';
                return null;          
            });

            try{
                /** Reference to iou document and request todo */ 
                const iouRef = firestore.collection(account)
                .doc(profile.account)
                .collection(collection)
                .doc(id);

                // const reqRef = firestore.collection(account)
                // .doc(profile.account)
                // .collection('requests')
                // .doc('todos')
                // .collection('iou')
                // .doc(id);

                const statRef = firestore.collection(account)
                .doc(profile.account)
                .collection('stat')
                .doc('iouStat');

                firestore.runTransaction(async transaction => {   
                    const stat = await transaction.get(statRef); 
                    const storageRef = firebase.storage().ref();
                    var uploadTask = storageRef.child(profile.account + '/iou/iou' + iou.index + '/sr/'+ salesReturnNumber + '.pdf').put(pdfFile.file);

                    // Listen to state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                        function(snapshot) {

                            // Get task progress, inlcuding number of bytes uploaded and the total number of bytes to be uploaded

                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            dispatch({type: 'IOU_UPLOAD_PROGRESS', progress});

                        }, function(error){
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    console.log("storage/unauthorized", error)
                                    break;
                                case 'storage/canceled':
                                    // User canceled the upload
                                    console.log("storage/canceled", error)
                                    break;
                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    console.log("storage/unknown", error)
                                    break;
                                default:
                                    break;
                            }

                        }, function(){
                            // Upload completed successfully, now we can get the download URL
                            uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then((downloadURL) => {
                                /** Update iou with details of delivery note */
                                //console.log('documents',iou);
                                const documents = [...iou.iou.documents, {
                                    salesReturnNumbers: salesReturnNumbers,
                                    name: 'Sales Return ' + salesReturnNumber,
                                    dataURL: downloadURL,
                                    docDate: new Date()
                                }];
                                iouRef.update({
                                    productList: iou.iou.productList,
                                    messages: messages,
                                    status:  iouStatus,
                                    documents: documents,
                                    modifier_id: modifier_id,
                                    modified_at:  new Date(),
                                    lastChangeBy: profile.firstName + ' ' + profile.lastName
                                });  
                                
                                /** Update approvel request - for customer sign */
                                // if(closeDeliveryNoteReq){
                                //     reqRef.update({                                                
                                //         todo: ''
                                //     }); 
                                // }

                                /** Update Stat */
                                if(iou.iou.status !== 'Closed' && iouStatus === 'Closed'){
                                    statRef.update({
                                        closed: stat.data().closed + 1
                                    });  
                                }
                                
                            });
                    });
                });

                const newIOU = {data:{}}
                const docData = {
                    ...iou.iou,   
                    salesReturnNumbers: salesReturnNumbers,
                    status: iouStatus
                };
                const key = id;
                newIOU.data[key] = docData;
                dispatch({type: 'CLOSE_IOU', iou: newIOU});

            }catch(err){
                dispatch({type:'IOU_ERROR', err});
            }
        }else{
            dispatch({type:'IOU_ERROR', err:'Error: Returing quantity more than issued'});
        }

        
    }
}

export const RejectIOU = (id, messages ) => {
    return(dispatch, getState, { getFirebase }) => {
        const profile = getState().firebase.profile;     
        const modifier_id = getState().firebase.auth.uid;

        /*Make async call to database */     
        const firestore = getFirebase().firestore();
        try{
            const iouRef = firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc(id);

            firestore.runTransaction(async transaction => {                
                const reqRef = firestore.collection(account)
                .doc(profile.account)
                .collection('requests')
                .doc('todos')
                .collection('iou')
                .doc(id);
                /**Update iou with rejection status */
                transaction.update(iouRef, {
                    status: 'Rejected',
                    rejectedBy: profile.firstName + ' ' + profile.lastName,
                    rejected_at: new Date(),
                    messages: messages,
                    modifier_id: modifier_id,
                    modified_at: new Date(),
                    lastChangeBy: profile.firstName + ' ' + profile.lastName
                });
                /** Delete todo request */
                transaction.delete(reqRef);
                
                dispatch({type: 'REJECT_IOU'})
            });

        }catch(err){
            dispatch({type:'IOU_ERROR', err});
        }
        
    }
}

export const loadIOUs = (search, pagination, pageInfo) => {
    //search = search.toLowerCase().replace(/\W/g, "");
    return(dispatch, getState,{ getFirebase }) => {
        const profile = getState().firebase.profile;
        const auth = getState().firebase.auth;
        const firestore = getFirebase().firestore();

        if(profile.userId !== undefined){
            firestore.collection(account)
            .doc(profile.account)
            .collection('divisions') 
            .where('membersList', 'array-contains', profile.userId)
            .get()
            .then((snapshot) => {     
                const departmentId =  snapshot.size > 0 ? snapshot.docs[0].id : '';           
                //console.log('privilege',snapshot.size);
                var query = firestore.collection(account)
                .doc(profile.account)
                .collection(collection);                
                let searchFields = [];
                switch(profile.privilege){
                    case 'logistics':
                        if(search !== '') {
                            switch(search){
                                case 'all':
                                    searchFields = ['Open', 'Closed'];
                                    break;
                                default:
                                    searchFields = [search];
                                    break;
                            }
                            //console.log('searchFields', searchFields);
                            query = query.where("status", "in", searchFields);
                        }
                        else  query = query.where("status", "in", ['Open']); 
                        query = query.where("storeId", "==", departmentId);
                        break;
                    case 'finance':
                        if(search !== '') {
                            switch(search){
                                case 'all':
                                    searchFields = ['Open', 'Closed'];
                                    break;
                                default:
                                    searchFields = [search];
                                    break;
                            }
                            query = query.where("status", "in", searchFields);
                        }
                        else  query = query.where("status", "in", ['Open']); 
                        query = query.where("finacialDepId", "==", departmentId);  
                        break;
                    default:
                        if(search !== '') {
                            switch(search){
                                case 'all':
                                    searchFields = ['Draft', 'Approval Pending', 'Approved','Rejected','Delivery','Open', 'Closed'];
                                    break;
                                default:
                                    searchFields = [search];
                                    break;
                            }
                            query = query.where("status", "in", searchFields);
                        }
                        else  query = query.where("status", "in", ['Draft', 'Approval Pending', 'Approved','Rejected','Delivery','Open'])
                        query = query.where("access", 'array-contains', auth.uid);
                        break;
                }
                //console.log('date filter', pageInfo.dateFilter.startDate);

                let startDate = null;
                let endDate = null;
                if(pageInfo.dateFilter.startDate !== undefined 
                && pageInfo.dateFilter.startDate !== '' ){
                    startDate = pageInfo.dateFilter.startDate;                    
                }

                if(pageInfo.dateFilter.endDate !== undefined 
                && pageInfo.dateFilter.endDate !== '' ){
                    const eDate = new Date(pageInfo.dateFilter.endDate);
                    endDate = new Date(pageInfo.dateFilter.endDate);
                    endDate.setDate(eDate.getDate() + 1);
                }

                if(startDate && endDate){ 
                    query = query.where("date", '>', startDate)
                    .where("date", '<', endDate);
                }else if(startDate){
                    query = query.where("date", '>', startDate);
                }else if(endDate){
                    query = query.where("date", '<', endDate);
                }

                /**Customer Filter */
                if(pageInfo.customerFilter !== ''){
                    query = query.where("customerId", '==', pageInfo.customerFilter);
                }
                
                /**Due Filter */
                let dueFlag = false;
                if(pageInfo.dueFilter !== '' 
                && startDate === ''
                && endDate === ''
                ){
                    dueFlag = true;
                    if(parseInt(pageInfo.dueFilter) === 0) query = query.where("returnDate", '<', new Date());
                    else {
                        const sDate = new Date(moment().add(parseInt(pageInfo.dueFilter), 'day'));
                        endDate = new Date(moment().add(parseInt(pageInfo.dueFilter), 'day'));
                        endDate.setDate(sDate.getDate() + 1);
                        query = query.where("returnDate", '>', sDate)
                        .where("returnDate", '<', endDate);
                    }
                    
                }

                if(dueFlag){
                    query = query.orderBy('returnDate','asc');
                    //.orderBy('index','asc');
                }
                else if(startDate !== null || endDate !== null){
                    query = query.orderBy('date','asc');
                    //.orderBy('index','asc');
                }else query = query.orderBy('index','asc');

                let filterFlag = false;
                if(search !== '' 
                || startDate !== null
                || endDate !== null
                || pageInfo.customerFilter !== ''                
                || pageInfo.dueFilter !== ''
                ) filterFlag = true;

                //console.log('cursor',pagination);
                //query = query.orderBy('index','asc');
                //if(!filterFlag){
                    query = query.startAfter(parseInt(pagination.cursor))
                    .limit(pagination.limit);
                //}
                
                query.get()
                .then((snapshot) => { 
                    //console.log('collection size', snapshot.size);
                    const iou = {
                        data:{}, 
                        pageInfo:{...pageInfo, 
                            getIOUList: false, 
                            pageSize: snapshot.size,
                            filtering: filterFlag
                        }
                    }
                    snapshot.docs.map(doc => {
                        const docData = {
                            ...doc.data(),
                            id: doc.id
                        };
                        const key = doc.id;
                        iou.data[key] = docData;
                        return null;
                    });
                    Object.keys(iou.data).length === 0  
                        ? dispatch({type: 'FILTER_IOU', iou}) 
                        : !filterFlag ? dispatch({type: 'LOAD_IOU', iou}) 
                        : dispatch({type: 'FILTER_IOU', iou});
                }).catch((err) => {
                    dispatch({type: 'IOU_ERROR', err});
                });
            });
        }
    }
}

export const deleteIOU = (newIOU, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(id !== ''){
            const profile = getState().firebase.profile;  
            const firestore = getFirebase().firestore();
            /*Make async call to database */
            const statRef = firestore.collection(account)
            .doc(profile.account)
            .collection('stat')
            .doc('iouStat');
            const iouRef =  firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc(id);

            try{
                firestore.runTransaction( async transaction => {
                    const stat = await transaction.get(statRef);
                    const newDeleted = stat.data().deleted === undefined ? 1 : stat.data().deleted + 1;
                    transaction.update(statRef, {deleted: newDeleted});

                    transaction.delete(iouRef);

                    
                })
                .then(() => {
                    const iou = {data:{}}
                    const docData = {
                        ...newIOU,
                        status: 'deleted',
                        id: id
                    };
                    const key = id;
                    iou.data[key] = docData;
                    dispatch({type: 'DELETE_IOU', iou});
                })
            }catch(err){
                dispatch({type: 'DELETE_IOU_ERROR', err});
            }
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
        }
    }
}

export const getIOUDetails = (id, control) => {
    //console.log('get iou details');
    return(dispatch, getState,{ getFirebase }) => {
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();

        firestore.collection(account)
        .doc(profile.account)
        .collection(collection)   
        .doc(id)
        .get()
        .then((snapshot) => { 
            //console.log('snapshot',snapshot.exists);
            if(snapshot.exists){
                const iou = {data:{}}
                const docData = {
                    ...snapshot.data(),
                    id: id
                };
                const key = id;
                iou.data[key] = docData;
                if(control === 'iou-summary') dispatch({type: 'GET_IOU_DETAILS_SUMMARY', iou});
                else if(control === 'iou-delivery') dispatch({type: 'GET_IOU_DETAILS_DELIVERY', iou});
                else if(control === 'iou-return') dispatch({type: 'GET_IOU_DETAILS_RETURN', iou});
                else dispatch({type: 'GET_IOU_DETAILS', iou});
            }
        }).catch((err) => {
            dispatch({type: 'LOAD_IOU_ERROR', err});
        });
        
    }
} 

export const resetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().iou.validation;
        if(validation) dispatch({type: 'RESET_IOU_VALIDATION'})
    }
}

export const closeControl = () => {
    return(dispatch, getState) => {
        dispatch({type: 'CLOSE_IOU_APPROVAL_CONTROL'});
        dispatch({type: 'CLOSE_IOU_DELIVERY_CONTROL'});
        dispatch({type: 'CLOSE_IOU_RETURN_CONTROL'});
    }
}

export const clearMessages = () => {
    return(dispatch, getState, { getFirebase }) => {
        dispatch({type: 'CLEAR_IOU_MESSAGES', err: {message: ''}});
    }
}

export const TestAction = () => {
    return(dispatch, getState, { getFirebase }) => {
        const firestore = getFirebase().firestore();
        firestore.collection('users')
        .where('userId', 'in', ['', ''])
        .get()
        .then(snap => {
            console.log('snap size', snap.size);
        })

        // const orgId = 'j4nLc4yksvMckqBKZpVt';
        // const iouRef = firestore.collection('organizations')
        // .doc(orgId)
        // .collection('ious')
        // .doc('EBgU3RSL2J7pqdwLsRf9');

        // iouRef.get()
        // .then(iouSnap => {
        //     const iou = iouSnap.data();
           
        //     firestore.collection('organizations')
        //     .doc(orgId)
        //     .collection('users')
        //     .where('uid', 'in', iou.access)
        //     .get()
        //     .then(snap => {
        //         const usersList = [];
        //         snap.docs.map(doc => {
        //             usersList.push(doc.data());// .uid === todo.todo;    
        //            return null;
        //         });
        //         console.log('usersList',usersList);
        //         const approver = usersList.filter(user => {
        //             return user.uid === 'EOqw5e7WNvOwq4ztsK2BQOg7iFF3';
        //         });
        //         const to = approver.length > 0 ? approver[0].email : 'balu.m.v@gmail.com' ; 
        //         console.log('approver',to, approver);
        //     })
        // })
    }
}

