import React from 'react';

import DivisionSummary from './DivisionSummary';

const DivisionList = (props) => {
    /* Defactor division from props */
    const { div } = props;

    return ( 
        <div className="section">
            <div className="row">
                { div && div.map(division => {
                    return(               
                        <div className='list-wrap' key={division.id}>
                            <DivisionSummary division={division}/>
                        </div>
                    ) 
                })}
            </div>
         </div>
     );
}
 
export default DivisionList;