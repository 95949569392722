import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'materialize-css/dist/css/materialize.min.css';
import Materialize from 'materialize-css/dist/js/materialize.min.js'
import Navbar from '../layout/Navbar';
import Dashboard from '../dashboard/Component';
import SignIn from '../auth/SignIn';
import SignUp from '../auth/SignUp';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import Routes from '../routes/Routes';
import PageNotFound from '../notFound/404';

class App extends Component {
  state = { 
    subdomain: null
   }

  componentDidMount() {
    /*Get subdomain details from host and add to state for organizational filetering */
    const host = document.location.host;
    const parts = host.split(".");
    const subdomain =   parts.includes('localhost:3000') ? 
      parts.length > 1 ? parts[0] : null
     :  parts.length > 2 ? parts[0] : null;
    this.setState({
      subdomain: subdomain
    })

    /*Initialize sidenav from materializecss */
    let sidenav = document.querySelector('#slide-out');
    Materialize.Sidenav.init(sidenav, {});

    /*Intitialize tooltip */
    // let elems = document.querySelectorAll('.tooltipped');
    // Materialize.Tooltip.init(elems,{});
  }
  render() {
    //console.log(this.state.subdomain);  
    //const routes = this.state.subdomain === null ? null : <Routes/>;
    const routes = <Routes/>;
    return (
      <BrowserRouter>        
        <div className="App">
          <Navbar/>
          <Switch>
            <Route exact path='/' component={Dashboard}/>
            <Route path='/signin' component={SignIn}/>
            <Route path='/signup' component={SignUp}/>
            <Route path='/forgot' component={ForgotPassword}/>
            <Route path='/reset' component={ResetPassword}/>
            {routes}    
            <Route  component={PageNotFound}/>          
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
 
export default App;
