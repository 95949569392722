import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { updateProduct } from '../../store/actions/productActions';

const ProductDetails = (props) => {    
    const dispatch = useDispatch();
    
    const [componentState, setComponentState] = useState({});
    const [sku, setSKU] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth); 
    const product = useSelector(state => state.product.data[props.match.params.id]);   
    const validation = useSelector(state => state.product.validation); 
    const productError = useSelector(state => state.product.productError); 
    
    useEffect(() => {
        //console.log(product);  
        if(product){
            setComponentState({
                sku: product.sku,
                name: product.name,
                description: product.description
            })
            setSKU(product.sku);
            setName(product.name);
            setDescription(product.description);
        }
    },[product]);    

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/pro'/>;  

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Previous state');
        //console.log('props state',componentState);
        dispatch(updateProduct(componentState, props.match.params.id));
    }

    return (       
        <div className="container product">
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">Update Product</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/pro' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="SKU">SKU</label>
                        <input defaultValue={sku} id="sku" onChange={handleChange} type="text" disabled/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Name">Name</label>
                        <input defaultValue={name} id="name" onChange={handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Description">Description</label>
                        <input defaultValue={description} id="description" onChange={handleChange} type="text" className="validate" required/>
                    </div> 
                </div>
                <div className="input-field">
                    <button className="btn red lighten-1 z-depth-0">Update</button>                        
                    <div className="red-text center">
                        {productError ? <p>{productError}</p> : validation ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default ProductDetails;