import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import Pagination from '../../utils/components/buttons/Pagination';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { GetOrderList, SetChoice } from '../../store/actions/orderActions';
import { EditLinkButton } from '../../utils/components/buttons/LinkButtons';

const pageInfo = { statusFilter: '', dateFilter: {}, customerFilter: ''}; 
const pagination = {
    pageNumber: 0,
    limit: 10,
    orderBy: 'asc',
    cursor: 0,
    currentPage: 1,
    lastPage: 1,
    totalRecords: 0
}; 

const OrderList = (props) => {
    const dispatch = useDispatch();
    const choice = props.match.params.ch;
    const [statusFilter, setStatusFilter] = useState(''); 
    const [clearField, setClearField] = useState();

    useEffect(() => {
        Materialize.AutoInit();
        const startDatePicker = document.querySelector('.startDatepicker');
        Materialize.Datepicker.init(startDatePicker, {
            format: "yyyy-mm-dd",
            onSelect: function () {
                //console.log(this);
                handleStartDateSelect(this.date);
            }
        });
        const endDatePicker = document.querySelector('.endDatepicker');
        Materialize.Datepicker.init(endDatePicker, {
            format: "yyyy-mm-dd",
            onSelect: function () {
                //console.log(this);
                handleEndDateSelect(this.date);
            }
        });
    });

    useEffect(() => {
        //console.log('set choice');
        dispatch(SetChoice(choice));
    })

    useEffect(() => {
        dispatch(GetOrderList(pageInfo, pagination));
    },[dispatch])

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);
    const profile = useSelector(state => state.firebase.profile);
    const pageStatus = useSelector(state => state.order.pageInfo);
    const ordList = useSelector(state => state.order.data);

    /* Check authentication and redirect to login page */
    if(!auth.uid) return <Redirect to='/signin'/>;
    
    //console.log('ordList',ordList); 
    const orderList = ordList ? (
        ordList.map(order => {  
             return(                
                <tr className='list' key={order.ofId}> 
                    <td>{order.orderNumber}</td>                           
                    <td>{order.orderDate}</td>
                    <td>{order.companyName}</td>  
                    <td>{order.salesPerson}</td>
                    <td>{order.location}</td>
                    <td>{order.currencyCode + ' ' + order.grandTotal}</td>                  
                    <td>{order.orderStatus}</td>
                    <td>
                        <EditLinkButton to={'/ord/' + order.ofId} bg='grey lighten-5'/>
                    </td>
                </tr>
             );                                
         }) 
     ) : null;

     const handleCustomerSelection = (tags) => {
        //console.log('tags',tags);        
        setClearField();
        pageInfo.customerFilter = tags.value;
    }
    
    const handleStatusSelection = (e) => {
        //console.log(e.target.value);
        setStatusFilter(e.target.value);
        pageInfo.statusFilter = e.target.value;
    }

    const handleStartDateSelect = (date) => {
        //console.log('date picked', date);
        pageInfo.dateFilter = {
            ...pageInfo.dateFilter,
            startDate: date.toDateString()
        }
    }

    const handleEndDateSelect = (date) => {
        //console.log('date picked', date);
        pageInfo.dateFilter = {
            ...pageInfo.dateFilter,
            endDate: date.toDateString()
        }
    }

    const handleSearch = () => {
        dispatch(GetOrderList(pageInfo, pagination));
    }

    const handleClear = () => {
        pageInfo.statusFilter = '';
        pageInfo.dateFilter = {
            startDate: '',
            endDate: ''
        }
        pageInfo.customerFilter = '';
        pagination.cursor = 0;
        pagination.pageNumber = 0;
        setStatusFilter('');
        document.querySelector('.startDatepicker').value = '';
        document.querySelector('.endDatepicker').value = '';
        setClearField({label:  'Search by customers'});
        dispatch(GetOrderList(pageInfo, pagination));
    }

    const handleNextPage = (cursor) => {     
        //console.log('pageNumber',pagination.pageNumber);  
        if(pagination.lastPage > pagination.pageNumber){
            pagination.pageNumber++;
            pagination.orderBy = 'asc';    
            dispatch(GetOrderList(pageInfo, pagination));
        }
    }

    const handlePreviousPage = (cursor) => {
        //console.log('pageNumber',pagination.pageNumber);        
        if(pagination.pageNumber > 0){
            pagination.pageNumber--;        
            pagination.orderBy = 'desc';
            dispatch(GetOrderList(pageInfo, pagination));
        } 
    }

    return ( 
        <div className="section ord">
            <div className="row">                                        
                <div className="col s12 p-0">
                    <div className="card">
                        <div className="card-content sec-head">                            
                            <div className="row">                               
                                <div className="input-field col s12 m3 l3">
                                    <i className="material-icons prefix">search</i>
                                    <AutoComplete
                                    onSelected={handleCustomerSelection} 
                                    collection={'customerCode'} search={'name'} account={profile.account}
                                    placeholder={'Search by customers'}
                                    value={clearField}
                                    />
                                </div> 
                                <div className="input-field col s12 m9 l9 ord-cr">
                                    <div className="row">
                                        <div className="input-field col s12 m3 l3">
                                            <select onChange={handleStatusSelection} className="browser-default" value={statusFilter}>
                                                <option value="" disabled>Select Order Status</option>
                                                <option value="all">All</option>
                                                <option value="0">Order Placed</option>
                                                <option value="1">In Process</option>                                                   
                                                <option value="5">Ready for Collection</option> 
                                                <option value="2">Delivered</option>  
                                                <option value="3">Cancelled</option>
                                                <option value="open">Open</option>      
                                            </select>                                    
                                        </div>
                                        <div className="input-field col s12 m2 l2">
                                            <label htmlFor="Date From">Date from</label>
                                            <input id='sartDate' type="text" className="startDatepicker"></input>
                                        </div>
                                        <div className="input-field col s12 m2 l2">
                                            <label htmlFor="Date To">Date to</label>
                                            <input id='endDate' type="text"  className="endDatepicker"></input>
                                        </div>
                                        <div className="input-field col s12 m4 l4">                                   
                                            <Link to='#' onClick={handleSearch} className="orange waves-light btn"><i className="material-icons left">search</i>SEARCH</Link>
                                            <Link to='#' onClick={handleClear} className="grey waves-light btn"><i className="material-icons left">clear</i>CLEAR</Link>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                                                       
                            <div className="section sec-details">  
                               <div className="table-overflow">
                                    <table className=" highlight">
                                        <thead>
                                            <tr>
                                                <th data-field="orderNumber">Order #</th>
                                                <th data-field="date">Date</th>
                                                <th data-field="customer">Customer</th>  
                                                <th data-field="salesPerson">Sales Person</th>   
                                                <th data-field="location">Store</th>   
                                                <th data-field="grandTotal">Amount</th>                         
                                                <th data-field="status">Status</th>
                                                <th data-field="edit" className="icon-head"></th>
                                            </tr>
                                        </thead>                                            
                                        <tbody>
                                            {orderList}
                                        </tbody>
                                    </table>
                                </div> 
                            </div>
                        </div>
                        <div className="card-action">
                            <Pagination
                            pageNumber = {pageStatus.currentPage}
                            firstDoc = ''
                            lastDoc = ''
                            onNextPageClick={handleNextPage} 
                            onPreviousPageClick={handlePreviousPage}
                            enablePrevious = { true }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default OrderList;