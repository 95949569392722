import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import { DownloadLinkButton } from '../../utils/components/buttons/LinkButtons';

const MsgAndDoc = ({messages, documents, onPdfView}) => {
    useEffect(() =>  {
        Materialize.AutoInit();
     })

     const handlePdfView = (pdfURL) => {
        onPdfView(pdfURL);
     }

    let msgIndex = 0;
    const msgList = messages ? (
        <li className='msg'>
            <div className="collapsible-header"><i className="material-icons">message</i>Messages  <span className='new badge pink darken-2' data-badge-caption=''>{messages && messages.length > 0 ? ' [' + messages.length + ']' : null}</span></div>  
            <div className="collapsible-body msg">
                {
                    messages.map(item => {
                        return(
                            <div className='msg-lst' key={msgIndex++}>
                                <span className="pink-text">{item.messageFrom} </span>
                                <span>{item.message}</span>
                                <div className="grey-text note-date">
                                    {moment(item.message_at.toDate()).fromNow()}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </li>
    ) : null;

    let docIndex = 0;
    const docList = documents ? (
        <li className='msg'>
            <div className="collapsible-header"><i className="material-icons">note</i>Documents  <span className='new badge purple' data-badge-caption=''>{documents && documents.length > 0 ? ' [' + documents.length + ']' : null}</span></div>  
            <div className="collapsible-body msg">
                <table>
                <tbody>
                    {
                        documents.map(doc => {
                            return(                                
                                <tr className='doc-list' key={docIndex++}>
                                    <td><Link onClick={(url) => {handlePdfView(doc.dataURL)}} className='grey-text text-darken-2' to='#'>{doc.name}</Link></td>
                                    <td><span className='pink-text'>{moment(doc.docDate.toDate()).format('LLL') }</span></td>
                                    <td className="icon-head"><DownloadLinkButton href={doc.dataURL} tooltip='Download'/></td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
                </table>
            </div>
        </li>
    ) : null;

    const msgsAndDocs = messages || documents ? (
        <ul className="collapsible">
           {msgList}
           {docList}
        </ul>
    ) : null;

    return ( 
        <React.Fragment>
            {msgsAndDocs}
        </React.Fragment>
     );
}
 
export default MsgAndDoc;