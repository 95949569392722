import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { AddCompatibleSpare, DeleteCompatibleSpare, ShowCompatible, UpdateSparePart } from '../../store/actions/sparePartsActions';
import Compatible from './Compatible';

import Materialize from 'materialize-css/dist/js/materialize.min.js';

const SparePartDetails = (props) => {    
    const dispatch = useDispatch();
    useEffect(() => {
        Materialize.AutoInit();
    });
    
    const [componentState, setComponentState] = useState({});
    const [sku, setSKU] = useState('');
    const [modelName, setModelName] = useState('');
    const [sparePartName, setSparePartName] = useState('');
    const [boardName, setBoardName] = useState('');
    const [partsSku, setPartsSku] = useState('');
    const [location, setLocation] = useState('');    
    const [stockQuantity, setStockQuantity] = useState('');
    const [price, setPrice] = useState();
    const [cost, setCost] = useState();

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);    
    const profile = useSelector(state => state.firebase.profile); 
    const sparePartsList = useSelector(state => state.spares.data);   
    const data = useSelector(state =>  state.spares.compatibleData);
    const validation = useSelector(state => state.spares.validation); 
    const spareError = useSelector(state => state.spares.spareError); 
    
    useEffect(() => {
        console.log('sparePartsList',sparePartsList);
        const spare = sparePartsList  !== null ? sparePartsList.filter(item => {
            return item.spId === props.match.params.id
         }) : [];
        //console.log('spare',spare);
        if(sparePartsList){
            setComponentState({
                spId: spare[0].spId,
                sku: spare[0].sku,
                modelName: spare[0].modelName,
                sparePartName: spare[0].sparePartName,
                boardName: spare[0].boardName,
                partsSku: spare[0].partsSku,
                location: spare[0].location,
                stockQuantity: spare[0].stockQuantity,
                price: spare[0].price,
                cost: spare[0].cost
            });
            setSKU(spare[0].sku);
            setModelName(spare[0].modelName);
            setSparePartName(spare[0].sparePartName);
            setBoardName(spare[0].boardName);
            setPartsSku(spare[0].partsSku);
            setLocation(spare[0].location);
            setStockQuantity(spare[0].stockQuantity);
            setPrice(spare[0].price);
            setCost(spare[0].cost);
        }
    },[sparePartsList, props.match.params.id]);    

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/spa'/>;  

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Previous state');
        //console.log('props state',componentState);
        dispatch(UpdateSparePart(componentState, props.match.params.id));
    }

    const handleViewCompatible = () => {
        //console.log(partsSku + 'Comptible view clicked');
        dispatch(ShowCompatible(props.match.params.id));
    }

    const handleCompatibleSpareAdd = (compatibleSpare) => {
        //console.log('Comptible Spare:' + compatibleSpare);
        if(props.match.params.id !== compatibleSpare){
            dispatch(AddCompatibleSpare(props.match.params.id, compatibleSpare));
        } else{
            Materialize.toast({html: 'Invalid Seletion!'})  
        }
    }

    const handleCompatibleSpareDelete = (compatibleSpare) => {
        dispatch(DeleteCompatibleSpare(props.match.params.id, compatibleSpare));
    }

    return (       
        <div className="container product">
            <Compatible
                data= {data}
                onCompatibleSpareAdd={(sku) => {handleCompatibleSpareAdd(sku)}}
                onCompatibleSpareDelete={(sku) => {handleCompatibleSpareDelete(sku)}}
                edit = {true}
            />
            <form onSubmit={handleSubmit} className="white">
                <div className="row">
                    <div className="col s11">
                        <h5 className="red-text text-lighten-1">Update Spare Part</h5>
                    </div>
                    <div className="col s1 btn-close">
                        <Link to='/spa' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="SKU">SAP No.</label>
                        <input defaultValue={partsSku} id="partsSku" onChange={handleChange} type="text" disabled/>
                    </div>
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Name">Spare Part Name</label>
                        <input defaultValue={sparePartName} id="sparePartName" onChange={handleChange} type="text" className="validate" required/>
                    </div>                    
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Board Name">Board Name</label>
                        <input defaultValue={boardName} id="boardName" onChange={handleChange} type="text" className="validate" required/>
                    </div>                                         
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Model Name">Model Name</label>
                        <input defaultValue={modelName} id="modelName" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Material Code">Material Code</label>
                        <input defaultValue={sku} id="sku" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="MQuantity">Quantity</label>
                        <input defaultValue={stockQuantity} id="stockQuantity" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l6 input-field">
                        <label className="active" htmlFor="Location">Location</label>
                        <input defaultValue={location} id="location" onChange={handleChange} type="text" className="validate" required/>
                    </div>                     
                    <div className="col s12 m6 l3 input-field">
                        <label className="active" htmlFor="Location">Price</label>
                        <input defaultValue={price} id="price" onChange={handleChange} type="number" className="validate" required/>
                    </div>  
                    {
                        profile.privilege === 'service-manager' ?
                        <div className="col s12 m6 l3 input-field">
                            <label className="active" htmlFor="Location">Cost</label>
                            <input defaultValue={cost} id="cost" onChange={handleChange} type="number" className="validate" required/>
                        </div> 
                        : null
                    }                   
                    
                </div>
                <div className="input-field">
                    <button className="btn red lighten-1 z-depth-0">Update</button> 
                    <Link to="#" onClick={handleViewCompatible} className="orange waves-light btn"><i className="material-icons left">search</i>COMPATIBLE</Link>     
                    <div className="red-text center">
                        {spareError !== undefined ? <p>{spareError}</p> : validation !== undefined ? <p>{validation}</p> : null}
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default SparePartDetails;