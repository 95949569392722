const initState = {
    data: {},
    exportStat: {
        queued: 0,
        uploaded: 0,
        failed: 0,
        control: 'close'            
    },
    duplicate: [],
    filteredData: {},
    customerStat: {},
    customer: {
        id: '',
        customerName: '',
        customerEmail: '',
        customerPhone: '',
        customerAddress: ''
    },
    loadData: true,
    pageInfo: {},
    validation: null
}

const customerReducer = (state= initState, action) => {
    switch(action.type){
        case 'CREATE_CUSTOMER_ERROR':
            console.log('Create customer error', action.err);
            return{
                ...state,
                customerError: action.err.message
            }
        case 'CREATE_CUSTOMER':
            console.log('Created new customer', action.customer);
            return{
                ...state,
                data: {...state.data, ...action.customer.data},
                customerError: null,
                validation: 'Passed'
            }
        case 'CUSTOMER_DUPLICATE':
            console.log('Duplicate customer', action.customer);
            return{
                ...state, 
                duplicate: [...state.duplicate, action.customer],
                customerError: 'Product already exist with this name'
            }
        case 'EXPORT_CUSTOMER_ERROR':
            console.log('Export customer error', action.err);
            return{
                ...state,                                
                exportStat: {
                    ...state.exportStat, 
                    failed: state.exportStat.failed + 1
                },
                customerError: action.err.message
            }
        case 'EXPORT_CUSTOMER_QUEUED':
            console.log('Total customer queued for export', action.queued);
            return{
                ...state,
                duplicate: [],
                exportStat: {...state.exportStat, 
                    queued: action.queued,
                    uploaded: 0,
                    failed: 0,
                    control: 'open'
                }
            }
        case 'EXPORT_CUSTOMER':
            console.log('Exported new customer', action.customer);
            return{
                ...state,
                data: {...state.data, ...action.customer.data},                    
                exportStat: {
                    ...state.exportStat, 
                    uploaded: state.exportStat.uploaded + 1
                },
                customerError: null
            }
        case 'EXPORT_CUSTOMER_DUPLICATE':
            console.log('Export failed, duplicate customer', action.customer);
            return{
                ...state, 
                duplicate: [
                    ...state.duplicate, action.customer],                                 
                    exportStat: {...state.exportStat, 
                    failed: state.exportStat.failed + 1 
                },
                customerError: null
            }
        case 'UPDATE_CUSTOMER_ERROR':
            console.log('Update customer error', action.err);
            return{
                ...state,
                customerError: action.err.message
            }
        case 'UPDATE_CUSTOMER':
            console.log('Updated customer', action.customer);
            return{
                ...state,
                data: {...state.data, ...action.customer.data},
                customerError: null,
                validation: 'Passed'
            }
        case 'LOAD_CUSTOMERS':
            console.log('Loading customers', action.customers);
            return{
                ...state,
                data: {...state.data, ...action.customers.data},
                filteredData: {},
                loadData: false
            }
        case 'FILTER_CUSTOMERS':
            console.log('Loading customers', action.customers);
            return{
                ...state,
                filteredData: action.customers.data
            }
        case 'LOAD_ERROR':
            console.log('Loading customers', action.data);
            return{
                ...state,
                prodcutError: null
            }
        case 'GET_CUSTOMER_DETAILS':
            console.log('Get customer', action.customers);
            return{
                ...state,
                data: {...state.data, ...action.customers.data}
            }
        case 'GET_CUSTOMER':
            console.log('Get customer', action.customer);
            return{
                ...state,
                customer: {
                    id: action.customer.id,
                    customerName: action.customer.name,
                    customerEmail: action.customer.email,
                    customerPhone: action.customer.phone,
                    customerAddress: action.customer.address
                }
            }
         case 'SET_CUSTOMER_DETAILS':
            console.log('Setting customer details', action.customer);
            return{
                ...state,
                ...action.customer
            }            
         case 'SET_CUSTOMER':
            console.log('Setting customer', state.customer);
            const newCustomer = {...state.customer, ...action.customer};
            console.log('new Customer', newCustomer);
            return{
                ...state,
                customer: newCustomer
            }
        case 'RESET_VALIDATION':
            return{
                ...state,
                validation: null
            }
        case 'CLOSE_CONTROL':
            console.log('Closing export control');
            return{
                ...state,                                
                exportStat: {...state.exportStat, 
                control: 'close' },
                validation: null
            }
        case 'CLEAR_CUSTOMER':
            console.log('Clearing customer details');
            return{
                ...state,
                customer: {
                    id: '',
                    customerName: '',
                    customerEmail: '',
                    customerPhone: '',
                    customerAddress: ''
                }
            }     
        default:
            return state;
    }
}

export default customerReducer;