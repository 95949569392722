const initState = {
    data: null,
    exportStat: {
        queued: 0,
        uploaded: 0,
        failed: 0,
        control: 'close'            
    },
    compatibleData: null,
    compatibleViewState: 'close',
    duplicate: [],
    filteredData: {},
    spareStat: {},
    loadData: true,
    pageInfo: {},
    validation: null
}

const sparePartsReducer = (state= initState, action) => {
    switch(action.type){
        case 'SPARE_ERROR':
            console.log('Error', action.err);
            return{
                ...state,
                spareError: action.err.message
            }
        case 'CREATE_SPARE':
            console.log('Created new spare');
            return{
                ...state,
                data: [...state.data, action.sparePart.data],
                spareError: null,
                validation: 'Passed'
            }
        case 'SPARE_DUPLICATE':
            console.log('Duplicate spare', action.spareParts);
            return{
                ...state, 
                duplicate: [...state.duplicate, action.spareParts],
                spareError: 'Spare Part already exist with this SKU'
            }
        case 'EXPORT_SPARE_ERROR':
            console.log('Export spare error', action.err);
            return{
                ...state,                                
                exportStat: {...state.exportStat, 
                    failed: state.exportStat.failed + 1},
                spareError: action.err.message
            }
        case 'EXPORT_SPARES_QUEUED':
            console.log('Total spare parts queued for export', action.queued);
            return{
                ...state,
                duplicate: [],
                exportStat: {...state.exportStat, 
                    queued: action.queued,
                    uploaded: 0,
                    failed: 0,
                    control: 'open'
                }
            }
        case 'EXPORT_SPARE':
            console.log('Exported new spare parts', action.sparePart);
            return{
                ...state,                
                exportStat: {...state.exportStat, 
                    uploaded: state.exportStat.uploaded + 1},
                spareError: null
            }
        case 'EXPORT_SPARE_DUPLICATE':
            console.log('Export failed, duplicate spare', action.sparePart);
            return{
                ...state, 
                duplicate: [...state.duplicate, action.sparePart],                                 
                exportStat: {...state.exportStat, 
                    failed: state.exportStat.failed + 1 },
                spareError: null
            }
        case 'UPDATE_SPARE':
            //console.log('Updated spare parts', action.sparePart);
            if(state.data){
                state.data.forEach((doc) => {
                    if(doc.spId === action.sparePart.Id){
                        doc.sku = action.sparePart.data.sku;
                        doc.modelName = action.sparePart.data.modelName;
                        doc.sparePartName = action.sparePart.data.sparePartName;
                        doc.boardName = action.sparePart.data.boardName;
                        doc.location = action.sparePart.data.location;
                        doc.stockQuantity = action.sparePart.data.stockQuantity;
                        doc.price = action.sparePart.data.price;
                        doc.cost = action.sparePart.data.cost;
                    }
                });
            }
            
            return{
                ...state,
                spareError: null,
                validation: 'Passed'
            }
        case 'LOAD_SPARES':
            console.log('Loading spare parts', action.data);
            return{
                ...state,
                data: action.data.SpareParts,
                pageInfo: {
                    ...state.pageInfo, 
                    currentPage: action.data.CurrentPage,
                    lastPage: action.data.LastPage,
                    totalRecords: action.data.TotalRows
                },
                filteredData: {},
                loadData: false
            }
        case 'FILTER_SPARES':
            console.log('Loading spare parts', action.spareParts);
            return{
                ...state,
                filteredData: action.spareParts.data
            }
        case 'LOAD_ERROR':
            console.log('Error Loading spare parts', action.data);
            return{
                ...state,
                prodcutError: null
            }
        case 'CLOSE_EXPORT_SPARE_WIDGET':
            console.log('Closing export control');
            return{
                ...state,                                
                exportStat: {...state.exportStat, 
                    control: 'close' },
                validation: null
            }   
        case 'SHOW_COMPATIBLE_SPARES':
            return{
                ...state,
                compatibleData: action.data.SpareParts,
                compatibleViewState: 'Open'
            }               
        case 'CLOSE_COMPATIBLE_SPARES':
            console.log('close');
            return{
                ...state,
                compatibleViewState: 'close'
            }            
        case 'RESET_VALIDATION':
            return{
                ...state,
                validation: null
            }
        default:
            return state;
    }
}

export default sparePartsReducer;