import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeControl, AddDelivery, clearMessages } from '../../store/actions/iouActions';
import { CloseButton } from '../../utils/components/buttons/LinkButtons';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import Document from './Document';
import MsgAndDoc from './MsgAndDoc';

let ordered = [];
let products = null;
let productTable = null;
let productTotal = null;
const IOUDelivery = ({iouId}) => {
    const dispatch = useDispatch();    
    const [componentState, setComponentState] = useState({});
    const [purpose, setPurpose] = useState();
    const [period, setPeriod] = useState();
    const [customerName, setCustomerName] = useState();
    const [address, setAddress] = useState();
    const [contactPerson, setContactPerson] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [requested, setrRequested] = useState();
    const [requestedPhone, setRequestedPhone] = useState();
    const [projectName, setProjectName] = useState('');
    const [projectValue, setProjectValue] = useState();
    const [endUser, setEndUser] = useState('');
    const [psm, setPsm] = useState('');
    const [totalAmount, setTotalAmount] = useState();
    const [message, setMessage] = useState();
    const [userMessage, setUserMessage] = useState();
    const [deliveryNoteNumber, setDeliveryNoteNumber] = useState();
    const [uploadFile, setUploadFile] = useState({
        file: null,
        dataURL: null
    });
    // const issuingQty = useRef('');

    useEffect(() => {
        Materialize.AutoInit();
    });

    const handleClose = () => {   
        iouDetails = null;
        products = null;
        productTable = null;
        productTotal = null;
        setComponentState({});
        setUploadFile({
            file: null,
            dataURL: null
        });
        setUserMessage('');
        document.querySelector('#deliveryNoteNumber').value = '';
        document.querySelector('.save').disabled = true;
        
        let items = document.getElementsByClassName('input-qty');
        for (var i=0; i < items.length; i++) {
           items[i].value = '';
        }
        
        dispatch(clearMessages());
        dispatch(closeControl());
    }   
    
    const handleChange = (e) => {

        if( e.target.id === 'userMessage') setUserMessage(e.target.value);
        if( e.target.id === 'deliveryNoteNumber') setDeliveryNoteNumber(e.target.value);
    }

    const handleDeliveryNote = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let fileObj = e.target.files[0];
        if(fileObj !== undefined){
            if(!(fileObj.type === 'application/pdf')){
                Materialize.toast({html: 'Attach document in a pdf format!'})  
                delete e.target.files;
                return false;           
            }else {   
                let reader = new FileReader();
                reader.onloadend = () => {    
                    setUploadFile({
                        file: fileObj,
                        dataURL: reader.result
                    });
                }
                reader.readAsDataURL(fileObj);
            }
        }        
    }

    const profile = useSelector(state => state.firebase.profile);
    const iou = useSelector(state => state.iou);
    let iouDetails = useSelector(state => state.iou.data[iouId]); //Data loaded from dashboard  
    

    useEffect(() => {
        console.log('iouDetails', iouDetails);
        if(iouDetails){
            setComponentState({
                iou: iouDetails
            })
            setPurpose(iouDetails.purpose);
            setPeriod(iouDetails.period);
            setCustomerName(iouDetails.customerName);
            setAddress(iouDetails.applied ? iouDetails.applied.address : null);
            setContactPerson(iouDetails.contactPerson);
            setContactNumber(iouDetails.contactNumber);
            setrRequested(iouDetails.requested ? iouDetails.requested.name : null);
            setRequestedPhone(iouDetails.requested ? iouDetails.requested.phone : null);
            setProjectName(iouDetails.projectName);
            setProjectValue(iouDetails.projectValue);
            setEndUser(iouDetails.endUser);
            setPsm(iouDetails.psm);
            setTotalAmount(iouDetails.totalAmount);
            setMessage(iouDetails.message);            

            
        }  
    },[iouDetails]); 

    const handleIssueItems = (productId, e) => {
        //console.log('Product Issuing', productId, componentState);
        const selectedPro = iouDetails.productList[productId];
        if(selectedPro){
            //console.log('Selected Pro', selectedPro);
            const pro = {data:{}}
            const docData = {
                ...selectedPro,
                issuingQuantity: parseFloat(e.target.value)
            };
            const key = productId;
            pro.data[key] = docData;
            setComponentState({
                ...componentState,
                productList: {...componentState.productList,...pro.data }
            });
        }      
    }

    if(iouDetails){
        /*Create Array list from object collection */
        ordered = [];
        const orderedArray = Object.keys(iouDetails.productList).map((key) => [key, iouDetails.productList[key]]);    
    
        orderedArray.map(item => {    
            ordered.push(item.pop());
            return null;
        });   

        //console.log('ordered',ordered);
        let slNo = 1;
        let totalAmount = 0;
        products = ordered.length ? (       
            ordered.map(product => {
                totalAmount += parseFloat(product.netAmount);
                return(
                    <div className="row bb" key={product.id}>
                        <div className="col s1">
                            <p>{slNo++}</p>
                        </div>
                        <div className="col s3">
                            <p>{product.name}</p>
                        </div>
                        <div className="col s1">
                            <p>{product.quantity}</p>
                        </div>                
                        <div className="col s2">
                            <p className="right">{product.price.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                        </div>                                
                        <div className="col s2">
                            <p className="right">{product.netAmount.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                        </div>                                                
                        <div className="col s1">
                            <p className="center">{product.willReturn}</p>
                        </div>                                                
                        <div className="col s1">
                            <p className="center">{product.deliveredQuantity}</p>
                        </div>                                                
                        <div className="col s1">
                            <input className='input-qty validate' onChange={(e) => {handleIssueItems(product.id, e)}} type="number" max={ product.deliveredQuantity ? product.quantity - product.deliveredQuantity : product.quantity} />
                        </div>
                    </div>
                );                                
            }) 
        ) : null;

        productTotal = ordered.length ? ( 
            <div className="product-total">
                <div className="row bold">
                    <div className="col s1">
                    </div>   
                    <div className="col s8">
                        <p>Total</p>
                    </div>                        
                    <div className="col s2">
                        <p className="right">AED {totalAmount.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                    </div>                                                
                    <div className="col s1">
                    </div>
                </div>
            </div>           
        ) : null;

        productTable = ordered.length ? (  
            <div className="product-list">
                <div className="row bold grey lighten-4 bb">
                    <div className="col s1">
                        <p>Sl No.</p>
                    </div>
                    <div className="col s3">
                        <p>Model No.</p>
                    </div>
                    <div className="col s1">
                        <p>Qty. (Pcs)</p>
                    </div>                
                    <div className="col s2">
                        <p className="right">Unit Price</p>
                    </div>                                
                    <div className="col s2">
                        <p className="right">Total Amount</p>
                    </div>                                                
                    <div className="col s1">
                        <p className="center">Return</p>
                    </div>                                                                         
                    <div className="col s1">
                        <p className="center">Issued</p>
                    </div>                                               
                    <div className="col s1">
                        <p className="center">Issuing Qty</p>
                    </div>
                </div>
                {products}
            </div>
        ) : null;   
    }
    
    const handleValidation = (valid) => {
        //console.log('validation', valid); 
        const saveButton = document.querySelector('.save');
        if(saveButton !== null){
            if(componentState.productList !== undefined) saveButton.disabled = !valid;
        } 
    }

    const handlePdfView = (url) => {

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log("componentState", componentState, deliveryNoteNumber, userMessage, uploadFile);
        const msg = {
            message_at: new Date(),
            messageFrom: profile.firstName + ' ' + profile.lastName,
            message: userMessage
        }
        const userMessages = componentState.iou.messages === undefined ? [msg] : [...componentState.iou.messages, msg];
        
        const deliveryNoteNumbers = componentState.iou.deliveryNoteNumbers === undefined ? [deliveryNoteNumber] : [...componentState.iou.deliveryNoteNumbers, deliveryNoteNumber];
        dispatch(AddDelivery(iouId, componentState, userMessages, deliveryNoteNumber, deliveryNoteNumbers, uploadFile));
    }

    return ( 
        <React.Fragment>
            <div id="message" className={'big message ' + iou.deliveryScreen}>
                <div className="right"><CloseButton  onClick={handleClose} bg='grey lighten-5'/> </div> 
                <div className="modal-content"> 
                    <div className="card z-depth-1 modal-card">                
                        <div className="card-content msg grey-text text-darken-3">
                            <span className="card-title teal-text text-darken-4">IOU Delivery <span className="red-text sub">{purpose} for {period} days</span></span>
                            
                            <div className="row">
                                <div className="col s7">
                                    <div className="box-container">
                                        <div className="box">
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Customer Name</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{customerName}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Address</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{address}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Contact Person</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{contactPerson}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Contact Number</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{contactNumber}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Requested by</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{requested}</p>
                                                </div>
                                            </div>                           
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Mobile</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{requestedPhone}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s5">
                                    <div className="todo">
                                        <div className="box">
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Project Name</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{projectName ? projectName.toUpperCase() : null}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Project Value</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{parseFloat(projectValue).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>End User</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{endUser ? endUser.toUpperCase() :  null}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>PSM</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{psm ? psm.toUpperCase() : null}</p>
                                                </div>
                                            </div>                           
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>IOU Amount.</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{parseFloat(totalAmount).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="row ">
                                                <div className="col s4">
                                                    <p>Message</p>
                                                </div>
                                                <div className="col s8">
                                                    <p>{message}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    {productTable}                            
                                    {productTotal}
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="msg">
                            <div className="row">
                                <div className="col s6">
                                    <div className="input-field">
                                        <label  htmlFor="Message">Message</label>
                                        <textarea value={userMessage} id="userMessage" onChange={handleChange} className="validate materialize-textarea" required></textarea>
                                    </div>
                                    <div className="row">
                                        <div className="col s4">
                                            <div className="input-field">
                                                <label htmlFor="Delivery Note Number">Delivery Note #</label>
                                                <input  id="deliveryNoteNumber" onChange={handleChange} type="text" className='validate all-cap' required/>
                                            </div>
                                            <div className="input-field"> 
                                                <button disabled className='btn orange z-depth-0 save'>Save</button> 
                                            </div>
                                        </div>
                                        <div className="col s8">
                                            <div className="file-field input-field">
                                                <div className="btn">
                                                    <span>Attach Delivery Note</span>
                                                    <input 
                                                    onChange={(e) => {handleDeliveryNote(e)}} 
                                                    type="file" 
                                                    multiple={false}
                                                    /> 
                                                </div>
                                                <div className="file-path-wrapper fl-path">
                                                    <input className="file-path validate" type="text"/>
                                                </div>
                                            </div>
                                            <div className="preivew">
                                                <Document 
                                                message={userMessage}
                                                docNumber={deliveryNoteNumber} 
                                                pdfFile={uploadFile}
                                                onValidated={handleValidation}
                                                />
                                                {/* <PreviewPDF pdfFile={uploadFile}/> */}
                                            </div> 
                                        </div>
                                    </div>   
                                </div> 
                                <div className="col s6">
                                    <MsgAndDoc
                                    messages={iouDetails && iouDetails.messages}
                                    documents={iouDetails && iouDetails.documents}
                                    onPdfView={(url) => {handlePdfView(url)}}                                    
                                    />
                                    <p className='red-text'>{iou.iouError}</p>
                                </div>
                            </div>   
                            
                        </form>
                    </div>
                </div>
            </div>     
              
            <div id="overlay" className={'message-overlay ' + iou.deliveryScreen}></div>
        </React.Fragment>
     );
}
 
export default IOUDelivery;