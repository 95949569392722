import React, { useEffect } from 'react';

import Materialize from 'materialize-css/dist/js/materialize.min.js';

const DeliveryNoteItems = ({data, onServiceChargeChange, totalSC, onTotalSCChange}) => {    
    useEffect(() => {
        Materialize.AutoInit();
    },[]);

     /* Defactor data from props */
    //console.log('products',data);
    const orderedArray = Object.keys(data).map((key) => [key, data[key]]);    
       
    let ordered = [];
    orderedArray.map(item => {    
        ordered.push(item.pop());
        return null;
    }); 

    const handleServiceCharge = (serialNumber, e) => {
        onServiceChargeChange(serialNumber, parseFloat(e.target.value));  
    }

    let slNo = 1;
    let totalAmount = 0;
     const products = ordered ? (
        ordered.map(product => {
            //console.log('product', product);
            totalAmount += parseFloat(product.serviceCharge);
             return(
                <div className="row bb" key={product.id}>
                    <div className="col s1">
                        <p>{slNo++}</p>
                    </div>
                    <div className="col s4">
                        <p>{product.modelNumber}</p>
                    </div>
                    <div className="col s1">
                        <p className="center">1</p>
                    </div>                
                    <div className="col s2">
                        <p>{product.serialNumber}</p>
                    </div>                  
                    <div className="col s2">
                        <p>{product.rmaNo}</p>
                    </div>                    
                    <div className="col s1">
                        <p>{product.rmaDate}</p>
                    </div>                                            
                    <div className="col s1">
                        <input 
                            className='input-qty validate pt-2' 
                            onChange={(e) => {handleServiceCharge(product.serialNumber, e)}} 
                            type="number"
                            defaultValue={product.serviceCharge.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}
                        />
                    </div>
                </div>
             );                                
         }) 
     ) : null;

    const productTotal = ordered ? ( 
        <div className="product-total">
            <div className="row bold">
                <div className="col s1">
                </div>   
                <div className="col s8">
                    <p>Total</p>
                </div>                        
                <div className="col s3">
                    <p className="right">AED {totalAmount.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</p>
                </div> 
            </div>
        </div>           
    ) : null;

    const productTable = ordered ? (  
        <div className="product-list">
            <div className="row bold grey lighten-4 bb">
                <div className="col s1">
                    <p>Sl No.</p>
                </div>
                <div className="col s4">
                    <p>Model No.</p>
                </div>
                <div className="col s1">
                    <p>Qty. (Pcs)</p>
                </div>                
                <div className="col s2">
                    <p>Serial Number</p>
                </div>                                
                <div className="col s2">
                    <p>RMA#</p>
                </div>                                                
                <div className="col s1">
                    <p>RMA Date</p>
                </div>                                                                         
                <div className="col s1">
                    <p className="right">Service Charge</p>
                </div>
            </div>
            {products}
        </div>
    ) : null;  
    
   if(totalSC !== totalAmount){
       onTotalSCChange(totalAmount);
   }

    return ( 
        <div>
            {productTable}                            
            {productTotal}
        </div>       
     );
}
 
export default DeliveryNoteItems;