import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const ResetPassword = ({auth, passwordReset}) => {
    if(auth.uid || !passwordReset) return <Redirect to='/'/>;
    return ( 
        <div className="container auth">
            <div className="row">                   
                <div className="card col s12 m7 l7 offset-m5 offset-l5">
                    <h5 className="grey-text text-darken-3">Reset Password</h5>
                    <p>An email has been sent to your email address to reset password</p>
                    <span>Go to  <NavLink to='/signin'className="red-text text-darken-3">login</NavLink> page.</span>
                    <p></p>
                    <p></p>
                </div>                
            </div>
        </div> 
     );
}

const mapStateToProps = (state) => {
    //console.log(state);
    return{
        auth: state.firebase.auth,
        resetPassword: state.auth.passwordReset
    }
}
 
export default connect(mapStateToProps)(ResetPassword);