import React, { useEffect,  useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signOut } from '../../store/actions/authActions';
import Todo from './Todo';
import Notifications from './Notifications';
import { getIOUDetails, RequestDelivery } from '../../store/actions/iouActions';
import ApprovalRequestSummary from '../iou/ApprovalRequestSummary';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import PDFUpload from '../../utils/components/uploadControls/PDFUpload';
import IOUDelivery from '../iou/IOUDelivery';
import { getUserDetails } from '../../store/actions/userActions';
import { getOrgDetails } from '../../store/actions/orgActions';
import { getUserDivisions } from '../../store/actions/divisionActions';

let iouId = '';
let eudFlag = true;
let orgFlag = true;
const Dashboard = () => {   
    const dispatch = useDispatch();
    const [uploadFile, setUploadFile] = useState({
        file: null,
        dataURL: null
    });
    const [pdfViewerStatus, setPDFViewerStatus] = useState('close');
    const [selectedComponent, setSelectedComponent] = useState({
        id: '',
        component: ''
    });

    useEffect(() =>  {
         Materialize.AutoInit();         
        if(profile.account !== undefined && userDetails === null) dispatch(getUserDetails(auth.uid));
        if(profile.account !== undefined  && org === undefined && orgFlag ){
            orgFlag = false;
            dispatch(getOrgDetails(profile.account));
        } 
        //console.log('userDetails', userDetails);
        if(userDetails !== null && Object.keys(userEnrolledDivisions).length === 0 && eudFlag){
            eudFlag = false;
            dispatch(getUserDivisions(userDetails.id));
        }
    })
    useEffect(() => {
        //console.log('profile', profile);
        if(profile.status === 'deleted') { dispatch(signOut())}
    }) 
    
     /** Handling input file component from Link component */
     const inputFile = useRef(null);

    const handleUploadIOU = (id) => {          
        setSelectedComponent({
            id: id,
            component: 'IOU'
        })
        inputFile.current.click();
    }
 
     /** Resetting the input file component for multiselect */
     const handleImportClick = (e) => {
         e.target.value = null;
     }
 
     /** Input pdf file handling for upload */
     const changeHandler = (e) => {  
        e.stopPropagation();
        e.preventDefault();
        let fileObj = e.target.files[0];
        if(!(fileObj.type === 'application/pdf')){
            Materialize.toast({html: 'Upload document in a pdf format!'})  
            return false;           
        }else {                                       
            setPDFViewerStatus('open');           
            //console.log('upload  file',  fileObj);
            let reader = new FileReader();
            reader.onloadend = () => {    
                setUploadFile({
                    file: fileObj,
                    dataURL: reader.result
                });
            }
            reader.readAsDataURL(fileObj);
        }
     }

    const auth = useSelector(state => state.firebase.auth);
    const profile = useSelector(state => state.firebase.profile);    
    const org = useSelector(state => state.org.data[profile.account]);
    const userDetails = useSelector(state => state.user.loggedInUser);
    const userEnrolledDivisions = useSelector(state => state.division.userDivisions);
    const iouTodos = useSelector(state => state.firestore.ordered.iouTodos);

    if(!auth.uid) return <Redirect to='/signin'/>;  

    const handleShowIOU = (id) => {
        iouId = id;
        setSelectedComponent({
            id: id,
            component: 'IOU'
        })
        dispatch(getIOUDetails(id, 'iou-summary'));
    }

    const handlePDFUploadClose = () => {
        setPDFViewerStatus('close');
    }

    const handleConfirm = (selected) => {
        switch(selected.component){
            case 'IOU':
                dispatch(RequestDelivery(selected.id, uploadFile));
                setPDFViewerStatus('close');
                break;
            default:
                break;
        }
    }

    const handleDeliveryIOU = (id) => {
        iouId = id;
        setSelectedComponent({
            id: id,
            component: 'IOU'
        })
        dispatch(getIOUDetails(id, 'iou-delivery'));
    }
    
    return ( 
        <div className="section dashboard white">
            <ApprovalRequestSummary iouId={iouId}/>
            <IOUDelivery iouId={iouId}/>
            <PDFUpload 
                uploadFile={uploadFile}
                heading="Upload Signed Customer IOU"
                onCloseControl={handlePDFUploadClose}
                pdfViewerStatus ={pdfViewerStatus}
                selectedComponent = {selectedComponent}
                onConfirm={(component) => {handleConfirm(component)}}
            />
            <div className="row">
                <div className="col s12 m4 l4">
                    <input  type="file" 
                            ref={inputFile}
                            style={{display: 'none'}}
                            onChange={changeHandler.bind(this)}
                            onClick={handleImportClick} 
                    />
                   <Todo 
                   onShowIOU={(id) => {handleShowIOU(id)}}
                   onUploadIOU={(id) => {handleUploadIOU(id)}}
                   onDeliveryIOU={(id) => {handleDeliveryIOU(id)}}
                   iouTodoCount={ iouTodos ? iouTodos.length : 0}
                   />
                </div>
                <div className="col s12 m8 l8">
                   <Notifications/>
                </div>
            </div>                      
        </div>
     );
}

export default Dashboard;