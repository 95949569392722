import { GenerateKeywords } from '../../utils/GenerteKeywords';
const collection = 'users';
const account = 'organizations';

export const createUser = (newUser) => {
    return(dispatch, getState, { getFirebase }) => {
        /*Validate data */
       if( newUser.privilege !== '' &&  newUser.privilege !==  undefined){
           console.log('text data', newUser.privilege);
            const profile = getState().firebase.profile;
            const createdBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const keywords = GenerateKeywords(newUser.firstName + ' ' + newUser.lastName);
            const firebase = getFirebase();
            const firestore = firebase.firestore();

            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .where('email', '==', newUser.email)
            .get()
            .then((snapshot) => {
                if(!snapshot.empty){
                    dispatch({type:'VALIDATION_ERROR', err:'Email address already exists'})
                }else{
                    const statRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection('stat')
                    .doc('userStat');

                    const newUserRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection(collection)
                    .doc();

                    firestore.runTransaction(async transaction => {
                        const stat = await transaction.get(statRef);
                        const newIndex = stat.data().count === undefined ? 1 : stat.data().count + 1;
                        const newActive= stat.data().active === undefined ? 1 : stat.data().active + 1;
                        //console.log('new index', newIndex);
                        transaction.update(statRef, {count: newIndex, active: newActive});
        
                        const res = await transaction.set(newUserRef, {
                            ...newUser,
                            index: newIndex,
                            status: 'active',
                            keywords: keywords,
                            creator_id: createdBy,
                            created_at: new Date()
                        });
                        let uid = newIndex;
                        res.md.mutations.map(key => {
                            //console.log('key', key.key.path.segments[3]);
                            if(key.key.path.segments[3] !== 'userStat' ) uid = key.key.path.segments[3];
                            return null;
                        });  
                        
                        const user = {data:{}}
                        const docData = {
                            ...newUser,
                            index: newIndex,
                            status: 'active',
                            id: uid
                        };
                        const key = uid;
                        user.data[key] = docData;
                        dispatch({type: 'CREATE_USER', user})
                    });
                }              
            });

            // firebase.auth().createUserWithEmailAndPassword(
            //     newUser.email,
            //     newUser.password
            // ).then((res) => {
            //     uid = res.user.uid;
            //     return firestore.collection('users').doc(res.user.uid).set({
            //         ...newUser,
            //         initials: newUser.firstName[0] + newUser.lastName[0],
            //         status: 'active',
            //         keywords: keywords,
            //         creatorId: createdBy,
            //         time: new Date()
            //     })       
            // })
            // .then(() => { 
            //     const user = {data:{}}
            //     const docData = {
            //         ...newUser,
            //         status: 'active',
            //         id: uid
            //     };
            //     const key = uid;
            //     user.data[key] = docData;
            //     dispatch({type: 'CREATE_USER', user})
            // }).catch((err) => {
            //     dispatch({type: 'CREATE_USER_ERROR', err})
            // });
       }else{
            dispatch({type:'VALIDATION_ERROR', err:'Choose Privilege'})
       }
        
    }
}

export const updateUser = (usr, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(usr.privilege !== '' &&  usr.privilege !==  undefined){
            const profile = getState().firebase.profile;    
            const modifiedBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const keywords = GenerateKeywords(usr.firstName + ' ' +  usr.lastName);
            const firestore = getFirebase().firestore();

            const userRef = firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc(id);

            try{
                firestore.runTransaction(async transaction => {
                    /** Update auth user profile */
                    const pro = await transaction.get(userRef);
                    if(pro.data().uid !== undefined && pro.data().uid !== ''){
                        const authRef = firestore.collection('users')
                        .doc(pro.data().uid);
    
                        transaction.update(authRef, {
                            firstName: usr.firstName,
                            lastName: usr.lastName,
                            initials: usr.firstName[0] + usr.lastName[0],
                            privilege: usr.privilege
                        })
                    } 
    
                    transaction.update(userRef, {
                        ...usr,
                        status: 'active',
                        keywords: keywords,
                        modifier_id: modifiedBy,
                        modified_at: new Date()
                    });
                });

                const user = {data:{}}
                const docData = {
                    ...usr,
                    status: 'active',
                    id: id
                };
                const key = id;
                user.data[key] = docData;
                dispatch({type: 'UPDATE_USER', user});
            }catch(err){
                dispatch({type: 'UPDATE_USER_ERROR', err});
            }
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'});
        }
    }
}

export const loadUsers = (search, pagination, pageInfo) => {     
    
    return(dispatch, getState, { getFirebase }) => {
        const page = getState().user.pageInfo;
        const loadList = Object.keys(page).length === 0 ? true : page.getUsersList;
        const profile = getState().firebase.profile;
        const userList = getState().user.data;
        if(loadList) dispatch({type: 'PAGE_LOADING'});
        if(loadList || search !== '') {
            const firestore = getFirebase().firestore();
            firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .where('keywords', 'array-contains', search )
            .get()
            .then((snapshot) => { 
                const user = {data:{}, pageInfo:{...pageInfo, getUsersList: false}}
                snapshot.docs.map(doc => {
                    const docData = {
                        ...doc.data(),
                        id: doc.id
                    };
                    const key = doc.id;
                    user.data[key] = docData;
                    return null;
                });
                if(search === '') Object.keys(user.data).length === 0  
                    ? dispatch({type: 'PAGE_ERROR'}) 
                    : dispatch({type: 'LOAD_USERS', user});
                else dispatch({type: 'FILTER_USERS', user});
            }).catch((err) => {
                dispatch({type: 'LOAD_ERROR', err});
            });
        }
        else
        {
            const user = {data:userList, pageInfo:pageInfo}
            dispatch({type: 'LOAD_USERS', user});
        }
    }
} 

export const getUserDetails = (uid) => {
    return(dispatch, getState,{ getFirebase }) => {
        const profile = getState().firebase.profile;
        const firestore = getFirebase().firestore();
        firestore.collection(account)
        .doc(profile.account)
        .collection(collection) 
        .where('uid', '==', uid)
        .get()
        .then((snapshot) => { 
            const user = {data:{}, loggedInUser: null}
            snapshot.docs.map(doc => {
                const docData = {
                    ...doc.data(),
                    id: doc.id
                };
                const key = doc.id;
                user.data[key] = docData;
                user.loggedInUser = docData;
                return null;
            });
            if(Object.keys(user.data).length > 0) dispatch({type: 'GET_USER_DETAILS', user});
        }).catch((err) => {
            dispatch({type: 'LOAD_ERROR', err});
        });
        
    }
} 

export const deleteUser = (usr, id) => {
    return(dispatch, getState, { getFirebase }) => {
         /*Validate data */
        if(id !== ''){
            const profile = getState().firebase.profile;
            const modifiedBy = getState().firebase.auth.uid;
            /*Make async call to database */
            const firebase = getFirebase();
            const firestore = getFirebase().firestore();
            let proStatus = '';

            const userRef = firestore.collection(account)
            .doc(profile.account)
            .collection(collection)
            .doc(id);

            try{
                firestore.runTransaction(async transaction => {
                    /** Update auth user profile */
                    const pro = await transaction.get(userRef);
                    if(pro.data().uid !== undefined && pro.data().uid !== ''){
                        proStatus = pro.data().status;
                        const authRef = firestore.collection('users')
                        .doc(pro.data().uid);
    
                        transaction.update(authRef, { status: 'deleted' })
                    } 
    
                    transaction.update(userRef, {
                        status: 'deleted',
                        modifier_id: modifiedBy,
                        modified_at: new Date()
                    });                    
                });

                if(proStatus !== 'deleted'){
                    const statRef = firestore.collection(account)
                    .doc(profile.account)
                    .collection('stat')
                    .doc('userStat');
                    const increment = firebase.firestore.FieldValue.increment(1);
                    statRef.update({inactive: increment});
                }

                const user = {data:{}}
                const docData = {
                    ...usr,
                    status: 'deleted',
                    id: id
                };
                const key = id;
                user.data[key] = docData;
                dispatch({type: 'DELETE_USER', user})
            }catch(err){
                dispatch({type: 'DELETE_USER_ERROR', err});
            }
        }else{
            dispatch({type:'VALIDATION_ERROR', err:'Invalid data'})
        }
    }
}

export const resetValidation = () => {
    return(dispatch, getState) => {
        /*Do not dispatch action if validation is null */
        const validation = getState().user.validation;
        if(validation) dispatch({type: 'RESET_VALIDATION'})
    }
}