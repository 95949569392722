import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { getFirebase } from 'react-redux-firebase'; 
import axios from 'axios';
import {Signature} from '../../../utils/GenerateSignature';

class AutoComplete extends Component {
    state = { 
        selectedTag: []
     }    
   
     
    loadOptions = async (inputValue) => {  
        const { account, collection, search, condition='', clearSearchParam = true, api = '' } = this.props;
        const firestore = getFirebase().firestore();
        const searchWord = inputValue;
        const tag = {
            value: '0',
            label: searchWord
        }
        inputValue = inputValue.toLowerCase().replace(/\W/g, "");
        let recommendedTags = [];
        
        recommendedTags.push(tag);
        return new Promise((resolve => {
            if(api !== ''){
                //console.log('api search',api);
                let url = '';
                //const urlPrefix = `http://localhost/www/htl/rma/`;
                const urlPrefix = 'https://rma.onlinecart.ae/';
                if(api === 'rma'){
                    url = urlPrefix + 'core-rma-api.php/';
                }
                
                // Hash encode data 
                const payload = {
                    api: 'apiSearch',
                    account: account
                }
                const encoded_hash = Signature(JSON.stringify(payload));
                
                const axiosAPI = axios.create({
                    baseURL: url,
                    headers: {
                        'X-HTL-CORE-HMAC-SHA256': encoded_hash
                    }
                });

                if(collection === 'spareparts'){     
                    //console.log('searchField', inputValue);   
                    let query =  '';
                    switch(search) {
                        case 'spareParts':
                            query = 'searchSpareParts'
                            break;
                        case 'compatibleParts':
                            query = 'searchParts'
                            break;
                        case 'sparePartsList':
                            query = 'getSparePartsList'
                            break;
                        default:
                            query = 'searchSpareParts'
                            break;

                    }       
                    //console.log('condition', condition);
                    if(account !== undefined){ 
                        axiosAPI.get('/',{
                            params: {
                                query: query,
                                account: account,
                                searchField: 'sparePartName',
                                searchWord: searchWord,
                                condition: condition
                            }
                        })
                        .then(res => {
                            console.log(res);
                            if (res.data.Components) {
                                recommendedTags = [];
                                res.data.Components.forEach(function (doc) {
                                    const tag = {
                                        value: doc.sku,
                                        label: doc.sparePartName,
                                        token: doc.token
                                    }
                                    recommendedTags.push(tag);
                                });
                                return resolve(recommendedTags);
                            } else {
                                if(clearSearchParam) return resolve([]);
                                else return resolve(recommendedTags);
                            }
                        })
                    } 
                }else if(collection === 'products'){     
                    //console.log('searchField', inputValue);   
                    let query =  '';
                    switch(search) {
                        case 'productList':
                            query = 'getProductList'
                            break;
                        default:
                            query = 'searchProducts'
                            break;

                    }       
                    //console.log('condition', condition);
                    if(account !== undefined){ 
                        axiosAPI.get('/',{
                            params: {
                                query: query,
                                account: account,
                                searchField: 'productName',
                                searchWord: searchWord,
                                condition: condition
                            }
                        })
                        .then(res => {
                            console.log(res);
                            if (res.data.Products) {
                                recommendedTags = [];
                                res.data.Products.forEach(function (doc) {
                                    const tag = {
                                        value: doc.sku,
                                        label: doc.productName
                                    }
                                    recommendedTags.push(tag);
                                });
                                return resolve(recommendedTags);
                            } else {
                                if(clearSearchParam) return resolve([]);
                                else return resolve(recommendedTags);
                            }
                        })
                    } 
                }
            }else if(collection === 'organizations'){
                firestore.collection(collection)
                .where("keywords", "array-contains", inputValue)
                .get()
                .then(docs => {
                    if (!docs.empty) {
                        recommendedTags = [];
                        docs.forEach(function (doc) {
                            const tag = {
                                value: doc.id,
                                label: doc.data()[search]
                            }
                            recommendedTags.push(tag);
                        });
                        return resolve(recommendedTags);
                    } else {
                        if(clearSearchParam) return resolve([]);
                        else return resolve(recommendedTags);
                    }
                })

            }else  if(collection === 'users'){
                firestore.collection(collection)
                .get()
                .then(docs => {
                    if (!docs.empty) {
                        recommendedTags = [];
                        docs.forEach(function (doc) {
                            const tag = {
                                value: doc.id,
                                label: doc.data()[search]
                            }
                            recommendedTags.push(tag);
                        });
                        return resolve(recommendedTags);
                    }else {
                        if(clearSearchParam) return resolve([]);
                        else return resolve(recommendedTags);
                    }
                })

            }else if(collection === 'customerCode'){
                //console.log('fetching');
                firestore.collection('organizations')
                .doc(account).collection('customers')
                .where("keywords", "array-contains", inputValue)                    
                .limit(5)
                .get()
                .then(docs => {
                    if (!docs.empty) {
                        recommendedTags = [];
                        docs.forEach(function (doc) {
                            const tag = {
                                value: doc.data().code,
                                label: doc.data()[search]
                            }
                            recommendedTags.push(tag);
                        });
                        return resolve(recommendedTags);
                    }else {
                        if(clearSearchParam) return resolve([]);
                        else return resolve(recommendedTags);
                    }
                })
            }else{
                //console.log('fetching');
                firestore.collection('organizations')
                .doc(account).collection(collection)
                .where("keywords", "array-contains", inputValue)                    
                .limit(5)
                .get()
                .then(docs => {
                    if (!docs.empty) {
                        recommendedTags = [];
                        docs.forEach(function (doc) {
                            const tag = {
                                value: doc.id,
                                label: doc.data()[search]
                            }
                            recommendedTags.push(tag);
                        });
                        return resolve(recommendedTags);
                    } else { 
                        if(clearSearchParam) return resolve([]);
                        else return resolve(recommendedTags);
                    }
                })
            }
        }))
    }

    promiseOptions = (inputValue) => 
        new Promise(resolve => {
            setTimeout(() => {
            resolve(this.loadOptions(inputValue));
            }, 500);    
        });

    handleOnChange = (tags) => {
        this.setState({
            selectedTag: []
        })
        this.props.onSelected(tags);
    }

    render() {         
        const { placeholder, defaultInputValue, value, multiSelect = false, defaultValue, keepCache = true } = this.props; 
        let customStyles = {};

        customStyles = multiSelect ? {
            control: (provided, state) => ({
              ...provided,
              background: '#fff',
              borderColor: '#9e9e9e',
              minHeight: '40px',
              boxShadow: state.isFocused ? null : null,
            }),
        
            valueContainer: (provided, state) => ({
              ...provided,
              padding: '0 6px'
            }),
        
            input: (provided, state) => ({
              ...provided,
              margin: '0px',
            }),
            indicatorSeparator: state => ({
              display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '40px',
            }),
          } : 
          {
            control: (provided, state) => ({
              ...provided,
              background: '#fff',
              borderColor: '#9e9e9e',
              minHeight: '40px',
              height: '40px',
              boxShadow: state.isFocused ? null : null,
            }),
        
            valueContainer: (provided, state) => ({
              ...provided,
              height: '40px',
              padding: '0 6px'
            }),
        
            input: (provided, state) => ({
              ...provided,
              margin: '0px',
            }),
            indicatorSeparator: state => ({
              display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '40px',
            }),
          }; 
        return ( 
            <div>
                <AsyncSelect
                    cacheOptions={keepCache}
                    defaultOptions
                    loadOptions={this.promiseOptions}
                    onChange={this.handleOnChange}                    
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    defaultInputValue={defaultInputValue}
                    styles={customStyles}
                    value={value}
                    isMulti = {multiSelect}
                    hasValue
                />
            </div>
         );
    }
}
 
export default AutoComplete;