import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDDO-gu5fj6WLJq-mZj0mrzh0HFnMSnbhA",
    authDomain: "htl-core.firebaseapp.com",
    databaseURL: "https://htl-core.firebaseio.com",
    projectId: "htl-core",
    storageBucket: "htl-core.appspot.com",
    messagingSenderId: "60161376291",
    appId: "1:60161376291:web:c273b982ca39c78f497196",
    measurementId: "G-F6C00YKB4G"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);  
//   firebase.analytics();

  export default firebase;