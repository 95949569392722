import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Materialize from 'materialize-css/dist/js/materialize.min.js';
import AutoComplete from '../../utils/components/autoComplete/AutoComplete';
import { getCustomerDetails } from '../../store/actions/customerActions';
import { getOrgDetails } from '../../store/actions/orgActions';
import { getUserDetails } from '../../store/actions/userActions';
import { getProductDetails } from '../../store/actions/productActions';
import { getUserDivisions } from '../../store/actions/divisionActions';
import IOUSummary from './IOUSummary';
import { CloseLinkButton } from '../../utils/components/buttons/LinkButtons';
import { deleteIOU, createIOUDraft, RequestDivisionalApproval } from '../../store/actions/iouActions';
import PDFViewer from '../../utils/PDFViewer';

let productId = '';
let udFlag = true;
let eudFlag = true;
let orgFlag = true;
let totalAmount = 0;
const IOUDetails = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        Materialize.AutoInit();
        if(profile.account !== undefined && userDetails === null) dispatch(getUserDetails(auth.uid));
        if(profile.account !== undefined  && (org === undefined || orgFlag) ){
            orgFlag = false;
            dispatch(getOrgDetails(profile.account));
        } 
        //console.log('msg',userEnrolledDivisions,eudFlag);
        if(userDetails !== null && Object.keys(userEnrolledDivisions).length === 0 && eudFlag){
            eudFlag = false;
            dispatch(getUserDivisions(userDetails.id));
        } 
    })

    const [componentState, setComponentState] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const [itemName, setItemName] = useState();
    const div = useRef(null);    

    /*Use redux hook to get global store */
    const auth = useSelector(state => state.firebase.auth);     
    const validation = useSelector(state => state.iou.validation); 
    const iouError = useSelector(state => state.iou.iouError); 
    const profile = useSelector(state => state.firebase.profile); 
    const customer = useSelector(state => state.customer.data);
    const product = useSelector(state => state.product.data);
    const customerDetails = useSelector(state => state.customer.data[componentState.customerId]);
    const userDetails = useSelector(state => state.user.loggedInUser);
    const userEnrolledDivisions = useSelector(state => state.division.userDivisions);
    const org = useSelector(state => state.org.data[profile.account]);    
    const iou_ = useSelector(state => state.iou.data[props.match.params.id]);  
    const iouFiltered = useSelector(state => state.iou.filteredData[props.match.params.id]);
    const iou = iou_ !== undefined ? iou_ : iouFiltered; 
    
    console.log('iou',iou);
    const [pdfURL, setPdfURL] = useState();
    const [pdfViewStatus, setPdfViewStatus] = useState('close');
    
    useEffect(() => {
        if(iou){
            udFlag = true;
            setComponentState({
                id: iou.id,
                index: iou.index,
                date: iou.date,
                customerName: iou.customerName,
                customerId: iou.customerId,
                contactPerson: iou.contactPerson,
                contactNumber: iou.contactNumber,
                purpose: iou.purpose,
                period: iou.period,
                projectName: iou.projectName,
                projectValue: iou.projectValue,
                endUser: iou.endUser,
                psm: iou.psm,
                orderExpected: iou.orderExpected,
                message: iou.message ? iou.message : '',
                requestedBy: iou.requestedBy ? iou.requestedBy : '',
                requestedDivision: iou.requestedDivision ? iou.requestedDivision : '',
                organizationalApproval: iou.organizationalApproval ? iou.organizationalApproval : '',
                organizationalApproval_at: iou.organizationalApproval_at ? iou.organizationalApproval_at : '',
                requested: iou.requested ? iou.requested : '',
                productList: iou.productList,
                status: iou.status
            });            
        }
    },[iou]); 

    /*User authentication and redirect checks  */
    if(!auth.uid) return <Redirect to='/signin'/>;
    if(validation === 'Passed') return <Redirect to='/iou'/>;

   /*Create Array of user enrolled divisions from object collection userEnrolledDivisions */
   const orderedUEDArray = Object.keys(userEnrolledDivisions).map((key) => [key, userEnrolledDivisions[key]]);    
      
   let orderedUED = [];
   orderedUEDArray.map(item => {    
       orderedUED.push(item.pop());
       return null;
   });       

   /*Filter out deleted divisions */
   orderedUED = orderedUED.filter(item => {
       return item.status !== 'deleted'
   });

   const userEDList = orderedUED.length ? (
       orderedUED.map(div => {
           return(
               <option key={div.id} value={div.id}>{div.name}</option>
           )     
       })
              
   ) : (        
       null
   );

    /** Handle default values in controls */
    let selectedCustomer = '';
    let selectedPurpose ='';
    if(iou){
        selectedCustomer = iou.customerName;
        selectedPurpose = iou.purpose        
        if(udFlag){            
            udFlag = false;
            dispatch(getCustomerDetails(iou.customerId));
            if(Object.keys(userEnrolledDivisions).length === 0) eudFlag = true;
        } 
    } 

    const handleChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.id]: e.target.value.trim()
        })
    }

    const handleQuantityChange = (e) => {
        //console.log('componentState',componentState);
        const selectedPro = componentState.productList[productId];
        if(selectedPro){
            const netAmount = selectedPro.price === 0 ? 0 : (selectedPro.price * e.target.value) + (selectedPro.quantity * e.target.value) * (0.01 * selectedPro.taxPer);
            const pro = {data:{}}
            const docData = {
                ...selectedPro,
                quantity: e.target.value,            
                netAmount: netAmount
            };
            const key = productId;
            pro.data[key] = docData;
            setComponentState({
                ...componentState,
                productList: {...componentState.productList,...pro.data }
            });
            setQuantity(e.target.value);
        }        
    }

    const handlePriceChange = (e) => {
        //console.log('componentState',componentState);
        const selectedPro = componentState.productList[productId];
        if(selectedPro){
            const netAmount = selectedPro.quantity === 0 ? 0 : (selectedPro.quantity * e.target.value) + (selectedPro.quantity * e.target.value) * (0.01 * selectedPro.taxPer);
            const pro = {data:{}}
            const docData = {
                ...selectedPro,
                price: parseFloat(e.target.value),
                netAmount: netAmount
            };
            const key = productId;
            pro.data[key] = docData;
            setComponentState({
                ...componentState,
                productList: {...componentState.productList,...pro.data }
            });
            setPrice(e.target.value);
        }        
    }

    const handleCustomerSelection = (tags) => {
        //console.log(componentState);
        setComponentState({
            ...componentState,
            customerId: tags.value,
            customerName: tags.label
        });
        /** Fetch data if not exist */    
        !customer[tags.value] && dispatch(getCustomerDetails(tags.value));
    }

    const handleProductSelection = (tags) => {               
        productId = tags.value;
        
        if(!componentState.productList){
            //console.log('componentState',componentState);
            const pro = {data:{}}
            const docData = {
                id: tags.value,
                name: tags.label,
                quantity: 1,
                price: 0,
                taxPer: 5,
                netAmount: 0,
                willReturn: 'Yes'
            };
            const key = tags.value;
            pro.data[key] = docData;

            setComponentState({
                ...componentState,
                productList: {...componentState.productList,...pro.data }
            });
            setQuantity(1);
            setPrice(0);
        }else{
            if(!componentState.productList[tags.value]){
                const pro = {data:{}}
                const docData = {
                    id: tags.value,
                    name: tags.label,
                    quantity: 1,
                    price: 0,
                    taxPer: 5,
                    netAmount: 0,
                    willReturn: 'Yes'
                };
                const key = tags.value;
                pro.data[key] = docData;

                setComponentState({
                    ...componentState,
                    productList: {...componentState.productList,...pro.data }
                });
                setQuantity(1);
                setPrice(0);
            }else{
                const pro = componentState.productList[tags.value];
                setQuantity(pro.quantity);
                setPrice(pro.price);
            }            
        }

        !product[tags.value] && dispatch(getProductDetails(tags.value));
    }

    const handlePurposeSelection = (e) => {
        setComponentState({
            ...componentState,
            purpose: e.target.value
        });
    }

    const handleReturnSelection = (e) => {
        setComponentState({
            ...componentState,
            willReturn: e.target.value
        });
    }

    const handleItemDelete = (id) => {
        delete componentState.productList[id];        
    }

    const handleItemSelected = (id) => {
        productId = id;
        const pro = componentState.productList[id];
        setPrice(pro.price.toString());
        setQuantity(pro.quantity.toString());
        setItemName({
            value: id, 
            label: pro.name
        });
    }   

    const handleApprovalDivision = (e) => {
        //console.log('componentState', componentState);       
        //setRequestedDiv(div.current.value);
        const division = userEnrolledDivisions[div.current.value];
        const requested = {
            id: userDetails.id,
            name: userDetails.firstName + ' ' + userDetails.lastName,
            email: userDetails.email,
            phone: userDetails.mobileNumber,
            designation: userDetails.designation,
            division: division.id
        }

        const applied = {
            id: customerDetails.id,
            code: customerDetails.code,
            name: customerDetails.name,
            address: customerDetails.address,
            city: customerDetails.city,
            email: userDetails.email === undefined ? '' : userDetails.email,
            phone: userDetails.phone === undefined ? '' : userDetails.phone,
            contact: userDetails.contact === undefined ? '' : userDetails.contact,
            contactEmail: userDetails.contactEmail === undefined ? '' : userDetails.contactEmail
        }
        setComponentState({
            ...componentState,
            requested: requested,
            applied: applied,
            requestedBy: userDetails.id,
            requestedDivision: division.id,
            divisionalApprovalFrom: division.divisionalApprovalFrom,
            organizationalApprovalFrom: division.organizationalApprovalFrom,
            divisionalApproval: '',
            organizationalApproval: '',
            totalAmount: totalAmount,
            status: 'Draft'
        });
    }

    const handleValidation = (valid, iouAmount) => {
        //console.log('validation', valid); 
        if(div.current){
            totalAmount = iouAmount;
            //console.log('div.current.value', div.current.value); 
            div.current.disabled = !valid;
            const approvalButton = document.querySelector('.approval');
            const draftButton = document.querySelector('.draft');
            if(div.current.value === ''){
                approvalButton.disabled = true;
                draftButton.disabled = true;
            }else if(iou && (iou.status === 'Draft' || iou.status === 'Rejected' || iou.status === '')) {
                approvalButton.disabled = !valid;
                draftButton.disabled = !valid;
            }
        }
    }

    const handleSaveDraft = (e) => {
        e.preventDefault();
                
        //console.log('Save Draft',componentState);
        dispatch(createIOUDraft(componentState));
    }

    const handleRequestApporval = (e) => {
        e.preventDefault();
                
        //console.log('Request Approval',componentState);
        dispatch(RequestDivisionalApproval(componentState));
    }

    const handleDelete = (id) => {
        //console.log('delete', componentState, id);
        dispatch(deleteIOU(componentState,id));
    }

    const handlePdfView = (url) => {
        setPdfViewStatus('open');        
        setPdfURL(url);
    } 

    const handlePdfViewClose = () => {
        setPdfViewStatus('close');        
        setPdfURL('');
    }

    const editTool = iou && (iou.status === 'Draft' || iou.status === 'Rejected' || iou.status === '') ? 'open' : 'close';

    // const del = iou && (iou.status === 'Draft' || iou.status === 'Rejected') ? (
    //     <DeleteLinkButton to='#' onClick={(id) => {handleDelete(componentState.id)}}/>
    // ) : null;

    return ( 
        <div className="section iou">
            
            <PDFViewer
                pdfURL={pdfURL}
                viewStatus={pdfViewStatus}
                onClose={() => handlePdfViewClose()}
            />
            <form  className="white">
                <div className="row">
                    <div className="col s10">
                        <h5 className="red-text text-lighten-1">IOU Form</h5>
                    </div>
                    <div className="col s2 btn-gr">
                        <CloseLinkButton to='/iou'/>
                        {/* <Link to='/iou' className="btn white black-text z-depth-0 btn-close-mr0"><i className="material-icons">close</i></Link> */}
                    </div>
                </div>
                <br />
                <div className="row iou-sum">
                    <div className={'col s12 m4 l4 iou-edit-tool ' + editTool}>
                        <div className="col s12 input-field">
                            <label htmlFor="Customer">Customer</label>
                            <div className="input-field">
                                <AutoComplete 
                                    onSelected={handleCustomerSelection} 
                                    collection={'customers'} search={'name'} account={profile.account}
                                    placeholder={'Select customer'}
                                    defaultInputValue={selectedCustomer}
                                    />
                            </div>
                        </div>
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Contact Person">Contact Person</label>
                            <input id="contactPerson" defaultValue={componentState.contactPerson} onChange={handleChange} type="text" className="validate" required/>
                        </div>                     
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Contact Number">Contact Number</label>
                            <input id="contactNumber" defaultValue={componentState.contactNumber} onChange={handleChange} type="text" className="validate" required/>
                        </div>                     
                        <div className="col s12">
                            <div className="row">
                                <div className="col s6 input-field">
                                    <label className='active' htmlFor="Purpose">Purpose</label>
                                    <select onChange={handlePurposeSelection} defaultValue={selectedPurpose} id="purpose">
                                        <option value="" disabled>Select purpose</option>
                                        <option value="POC">POC</option>
                                        <option value="Testing">Testing</option>
                                        <option value="Display">Display</option>
                                    </select> 
                                </div>
                                <div className="col s6 input-field">
                                    <label className='active' htmlFor="Period">Period</label>
                                    <input id="period" defaultValue={componentState.period} onChange={handleChange} type="number" className="validate" required/>
                                </div>
                            </div>
                           
                        </div>                     
                        <div className="col s12 input-field">
                            <label htmlFor="Product">Product</label>
                            <div className="input-field">
                                <AutoComplete 
                                    onSelected={handleProductSelection} 
                                    collection={'products'} search={'name'} account={profile.account}
                                    placeholder={'Select product'}
                                    value={itemName}
                                />
                            </div>
                        </div> 
                        <div className="col s12">
                            <div className="row">
                                <div className="col s4 input-field">
                                    <label className="active" htmlFor="Quantity">Quantity</label>
                                    <input id="quantity" onChange={handleQuantityChange} type="number" value={quantity} className="validate" required/>
                                </div>
                                <div className="col s4 input-field">
                                    <label className="active" htmlFor="Unit Price">Unit Price</label>
                                    <input id="unitPrice" onChange={handlePriceChange} type="number" value={price.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2}) } className="validate" required/>
                                </div>
                                <div className="col s4 input-field">
                                    <label className="active" htmlFor="Return">Will Return</label>
                                    <select onChange={handleReturnSelection} defaultValue="Yes" id="willReturn">
                                        <option value="" disabled>Choose option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> 
                                </div>
                            </div>
                           
                        </div> 
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Project Name">Project Name</label>
                            <input id="projectName" defaultValue={componentState.projectName} onChange={handleChange} type="text" className="validate all-cap" required/>
                        </div>                     
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Project Value">Project Value</label>
                            <input id="projectValue" defaultValue={componentState.projectValue} onChange={handleChange} type="number" className="validate" required/>
                        </div>                      
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="End User">End User</label>
                            <input id="endUser" defaultValue={componentState.endUser} onChange={handleChange} type="text" className="validate all-cap" required/>
                        </div>                                                
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="PSM">PSM</label>
                            <input id="psm" defaultValue={componentState.psm} onChange={handleChange} type="text" className="validate all-cap" required/>
                        </div> 
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Order Expected">Order Expected</label>
                            <input id="orderExpected" defaultValue={componentState.orderExpected} onChange={handleChange} type="text" className="validate all-cap" required/>
                        </div> 
                        <div className="col s12 input-field">
                            <label className='active' htmlFor="Message">Message</label>
                            <textarea id="message" defaultValue={componentState.message} onChange={handleChange} className="validate materialize-textarea" required></textarea>
                        </div> 
                        <div className="col s12">
                            <div className="input-field">
                                <label className="active" htmlFor="Division">Request approval from</label>
                                <select ref={div} onChange={handleApprovalDivision} defaultValue='' disabled id="division">
                                    <option value="" disabled>Select division</option>
                                    {userEDList}
                                </select> 
                            </div>
                            <div className="input-field"> 
                                <div className="row">
                                    <div className="col s8">
                                        <button onClick={handleRequestApporval} className={'btn orange z-depth-0 approval'}>Request Approval</button> 
                                    </div>
                                    <div className="col s4">
                                        <button onClick={handleSaveDraft} className={'btn red lighten-1 z-depth-0 right draft'}>Save Draft</button>
                                    </div>
                                </div>  
                            </div>
                            <div className="input-field">                                                          
                                <div className="red-text center">
                                    {iouError ? <p>{iouError}</p> : validation ? <p>{validation}</p> : null}
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <IOUSummary 
                    customer={customerDetails} 
                    iou={componentState}
                    messages={iou && iou.messages}
                    documents={iou && iou.documents}
                    organization={org}
                    profile={userDetails}
                    onItemDelete={(id) => {handleItemDelete(id)}}
                    onItemSelected={(id) => {handleItemSelected(id)}}
                    onValidated={(valid, iouAmount) => {handleValidation(valid, iouAmount)}}
                    onPdfView={(pdfURL) => {handlePdfView(pdfURL)}}
                    onDelete={(id) => {handleDelete(id)}}
                    />                  
                    
                </div>
            </form>
        </div>
     );
}
 
export default IOUDetails;