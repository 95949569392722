import React from 'react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export const SettingsLinkButton = ({to}) => {
    return ( 
        <Link to={to} className="btn tooltipped white black-text z-depth-0 btn-close-mr0" data-position="bottom" data-tooltip="Settings"><i className="material-icons">settings</i></Link>
     )
}

export const DownloadLinkButton = ({href='#', tooltip = 'Download', bg='white'}) => {
    return(
        <a href={href} target="_blank" rel="noopener noreferrer" className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip={tooltip}><i className="material-icons">file_download</i></a>
    )
}

export const UploadLinkButton = ({to='#', tooltip = 'Upload', bg='white', onClick}) => {
    const handleOnClick = () => {
        onClick();
    }
    return(
        <Link onClick={handleOnClick} to={to} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip={tooltip}><i className="material-icons">file_upload</i></Link>
    )
}

export const CloseButton = ({to='#', bg='white', onClick}) => {
    const handleOnClick = () => {
        onClick();
    }
    return(
        <Link onClick={handleOnClick} to={to} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip="Close"><i className="material-icons">close</i></Link>
    )
}

export const CloseLinkButton = ({to, bg='white'}) => {
    return(
        <Link to={to} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip="Close"><i className="material-icons">close</i></Link>
    )
}

export const EditLinkButton = ({to , bg='white'}) => {
    return(
        <Link to={to} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip="Edit"><i className="material-icons">edit</i></Link>
        // <Link to={to} className="btn tooltipped white black-text z-depth-0 btn-close-mr0" data-position="bottom" data-tooltip="Close"><i className="material-icons">close</i></Link>
    )
}

export const ViewLinkButton = ({to , bg='white'}) => {
    return(
        <Link to={to} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip="View"><i className="material-icons grey-text text-darken-2">visibility</i></Link>
    )
}

export const ViewButton = ({to='#', tooltip = 'View', bg='white', onClick}) => {
    const handleOnClick = () => {
        onClick();
    }
    return(
        <Link onClick={handleOnClick} to={to} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip={tooltip}><i className="material-icons  grey-text text-darken-2">visibility</i></Link>
    )
}

export const NextLinkButton = ({to, tooltip = 'Next', onClick}) => {
    const handleOnClick = () => {
        onClick();
    }
    return(
        <Link onClick={handleOnClick} to={to} className="btn tooltipped white black-text z-depth-0 btn-close-mr0" data-position="bottom" data-tooltip={tooltip}><i className="material-icons white grey-text text-darken-2">navigate_next</i></Link>
    )
}

export const DeleteLinkButton = ({to='#', onClick}) => {
    const handleOnClick = () => {
        onClick();
    }
    return(
        <Link to={to} onClick={           
            //(e) => { if (window.confirm('Are you sure you wish to delete?'))  handleOnClick() } 
            () => {
                confirmAlert({
                  title: 'Delete Item!',
                  message: 'Are you sure?.',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => handleOnClick()
                    },
                    {
                      label: 'No',
                      onClick: () => console.log('cancelled')
                    }
                  ]
                });
              }
        } className="btn tooltipped white black-text z-depth-0 btn-close-mr0" data-position="bottom" data-tooltip="Delete"><i className="material-icons white grey-text text-darken-2">delete</i></Link>
        // <Link to='#' onClick={() => dispatch(deleteDivision(division, division.id))} className="btn-flat"><i className="material-icons white grey-text text-darken-2">delete</i></Link>
    )
}

export const PrintButton = ({to='#', bg='white', onClick}) => {
    const handleOnClick = () => {
        onClick();
    }
    return(
        <Link onClick={handleOnClick} to={to} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip="Print"><i className="material-icons">print</i></Link>
    )
}

export const AddButton = ({to='#', bg='white',  tooltip='Add'}) => {
    
    return(
        <Link to={to} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip={tooltip}><i className="material-icons">add_box</i></Link>
    )
}

export const CollapseButton = ({bg='white', onClick,  tooltip='Expand', icon = 'expand_more'}) => {
   
    const handleOnClick = () => {
        onClick();
    }
    return(
        <Link to="#" onClick={handleOnClick} className={'btn tooltipped black-text z-depth-0 btn-close-mr0 ' + bg} data-position="bottom" data-tooltip={tooltip}><i className="material-icons">{icon}</i></Link>
    )
}

